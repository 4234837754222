import React, { useState, useEffect, useRef } from "react";
// import Messenger from "../../../src/images/messenger.png";
import Instagram from "../../../src/images/Instagramicons.png";
import Whatsapp from "../../../src/images/Whatsappicons.png";
import Twitter from "../../../src/images/twittericons.png";
import Youtube from "../../../src/images/Youtube icons.png";
import facebook1 from "../../../src/images/fbicons.png";
import gnmailicon from "../../../src/images/gmailicons.png";
import phoneicon from "../../../src/images/phone_icon.png";
import drArchikadidiLogo from "../../../src/images/newlogo.png";
import applelogo from "../../../src/images/apple.png";
import andrroidlogo from "../../../src/images/adroidicons.png";
import { getContactUs } from "../../Allslices/ContactUsSlice";
import { useSelector, useDispatch } from "react-redux";
import FixMusicPlayer from "../../maincontent/FixMusicPlayer";
import { Modal } from "react-bootstrap";
import locationicon from "../../../src/images/locationicons.png";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getmyOrder } from "../../Allslices/orderSlice";
import { getAllCourses } from "../../Allslices/courseSlice";
import { getwisdomList } from "../../Allslices/wisdomSlice";
import { createSubcription } from "../../Allslices/subcription";
import Slider from "react-slick";
import { IoCloseSharp, IoLockOpen } from "react-icons/io5";

const Footer = () => {
  const dispatch = useDispatch();
  const { contact } = useSelector((state) => state.contactUs);
  useEffect(() => {
    dispatch(getContactUs());
  }, []);
  const navigate = useNavigate();

  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const { subscription } = useSelector((state) => state.subscription);

  const handleSubscription = async (e) => {
    e.preventDefault();
    dispatch(createSubcription({ name: username, email }));
    // SHOW_SUCCESS_NOTIFICATION("Subscription successfully")

    setUserName("");
    setEmail("");
  };

  const { courselist } = useSelector((state) => state.course);
  const { wisdom } = useSelector((state) => state.wisdom);
  const [showModal, setShowModal] = useState(false);

  const handleCloseCommunityModal = () => setShowModal(false);
  const handleShowCommunityModal = () => setShowModal(true);

  const handleNavigateToWisdomSubpage = (slug) => {
    navigate(`/wisdom/${slug}`);
  };
  const handleNavigateToCourse = (slug) => {
    navigate(`/courses/${slug}`);
    window.location.reload();
  };
  const { orderAll } = useSelector((state) => state?.order);
  let showCommunity = false;
  orderAll.map((item) => { if (item?.orderItems?.price > 0) { showCommunity = true } })

  useEffect(() => {
    dispatch(getAllCourses({ page: 1 }));
    dispatch(getwisdomList({ page: 1 }));
    dispatch(getmyOrder());
  }, []);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();
  const currentPath = location.pathname;

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    // centerMode: true,
    // centerPadding: "20%",
    autoplaySpeed: 1500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderSettings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    // centerMode: true,
    // centerPadding: "20%",
    autoplaySpeed: 1500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  console.log("The CourseList of Footer is:", courselist.slice(0, 6));

  let sliderRef1 = useRef();
  let sliderRef2 = useRef();
  const wisdomLists = wisdom;
  // const [randomItem, setRandomItem] = useState(null);

  // useEffect(() => {
  //   if (Array.isArray(wisdomLists) && wisdomLists.length > 0) {
  //     const randomIndex = Math.floor(Math.random() * wisdomLists.length);
  //     setRandomItem(wisdomLists[randomIndex]);
  //   }
  // }, [wisdomLists]);
  // console.log("randomItem", randomItem);
  const [showBox, setShowBox] = useState(false);

  const handleIconClick = () => {
    setShowBox(!showBox);
  };

  useEffect(() => {
    // Initialize Bootstrap tooltips
    const tooltipTriggerList = Array.from(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.forEach(
      (tooltipTriggerEl) => new window.bootstrap.Tooltip(tooltipTriggerEl)
    );
  }, []);
  const maxLength = 20;
  return (
    <>
      {/* <FixMusicPlayer /> */}
      <footer className="footer-section">
        <div style={{ visibility: "", cursor: "pointer" }}>
          <FixMusicPlayer />
        </div>
        <div className="container">
          <div className="footer-content ">
            <div className="row g-3 mainconta">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-50">
                <div className="d-flex mx-4  footerlistitems gap-0">
                  <ul
                    className="col-md-6 col-lg-6 col-sm-6 px-1"
                    style={{ listStyle: "none" }}
                  >
                    {contact?.pagelink1 === '/community' ?
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink1}>{contact?.pagename1}</Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename1}
                        </li>
                      )
                      :
                      <li>
                        <Link to={contact?.pagelink1}>{contact?.pagename1}</Link>
                      </li>
                    }
                    {contact?.pagelink2 === '/community' ?
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink2}>{contact?.pagename2}</Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename2}
                        </li>
                      )
                      :
                      <li>
                        <Link to={contact?.pagelink2}>{contact?.pagename2}</Link>
                      </li>
                    }
                    {contact?.pagelink3 === '/community' ?
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink3}>{contact?.pagename3}</Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename3}
                        </li>
                      )
                      :
                      <li>
                        <Link to={contact?.pagelink3}>{contact?.pagename3}</Link>
                      </li>
                    }
                    {contact?.pagelink4 === '/community' ?
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink4}>{contact?.pagename4}</Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename4}
                        </li>
                      )
                      :
                      <li>
                        <Link to={contact?.pagelink4}>{contact?.pagename4}</Link>
                      </li>
                    }
                    <div className="d-flex flex-column text-align-start my-4">
                      <button
                        className="btn text-start subscribebtn bg-white"
                        onClick={handleShow}
                      >
                        SUBSCRIBE
                      </button>
                    </div>
                  </ul>

                  <ul
                    className="col-md-6 col-lg-6 col-sm-6 borderul px-1"
                    style={{
                      listStyle: "none",
                    }}
                  >

                    {contact?.pagelink5 === '/community' ?
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink5}>{contact?.pagename5}</Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename5}
                        </li>
                      )
                      :
                      <li>
                        <Link to={contact?.pagelink5}>{contact?.pagename5}</Link>
                      </li>
                    }
                    {contact?.pagelink6 === '/community' ?
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink6}>{contact?.pagename6}</Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename6}
                        </li>
                      )
                      :
                      <li>
                        <Link to={contact?.pagelink6}>{contact?.pagename6}</Link>
                      </li>
                    }
                    {contact?.pagelink7 === '/community' ?
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink7}>{contact?.pagename7}</Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal}>
                          {contact?.pagename7}
                        </li>
                      )
                      :
                      <li>
                        <Link to={contact?.pagelink7}>{contact?.pagename7}</Link>
                      </li>
                    }

                    {contact?.pagelink8 === '/community' ?
                      showCommunity ? (
                        <li>
                          <Link to={contact?.pagelink8}>{contact?.pagename8}</Link>
                        </li>
                      ) : (
                        <li onClick={handleShowCommunityModal} className="text-white" style={{cursor:'pointer',textDecoration:'underline'}}>
                          {contact?.pagename8}
                        </li>
                      )
                      :
                      <li>
                        <Link to={contact?.pagelink8}>{contact?.pagename8}</Link>
                      </li>
                    }

                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-50 appsdownloadfooter">
                <h5 className="fw-bolder text-white ">
                  DOWNLOAD <br /> DR. ARCHIKA DIDI APP
                </h5>
                <div className="d-flex imgsandsign">
                  <div className="d-flex flex-column ">
                    <img
                      src={contact?.footer_image}
                      alt=""
                      style={{
                        width: "130px",
                        border: "2px solid white",
                        borderRadius: "9px",
                      }}
                    />
                    <img
                      src={drArchikadidiLogo}
                      alt=""
                      style={{ width: "150px", margin: "10px 0" }}
                    />
                  </div>
                  <div className="osbuttons d-flex flex-column mt-3">
                    <button className="d-flex">
                      {" "}
                      <img src={applelogo} />
                      iOS App
                    </button>
                    <button className="d-flex">
                      {" "}
                      <img src={andrroidlogo} />
                      Andriod App
                    </button>
                    <div className="invisible" style={{ visibility: "hidden" }}>
                      bbbbbbbbb
                    </div>
                    <div className="invisible">bbbbbbbbb</div>
                  </div>
                </div>
              </div>
              {currentPath === "/courses" ||
                currentPath.includes("/courses") ? (
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-3 footersliderr1 pb-5">
                  <Slider
                    ref={(slider) => (sliderRef1 = slider)}
                    {...sliderSettings}
                  >
                    {Array.isArray(courselist) &&
                      courselist.slice(0, 6).map((item, index) => (
                        <div
                          className="card-body bg-white overflow-hidden footersliderr"
                          key={index}
                          onClick={() => handleNavigateToCourse(item?.slug)}

                          style={{ borderRadius: "10px" }}
                        >
                          <div className="">
                            <img
                              src={item?.courseImage}
                              alt=""
                              className="card-img-top"
                              style={{
                                // height: "25vh",
                                borderRadius: "7px",
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              ) : currentPath === "/wisdom" ||
                currentPath.includes("/wisdom") ? (
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-3 footercourselider footersliderr1 pb-5 ">
                  <Slider
                    ref={(slider) => (sliderRef2 = slider)}
                    {...sliderSettings1}
                  >
                    {Array.isArray(wisdomLists) &&
                      wisdomLists.map((item, index) => (
                        <div
                          className="card-body bg-white overflow-hidden footersliderr"
                          style={{ borderRadius: "15px" }}
                          key={index}
                          onClick={() =>
                            handleNavigateToWisdomSubpage(item?.slug)
                          }
                        >
                          <div className="">
                            <img
                              src={item?.thumbnail_image}
                              alt=""
                              className="card-img-top"
                              style={{
                                // height: "25vh",
                                borderRadius: "7px",
                              }}
                            />
                            {/* <h6 className="p-2 text-center text-capitalize ftitle">
                              {item.title.length > maxLength
                                ? `${item.title.substring(0, maxLength)}...`
                                : item.title}

                                
                              </h6> */}
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>
              ) : (
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 mb-5 socialscontainer d-flex">
                  <div className="d-flex flex-column contactscontainer pb-4">
                    <h4 className="text-light fw-bold ">CONTACT US</h4>
                    <div className="contacticons d-flex">
                      {contact?.phone ? (
                        <a
                          href={`tel:${contact?.phone}`}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={contact?.phone}
                        >
                          <img src={phoneicon} alt="Phone" />
                        </a>
                      ) : null}

                      {contact?.email ? (
                        <a
                          href={`mailto:${contact?.email}`}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={contact?.email}
                        >
                          <img src={gnmailicon} alt="Email" />
                        </a>
                      ) : null}

                      {contact?.address ? (
                        <a
                          href={contact?.address_Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Anand Dham Ashram
Bakkarwala Marg, Nangloi Najafgarh Road
New Delhi 110041"
                        >
                          <img src={locationicon} alt="Location" />
                        </a>
                      ) : null}

                      {contact?.whatsapp_Link ? (
                        <a
                          href={contact?.whatsapp_Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={contact?.whatsapp_Link}
                        >
                          <img src={Whatsapp} alt="WhatsApp" />
                        </a>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex flex-column followcantainer">
                    <h4 className="text-light fw-bold  ">FOLLOW US</h4>
                    <div className="contacticons d-flex">
                      {contact?.fb_Link ? (
                        <a target="_blank" href={contact?.fb_Link}>
                          <img src={facebook1} alt="" />
                        </a>
                      ) : (
                        ""
                      )}
                      {contact?.insta_Link ? (
                        <a target="_blank" href={contact?.insta_Link}>
                          <img src={Instagram} alt="" />
                        </a>
                      ) : (
                        ""
                      )}

                      {contact?.x_Link ? (
                        <a target="_blank" href={contact?.x_Link}>
                          <img
                            src={Twitter}
                            alt=""
                            style={{
                              borderRadius: "10px",
                            }}
                          />
                        </a>
                      ) : (
                        ""
                      )}

                      {contact?.youtube_Link ? (
                        <a target="_blank" href={contact?.youtube_Link}>
                          <img src={Youtube} alt="" />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white footorbottom  ">
          <div className="container">
            <div className="row gap-0">
              <div className="col-md-6 col-sm-6 col-6 d-flex align-items-center archiikk">
                <span>&#174;</span> Dr. Archika Didi 2024
              </div>
              <div className="col-md-6 col-sm-6 col-6 d-flex align-items-center justify-content-end">
                <a className="px-1" href="/terms&conditions">
                  Terms And Conditions
                </a>{" "}
                |{"  "}
                <a className="px-1" href="/privacypolicy">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Body>
            <h2
              className="my-4 text-center mb-5"
              style={{ fontFamily: "myFont" }}
            >
              Subscribe For E-Newsletter
            </h2>
            <form onSubmit={(e) => handleSubscription(e)}>
              <div className="my-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Email address"
                  aria-describedby="emailHelp"
                  style={{ border: "1.5px solid black", color: "black" }}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ backgroundColor: "#B767A2" }}
                onClick={handleClose}
              >
                Submit
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </footer>


      {/* <!-- Modal --> */}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="communityModal"
        tabIndex="-1"
        aria-labelledby="communityModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="communityModalLabel"
                style={{ fontFamily: "myFont", color: "#9767a2" }}
              >
                Community Locked
              </h5>
              <button
                type="button"
                className="fs-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseCommunityModal}
                style={{ color: "#9767a2" }}
              >
                <IoCloseSharp />
              </button>
            </div>
            <div className="modal-body lato pb-4 d-flex text-center align-items-center gap-2">
              {/* <img src={coming_soon_image} alt="Coming Soon Image" height={10} width={100}/> */}
              <IoLockOpen /> Please Buy Something To Unlock The Community !
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn shadowbuttons text-white fs-6" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
