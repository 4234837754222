
import React from "react";
// import { useSelector } from "react-redux";
import { Navigate, Outlet ,Route} from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";




const ProtectedRoute = ({ isAuthenticated, children, adminRoute }) => {
    const { isLoading, user } = useSelector((state) => state.auth);
    // const { loading, user } = useSelector((state) => state.user);
    // if (!loading && isAuthenticated === false) {
    if (!isLoading && isAuthenticated === false) {
        return <Navigate to="/login" replace />;
    }

    

    return (

        children ? children : <Outlet />


    );
}


export default ProtectedRoute