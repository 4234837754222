import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import "../../component/layout/style.css";
import menicon from '../../../src/images/menicon.jpeg';
import Moment from 'react-moment';
import { SHOW_ERROR_NOTIFICATION } from '../../appUtils/appToast';
import { addComment, fetchPosts, isAnonymusComment, isAnonymusPost, likePost } from '../../Allslices/communitySlice';
import { allComments } from '../../Allslices/communitySlice';
import AppCarousel from '../../component/shared/AppCarousel';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import CommentItem from './CommentItem';
import AddFileModal from '../../component/modal/AddFileModal';
import { loadUser } from '../../component/auth/authSlice';
import anonymousUser from '../../images/anonymous_user.png'

const PostItem = ({ listItem }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const { _id: postId, commentPage } = listItem;

    const {
        likePostState: { loading, loadingId },
        allCommentsState: { loading: caLoading, loadingId: caId },
        commentAnonymus
    } = useSelector((state) => state.community);
    const postType = listItem?.type;

    let commentsOrAnswers = postType == 'question' ? listItem?.answers : listItem?.comments;

    const totalLikes = listItem?.likedBy?.length ?? 0;

    const userImage = localStorage.getItem('userImage');

    const callLikePost = useCallback(() => {
        dispatch(likePost(postId)).then((resultAction) => {
            if (resultAction?.error) {
                SHOW_ERROR_NOTIFICATION(resultAction.payload);
                return;
            }
        });
    }, [postId]);

    const loadMoreComments = useCallback(() => {
        dispatch(allComments({ postId: postId, page: commentPage ?? 1, postType })).then((resultAction) => {
            if (resultAction?.error) {
                SHOW_ERROR_NOTIFICATION(resultAction.payload);
                return;
            }
        });
    }, [postId, commentPage]);


    // region addComment
    const [selectedFiles, setSelectedFiles] = useState({
        audio: null,
        video: null,
        image: [],
    });


    const initialValue = {
        description: '',
        image: null,
        audio: null,
        video: null,
        anonymous: false,
    };

    const [showAddFile, setShowAddFile] = useState(false);

    const [formData, setFormData] = useState(initialValue);

    const callAddComment = (() => {
        formData.image = selectedFiles.image;
        formData.audio = selectedFiles.audio;
        formData.video = selectedFiles.video;
        formData.anonymous = commentAnonymus;

        dispatch(addComment({ postId, inputData: formData, postType })).then((resultAction) => {
            if (resultAction?.error) {
                SHOW_ERROR_NOTIFICATION(resultAction.payload);
                return;
            }
            setFormData(initialValue);
            dispatch(fetchPosts({ type: 'all' }));
        });

        dispatch(isAnonymusComment(false));

    });


    const handleInputChange = (e) => {

        const { name, value, type, files } = e.target;

        if (name === 'anonymous') {
            // setAnonymous(!anonymous);
            dispatch(isAnonymusComment(!commentAnonymus))
        } else {
            setFormData({
                ...formData,
                [name]: type === "file" ? files : value
            });
        }
    }
    // #endregion
    // console.log("listItem", listItem)

    return (
        <div className="border mt-5 con_box overflow-y-auto overflow-x-hidden" style={{ height:'auto',maxHeight:'100vh'}}>
            <div style={{ backgroundColor: 'whitesmoke' }}>
                <div className="commeimg d-flex p-3">
                    {listItem.anonymous ? (
                        <>
                            <i className="fa-regular fa-circle-user p-1" style={{ fontSize: '3rem' }}></i>
                            <div className="mx-3">
                                <h5 className="fw-bold">Anonymous User</h5>
                                <Moment className="text-secondary" format="lll" withTitle>{listItem?.createdAt} </Moment>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* <img src={listItem?.createdBy?.imageUrl ?? menicon} alt="" /> */}
                            <img src={listItem?.createdBy?.imageUrl ?? ''} alt="user_image"
                                style={{height:'55px',width:'55px', borderRadius:'50%'}}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "default_usericon.jpg";
                                    // currentTarget.style.width = "100px";
                                }}
                            />
                            <div className="mx-3">
                                <h5 className="fw-bold">{listItem?.createdBy?.name}</h5>
                                <Moment className="text-secondary" format="lll" withTitle>{listItem?.createdAt}</Moment>
                            </div>
                        </>

                    )}


                    <div style={{ flexGrow: "1" }}></div>
                </div>

                <div className="py-3 px-3">
                    <p >
                        {listItem?.description}
                    </p>
                </div>
            </div>

            <div>
                {/* <img src={commentimg} className="w-100" alt="" /> */}
                {/* <div className='px-5'> */}
                {listItem?.fileUrls?.images?.length > 0 && <AppCarousel
                    id={listItem._id}
                    images={listItem?.fileUrls?.images.map((item, index) => item.url)}
                />}
                {/* </div> */}

                {listItem?.fileUrls?.audio && <div className="d-flex justify-content-center py-5">
                    <audio controlsList="nodownload" controls src={listItem?.fileUrls?.audio} />
                </div>}

                {listItem?.fileUrls?.video &&
                    <MediaPlayer title={`Video by ${listItem?.createdBy?.name}`} src={listItem?.fileUrls?.video}>
                        <MediaProvider />
                        <DefaultVideoLayout icons={defaultLayoutIcons} />
                    </MediaPlayer>
                }

                {/* <div className="d-flex justify-content-end mb-4" style={{ position: 'relative', bottom: '25px', right: '10px' }}>
                    <div className="p-1 rounded">
                        <button className="btn btn-primary btn-circle btn-circle-lg  m-1 rounded-circle"><i className="fa-regular fa-comment commneticone"></i></button>
                    </div>
                    <div className="p-1 rounded">
                        <button className="btn btn-primary btn-circle btn-circle-lg m-1 rounded-circle"><i className="fa-solid fa-paperclip commneticone"></i></button>
                    </div>
                    <div className="p-1 rounded">
                        {(loadingId == listItem?._id && loading) ?
                            <div className="app-circle-lg">
                                <div className="spinner-border text-dark" role="status"></div>
                            </div>
                            :
                            <button className={`btn ${listItem?.liked ? 'btn-danger' : 'btn-light'} btn-circle btn-circle-xl rounded-circle`} onClick={callLikePost}>
                                <i className={`fa-solid fa-heart commneticone ${listItem?.liked ? 'text-light' : 'text-danger'}`}></i>
                            </button>}
                    </div>
                </div> */}
            </div>
            {/* <hr /> */}
            <div className="py-2 px-4">
                
                <div className="likeimg d-flex align-items-center" >
                
                <div className="p-2 d-flex justify-content-center align-items-center">
                    {(loadingId == listItem?._id && loading) ?
                        <div className="app-circle-lg">
                            <div className="spinner-border text-dark" role="status"></div>
                        </div>
                        :
                        <button className={` ${listItem?.liked ? 'btn-danger' : 'btn-light'}`} onClick={callLikePost}>
                            <i className={`fa-solid fa-heart commneticone ${listItem?.liked ? 'text-danger' : 'text-dark'}`}></i>
                        </button>}
                </div>

                    {/* {(totalLikes > 0) && <div className="d-flex flex-row">
                        {totalLikes > 0 && <img src={menicon} alt="" />}
                        {totalLikes > 1 && <img src={menicon} alt="" />}
                        {totalLikes > 2 && <img src={menicon} alt="" />}
                        {totalLikes > 3 && <img src={menicon} alt="" />}
                    </div>} */}
                    <div className="mx-2 d-flex gap-1 d-flex justify-content-end align-items-end">
                        {listItem?.lastLikedBy && <h6 className="fw-bold m-0">{listItem?.lastLikedBy}</h6>}
                        <h6 className="text-secoundry m-0" style={{ fontSize: '8px!important' }}>{`${(totalLikes > 1) ? `and ${totalLikes - 1} more ` : ''}${(totalLikes > 0) ? 'liked this' : ''}`}</h6>
                    </div>
                </div>
            </div>

            <div className="bgcolorc p-1" style={{ borderBottomLeftRadius: `${commentsOrAnswers?.length > 0 ? '0px' : '15px'}`, borderBottomRightRadius: `${commentsOrAnswers?.length > 0 ? '0px' : '15px'}` }}>
                <div className="row d-flex align-items-center justify-content-evenly px-3" >

                    <div className='d-flex col-lg-5 col-md-6 col-sm-12 gap-2 p-1 justify-content-between align-items-center' >
                        <div style={{ borderRadius: '50%', width: '20%' }}>
                            {!commentAnonymus ? (
                                <img src={user?.image ?? ''} alt="user_image" style={{ borderRadius: '50%', height: '55px' }}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "default_usericon.jpg";
                                    }}
                                />
                            ) : (
                                <img src={anonymousUser ?? ''} alt="anonymousUser" style={{ borderRadius: '50%', height: '55px' }} />
                            )}
                        </div>
                        <div style={{ width: '80%' }}>
                            <textarea
                                name="description"
                                style={{ minWidth: '100%' }}
                                value={formData.description}
                                onChange={handleInputChange}
                                className="border-0 py-2"
                                placeholder={` Add ${postType == 'question' ? 'an answer' : 'a comment'}...........`}
                                rows="2">
                            </textarea>
                        </div>
                    </div>

                    <div className="d-flex col-lg-4 col-sm-12 col-md-6 gap-2 p-1 align-items-center justify-content-center" >

                        <div className="form-check text-center d-flex gap-3" id='anonymousPadding'>
                            <div>
                                <input className="form-check-input ms-lg-2 ms-md-2" type="checkbox" checked={commentAnonymus} id="flexCheckDefault" name='anonymous' onChange={handleInputChange} />
                                <label className="form-check-label px-2" for="" id='anonymousFont' style={{ fontSize: '18px' }}>
                                    Post Anonymous
                                </label>
                            </div>
                        </div>

                        <div
                            // col-lg-1 col-sm-6 col-2
                            className=' text-center '
                        >
                            {/* <div className='pt-2'> */}
                            <button onClick={() => setShowAddFile(true)}>
                                <i class="fa-solid fa-paperclip text-secondary fs-3" />
                            </button>
                        </div>

                    </div>

                    <div className="col-lg-3 col-sm-12 col-md-12 text-center p-1">
                        {/* <div className="text-center"> */}
                        {listItem?.addCommentLoading ?
                            <div className="spinner-border align-self-center ms-3" role="status"></div>
                            : <button className="btn btn-lg text-light align-self-center "
                                onClick={callAddComment}
                                style={{ backgroundColor: '#B767A2', borderRadius: '80px', fontSize: '1rem' }}>{`Add ${postType == 'question' ? 'an answer' : 'a comment'}`}</button>}
                    </div>


                </div>
            </div>

            {commentsOrAnswers?.map((caItem, index) => (
                <CommentItem key={caItem?._id} caItem={caItem} postId={postId} postType={postType} />
            ))}

            {(commentsOrAnswers?.length > 0 && !listItem?.hideShowMore) && <div className="row">
                <div className="col-12 p-2">
                    {(caId == listItem?._id && caLoading) ?
                        <div className="d-flex justify-content-center mt-5">
                            <div className="spinner-border" role="status"></div>
                        </div> : <div className="d-flex justify-content-center">
                            <button className="btn border fs-6 combtn rounded-pill" onClick={loadMoreComments}>View more {`${postType == 'question' ? 'Answers' : 'Comments'}`}&nbsp;<i className="fa-solid fa-angle-down"></i></button>
                        </div>}
                </div>
            </div>}
            {/* <!-- Modal --> */}
            {showAddFile && <AddFileModal open={showAddFile}
                onHide={() => setShowAddFile(false)}
                inputFiles={selectedFiles}
                onSubmit={(result) => setSelectedFiles(result)}
                longerMedia={postType == "question"}
            />}
        </div>
    );
}

export default PostItem;