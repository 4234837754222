import { useState, useEffect, useRef } from "react";
import "../component/layout/style.css";
import { useNavigate, useLocation, Link } from "react-router-dom";
import filtericon from "../images/filtericons.png";
import searchicon from "../images/searchicons.png";
import nodata from "../images/No-Data.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getVideo,
  getConfigVideo,
  getlistVideo,
  getLanguages,
  filtersUpdate,
} from "../Allslices/videoSlice";
import usePreserveScroll from "./histrorysave";

import MetaData from "../component/layout/MetaData";
const Video = () => {
  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  usePreserveScroll();

  const dispatch = useDispatch();
  const { videoCategory, currentPage, limit, totalVideoList, videolist, isLoading, Filters: { categoryId, language, sort, contentType, text }
  } = useSelector((state) => state.video);

  const { videoConfig } = useSelector((state) => state.video);

  const location = useLocation();

  const { Languages } = useSelector((state) => state.video);

  const navigate = useNavigate();

  const handleNavigateToWellnaessAudio = (slug) => {
    navigate(`/video/singleVideo/${slug}`);
  };

  const query = location.search.split("?")[1];

  let initialfilters = {
    categoryId: null,
    language: null,
    newest: "",
    text: query ?? "",
  };
  const [filters, setFilters] = useState(initialfilters);

  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);

  const handleFiltersSubmit = (e, action) => {

    if (action == "loadmore") {
      dispatch(
        getlistVideo({
          page: currentPage,
          limit: limit + 8,
          categoryId: filters.categoryId,
          language: filters.language,
          sort: filters.newest,
          text: filters.text,
        })
      )
    } else {
      dispatch(
        getlistVideo({
          page: currentPage,
          limit: limit,
          categoryId: filters.categoryId,
          language: filters.language,
          sort: filters.newest,
          text: filters.text,
        })
      )
      window.scrollTo(
        {
          top: 650,
          behavior: 'smooth'
        })
    }


  };


  useEffect(() => {
    dispatch(getVideo());
    dispatch(getConfigVideo());
    dispatch(getlistVideo());
    dispatch(getLanguages());
    // dispatch(
    //   getlistVideo({
    //     page: currentPage,
    //     limit: limit
    //   })
    // );
  }, []);

  const handleFilterChange = (e) => {
    const { value, name } = e.target;

    const payload = {
      [name]: value
    };

    dispatch(filtersUpdate(payload));
  };

  const maxLength = 100;
  const minLength = 50;


  useEffect(() => {
    dispatch(filtersUpdate(query ? { text: query } : { text: text }));
    handleFiltersSubmit()
  }, [query])
  
  return (
    <>
      <MetaData title={videoConfig?.meta_title} description={videoConfig?.meta_description} tags={videoConfig?.meta_tag} />

      <div className="vidomediaquery desktop-only" style={{ backgroundSize: "cover", overflow: "hidden", backgroundImage: `url(${videoConfig?.background_image})` }} >
        <div className="container wisdompad desktop-only">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

              {videoConfig?.alignItem === "left" && (<>
                <div>
                  <h1 className="fw-bold ">
                    <span className="wisdomhead haedingfont">
                      {videoConfig?.heading}
                    </span>
                  </h1>
                  <p
                    className=" w-75 wisdomPara homebdes"
                    style={{
                      fontFamily: "Lato",
                      textAlign: "justify"
                    }}
                  >
                    {videoConfig?.subheading}
                  </p>
                </div></>)}

            </div>
            <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

              {videoConfig?.alignItem === "right" && (<>
                <div>
                  <h1 className="fw-bold ">
                    <span className="wisdomhead haedingfont">
                      {videoConfig?.heading}
                    </span>
                  </h1>
                  <p
                    className=" w-75 wisdomPara homebdes"
                    style={{
                      fontFamily: "Lato",
                      textAlign: "justify"
                    }}
                  >
                    {videoConfig?.subheading}
                  </p>
                </div></>)}

            </div>
          </div>
        </div>


      </div>




      <div className="mobile-only">
        <div className="container-fluid mobile-only" style={{ position: "relative", display: "flex" }}>
          <img src={`${videoConfig?.res_background_image}`} />
          <div className="container" style={{ position: "absolute", height: "100%" }}
          >
            <div className="row" style={{ height: "100%" }}>
              <div className="col-6 d-flex justify-content-start align-items-center">

                {videoConfig?.alignItem === "left" && (<>
                  <p
                    className="homebannertitle"
                    style={{ fontFamily: "myFont", fontSize: "24px" }}
                    dangerouslySetInnerHTML={{
                      __html: videoConfig?.res_heading,
                    }}
                  ></p>
                </>)}

              </div>
              <div className="col-6 d-flex justify-content-start align-items-center">
                {videoConfig?.alignItem === "right" && (<>
                  <p
                    className="homebannertitle"
                    style={{ fontFamily: "myFont", fontSize: "24px" }}
                    dangerouslySetInnerHTML={{
                      __html: videoConfig?.res_heading,
                    }}
                  ></p>
                </>)}
              </div>


            </div>





          </div>


        </div>

        <div className="container">
          <p
            className="py-4 "
            style={{
              fontFamily: "Lato",
              textAlign: "justify"
            }}
            dangerouslySetInnerHTML={{
              __html: videoConfig?.res_subheading,
            }}
          >

          </p>

        </div>


        {/* banner ends here */}

      </div>








      <div className="container filterpad py-2 shadow vidomediaquery1" style={{ overflow: "hidden" }}>

        <div className="row pb-4 p-3 justify-content-center">
          <div className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2 pola" id="mobileScreen">
            <label for="filter" className="fw-bold myfiltericon" style={{ color: "#A0A0A0" }}>
              <img style={{ width: "25px" }} src={filtericon} alt="" />
            </label>
            <select
              className="select searchborder rounded-pill p-2 w-100"
              value={language}
              name="language"
              id="selectFilter"
              onChange={(e) => handleFilterChange(e)}
            >
              <option value="">All Languages</option>
              {Array.isArray(Languages) &&
                Languages?.map((item, index) => {
                  return (
                    <option value={item?.language} className="text-black">
                      {item?.language}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-6 pola " id="mobileScreen">
            <select
              value={categoryId}
              name="categoryId"
              className="select searchborder rounded-pill p-2 w-100"
              onChange={(e) => handleFilterChange(e)}
              id="selectFilter"
            >
              <option value="">Category</option>
              {Array.isArray(videoCategory) &&
                videoCategory?.map((item, index) => {
                  return <option value={item?._id}>{item?.title}</option>;
                })}
            </select>
          </div>

          <div className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2 pola" id="mobileScreen">

            <select
              value={sort}
              name="sort"
              className="select searchborder rounded-pill p-2 w-100"
              onChange={(e) => handleFilterChange(e)}
              id="selectFilter"
            >
              <option value="">Sort by</option>
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
            id="mobileScreen"
          >
            <label for="filter" className="fw-bold mysearchicon" style={{ color: "#A0A0A0" }}>

              <img src={searchicon} alt="" style={{ width: "25px" }} onClick={handleFiltersSubmit} />
            </label>
            <form class=" w-100 pt-4" role="search">
              <input
                className="form-control rounded-pill w-100 searchborder"
                type="search"
                name="text"
                placeholder="Search by keyword..."

                aria-label="Search"
                onChange={(e) => handleFilterChange(e)}
                value={text}
                style={{ borderRadius: "19px", color: "#A0A0A0" }}
              />
              <i class="fa-solid fa-magnifying-glass mysearchiconforphone"></i>
            </form>

          </div>
          <div className="pt-3 d-flex justify-content-center align-items-center"
          >

            <button
              className="btn shadowbuttons btn-lg text-light rounded-5 cmn-t-shake"
              style={{

                padding: "0.6rem 2.5rem",
                borderRadius: "26px",
              }}
              onClick={handleFiltersSubmit}
            >
              SEARCH
            </button>

          </div>
        </div>
      </div>

      <section style={{ overflow: "hidden" }} ref={listStartRef} id="listStart">
        <div className="container artvid mt-2">
          {videolist?.length > 0 ? <div className="row g-3">
            {
              Array.isArray(videolist) && videolist?.map((item, index) => (
                <div className="col-md-6 col-sm-12 col-lg-3 item" key={index}>
                  <Link to={`/video/singleVideo/${item?.slug}`}>
                    <div className="card item-card card-block  vidomediaquery2 card_height" style={{ borderRadius: "10px", overflow: "hidden" }} /*onClick={() => handleNavigateToWellnaessAudio(item.slug)}*/>
                      <img src={item?.thumbnail_image} alt={item?.title}
                        style={{ aspectRatio: "3/2", }} />

                      <div className="d-flex p-3 justify-content-between">
                        <div className="d-flex align-items-center gap-2">
                          <span className="text-secondary">{item?.language}</span>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <span className="fs-6 text-black ">
                            {item.contentType == "audio"
                              ? "Audio"
                              : item.contentType === "video"
                                ? "Video"
                                : "video"}
                          </span>
                          <a href="#">
                            <i
                              className={`fa-solid ${item.contentType == "audio"
                                ? "fa-headphones"
                                : item.contentType == "video"
                                  ? "fa-play"
                                  : "fa-circle-play"
                                } text-dark fs-6`}
                            ></i>
                          </a>
                        </div>
                      </div>
                      <div className="container">
                        <h5
                          className="m-1"
                          style={{ font: "Playfair Display SC" }}
                        >
                          {item.title.length > minLength
                            ? `${item.title.substring(0, minLength)}...`
                            : item.title}
                        </h5>

                        <p
                          className="card-text"
                          style={{ font: "Lato", color: "#666666", margin: "0", height: "max-content" }}
                        > {item?.description?.length > maxLength
                          ? `${item?.description.substring(0, maxLength)}...`
                          : item?.description}
                        </p>
                        <h3
                          className="fw-semibold d-flex fs-4 justify-content-between px-2 p-2  position-absolute bottom-0"
                          style={{ color: "#666666" }}
                        >

                        </h3>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            <div className="col-12">
              <div className="py-3 mb-2 d-flex justify-content-center align-items-center">
                <div className="pt-3 d-flex justify-content-center align-items-center">
                  {videolist &&
                    (videolist?.length == totalVideoList) ? (
                    ""
                  ) : (
                    <a>
                      <button
                        className="btn shadowbuttons btn-lg text-light cmn-t-shake mb-3"
                        style={{
                          borderRadius: "20px",
                        }}
                        onClick={(e) => handleFiltersSubmit(e, "loadmore")}
                      >
                        {isLoading ? (
                          <div class="spinner-border text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <span>LOAD MORE</span>
                        )}
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>:
          <section>
          <div className="text-center d-flex justify-content-center">
          <img style={{ width: "400px" }} src={nodata} alt="No Data Image" />
          </div>
        </section>}
        </div>
      </section>

    </>
  );
};

export default Video;
