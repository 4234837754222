import React, { useEffect, useRef, useState } from "react";
import bookimage from "../images/book-image 1.png";
import backgroundimg from "../images/Blog1.png";
import emotion from "../images/emotional healing book 1.png";
import programming from "../images/REprogram your mind (2) 1.png";
import coming_soon_image from "../images/coming_soon.jpg";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { getconfigBook, getBookPage } from "../Allslices/BookSlice";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { IoCloseSharp } from "react-icons/io5";

export default function BookPage() {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const { Bookdata, configdata, currentPage, limit, totalAllBooks, isLoading } =
    useSelector((state) => state.bookpage);
  console.log("Bookdata", Bookdata);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    dispatch(getconfigBook());
    dispatch(
      getBookPage({
        page: currentPage,
        limit: limit,
      })
    );
  }, [dispatch]);

  const navigate = useNavigate();

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const blissfullpage = (book) => {
    // console.log("The onClicked Book Value is:", book.coming_soon);
    if (book.coming_soon) {
      handleShow();
    } else {
      navigate(`/book/${book.slug}`);
    }
  };

  const handleLoadMore = (e, action) => {
    if (action == "loadmore") {
      dispatch(
        getBookPage({
          page: currentPage,
          limit: limit + 6,
        })
      );
      console.log("The loadmore is working");
    } else {
      dispatch(
        getBookPage({
          page: currentPage,
          limit: limit,
        })
      );
      console.log("The else part for handleLoadMore");
    }
  };

  const calculateSavingsPercentage = (mrp, sellPrice) => {
    if (!mrp || mrp === 0) return 0; // Prevent division by zero
    return ((mrp - sellPrice) / mrp) * 100;
  };

  let sliderRef1 = useRef();

  const settings1 = {
    infinite: true,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          right: "-15px!important",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowRight color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          left: "-35px",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowLeft color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  return (
    <div>
      <div
        className="vidomediaquery desktop-only"
        style={{
          backgroundSize: "cover",
          overflow: "hidden",
          backgroundImage: `url(${configdata?.background_image})`,
        }}
      >
        <div className="container wisdompad desktop-only">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
              {configdata?.alignItem === "left" && (
                <>
                  <div>
                    <h1 className="fw-bold ">
                      <span className="wisdomhead haedingfont">
                        {configdata?.heading}
                      </span>
                    </h1>
                    <p
                      className=" w-75 wisdomPara homebdes"
                      style={{
                        fontFamily: "Lato",
                        textAlign: "justify",
                      }}
                    >
                      {configdata?.subheading}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
              {configdata?.alignItem === "right" && (
                <>
                  <div>
                    <h1 className="fw-bold ">
                      <span className="wisdomhead haedingfont">
                        {configdata?.heading}
                      </span>
                    </h1>
                    <p
                      className=" w-75 wisdomPara homebdes"
                      style={{
                        fontFamily: "Lato",
                        textAlign: "justify",
                      }}
                    >
                      {configdata?.subheading}
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>


      <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${configdata?.res_background_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {configdata?.alignItem   === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: configdata?.res_heading,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {configdata?.alignItem  === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: configdata?.res_heading,
                      }}
                    ></p>
                  </>)}
                </div>
              </div>
            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: configdata?.res_subheading,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>


      

      <div className="container mt-5">
        <div className="row">
          {/* <Slider
            ref={(slider) => {
              sliderRef1 = slider;
            }}
            {...settings1}
          > */}
          {Array.isArray(Bookdata) &&
            Bookdata.map((item, index) => {
              const bookreting = [];
              for (let i = 1; i <= 5; i++) {
                if (i <= item?.rating) {
                  bookreting.push(
                    <i
                      className="fa-solid fa-star"
                      key={i}
                      style={{ color: "#B767A2" }}
                    ></i>
                  );
                } else {
                  bookreting.push(
                    <i
                      className="fa-regular fa-star"
                      key={i}
                      style={{ color: "gray" }}
                    ></i>
                  );
                }
              }

              return (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={index}>
                  <div
                    className="card item-card text-dark overflow-hidden mx-2 my-2"
                    onClick={() => blissfullpage(item)}
                  >
                    <div className="blissImg1">
                      <img
                        src={item?.thumbnail_image}
                        alt=""
                        style={{ width: " -webkit-fill-available" }}
                      />
                    </div>
                    <div className="content_here px-3">
                      {item?.coming_soon === true ? (
                         <h1 style={{ fontSize: "25px", fontFamily: "myFont", height:"169px" }}>
                         {item.title}
                         </h1>
                      ) :(
                        <>
                        <h1 style={{ fontSize: "25px", fontFamily: "myFont" }}>
                      {item?.title.length > 20 ? item.title.substring(0, 20) + "..." : item.title}
                      </h1>
                      <p style={{ fontSize: "16px", fontFamily: "Lato" }}>
                        By {item?.author}
                        <br />
                        (Rating By Good Reads) {bookreting}
                        <br />
                        <span
                          style={{
                            textDecoration: "line-through",
                            fontFamily: "Lato",
                          }}
                        >
                          {" "}
                          Rs. {item?.mrp}
                        </span>{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Rs. {item?.sellPrice}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Save{" "}
                        {Math.floor(
                          calculateSavingsPercentage(item?.mrp, item?.sellPrice)
                        )}{" "}
                        % <br />
                        Binding: {item?.bindingType}
                        <br />
                        {/* Condition: {item?.condition} */}
                        <br />
                      </p>
                        </>
                      )}
                      
                    </div>
                  </div>
                </div>
              );
            })}
          {/* </Slider> */}
        </div>
      </div>
      <section className="py-3">
        <div className="pt-3 d-flex justify-content-center py-5">
          {Bookdata && Bookdata?.length == totalAllBooks ? (
            ""
          ) : (
            <a>
              <button
                className="btn shadowbuttons btn-lg text-light cmn-t-shake"
                onClick={(e) => handleLoadMore(e, "loadmore")}
              >
                {isLoading ? (
                  <div class="spinner-border text-dark" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <span>LOAD MORE</span>
                )}
              </button>
            </a>
          )}
        </div>
      </section>

      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ fontFamily: "myFont", color: "#9767a2" }}
              >
                Coming Soon
              </h5>
              <button
                type="button"
                className="fs-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
                style={{ color: "#9767a2" }}
              >
                <IoCloseSharp />
              </button>
            </div>
            <div className="modal-body lato pb-4 ">
              {/* <img src={coming_soon_image} alt="Coming Soon Image" height={10} width={100}/> */}
              This book is coming soon. Please check back later!
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn shadowbuttons text-white fs-6" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
