import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getGalleryBanner, getAllGalleryCategory } from "../Allslices/gallerySlice";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
import usePreserveScroll from "./histrorysave";

function Gallery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  usePreserveScroll();
  const { currentPage, limit, isLoading, totalgallerycategory, gallerybanner, gallerycategory } = useSelector((state) => state.gallery);
  const handleNavigatetoGalleryPage = (slug) => {
    navigate(`/gallery/singlecategory/${slug}`);
  };

  useEffect(() => {
    dispatch(getGalleryBanner())
    dispatch(getAllGalleryCategory({
      page: currentPage,
      limit: limit
    }));

  }, []);


  const handleLoadMore = (e, action) => {
    if (action == "loadmore") {
      dispatch((getAllGalleryCategory({
        page: currentPage,
        limit: limit * 2,
      })))
    } else {
      dispatch(getAllGalleryCategory({
        page: currentPage,
        limit: limit
      }));
    }
  }

  return (
    <div>
      <MetaData title={gallerybanner?.meta_title} description={gallerybanner?.meta_description} tags={gallerybanner?.meta_tag} />
      {gallerybanner ? (
        <>

          <div
            className="wisdomimg desktop-only"
            style={{ backgroundImage: `url(${gallerybanner?.background_image})`, backgroundSize: "cover" }}>
            <div className="container padding_gall">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

                  {gallerybanner?.alignItem === "left" && (<>
                    <div>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {gallerybanner?.heading}
                        </span>
                      </h1>
                      <p
                        className=" w-75 wisdomPara homebdes"
                        style={{
                          fontFamily: "Lato",
                          textAlign: "justify"
                        }}
                      >
                        {gallerybanner?.subheading}
                      </p>
                    </div></>)}

                </div>
                <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

                  {gallerybanner?.alignItem === "right" && (<>
                    <div>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {gallerybanner?.heading}
                        </span>
                      </h1>
                      <p
                        className=" w-75 wisdomPara homebdes"
                        style={{
                          fontFamily: "Lato",
                          textAlign: "justify"
                        }}
                      >
                        {gallerybanner?.subheading}
                      </p>
                    </div></>)}

                </div>
              </div>
            </div>
          </div>


          {/* banner starts here */}
          {/* banner starts here */}
                  


          <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${gallerybanner?.backgroundres_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {gallerybanner?.alignItem === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: gallerybanner?.headingres,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {gallerybanner?.alignItem  === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: gallerybanner?.headingres,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>


             
             

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: gallerybanner?.subheadingres,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>


          {/* <div className="container-fluid mobile-only">
            <div
              className="container mycontainer2"
              style={{
                backgroundSize: "contain",
                backgroundImage: `url(${gallerybanner?.backgroundres_image})`,
                backgroundRepeat: "no-repeat",
                height: "63vh",
              }}
            >
              <div className="row h-50">
                <div className="col-6 d-flex justify-content-center align-items-center h-75">
                  {gallerybanner?.alignItem === "left" && (
                    <>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {gallerybanner?.headingres}
                        </span>
                      </h1>
                    </>
                  )}
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center h-75">
                  {gallerybanner?.alignItem === "right" && (
                    <>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {gallerybanner?.headingres}
                        </span>
                      </h1>
                    </>
                  )}
                </div>
              </div>

              <p
                className="m-0 py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify",
                }}
              >
                {gallerybanner?.subheadingres}
              </p>
            </div>
          </div> */}
          {/* banner ends here */}
        </>





      ) : (
        <SmallLoader />
      )}

      {/* gallery */}
      <section>
        {gallerycategory ? (
           <div className="container  galleryimgh pt-5 my-3">
           <div className="row">

             {Array.isArray(gallerycategory) && gallerycategory?.map((item, index) => (
               <div className="col-lg-4 col-md-6 col-sm-6 col-6 p-2" key={index}>
                 <div
                   className="image-container"
                   onClick={() => handleNavigatetoGalleryPage(item.slug)}
                 >
                   <a>
                     <img
                       src={item?.thumbnail_image}
                       alt={item?.title}
                       className="w-100 mb-md-4 shadow-1-strong gallery-image"
                     />
                     <div className="image-overlay">
                       <div className="container paddtccc">
                         <h4 className="text-center gallerycd" style={{fontFamily:"myFont"}}>{item?.title}</h4>
                         <p className="text-center text-white gallerycard"style={{fontFamily:"lato"}}>
                           {item?.short_description}
                         </p>
                       </div>

                     </div>
                   </a>
                 </div>

               </div>
             ))}

             <div className="col-12">
               {gallerycategory.length !== totalgallerycategory ? (<div className="p-3 d-flex justify-content-center mb-3">
                 <button
                   className="btn shadowbuttons btn-lg text-light rounded-0 cmn-t-shake rounded-5 "
                   onClick={(e) => handleLoadMore(e, "loadmore")}
                 >
                   {isLoading ? (
                     <div class="spinner-border text-dark" role="status">
                       <span class="visually-hidden">Loading...</span>
                     </div>
                   ) : (
                     <span>LOAD MORE</span>
                   )}
                 </button>
               </div>) : ("")}

             </div>
           </div>
         </div>
         
        ) : (
          <SmallLoader />
        )}
      </section>
    </div>
  );
}

export default Gallery;
