import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./styles.modules.css";
import Home1 from "../../src/images/home1img.jpg";
import teamWork1 from "../../src/images/teamwork 1.png";
import chakras1 from "../../src/images/chakras 1.png";
import morepankh from "../../src/images/morpankh.png";
import Meditation1 from "../../src/images/meditation 1.png";
import Pic1 from "../../src/images/Pic 1.png";
import revSlider09 from "../../src/images/rev-slider-09 1.png";
import Podcast from "../../src/images/podcast.jpg";
import bookImage1 from "../../src/images/book-image 1.png";
import Yogashala from "../../src/images/zapp2.png";
import Yogamobile from "../../src/images/yogmobile.png";
import ExploreBg from "../../src/images/explorebg.png";
import Quotes from "../../src/images/quote_purple.png";
import ".././component/layout/style.css";
import "../component/auth/auth.css";
import axios from "../appUtils/axiosConfig";
import BackgroundModal from "../../src/images/Bg 1.png";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
// import { getsinglehome } from "../Allslices/homeSlice";
import LoaderPage from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
// import "bootstrap/dist/css/bootstrap.min.css";
import Slider from "react-slick";
import archikadidilogo from "../images/Dr-archika-didi-logo 2 1.png";
import ReactModal from "react-modal";
// import { loginwithgoogle } from '../component/auth/authSlice';
import {
  checkSocialLogin,
  loadUser,
  loginwithgoogle,
} from "../component/auth/authSlice";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

import {
  getHome,
  getHomeModal,
  getTestimonials,
  todayMessageHomeModal,
} from "../Allslices/homeSlice";
import { getTrendingVideo } from "../Allslices/videoSlice";
import { position } from "@chakra-ui/react";

function Home(userDetails) {
  const { testimonials } = useSelector((state) => state.home);
  const dispatch3 = useDispatch();
  useEffect(() => {
    dispatch3(getTestimonials());
  }, []);

  // const { user } = useSelector((state) => state.auth)
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { home } = useSelector((state) => state.home);
  console.log("homeconfig@", home);

  const { trendingvideo } = useSelector((state) => state.video);
  const dispatch = useDispatch();

  let sliderRef1 = useRef();

  let sliderRef2 = useRef();

  const numCards1= testimonials?.length||0
  const minSlidesToShow1 = Math.min(3,numCards1);
 
  const settings1 = {
    infinite: true,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: minSlidesToShow1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
settings1.slidesToShow=minSlidesToShow1
  let gridColumnClass1 = "col-md-4 col-sm-12 col-lg-3";
  if (minSlidesToShow1 === 3) {
    gridColumnClass1 = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow1 === 2) {
    gridColumnClass1 = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow1 === 1) {
    gridColumnClass1 = "col-md-3 col-sm-12 col-lg-3";
  }



  const numCards2= trendingvideo?.length||0
  const minSlidesToShow = Math.min(4,numCards2);
  
  const settingsForTrendingVideo = {
    infinite: true,
    autoplay: true,
    // dots: true,
    autoplaySpeed: 6000,
    speed: 700,
    slidesToShow:4 , 
    slidesToScroll: minSlidesToShow,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  settingsForTrendingVideo.slidesToScroll=minSlidesToShow
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col";
  }


  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          right: "-15px!important",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowRight color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          left: "-35px",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowLeft color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  const maxLength = 100;

  useEffect(() => {
    dispatch(getHome());
    dispatch(getTrendingVideo());
  }, []);

  const { homeModal, todaymessage } = useSelector((state) => state.home);

  const dispatch1 = useDispatch();

  useEffect(() => {
    dispatch1(getHomeModal());
    dispatch(todayMessageHomeModal());
  }, []);

  const [showMessageLoader, setShowMessageLoader] = useState(false);
  const [TodayMessage, setTodayMessage] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowMessageLoader(true);
        const action = await dispatch(todayMessageHomeModal());
        if (todayMessageHomeModal.fulfilled.match(action)) {
          console.log(
            "The Message is fetched Succesffully",
            action?.payload?.message
          );
          setTodayMessage(action?.payload?.message);
          setShowMessageLoader(false);
        }
      } catch (error) {
        setShowMessageLoader(false);
      }
    };

    fetchData();
  }, []);

  const getVideoId = (url) => {
    const videoId = url?.split("v=")[1];
    const ampersandPosition = videoId?.indexOf("&");
    if (ampersandPosition !== -1) {
      return videoId?.substring(0, ampersandPosition);
    }
    return videoId;
  };

  const videoId = getVideoId(home?.image_1?.youtube_url);
  const [user1, setUser1] = useState(null);

  
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(loadUser()).then((resultAction) => {
        if (!resultAction.payload) {
          dispatch(checkSocialLogin());
        }
      });
    }
  }, []);

  const [showFirstModal, setShowFirstModal] = useState(true);

  const [isOpen, setIsOpen] = useState(false);

  // Code to Hide the Displayed Model for 2 Hours if the user Clicked on OK button

  useEffect(() => {
    const modalLastClicked = localStorage.getItem("modalLastClicked");
    const twoHours = 2 * 60 * 60 * 1000; // 2 hours in milliseconds

    // console.log(
    //   "The Condtion Checker:",
    //   (modalLastClicked && Date.now() - parseInt(modalLastClicked) < twoHours)
    // );
    if (
      modalLastClicked &&
      Date.now() - parseInt(modalLastClicked) < twoHours
    ) {
      setIsOpen(false); // Hide modal if less than 2 hours have passed
    } else {
      setIsOpen(true); // Show modal if 2 hours have passed or if it's the first time
    }
  }, []);

  // For verfifying the code is working or not
  // const threeHoursAgo = Date.now() - 3 * 60 * 60 * 1000;
  // console.log("Temprory Passing the Time by 3 Hours", threeHoursAgo);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
    localStorage.setItem("modalDisplayed", "false");
    localStorage.setItem("modalLastClicked", Date.now().toString());
  };
  const navigate = useNavigate();
  const handleNavigateToAboutUs = () => {
    navigate("/aboutus");
    localStorage.setItem("activeTab", "aboutus");
  };

  const { user } = useSelector((state) => state.auth);

  const handleexplorenow = () => {
    localStorage.setItem("activeTab", "onlinecourses");
  };

  //for trending visdeos
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  useEffect(() => {
    if (isOpenVideo) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpenVideo]);

  const openModal = (videoUrl) => {
    console.log("Opening modal with video URL:", videoUrl); // Debug log
    setSelectedVideo(videoUrl);
    setIsOpenVideo(true);
  };

  const closeModal = () => {
    setSelectedVideo("");
    setIsOpenVideo(false);
  };

  return (
    <>
      <MetaData title={home?.meta_title} description={home?.meta_description} tags={home?.meta_tag} />
      {home ? (
        <>
          <div
            className="hero homepage homeimage desktop-only"
            style={{
              backgroundImage: `url(${home?.bannercontent?.banner_image})`,
              backgroundSize: "cover",
              overflow: "hidden",
            }}
          >


            <div className="container padding_h1 ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  {home?.bannercontent?.alignItem === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont" }}
                      dangerouslySetInnerHTML={{
                        __html: home?.bannercontent?.title,
                      }}
                    ></p>
                    <p className="pt-3 text-dark lato homebdes">
                      {home?.bannercontent?.description}
                    </p>
                    <div className="pt-3"></div>
                    <button
                      className={`btn  btn-lg lato text-light shadowbuttons cmn-t-shake ${localStorage.getItem("activeTab") === "aboutus" ? "" : ""
                        }`}
                      onClick={handleNavigateToAboutUs}
                    >
                      Learn More
                    </button>
                  </>)}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  {home?.bannercontent?.alignItem === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont" }}
                      dangerouslySetInnerHTML={{
                        __html: home?.bannercontent?.title,
                      }}
                    ></p>
                    <p className="pt-3 text-dark lato homebdes">
                      {home?.bannercontent?.description}
                    </p>
                    <div className="pt-3"></div>
                    <button
                      className={`btn btn-lg lato text-light shadowbuttons cmn-t-shake ${localStorage.getItem("activeTab") === "aboutus" ? "" : ""
                        }`}
                      onClick={handleNavigateToAboutUs}
                    >
                      Learn More
                    </button>
                  </>)}
                </div>



              </div>

            </div>
          </div>

        
          {/* banner starts here */}
<div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${home?.bannercontent?.res_banner_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {home?.bannercontent?.alignItem === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: home?.bannercontent?.titleres,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {home?.bannercontent?.alignItem === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: home?.bannercontent?.titleres,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>


             
             

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: home?.bannercontent?.descriptionres,
                }}
               >
             
               </p>
               <button
                className={`btn btn-lg lato text-light shadowbuttons cmn-t-shake ${localStorage.getItem("activeTab") === "aboutus" ? "" : ""
                  }`}
                onClick={handleNavigateToAboutUs}
               >
                Learn More
               </button>
             </div>

             
          {/* banner ends here */}

          </div>



        </>
      ) : (
        <LoaderPage />
      )}

     

      {home ? (
        <>


          <section className="card_sec">
            <div className="container mt-5">
              <div className="row ">
                <div className="col-lg-4 col-md-12 col-sm-12 item">
                <a href={home?.bannercontent?.Meditation}>
                <div className="card item-card card-block p-5 cardyou d-flex justify-content-center align-items-center flex-column">
                    <div
                      className="banner-card d-flex justify-content-center align-items-center"
                    // style={{ position: "relative", overflow: "hidden" }}
                    >
                      <img
                        src={home?.cards?.card_1?.thumbnail_image}
                        alt=""
                        style={{ height: "82px", width: "82px" }}
                      />
                    </div>
                    <h5
                      className="text-center fw-bold mt-3 mb-3 pt-4"
                      style={{ fontFamily: "playfair" }}
                    >
                      {home?.cards.card_1.title}{" "}
                    </h5>
                    <p className="text-center lato">
                      {home?.cards?.card_1?.description}
                    </p>
                  </div>

                </a>


                 
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 item">
                <a href={home?.bannercontent?.Motivation}>
                <div className="card item-card card-block p-5 cardyou cardmid d-flex justify-content-center align-items-center flex-column">
                    <div className="banner-card d-flex justify-content-center align-items-center">
                      <img
                        src={home?.cards?.card_2?.thumbnail_image}
                        alt=""
                        style={{ height: "82px", width: "82px" }}
                      />
                    </div>
                    <h5
                      className="text-center fw-bold mt-3 mb-3"
                      style={{ fontFamily: "playfair" }}
                    >
                      {home?.cards.card_2.title}
                    </h5>
                    <p className="text-center lato">
                      {home?.cards?.card_2?.description}
                    </p>
                  </div>
                </a>

               
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 item card_1">
                  <a href={home?.bannercontent?.Philanthropy}>
<div className="card item-card card-block p-5 cardyou d-flex justify-content-center align-items-center flex-column">
                    <div className="banner-card d-flex justify-content-center align-items-center">
                      <img
                        src={home?.cards.card_3?.thumbnail_image}
                        alt=""
                        style={{ height: "82px", width: "82px" }}
                      />
                    </div>
                    <h5
                      className="text-center fw-bold mt-3 mb-3"
                      style={{ fontFamily: "playfair" }}
                    >
                      {home?.cards.card_3.title}
                    </h5>
                    <p className="text-center lato">
                      {home?.cards?.card_3?.description}
                    </p>
                  </div>
</a>

                
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* about youtube */}
      {home ? (
        <>
          <section style={{ overflow: "hidden" }}>
            <div className="container  position-relative  archikamsg">
              {/* <img
                src={morepankh}
                alt=""
                className="position-absolute top-25 start-50 translate-middle"
                height={320}
              /> */}
              <div className="col-12 ">
                <h2
                  className="text-uppercase text-center pb-3 haedingfont messagehometitle"
                  style={{ fontFamily: "myFont" }}
                >
                  {home?.image_1?.title}
                </h2>

                <h5
                  className="text-center  lato mesahedes "
                  style={{ color: "#B767A2" }}
                >
                  {home?.image_1?.subTitle}
                </h5>
              </div>
              <div className="yvideo d-flex justify-content-center pt-4 pb-3">
                <iframe
                  style={{
                    zIndex: 1,
                    // borderRadius: "20px",
                    // boxShadow: " -2px 1px 26px -1px rgba(0,0,0,1)",
                  }}
                  width="816px"
                  height="462px"
                  src={home?.image_1?.youtube_url}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                // allowFullScreen
                ></iframe>
              </div>
              <div className="pt-3 messarch  btnphone">
                <p className=" lato lh-lg  mx-auto ">
                  {home?.image_1?.description}
                </p>
              </div>
            </div>

            <div className="mt-5 d-none d-lg-block"></div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* <!-- about youtube end=== -->

<!-- about=== --> */}
      {home ? (
        <>
          <section className="abouty archikamsg" style={{ overflow: "hidden" }}>
            <div className="color-mixer "></div>
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-sm-12 col-md-12">
                  <div className="aimage">
                    <img
                      src={home?.image_2?.thumbnail_image}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-lg-7 col-sm-12 col-md-12">
                  <div className="fontabout d-flex ">
                    <h1 className="fw-bold pt-3 haedingfont ">
                      <span>{home?.image_2?.title}</span>
                    </h1>
                  </div>
                  <div className="aboutpra">
                    <p
                      className="fw-light"
                      style={{ fontSize: "20px", fontFamily: "myFont", textAlign: 'justify' }}
                      dangerouslySetInnerHTML={{
                        __html: home?.image_2?.description,
                      }}
                    ></p>
                  </div>
                  <div className="col-12 btnphone">
                    <Link to="/aboutus" className="nav-link">
                      <button className="btn-read mt-4 cmn-t-shake lato">
                        Read More&nbsp; &nbsp;
                        <i className="fa-solid fa-circle-arrow-right fs-5"></i>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* <!-- about end=== -->

<!-- Discover Transformative===== --> */}
      {home ? (
        <>
          <section className="pt-4">
            <div className="bgcolor">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-12 btnphone">
                    <div className="pt-4 fontdis">
                      <h1
                        className="haedingfont"
                        style={{
                          color: "#B767A2",
                          fontFamily: "myFont!important",
                        }}
                      >
                        <span>{home?.image_3.title}</span>
                      </h1>
                      <p className="pt-3 lato" style={{ textAlign: 'justify' }}>{home?.image_3?.description}</p>
                      <div className="d-none d-lg-block pt-3  ">
                        <Link to="/books">
                          <button className="btn-read mt-4 cmn-t-shake lato">
                            Read More&nbsp; &nbsp;
                            <i className="fa-solid fa-circle-arrow-right fs-5"></i>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 col-12 ">
                    <div className="bookimg">
                      <img
                        src={home?.image_3?.thumbnail_image}
                        alt=""
                        style={{ width: "auto" }}
                      />
                    </div>
                  </div>
                  <div className="d-lg-none d-sm-block d-md-block d-flex justify-content-center pt-3 pb-3 btnphone">
                    <a href="/books">
                      <button className="btn-read mt-4 cmn-t-shake lato btnbloc">
                        Read More&nbsp; &nbsp;
                        <i className="fa-solid fa-circle-arrow-right fs-5"></i>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* <!-- Discover Transformative end===== -->


  <!-- new eventer====== --> */}
      {home ? (
        <>
          <section className="pt-5" style={{ overflow: "hidden" }}>
            <div
              className="container"
              style={{
                marginTop: "40px",
              }}
            >
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="" style={{ height: "100%" }}>
                    <img
                      src={home?.image_4?.thumbnail_image}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 fontabout ">
                  <h1 className="pt-5 btnphone haedingfont">
                    <span>{home?.image_4?.title}</span>
                  </h1>

                  <p className="pt-4 lato btnphone" style={{textAlign:"justify"}}>
                    {home?.image_4?.description}
                  </p>

                  <a href="/events" className="btnphone">
                    <button className="btn-read cmn-t-shake mt-4 lato ">
                      Join Event Now&nbsp; &nbsp;
                      <i className="fa-solid fa-circle-arrow-right"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* <!-- new eventer end====== -->

<!-- love and trusted --> */}
      {home ? (
        <>
          <section
            className="pt-3 mt-4"
            style={{ overflow: "hidden", background: "#f9f9e09e" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12 mt-3">
                  <h1
                    className="text-center haedingfont "
                    dangerouslySetInnerHTML={{
                      __html: home?.testimonial?.title,
                    }}
                  ></h1>
                  <p
                    className="text-center pt-2 lato homejoinde"
                    style={{ fontSize: "20px" }}
                  >
                    {home?.testimonial?.description}
                  </p>
                </div>
              </div>
            </div>
            <section>
              <div
                className="container beloved pt-5 mt-2"
                style={{ paddingTop: "20px" }}
              >
                <div className="container">
                  <div className={`row mb-5 d-flex justify-content-center mx-2 ${testimonials?.length<2 ?"sliderscontainertesti":""}`}>
                    <Slider
                      ref={(slider) => {
                        sliderRef1 = slider;
                      }}
                      {...settings1}
                    >
                      {Array.isArray(testimonials) &&
                        testimonials.filter((status) => status?.status == true).map((element, innerIndex) => (
                          <div
                            key={`${innerIndex}-${innerIndex}`}
                            className="col-md-6 col-sm-12 col-lg-4"
                            style={{ cursor: "pointer" }}
                          >
                            <div
                              className="card item-card text-dark my-3 mx-2 card_height2"
                              style={{
                                borderRadius: "20px",
                              }}
                            >
                              <div className="customer_comment lato">
                                {element?.description}
                              </div>
                              <div
                                className="image_review"
                                style={{ padding: "15px" }}
                              >
                                <div className="customer_image">
                                  <img src={element.user_image} />
                                </div>
                                <div className="customer_name_review_status pt-2">
                                  <div className="customer_name ">
                                    {element?.user_name}
                                  </div>
                                </div>
                                <div className="customer_name_review_status ">
                                  <div
                                    className="customer_name lato"
                                    style={{ color: "gray" }}
                                  >
                                    {element?.name_id}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            {/* <div className="bgcopink mt-4 pb-5">
              <div className="container">
                <div className="row">
                  {Array.isArray(testimonials) &&
                    testimonials.map((item, index) => (
                      <div className="col-lg-4 col-md-12 col-sm-12">
                        <div className="verified_customer_section" key={index}>
                          <div className="customer_comment lato">
                            {item?.description}
                          </div>
                          <div className="image_review">
                            <div className="customer_image">
                              <img src={item.user_image} />
                            </div>

                            <div className="customer_name_review_status pt-2">
                              <div className="customer_name">
                                {" "}
                                {item?.user_name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div> */}
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* <!-- love and trusted end-->


<!-- podcast start============== --> */}
      {home ? (
        <>
          <section className="pt-3 podcast" style={{ overflow: "hidden" }}>
            <div className="container">
              <div className="row g-5">
                <div className="col-lg-6 col-md-6 col-sm-12 pt-5 Podcast ">
                  <h1 className="pt-5 haedingfont mydividehome">
                    <span>{home?.image_5?.title}</span>
                  </h1>

                  <p
                    className="pt-4  lato"
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: home?.image_5?.description,
                    }}
                  ></p>
                  <div className="d-flex  podcastbtn">
                    <a href="/podcast" className="nav-link">
                      <button className="btn-read cmn-t-shake mt-4 lato">
                        Listen Now&nbsp; &nbsp;
                        <i className="fa-solid fa-circle-arrow-right"></i>
                      </button>
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="podcastimg pt-1">
                    <img
                      src={home?.image_5?.thumbnail_image}
                      className="img-fluid"
                      alt="Podcast"
                      style={{ width: "43vw !important" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* <!-- podcast end============== -->

<!-- download app start ========--> */}
      {home ? (
        <>
          <section className="downloadapp" style={{ overflow: "hidden" }}>
            <div
              className="bg-imgd"
              style={{
                backgroundImage: `url(${Yogashala})`,
                height: "927px",
                backgroundSize: "cover",
              }}
            >
              <div className="container mainappcon">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 paddingtt ">
                    <h1 className="btnphone haedingfont">
                      <span>{home?.image_6?.title}!</span>
                    </h1>
                    <hr style={{ color: "#FD6E12" }} />
                    <p className="lato btnphone">
                      {home?.image_6?.description}
                    </p>
                    <div className="pt-4 btnphone">
                      <a
                        href="#"
                        target="_blank"
                        className="market-btn google-btn mx-1 text-white rounded-3 bg-black"
                        role="button"
                      >
                        <span className="market-button-subtitle text-white">
                          Download on the
                        </span>
                        <span className="market-button-title text-white">
                          Google Play
                        </span>
                      </a>
                      <a
                        href="#"
                        target="_blank"
                        className="market-btn apple-btn mx-1 text-white rounded-3 bg-black"
                        role="button"
                      >
                        <span className="market-button-subtitle text-white">
                          Download on the
                        </span>
                        <span className="market-button-title text-white">
                          App Store
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 mycontainerdapp ">
                    <div className="downimg" style={{
                      backgroundImage:`url(${home?.image_6?.thumbnail_image})`
                      
                    }}>
                      {/* <img src={home?.image_6?.thumbnail_image} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* <!-- download app end ========-->
<!-- explore start=========== --> */}
      {home ? (
        <>
          <section className="explore " style={{ overflow: "hidden" }}>
            <div
              className="exploimg"
              style={{
                backgroundImage: `url(${ExploreBg})`,
                backgroundSize: "cover",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12 exploretext">
                    <p
                      className="text-center aboutusexplore haedingfont"
                      dangerouslySetInnerHTML={{
                        __html: home?.bannercontent?.title_e,
                      }}
                    >
                      {/* {home?.bannercontent?.title_e} */}
                    </p>
                    <p className="text-center lato">
                      {home?.bannercontent?.description_e}
                    </p>
                    <div className="pt-3 d-flex justify-content-center">
                      <Link
                        to="/courses"
                        className={`nav-link ${localStorage.getItem("activeTab") == "onlinecourses"
                          ? "colorChange"
                          : ""
                          }`}
                        onClick={handleexplorenow}
                      >
                        <button className="btn-read cmn-t-shake fw-semibold">
                          EXPLORE NOW
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}

      {/* <!-- explore end=========== -->

<!-- youtube --> */}
      {/* <>
        {trendingvideo ? (
          <div className="container mt-5">
            <div className="row w-100 d-flex justify-content-center">
              <Slider
                ref={(slider) => {
                  sliderRef1 = slider;
                }}
                {...settingsForTrendingVideo}
              >
                {Array.isArray(trendingvideo) &&
                  trendingvideo.map((item, index) =>
                    item.popularity === "yes" ? (
                      <div className="px-1">
                        <div className="item">
                          <div className="position-relative">
                            <div
                              className="overlay"
                              onClick={() => openModal(item.videourl)}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                zIndex: 1,
                              }}
                            ></div>
                            <iframe
                              width="100%"
                              height="150px"
                              style={{ borderRadius: "10px" }}
                              src={item.videourl}
                              title="YouTube video player"
                              onClick={() => openModal(item.videourl)}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
              </Slider>
            </div>
          </div>
        ) : (
          <LoaderPage />
        )}
      </> */}

      <section>
        {trendingvideo ? (
          <div
            className="container beloved pt-5 mt-2"
            style={{ paddingTop: "20px" }}
          >
            <div className="container">
              <div className="row mb-5 d-flex justify-content-center mx-2">
                <Slider
                  ref={(slider) => {
                    sliderRef1 = slider;
                  }}
                  {...settingsForTrendingVideo}
                >
                  {Array.isArray(trendingvideo) &&
                    trendingvideo
                      .filter((item) => item?.popularity === "yes") 
                      .map((item, innerIndex) => (
                        <div className="item" key={innerIndex}>
                          <div className="position-relative px-1 trandingvideo">
                            <div
                              className="overlay"
                              onClick={() => openModal(item.videourl)}
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                zIndex: 1,
                              }}
                            ></div>
                            <iframe
                              width="100%"
                              // height="150px"
                              style={{ borderRadius: "10px" }}
                              src={item.videourl}
                              title="YouTube video player"
                              onClick={() => openModal(item.videourl)}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                            ></iframe>
                          </div>
                        </div>
                      ))}
                </Slider>

              </div>
            </div>
          </div>
        ) : (
          <LoaderPage />
        )}
      </section>

      <ReactModal
        isOpen={isOpenVideo}
        onRequestClose={closeModal}
        style={{
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
          content: {
            top: "40%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginTop: "5rem",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "0",
            overflow: "hidden",
            border: "transparent",
            backgroundColor: "transparent",
            zIndex: 200,
            height: "280px",
            width: "380px"
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "20px",
            zIndex: 100,
          }}
        >
          <button
            onClick={closeModal}
            style={{ background: "none", border: "none", fontSize: "20px" }}
          >
            ✖
          </button>
        </div>
        <div
          className=""
          style={{ width: "100%", height: "calc(100% - 20px)" }}
        >
          <iframe
            width="100%"
            height="100%"
            style={{ borderRadius: "10px" }}
            src={selectedVideo}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </ReactModal>

      <Modal
        className="d-flex align-items-center px-2"
        show={isOpen}
        onHide={hideModal}
        style={{
          backgroundImage: `url(${BackgroundModal})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        <Modal.Header className="d-flex justify-content-center border-0 ">
          <div className="quotesImg d-flex ">
            <img
              src={Quotes}
              style={{
                width: "4rem",
              }}
            />
          </div>
        </Modal.Header>

        {showMessageLoader ? (
          <Modal.Body className="text-uppercase mx-2 p-4">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body className=" mx-2 p-4">
            <div className="">
              <h4
                className=" lh-lg"
                style={{ fontFamily: "playfair", marginRight: "5px" }}
              >
                {TodayMessage?.message}
              </h4>
              <div
                className="m-2 text-black text-center pt-4 fw-bold"
                style={{ fontFamily: "playfair" }}
              >
                <h2 className="messageautgor">-Dr. Archika Didi</h2>
                {/* <img src={archikadidilogo} /> */}
              </div>
            </div>
          </Modal.Body>
        )}

        <Modal.Footer className={`d-flex justify-content-center border-0`}>
          <button
            type="button"
            className="btn btn-secondary buttonsColor text-Faline-center m-2 fw-semibold"
            data-bs-dismiss="modal"
            onClick={hideModal}
            style={{
              // backgroundColor: "#FF9933",
              width: "100px",
            }}
            disabled={showMessageLoader}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Home;
