import { useState, useEffect } from "react";

const useGeolocation = () => {
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    if (!countryCode) {
      const fetchLocation = async () => {
        try {
          const response = await fetch(`https://ip.nf/me.json`);
          const data = await response.json();
          const code = data.ip.country_code;
          // console.log("The response of the IP is:", code);
          // localStorage.setItem("countryCode", code);
          setCountryCode(code);
        } catch (error) {
          console.error("Error fetching geolocation data:", error);
        }
      };

      fetchLocation();
    }
  }, [countryCode]);

  return countryCode;
};

export default useGeolocation;
