import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";

const initialState = {

    isLoading: false,
    error: null,
    video: null,
    BhagwatSinglelist:null,
    geetamainpage: null,
    BhagwatVerses: null,
    Bhagwattopvideo: null,
    BhagwatImage: null,

}

// videoCategory

export const getCategoryBhagwat = createAsyncThunk(
    'all_videocategory',
    async (_, { dispatch, rejectWithValue }) => {
      
        try {
            dispatch(Bhagwatstart());
            const response = await axios.get(`Bhagwatgita/get_bhagwatgitacategory`)
         
            dispatch(BhagwatSuccess(response?.data))
            

        } catch (error) {
            dispatch(BhagvaFailure(error.response.data.message))
        }
    }
)


// list
export const getlistBhagwat = createAsyncThunk(
    'all_videolist',
    async ({categoryId, text}, { dispatch, rejectWithValue }) => {

        try {
            dispatch(Bhagvaliststart());
            const response = await axios.get(`Bhagwatgita/get_BhagwatChapter?categoryId=${categoryId ?? ""}&text=${text ?? ""}`)
            dispatch(BhagvalistSuccess(response?.data))

        } catch (error) {
            dispatch(BhagvalistFailure(error.response.data.message))
        }
    }
)

// singlListAPI
export const getSinglelistBhagwat = createAsyncThunk(
    'Bhagwatgita/getsinglechapter/',
    async (slug, { dispatch, rejectWithValue }) => {
        try {
            dispatch(BhagvaSingleliststart());
            const response = await axios.get(`Bhagwatgita/getsinglechapter/${slug}`);
            dispatch(BhagvavSinglelistSuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(BhagvaSinglelistFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const getGeetaMainPage = createAsyncThunk(
    'config/getbhagwatgeetapage',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(geetamainpagestart());
            const response = await axios.get(`config/getbhagwatgeetapage`);
            dispatch(geetamainpageSuccess(response?.data?.data))
            // return response?.data;
        } catch (error) {
            dispatch(geetamainpageFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)


// verses
export const getVersesBhagwat = createAsyncThunk(
    'single_bhagwatVerse',
    async (slug, { dispatch, rejectWithValue }) => {
        try {
            dispatch(BhagwatVersesstart());
            const response = await axios.get(`Bhagwatgita/single_bhagwatVerse/${slug}`)
            dispatch(BhagwatVersesSuccess(response?.data))

        } catch (error) {
            dispatch(BhagwatVersesFailure(error.response.data.message))
        }
    }
)

export const get_topvideo_VersesBhagwat = createAsyncThunk(
    'getAll_bhagwatVerse',
    async (slug, { dispatch, rejectWithValue }) => {
        try {
            dispatch(Bhagwattop_videostart());
            const response = await axios.get(`Bhagwatgita/getAll_bhagwatVerse`)
            dispatch(Bhagwattop_videoSuccess(response?.data))

        } catch (error) {
            dispatch(Bhagwattop_videoFailure(error.response.data.message))
        }
    }
)

export const BhagwatChapterImage= createAsyncThunk(
    `config/get_bhagwat_image`,
    async (_, { dispatch, rejectWithValue }) => {
        
        try {
            dispatch(Bhagwat_ImageStart());
            const response = await axios.get(`config/get_bhagwat_image`)
         
            
            dispatch(Bhagwat_ImageSuccess(response?.data))
            
        } catch (error) {
            dispatch(Bhagwat_ImageFailure(error.response.data.message))
        }
    }
)

const BhagwatSlice = createSlice({
    name: 'BhagwatGita',
    initialState,
    reducers: {
        Bhagwatstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        BhagwatSuccess(state, action) {

            state.BhagwatCategory = action.payload?.allgallerycategories

            ;
            state.isLoading = false;
        },
        BhagvaFailure(state, action) {
            state.video = null;
            state.isLoading = false;
            state.error = action.payload;

        },


        
        Bhagvaliststart(state) {
            state.isLoading = true;
            state.error = null;
        },
        BhagvalistSuccess(state, action) {

            state.Bhagwatlist = action.payload?.galleryListItems


            state.isLoading = false;
        },
        BhagvalistFailure(state, action) {
            state.video = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        BhagvaSingleliststart(state) {
            state.isLoading = true;
            state.error = null;
        },
        BhagvavSinglelistSuccess(state, action) {

            state.BhagwatSinglelist = action?.payload;


            state.isLoading = false;
        },
        BhagvaSinglelistFailure(state, action) {
            state.BhagwatSinglelist = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        geetamainpagestart(state, action) {
            state.isLoading = true;
            state.error = null; 
        },
        geetamainpageSuccess(state, action) {
            state.geetamainpage = action.payload;
            state.isLoading = false;
        },
        geetamainpageFailure(state, action) {
            state.geetamainpage = null;
            state.isLoading = false;
            state.error = action.payload
        },
              
        BhagwatVersesstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        BhagwatVersesSuccess(state, action) {

            state.BhagwatVerses = action.payload.data;



            state.isLoading = false;
        },
        BhagwatVersesFailure(state, action) {
            state.BhagwatVerses = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        Bhagwattop_videostart(state) {
            state.isLoading = true;
            state.error = null;
        },
        Bhagwattop_videoSuccess(state, action) {

            state.Bhagwattopvideo = action.payload?.wisdomLists
            ;



            state.isLoading = false;
        },
        Bhagwattop_videoFailure(state, action) {
            state.Bhagwattopvideo = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        Bhagwat_ImageStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        Bhagwat_ImageSuccess(state, action) {

            state.BhagwatImage = action?.payload?.bhagwatgeeta_Image
            ;
             state.isLoading = false;
        },
        Bhagwat_ImageFailure(state, action) {
            state.BhagwatImage = null;
            state.isLoading = false;
            state.error = action?.payload;

        },


    }
})


export const { Bhagwatstart, BhagwatSuccess, BhagvaFailure,Bhagvaliststart,BhagvalistSuccess,BhagvalistFailure,BhagvaSingleliststart,BhagvavSinglelistSuccess,BhagvaSinglelistFailure, geetamainpagestart, geetamainpageSuccess, geetamainpageFailure,BhagwatVersesstart,BhagwatVersesSuccess,BhagwatVersesFailure,Bhagwattop_videostart,Bhagwattop_videoSuccess,Bhagwattop_videoFailure,Bhagwat_ImageStart,Bhagwat_ImageSuccess,Bhagwat_ImageFailure } = BhagwatSlice.actions;

export default BhagwatSlice.reducer;
