import React from "react";
import Carousel from "./Carousel";
import ".././component/layout/style.css";

const PodcastFavourite = () => {
  return (
    <>
      <div
        className="poascastsub3 p-3 pt-5 pb-5 mb-5"
        style={{ height: "100vh", overflow: "auto", overflowX: "hidden" }}>
        <h2 className="py-5" style={{ fontFamily: "Lato" }}>
          My Favorite
        </h2>
        <hr className="hr-3" />
        <Carousel />
        <br />
        <Carousel />
      </div>
    </>
  );
};

export default PodcastFavourite;
