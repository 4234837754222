import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../appUtils/axiosConfig';
const filters = {
    categoryId: '',
    language: '',
    sort: '',
    contentType: '',
    text: ''
}
const initialState = {
    isLoading: false,
    error: null,
    wellnesslist: [],
    currentPage: 0,
    limit: 8,
    totalwellness: 0,
    wellnessCategoryList: [],
    wellness: null,
    singlevideowellness: null,
    singlewellnessarticle: null,
    Filters: filters

};

export const getAllWellness = createAsyncThunk(
    'wellness/allwellness',
    async ({ page, limit }, { dispatch, getState, rejectWithValue }) => {
        const { allwellness: { Filters: { categoryId, language, sort, contentType, text } } } = getState();

        try {
            dispatch(getallwellnessStart());
            const response = await axios.get(`wellness/allWellnessLists?page=${page ?? + 1}&limit=${limit ?? ""}&categoryId=${categoryId ?? ""}&contentType=${contentType ?? ""}&sort=${sort ?? ""}&language=${language ?? ""}&text=${text ?? ""}`)
            dispatch(getallwellnessSuccess((response?.data)))
            return response?.data
        } catch (error) {
            dispatch(getallwellnessfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)

        }

    }
)

export const getSingleWellness = createAsyncThunk(
    'wellness/singlewellness',
    async (slug, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getsinglewellnessStart());
            const response = await axios.get(`wellness/${slug}`)
            dispatch(getsinglewellnessSuccess((response?.data)))
            return response?.data
        } catch (error) {
            dispatch(getsinglewellnessfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)

        }

    }
)

export const getSingleWellnessVideo = createAsyncThunk(
    'wellness/singlewellnessvideo',
    async (slug, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getsinglewellnessVideoStart());
            const response = await axios.get(`wellness/video/${slug}`)
            dispatch(getsinglewellnessVideoSuccess((response?.data)))
            return response?.data
        } catch (error) {
            dispatch(getsinglewellnessVideofailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)

        }

    }
)

export const getSingleWellnessArticle = createAsyncThunk(
    'wellness/singlewellnessarticle',
    async (slug, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getsinglewellnessArticleStart());
            const response = await axios.get(`wellness/article/${slug}`)
            dispatch(getsinglewellnessArticleSuccess((response?.data)))
            return response?.data
        } catch (error) {
            dispatch(getsinglewellnessArticlefailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)

        }

    }
)

export const getWellnessCategory = createAsyncThunk(
    'wellness/allwellness',
    async (_, { dispatch, getState, rejectWithValue }) => {

        try {
            dispatch(getallwellnessCategotyStart());
            const response = await axios.get(`wellness/allWellnessCategory?page=${1}&limit=${1000}`)
            dispatch(getallwellnessCategotySuccess((response?.data)))
            return response?.data
        } catch (error) {
            dispatch(getallwellnessCategotyfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)

        }

    }
)

export const getwellnessbanner = createAsyncThunk(
    'config/get_wellnessbanner',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(wellnessstart());
            const response = await axios.get(`config/get_wellnessbanner`)
            dispatch(wellnessSuccess(response?.data?.wellnessbanner))

        } catch (error) {
            dispatch(wellnessFailure(error.response.data.message))
        }
    }
)

const wellnessSlice = createSlice({
    name: 'wellness',
    initialState,
    reducers: {
        getallwellnessStart(state) {
            state.isLoading = true;
            state.error = null;
            state.wellnesslist = []

        },
        getallwellnessSuccess(state, action) {
            state.isLoading = false;
            state.currentPage = action.payload.currentPage;
            state.limit = action.payload.limit;
            state.totalwellness = action.payload.totalwellness;
            state.wellnesslist = action.payload?.wellnessLists;
        },
        getallwellnessfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },
        getallwellnessCategotyStart(state) {
            state.isLoading = true;
            state.error = null;
            state.wellnessCategoryList = []
        },
        getallwellnessCategotySuccess(state, action) {
            state.isLoading = false;
            state.wellnessCategoryList = action.payload?.wellnessCategories;
        },
        getallwellnessCategotyfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },
        wellnessstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        wellnessSuccess(state, action) {
            state.wellness = action.payload;
            state.isLoading = false;
        },
        wellnessFailure(state, action) {
            state.wellness = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        getsinglewellnessStart(state, action) {
            state.isLoading = true;
            state.error = null;
        },
        getsinglewellnessSuccess(state, action) {
            state.isLoading = false;
            state.wellness = action.payload?.wellness;
        },
        getsinglewellnessfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },
        getsinglewellnessVideoStart(state, action) {
            state.isLoading = true;
            state.error = null;
        },
        getsinglewellnessVideoSuccess(state, action) {
            state.isLoading = false;
            state.singlevideowellness = action.payload?.wellnessvideo;
        },
        getsinglewellnessVideofailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },
        getsinglewellnessArticleStart(state, action) {
            state.isLoading = true;
            state.error = null;
        },
        getsinglewellnessArticleSuccess(state, action) {
            state.isLoading = false;
            state.singlewellnessarticle = action.payload?.Wellnessarticle;
        },
        getsinglewellnessArticlefailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },
        filtersUpdate(state, action) {
            const { categoryId, language, sort, contentType, text } = action.payload;
            if (categoryId !== undefined) {
                state.Filters.categoryId = categoryId;
            }
            if (language !== undefined) {
                state.Filters.language = language;
            }
            if (sort !== undefined) {
                state.Filters.sort = sort;
            }
            if (contentType !== undefined) {
                state.Filters.contentType = contentType;
            }
            if (text !== undefined) {
                state.Filters.text = text;
            }
        }
    }
})

export const { filtersUpdate,
    getallwellnessStart, getallwellnessSuccess, getallwellnessfailure,
    getallwellnessCategotyStart, getallwellnessCategotySuccess, getallwellnessCategotyfailure,
    wellnessstart, wellnessSuccess, wellnessFailure,
    getsinglewellnessStart, getsinglewellnessSuccess, getsinglewellnessfailure,
    getsinglewellnessVideoStart, getsinglewellnessVideoSuccess, getsinglewellnessVideofailure,
    getsinglewellnessArticleStart, getsinglewellnessArticleSuccess, getsinglewellnessArticlefailure

} = wellnessSlice.actions;

export default wellnessSlice.reducer;
