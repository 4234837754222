import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import ".././component/layout/style.css";
import crownimg from "../../src/images/crown.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getAllalbums,
  getallalbumcategories,
  getallartists,
  getMusicBanner,
  filtersUpdate,
} from "../Allslices/albumSlice";
import { getalltags } from "../Allslices/tagSlice";
import Select from "react-select";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
import { getmyOrder } from "../Allslices/orderSlice";
import filtericon from "../images/filtericons.png";
import searchicon from "../images/searchicons.png";
import usePreserveScroll from "./histrorysave";
import nodata from "../images/No-Data.png";

const Music = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  usePreserveScroll();
  const handleNavigateToAlbums = (slug) => {
    navigate(`/music/${slug}`);
  };
  const query = location.search.split("?")[1];

  const [selectedOption, setSelectedOption] = useState(null);
  const { album = [], isLoading, totalalbum, categories, artists, musicbanner, limit, currentPage,
    Filters: { categoryId, language, sort, contentType, text, taggg, artistId }

  } = useSelector((state) => state.album);

  const { tags } = useSelector((state) => state.tag);
  // let initialfilters = {
  //   categoryId: null,
  //   artistId: null,
  //   tag: null,
  //   newest: "",
  //   text: query ?? "",
  // };
  // const [filters, setfilters] = useState(initialfilters);
  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);

  useEffect(() => {
    dispatch(getallalbumcategories());
    dispatch(getallartists());
    dispatch(getMusicBanner());
    dispatch(getalltags());

    // dispatch(getAllalbums({
    //   page: currentPage,
    //   limit: limit,
    // }))
  }, []);


  const transformedData =
    Array.isArray(tags) &&
    tags.map((item) => ({
      label: item.title,
      value: item.title,
    }));

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption)
  }

  const handleFilterChange = (e) => {
    const { value, name } = e.target;

    const payload = {
      [name]: value
    };

    dispatch(filtersUpdate(payload));
  };

  const handleFiltersSubmit = (e, action) => {
    let tags = null;
    if (selectedOption?.length > 0) {
      tags = selectedOption.map((item) => item.value).join(",");
    }

    if (action == "loadmore") {
      dispatch(
        getAllalbums({
          page: currentPage,
          limit: limit + 8,
          taggg: tags,
        })
      )
    } else {
      dispatch(
        getAllalbums({
          page: currentPage,
          limit: limit,
          taggg: tags,

        })
      )
      window.scrollTo(
        {
          top: 650,
          behavior: 'smooth'
        })
    }


  };

  const maxLength = 100;
  const maxLength1 = 200;

  const { orderAll } = useSelector((state) => state?.order);
  const [orderIds, setOrderIds] = useState();

  useEffect(() => {
    for (let j = 0; j < album.length; j++) {
      for (let i = 0; i < orderAll.length; i++) {
        if (album[j]._id === orderAll[i]?.orderItems?.product) {
          setOrderIds(orderAll[i]?.orderItems?.product);
        }
      }
    }
  }, [orderAll, album]);

  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);

  useEffect(() => {
    dispatch(filtersUpdate(query ? { text: query } : { text: text }));
    handleFiltersSubmit();
  }, [query])

  return (
    <>
      <MetaData title={musicbanner?.meta_title} tags={musicbanner?.meta_tag} description={musicbanner?.meta_description} />

      {/* <!-- banner section --> */}
      {musicbanner ? (
        <>
          <div
            className="wisdomimg desktop-only "
            style={{
              backgroundSize: "cover",
              backgroundImage: `url(${musicbanner?.background_image})`,
            }}
          >
            <div className="container wisdompad ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

                  {musicbanner?.alignItem === "left" && (<>
                    <div>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {musicbanner?.heading}
                        </span>
                      </h1>
                      <p
                        className=" w-75 wisdomPara homebdes"
                        style={{
                          fontFamily: "Lato",
                          textAlign: "justify"
                        }}
                      >
                        {musicbanner?.subheading}
                      </p>
                    </div></>)}

                </div>
                <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">

                  {musicbanner?.alignItem === "right" && (<>
                    <div>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {musicbanner?.heading}
                        </span>
                      </h1>
                      <p
                        className=" w-75 wisdomPara homebdes"
                        style={{
                          fontFamily: "Lato",
                          textAlign: "justify"
                        }}
                      >
                        {musicbanner?.subheading}
                      </p>
                    </div></>)}

                </div>
              </div>
            </div>


          </div>

          <div className="mobile-only">
            <div className="container-fluid mobile-only" style={{ position: "relative", display: "flex" }}>
              <img src={`${musicbanner?.res_background_image}`} />
              <div className="container" style={{ position: "absolute", height: "100%" }}
              >
                <div className="row" style={{ height: "100%" }}>
                  <div className="col-6 d-flex justify-content-start align-items-center">

                    {musicbanner?.alignItem === "left" && (<>
                      <p
                        className="homebannertitle"
                        style={{ fontFamily: "myFont", fontSize: "24px" }}
                        dangerouslySetInnerHTML={{
                          __html: musicbanner?.res_heading,
                        }}
                      ></p>
                    </>)}

                  </div>
                  <div className="col-6 d-flex justify-content-start align-items-center">
                    {musicbanner?.alignItem === "right" && (<>
                      <p
                        className="homebannertitle"
                        style={{ fontFamily: "myFont", fontSize: "24px" }}
                        dangerouslySetInnerHTML={{
                          __html: musicbanner?.res_heading,
                        }}
                      ></p>
                    </>)}
                  </div>


                </div>





              </div>


            </div>

            <div className="container">
              <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: musicbanner?.res_subheading,
                }}
              >

              </p>

            </div>


            {/* banner ends here */}

          </div>





        </>

      ) : (
        <SmallLoader />
      )}
      {/* <!-- banner section end --> */}

      {/* <!--  --> */}
      <div className="container filterpad shadow py-3">

        <div className="row  p-3">
          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2"
            id="mobileScreen"
          >
            <label
              for="filter"
              className="fw-bold myfiltericon"
              style={{ color: "#A0A0A0" }}
            >
              <img style={{ width: "25px" }} src={filtericon} alt="" />
            </label>
            <select
              value={categoryId}
              name="categoryId"
              onChange={(e) => handleFilterChange(e)}
              className="select searchborder rounded-pill p-2 w-100"
              id="selectFilter"
            >
              <option value="">Category</option>
              {Array.isArray(categories) &&
                categories?.map((item, index) => {
                  return <option value={item?._id}>{item?.title}</option>;
                })}
            </select>
          </div>

          <div className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center">
            <Select
              isMulti
              name="taggg"
              options={transformedData}
              onChange={handleChange}
              value={selectedOption}
              placeholder={"Select Tags"}
              className="select  rounded-pill py-2 w-100 selecttagmusic"
              id="selectFilter"
              classNames={{
                control: (state) =>
                  state.isFocused
                    ? "searchborder rounded-pill "
                    : "searchborder rounded-pill ",
              }}
              styles={{
                control: (provided) => ({
                  ...provided,
                  // height: "40px",
                  // minHeight: "40px",
                  borderRadius: "20px",
                  // border: "1.5px solid",
                  color: "gray",
                }),
                singleValue: (provided) => ({
                  ...provided,
                  height: "30px",
                  lineHeight: "30px",
                }),
                dropdownIndicator: (provided) => ({
                  ...provided,
                  height: "30px",
                }),
              }}
            />
          </div>

          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
            id="mobileScreen"
          >
            <select
              value={sort}
              name="sort"
              className="select searchborder rounded-pill p-2 w-100"
              id="selectFilter"
              onChange={(e) => handleFilterChange(e)}
            >
              <option value="English">Sort By</option>
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
            </select>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-end align-items-center ">
            <label
              for="filter"
              className="fw-bold mysearchicon"
              style={{ color: "#A0A0A0" }}
            >
              <img
                src={searchicon}
                alt=""
                style={{ width: "25px" }}
                onClick={handleFiltersSubmit}
              />
            </label>
            <form class=" w-100 pt-4" role="search">
              <input
                class="form-control me-2 w-100 searchborder"
                // type="search"
                name="text"
                placeholder="Search by keyword..."
                aria-label="Search"
                onChange={(e) => handleFilterChange(e)}
                value={text}
                style={{ borderRadius: "20px" }}
              />
              <i class="fa-solid fa-magnifying-glass mysearchiconforphone "></i>
            </form>
          </div>
          <div className="pt-md-3 d-flex justify-content-center align-items-center pt-3">

            <button
              className="btn  shadowbuttons  btn-lg t-2 text-light cmn-t-shake mb-2"
              style={{
                padding: "0.6rem 2.5rem",
                borderRadius: "26px",
              }}
              onClick={handleFiltersSubmit}
            >
              SEARCH
            </button>

          </div>
        </div>
      </div>

      {/* <!--  --> */}

      {/* <!-- Album section --> */}
      <section>
        <div className="container artvid my-3 pb-4">
          {album?.length == 0 || album == null ? (
            <div style={{ textAlign: "center" }}>
              <section>
                <div className="text-center d-flex justify-content-center">
                  <img style={{ width: "400px" }} src={nodata} alt="No Data Image" />
                </div>
              </section>
            </div>
          ) : (
            <div className="row g-3" ref={listStartRef} id="listStart">
              {album?.filter((status) => status.status).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="col-md-6 col-sm-12 col-lg-3 position-relative"
                    // onClick={() => handleNavigateToAlbums(item?.slug)}
                    style={{ borderRadius: "22px" }}
                  ><Link to={`/music/${item?.slug}`}>
                      <div
                        className="card item-card text-dark card_height1 "
                        style={{
                          borderRadius: "10px",
                        }}
                      >
                        <img
                          src={item?.thumbnail_image}
                          alt=""
                          style={{
                            borderRadius: "10px 10px 0px 0px",
                            aspectRatio: "3/2",
                          }}
                        />
                        <div className="container mt-1">
                          <h4
                            className="mt-1"
                            style={{
                              fontFamily: "Playfair Display SC",
                              color: "#000000",
                            }}
                          >
                            {item?.title?.length > maxLength
                              ? `${item?.title.substring(0, maxLength)}...`
                              : item?.title}
                          </h4>
                          <p
                            className="card-text lato mb-2"
                            dangerouslySetInnerHTML={{
                              __html:
                                item?.description?.length > maxLength1
                                  ? `${item?.description.substring(
                                    0,
                                    maxLength1
                                  )}...`
                                  : item?.description,
                            }}
                            style={{
                              marginLeft: "0.0rem",
                              fontFamily: "Lato",
                              color: "#5B5B5B",
                            }}
                          ></p>

                        </div>
                      </div>
                    </Link>
                    {item?._id !== orderIds && item?.isPaid ? (
                      <img
                        src={crownimg}
                        style={{
                          top: "1.2rem",
                          right: "1.7rem",
                          width: "1.5rem",
                        }}
                        className="img-fluid  position-absolute"
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
              <div className="col-12">
                <div className="pt-3 d-flex justify-content-center py-5">
                  {album &&
                    album?.length == totalalbum ? (
                    ""
                  ) : (
                    <a>
                      <button
                        className="btn shadowbuttons btn-lg text-light cmn-t-shake"
                        onClick={(e) => handleFiltersSubmit(e, "loadmore")}
                      >
                        {isLoading ? (
                          <div class="spinner-border text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          <span>LOAD MORE</span>
                        )}
                      </button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* <!-- Album section end --> */}
    </>
  );
};

export default Music;
