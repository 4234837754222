import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import "../../component/layout/style.css";
import "../../component/auth/auth.css";
import { useSelector, useDispatch } from 'react-redux';
import { addPost, fetchPosts, isAnonymusPost } from '../../Allslices/communitySlice';
import AppButton from '../../component/shared/AppButton';
import { SHOW_ERROR_NOTIFICATION, SHOW_SUCCESS_NOTIFICATION } from '../../appUtils/appToast';
import 'react-toastify/dist/ReactToastify.css';
import AddFileModal from '../../component/modal/AddFileModal';
import anonymousUser from '../../images/anonymous_user.png'


const AddPostModal = ({ open, onHide }) => {

    const [selectedType, setSelectedType] = useState('question');

    const [showAddFile, setShowAddFile] = useState(false);

    const [selectedFiles, setSelectedFiles] = useState({
        audio: null,
        video: null,
        image: [],
    });

    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth)

    const userImage = localStorage.getItem('userImage');

    const { addPostState: { loading },postAnonymus } = useSelector((state) => state.community);

    const initialValue = {
        description: '',
        type: selectedType,
        image: null,
        audio: null,
        video: null,
        anonymous: false,

    };

    const [formData, setFormData] = useState(initialValue);

    const handleSubmit = () => {
        formData.image = selectedFiles.image;
        formData.audio = selectedFiles.audio;
        formData.video = selectedFiles.video;
        formData.anonymous = postAnonymus;

        dispatch(addPost(formData)).then((resultAction) => {
            if (resultAction?.error) {
                SHOW_ERROR_NOTIFICATION(resultAction.payload);
                return;
            }
            SHOW_SUCCESS_NOTIFICATION('Added successfully!');
            //setFormData(initialValue);
            onHide();
            dispatch(isAnonymusPost(false));
            dispatch(fetchPosts({ type: 'all' }));
        });
    }

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;

        if (name === 'anonymous') {
            dispatch(isAnonymusPost(!postAnonymus))
        } else {
            setFormData({
                ...formData,
                [name]: type === "file" ? files : value
            });
        }
    }
    const changeType = (type) => {
        setFormData({
            ...formData,
            type
        });
        setSelectedType(type);
    }

    return (
        <>
            <Modal
                show={open}
                onHide={onHide}
                dialogClassName="CommunityModalBackground"
            >
                <div className="modal-content CommunityModalContent d-flex justify-content-center ">
                    <div className=" modalHeader border-0">
                        <div className="topButttons">
                            <button type="button" onClick={onHide} className="btn-close modalCloseButton" ></button>
                            <label className='btn  modalButtons' /* for="upload" */ onClick={() => setShowAddFile(true)}>Upload file</label>
                        </div>
                        <div className="headerListButtons">
                            <ul className='p-0'>
                                <button className='text-dark' onClick={() => changeType('question')} style={{
                                    flexGrow: 1,
                                    textAlign: 'center',
                                    borderBottom: selectedType == 'question' ? "2px solid #B767A2" : null,
                                }}><span style={{ fontSize: "smaller" }}>Ask Question</span></button>
                                <button className='text-dark' onClick={() => changeType('thought')} style={{
                                    flexGrow: 1,
                                    textAlign: 'center',
                                    borderBottom: selectedType == 'thought' ? "2px solid #B767A2" : null,
                                }}><span style={{ fontSize: "smaller" }}>Your Thoughts</span></button>

                            </ul>
                        </div>
                    </div>
                    <div className="modal-body CommunityModalBody">
                        <div className=" d-flex align-items-center">
                            {!postAnonymus ? (
                                <>
                                    <img src={user?.image ?? ''} alt="user_image" style={{ height: '2.5rem',width:'2.5rem', borderRadius:'50%' }}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "default_usericon.jpg";
                                            // currentTarget.style.width = "50px";
                                            currentTarget.style.height = "50px";
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <img src={anonymousUser ?? ''} alt="user_image" style={{ height: '2.5rem', borderRadius:'50%' }}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = "default_usericon.jpg";
                                            // currentTarget.style.width = "50px";
                                            currentTarget.style.height = "50px";
                                        }}
                                    />
                                    {/* <img src={polygon} alt="" style={{ width: '0.8rem', height: '0.6rem' }} /> */}
                                </>
                            )}
                        </div>
                        <div className="modalCommenting text-muted py-2">
                            <textarea
                                name='description'
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder={selectedType == 'question' ?
                                    'Start your question with “What”  “How”  “Why”  etc.' :
                                    'Share what\'s on your mind.'
                                }
                                style={{
                                    border: '0px ',
                                    background: 'transparent', width: '100%',
                                    maxHeight: '11rem',
                                    minHeight: '3rem',
                                }}></textarea>
                        </div>
                    </div>
                    <div className="modal-footer  d-flex justify-content-between px-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={postAnonymus} id="anonymuslabel" name="anonymous" onChange={handleInputChange} />
                            <label className="form-check-label" for="anonymuslabel">
                                Post as Anonymous
                            </label>
                        </div>
                        {/* <button type="button" className="btn text-secondary btn-white" onClick={onHide} >Close</button> */}
                        <AppButton
                            text={selectedType == 'question' ? 'Add A Question' : 'Share Thought'}
                            onSubmit={handleSubmit}
                            loading={loading}
                        />
                    </div>
                </div>
            </Modal>

            {/* <!-- Modal --> */}
            {showAddFile && <AddFileModal open={showAddFile}
                onHide={() => setShowAddFile(false)}
                inputFiles={selectedFiles}
                onSubmit={(result) => setSelectedFiles(result)}
            />}

        </>

    );
}

export default AddPostModal;