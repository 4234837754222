import React, { useEffect, useRef, useState } from "react";
import ".././component/layout/style.css";
import playbtn from "../images/playy-button (1).png";
// import bg from "../images/btop-bg.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  filtersUpdate,
  getAllPodcast,
  getPodcastBanner,
  getLanguages,
  getPodcastCategory,
} from "../Allslices/podcastSlice";
import usePreserveScroll from "./histrorysave"; // Adjust the path accordingly
import { getalltags } from "../Allslices/tagSlice";
import Select from "react-select";
import nodata from "../images/No-Data.png";
import filtericon from "../images/filtericons.png";
import searchicon from "../images/searchicons.png";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
const Podcast = () => {
  const location = useLocation();
  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  const query = location.search.split("?")[1];
  const [fetchedPodcast, setFetchedPodcast] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { tags } = useSelector((state) => state.tag);

  let initialfilters = {
    categoryId: null,
    language: null,
    tags: null,
    newest: "",
    text: query ?? "",
  };

  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);

  const [filters, setFilters] = useState(initialfilters);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    podcastlist,
    podcastbanner,
    podcastCategoryList,
    currentPage,
    limit,
    isLoading,
    totalalbums,
    Languages,
    Filters: { categoryId, language, sort, contentType, text },
  } = useSelector((state) => state.podcast);
  const handleNavigateToPodcastallepisodes = (slug) => {
    navigate(`/podcast/${slug}`);
  };
  usePreserveScroll();

  useEffect(() => {
    dispatch(getPodcastBanner());
    dispatch(getalltags());
    dispatch(getLanguages());
    dispatch(getPodcastCategory());
    // dispatch(
    //   getAllPodcast({
    //     page: currentPage,
    //     limit: limit,
    //     tags: filters.tags,
    //   })
    // );
  }, []);

  const transformedData =
    Array.isArray(tags) &&
    tags.map((item) => ({
      label: item.title,
      value: item.title,
    }));

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  console.log("The Language Value is:", Languages);
  const handlefilterchange = (e) => {
    const { value, name } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleFilterChange = (e) => {
    const { value, name } = e.target;

    const payload = {
      [name]: value,
    };

    dispatch(filtersUpdate(payload));
  };

  const handleFiltersSubmit = (e, action) => {
    let tags = null;
    if (selectedOption?.length > 0) {
      tags = selectedOption.map((item) => item.value).join(",");
    }
    if (action == "loadmore") {
      dispatch(
        getAllPodcast({
          page: currentPage,
          limit: limit + 4,
          tags: tags,
        })
      );
    } else {
      dispatch(
        getAllPodcast({
          page: currentPage,
          limit: limit,
          tags: tags,
        })
      );
      window.scrollTo({
        top: 650,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    dispatch(filtersUpdate(query ? { text: query } : { text: text }));
    handleFiltersSubmit()
  }, [query])
  
  return (
    <>
      <MetaData
        title={podcastbanner?.podcastbanner?.meta_title}
        description={podcastbanner?.podcastbanner?.meta_description}
        tags={podcastbanner?.podcastbanner?.meta_tag}
      />{" "}
      {/* <!-- banner section --> */}
      {podcastbanner ? (
        <>
          <div
            className="poascast desktop-only"
            style={{
              backgroundImage: `url(${podcastbanner?.podcastbanner?.background_image})`,
            }}
          >
            <div className=" container d-flex justify-content-center">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {podcastbanner?.podcastbanner?.alignItem === "left" && (
                    <>
                      <div>
                        <h1 className="haedingfont">
                          <span>{podcastbanner?.podcastbanner?.heading}</span>
                        </h1>
                        <p
                          className="text-dark lh-lg homebdes"
                          style={{
                            fontFamily: "Lato",

                            textAlign: "justify",
                          }}
                        >
                          {podcastbanner?.podcastbanner?.subheading}
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {podcastbanner?.podcastbanner?.alignItem === "right" && (
                    <>
                      <div>
                        <h1 className="haedingfont">
                          <span>{podcastbanner?.podcastbanner?.heading}</span>
                        </h1>
                        <p
                          className="text-dark lh-lg homebdes"
                          style={{
                            fontFamily: "Lato",

                            textAlign: "justify",
                          }}
                        >
                          {podcastbanner?.podcastbanner?.subheading}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* banner starts here */}



           <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${podcastbanner?.podcastbanner?.res_background_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {podcastbanner?.podcastbanner?.alignItem  === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: podcastbanner?.podcastbanner?.headingres,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {podcastbanner?.podcastbanner?.alignItem  === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: podcastbanner?.podcastbanner?.headingres,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: podcastbanner?.podcastbanner?.subheadingres,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>




          {/* banner ends here */}
        </>
      ) : (
        <SmallLoader />
      )}
    
      {/* <!-- section mp3-music end --> */}
      <div
        className="container filterpad py-2 shadow"
        style={{ bottom: "40px" }}
      >
        <div className="row pb-4 p-3 m-0">
          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center pola gap-2"
            id="mobileScreen"
          >
            <label
              for="filter"
              className="fw-bold myfiltericon"
              style={{ color: "#A0A0A0" }}
            >
              <img style={{ width: "25px" }} src={filtericon} alt="" />
            </label>
            <select
              name="categoryId"
              value={categoryId}
              className="select searchborder rounded-pill p-2 px-2 my-2 mx-1 w-100"
              onChange={(e) => handleFilterChange(e)}
            >
              <option value="">All Categories</option>
              {Array.isArray(podcastCategoryList) &&
                podcastCategoryList
                  ?.filter((e) => e.status === true)
                  .map((item, index) => {
                    return (
                      <option value={item?._id} key={index}>
                        {item?.title}
                      </option>
                    );
                  })}
            </select>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center pola">
            <select
              name="language"
              value={language}
              className="select searchborder rounded-pill p-2  px-2  my-2 mx-1 w-100"
              onChange={(e) => handleFilterChange(e)}
            >
              <option value="">Language</option>
              {Array.isArray(Languages) &&
                Languages?.map((item, index) => {
                  return (
                    <option value={item?.language}>{item?.language}</option>
                  );
                })}
            </select>
          </div>
          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center "
            // id="mobileScreen"
          >
            <Select
              // defaultValue={[tags[2], tags[3]]}
              isMulti
              name="tag"
              options={transformedData}
              onChange={handleChange}
              value={selectedOption}
              placeholder={"Select Tags"}
              id="tagss"
              className="select  rounded-pill py-2 w-100  selecttagmusic"
              classNames={{
                control: (state) =>
                  state.isFocused
                    ? "searchborder rounded-pill pb-1"
                    : "searchborder rounded-pill pb-1",
              }}
              classNamePrefix="select"
            />
          </div>

          <div
            className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
            id="mobileScreen"
          >
            <label
              for="filter"
              className="fw-bold mysearchicon"
              style={{ color: "#A0A0A0" }}
            >
              <img
                src={searchicon}
                alt=""
                style={{ width: "25px" }}
                onClick={handleFiltersSubmit}
              />
            </label>
            <form class=" w-100 pt-4" role="search">
              <input
                className="form-control  searchborder rounded-pill p-2 w-100 searchborder"
                type="search"
                name="text"
                placeholder="Search by keyword..."
                aria-label="Search"
                onChange={(e) => handleFilterChange(e)}
                value={text}
                style={{ borderRadius: "19px", color: "#A0A0A0" }}
              />
              <i class="fa-solid fa-magnifying-glass  mysearchiconforphone"></i>
            </form>
          
          </div>
          <div className="pt-3 d-flex justify-content-center">
            <button
              className="btn shadowbuttons btn-lg text-light cmn-t-shake mb-2"
              style={{
                padding: "0.6rem 2.5rem",
              }}
              onClick={handleFiltersSubmit}
            >
              SEARCH
            </button>
          </div>
        </div>
      </div>
      {/* <!-- album section --> */}
      <div className="p-3 album px-4">
        <div className="">
          {/* <div className="col-lg-3 conty ">
            <div className="serch p-2 text-center">
              <input
                className="input-elevated"
                type="text"
                placeholder="Search"
              />
            </div>

            <label for="" className="ps-4 fw-semibold">
              Category
            </label>
            <div className="select-dropdown p-2 text-center">
              <select className="aty">
                <option value="Option 1">First Option</option>
                <option value="Option 2">2nd Option</option>
                <option value="Option 3">Option Number 3</option>
              </select>
            </div>

            <label for="" className="ps-4 fw-semibold">
              ALBUMS
            </label>
            <div className="select-dropdown p-2 text-center">
              <select className="aty">
                <option value="Option 1">First Option</option>
                <option value="Option 2">2nd Option</option>
                <option value="Option 3">Option Number 3</option>
              </select>
            </div>

            <label for="" className="ps-4 fw-semibold">
              TAGS
            </label>
            <div className="tage p-2 text-center">
              <input className="input-elevateder" type="text" />
            </div>

            <div className="buotn py-3 row gap-3 align-items-center justify-content-center">
              <a
                href=""
                className="btn btn-orange mt-2 col-lg-6 col-sm-10 text-light"
                style={{ width: "7rem", height: "2.3rem" }}
              >
                Lorem{" "}
              </a>
              
              <button type="button" class="btn btn-orange text-light position-relative" style={{ width: "10rem", height: "2.3rem" }}>
              Baghvat Gita
                <span class="position-absolute top-0 start-100 translate-middle p-2 bg-light border border-light rounded-circle">
                  <span class=""><img src={close} alt="" height={"20px"} width={"20px"}/></span>
                </span>
              </button>
              <a
                href=""
                className="btn btn-orange mt-2 col-lg-6 col-sm-10 text-light"
                style={{ width: "10rem", height: "2.3rem" }}
              >
                Baghvat Gita{" "}
              </a>
              
              <button type="button" class="btn btn-orange text-light position-relative" style={{ width: "7rem", height: "2.3rem" }}>
              Lorem
                <span class="position-absolute top-0 start-100 translate-middle p-2 bg-light border border-light rounded-circle" >
                <span class=""><img src={close} alt="" height={"20px"} width={"20px"}/></span>
                </span>
              </button>
            </div>

            <div className="form-group">
              <label for="timedefault fw-semibold">Duration</label>
              <input type="time" className="form-control" />
            </div>

            <div className="buotn py-3 ">
              <label for="" className="">
                Store BY
              </label>
              <br />
              <div className="row gap-2 align-items-center justify-content-center pt-3">
                <a
                  href=""
                  className="btn btn-orange col-lg-3 col-md-4 text-light"
                >
                  Oldest
                </a>
                <a
                  href=""
                  className="btn btn-orange col-lg-3 col-md-4 text-light"
                >
                  Newest
                </a>
                <a
                  href=""
                  className="btn btn-orange col-lg-3 col-md-4 text-light"
                >
                  Popular
                </a>
              </div>
            </div>
          </div> */}

          <div className="container pt-4" ref={listStartRef} id="listStart">
            {podcastlist?.length > 0 ? <div className="row g-3">
              {Array.isArray(podcastlist) &&
                podcastlist?.map((item, index) => (
                  <div
                    key={index}
                    className="col-md-6 col-sm-12 col-lg-3 mic rounded p-2 "
                    onClick={() =>
                      handleNavigateToPodcastallepisodes(item?.slug)
                    }
                  >
                    <div
                      className="card item-card card-block text-dark overflow-hidden"
                      style={{ borderRadius: "10px" }}
                    >
                      <img
                        src={item?.thumbnail_image}
                        alt=""
                        style={{ aspectRatio: "3/2",  }}
                      />

                      <div
                        className="d-flex justify-content-between mt-2"
                        style={{ position: "relative" }}
                      >
                        <div className="moucis">
                          <a
                            href="#"
                            className="position-absolute"
                            style={{ left: "2%",display: "flex", alignItems: 'center' }}
                          >
                            <img
                              src={playbtn}
                              style={{ width: "38px" }}
                              alt=""
                            />
                            <i
                            className="fa-solid fa-headphones px-2 fs-4"
                            style={{ color: "#9767a2" }}
                          ></i>
                          </a>
                        </div>
                        <p
                          className="text-secondary"
                          style={{ fontFamily: "Lato", paddingRight: "20px" }}
                        >
                          {item.songList.length > 1
                            ? `Episodes ${item.songList.length}`
                            : `Episode ${item.songList.length}`}
                        </p>
                      </div>
                      <div className="mt-2" style={{}}>
                        <p className="green fw-semibold">
                          
                          {item?.category[0]?.title}
                        </p>
                      </div>
                      <div className="play mb-4">
                        <h5
                          className="text-dark px-1 fw-semibold px-2"
                          style={{ fontFamily: "Lato" }}
                        >
                          {item?.title}
                        </h5>
                      </div>
                      {/* <div className="d-flex justify-content-between text-dark fw-semibold p-2 ">
                          <div className="px-1">{item?.artist[0]?.title}</div>
                          <div className="d-flex gap-2 "></div>
                        </div> */}
                    </div>
                  </div>
                ))}
            </div>:
            <section>
            <div className="text-center d-flex justify-content-center">
            <img style={{ width: "400px" }} src={nodata} alt="No Data Image" />
            </div>
          </section>}
          </div>

          <div className="col-12 mt-2">
            <div className="pt-3 d-flex justify-content-center py-5">
              {podcastlist && podcastlist?.length == totalalbums ? (
                ""
              ) : (
                <a>
                  <button
                    className="btn shadowbuttons btn-lg text-light cmn-t-shake"
                    onClick={(e) => handleFiltersSubmit(e, "loadmore")}
                  >
                    {isLoading ? (
                      <div class="spinner-border text-dark" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <span>LOAD MORE</span>
                    )}
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- album section end --> */}
      {/* <!-- connect music --> */}
      {/* <div className="connect text-light d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pt-5">
              <div className="adio">
                <h1>
                  Connect to Your
                  <br /> Audience Everywhere
                </h1>
                <p>
                  Going Premium is easy and it also allows you to use more than
                  11.9M icons and stickers without attribution.
                </p>
              </div>
              <div className="pt-3">
                <a href="">
                  <button
                    className="btn btn-warning btn-lg text-light rounded-5 cmn-t-shake "
                    style={{ backgroundColor: "#FF9933!important" }}
                  >
                    SPIRITUAL
                  </button>
                </a>
              </div>
              <h1 className="py-2">
                <img
                  src={user}
                  style={{ width: "50px" }}
                  className="rounded-5 px-2"
                  alt=""
                />
                <span>Fling Mentns Of Joy in A Chanlange Your</span>
              </h1>
              <div className="d-flex gap-2">
                <a href="">
                  <button
                    className="btn btn- btn-lg text-light  cmn-t-shake border d-flex gap-1"
                    style={{
                      //   backgroundColor: "#fff",
                      color: "#1f374e!important",
                    }}
                  >
                    <img src={zzz} style={{ width: "27px" }} alt="" />
                    LISTEN NOW
                  </button>
                </a>
                <a href="">
                  <button
                    className="btn btn- btn-lg text-light  cmn-t-shake border "
                    style={{ backgroundColor: "" }}
                  >
                    BROWSE ALL
                  </button>
                </a>
              </div>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </div> */}
      {/* <!-- connect music end--> */}
      {/* 
<?php  
   include 'include/footer.php';
?> */}
    </>
  );
};

export default Podcast;
