import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../appUtils/axiosConfig";

const initialState = {
  addPostState: {
    loading: null,
    data: null,
    error: null,
  },
  adsState: {
    loading: null,
    data: null,
    error: null,
  },
  fetchPostState: {
    loading: null,
    data: null,
    error: null,
    currentPage: 0,
    limit: 10,
    totalCount: 0,
    hideLoadMore: false,
  },
  likePostState: {
    loading: null,
    loadingId: null,
    data: null,
    error: null,
  },
  allCommentsState: {
    loading: null,
    loadingId: null,
    data: null,
    error: null,
  },
  singlePost: null,
  communityconfig: null,
  postAnonymus: false,
  commentAnonymus: false,
  replyAnonymus: false,
};
const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const addPost = createAsyncThunk(
  "community/addPost",
  async (inputData, { dispatch, rejectWithValue }) => {
    try {
      if (!inputData.description) {
        return rejectWithValue("Please provide description!");
      }

      dispatch(addPostStart());
      let formData = new FormData();
      //let formData = {};

      //formData.audio = inputData.audio;
      //formData.video = inputData.video;
      //formData.image = inputData.image;

      formData.append("audio", inputData.audio);
      formData.append("video", inputData.video);
      for (let i = 0; i < inputData.image.length; i++) {
        formData.append("image", inputData.image[i]);
      }

      delete inputData.image;
      delete inputData.audio;
      delete inputData.video;

      //formData.data = JSON.stringify(inputData);
      formData.append("data", JSON.stringify(inputData));

      //await delay(4000);
      const response = await axios.post(`community/post`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response?.data?.success) {
        dispatch(addPostSuccess(response.data)); // Dispatch the success action with the response data
        // return response.data; // Returning the data if needed
      } else {
        const errorMsg = response?.data?.message ?? "Something went wrong!!";
        dispatch(addPostFailure(errorMsg));
        return rejectWithValue(errorMsg);
      }
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message ?? "Something went wrong!!";
      dispatch(addPostFailure(errorMsg));
      return rejectWithValue(errorMsg);
    }
  }
);

export const fetchPosts = createAsyncThunk(
  "community/fetchPosts",
  async ({ type, user, page, limit }, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        community: {
          fetchPostState: { currentPage },
        },
      } = getState();
      dispatch(fetchPostStart());

      const params = { type: type, page: page ?? + 1, limit: limit ?? '' };
      if (user) {
        params.user = user;
      }
      const response = await axios.get(`community/post/all`, { params });

      if (response?.data?.success) {
        const userId = localStorage.getItem("userId");

        let postList = response.data.postList ?? [];

        for (let element of response.data.postList) {
          if (element.likedBy?.includes(userId)) {
            element.liked = true;
          } else {
            element.liked = false;
          }

          let caList =
            element.type == "question" ? element.answers : element.comments;

          for (let caItem of caList) {
            if (caItem.likedBy?.includes(userId)) {
              caItem.liked = true;
            } else {
              caItem.liked = false;
            }

            if (caItem.disLikedBy?.includes(userId)) {
              caItem.disLiked = true;
            } else {
              caItem.disLiked = false;
            }

            for (let rItem of caItem.replies) {
              if (rItem.likedBy?.includes(userId)) {
                rItem.liked = true;
              } else {
                rItem.liked = false;
              }

              if (rItem.disLikedBy?.includes(userId)) {
                rItem.disLiked = true;
              } else {
                rItem.disLiked = false;
              }
            }
          }
        }

        dispatch(fetchPostSuccess(response.data));

        dispatch(fetchPostChangePage(currentPage + 1));
      } else {
        const errorMsg = response?.data?.message ?? "Something went wrong!!";
        dispatch(fetchPostFailure(errorMsg));
        return rejectWithValue(errorMsg);
      }
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message ?? "Something went wrong!!";
      dispatch(fetchPostFailure(errorMsg));
      return rejectWithValue(errorMsg);
    }
  }
);

export const likePost = createAsyncThunk(
  "community/likePost",
  async (postId, { dispatch, rejectWithValue }) => {
    try {
      dispatch(likePostStart(postId));

      const response = await axios.put(`community/post/${postId}/like`);

      if (response?.data?.success) {
        dispatch(likePostSuccess(response?.data?.data));
      } else {
        const errorMsg = response?.data?.message ?? "Something went wrong!!";
        dispatch(likePostFailure(errorMsg));
        return rejectWithValue(errorMsg);
      }
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message ?? "Something went wrong!!";
      dispatch(likePostFailure(errorMsg));
      return rejectWithValue(errorMsg);
    }
  }
);

export const getSinglePost = createAsyncThunk(
  'community/post/:id',
  async (postId, { dispatch, getState, rejectWithValue }) => {

    try {
      dispatch(getSinglePostStart());

      const response = await axios.get(`community/post/${postId}`)
      dispatch(getSinglePostSuccess(response?.data))

      return response?.data;

    } catch (error) {
      dispatch(getSinglePostFailure(error.response.data.message));
      return rejectWithValue(error.response.data.message)
    }
  }
)
export const addComment = createAsyncThunk(
  "community/addComment",
  async ({ inputData, postId, postType }, { dispatch, rejectWithValue }) => {
    try {
      if (!inputData.description) {
        return rejectWithValue("Please provide text!");
      }
      dispatch(addCommentStart(postId));
      let formData = new FormData();

      formData.append("audio", inputData.audio);
      formData.append("video", inputData.video);
      for (let i = 0; i < inputData.image.length; i++) {
        formData.append("image", inputData.image[i]);
      }

      delete inputData.image;
      delete inputData.audio;
      delete inputData.video;

      formData.append("data", JSON.stringify(inputData));

      const response = await axios.post(
        `community/post/${postId}/${postType == "question" ? "answer" : "comment"
        }`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response?.data?.success) {
        let resData = response?.data?.data;
        resData.liked = false;

        dispatch(addCommentSuccess({ postId, data: resData, postType }));
      } else {
        const errorMsg = response?.data?.message ?? "Something went wrong!!";
        dispatch(addCommentFailure(postId));
        return rejectWithValue(errorMsg);
      }
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message ?? "Something went wrong!!";
      dispatch(addCommentFailure(postId));
      return rejectWithValue(errorMsg);
    }
  }
);

export const addReply = createAsyncThunk(
  "community/addReply",
  async ({ inputData, postId, commentId }, { dispatch, rejectWithValue }) => {
    try {
      if (!inputData.description) {
        return rejectWithValue("Please provide text!");
      }

      dispatch(addReplyStart({ postId, commentId }));
      let formData = new FormData();

      formData.append("audio", inputData.audio);
      formData.append("video", inputData.video);
      for (let i = 0; i < inputData.image.length; i++) {
        formData.append("image", inputData.image[i]);
      }

      delete inputData.image;
      delete inputData.audio;
      delete inputData.video;

      formData.append("data", JSON.stringify(inputData));

      const response = await axios.post(
        `community/post/answer/${commentId}/reply`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response?.data?.success) {
        let resData = response?.data?.data;
        resData.liked = false;

        dispatch(addReplySuccess({ postId, data: resData, commentId }));
      } else {
        const errorMsg = response?.data?.message ?? "Something went wrong!!";
        dispatch(addReplyFailure({ postId, commentId }));
        return rejectWithValue(errorMsg);
      }
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message ?? "Something went wrong!!";
      dispatch(addReplyFailure({ postId, commentId }));
      return rejectWithValue(errorMsg);
    }
  }
);

export const allComments = createAsyncThunk(
  "community/allComments",
  async ({ postId, page, postType }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(allCommentsStart(postId));

      const limit = 5;
      const params = { page, limit };
      const response = await axios.get(
        `community/post/${postId}/${postType == "question" ? "answer" : "comment"
        }/all`,
        { params }
      );

      if (response?.data?.success) {
        const userId = localStorage.getItem("userId");
        let caList = response?.data?.data;
        for (let caItem of caList) {
          if (caItem.likedBy?.includes(userId)) {
            caItem.liked = true;
          } else {
            caItem.liked = false;
          }

          if (caItem.disLikedBy?.includes(userId)) {
            caItem.disLiked = true;
          } else {
            caItem.disLiked = false;
          }

          for (let rItem of caItem.replies) {
            if (rItem.likedBy?.includes(userId)) {
              rItem.liked = true;
            } else {
              rItem.liked = false;
            }

            if (rItem.disLikedBy?.includes(userId)) {
              rItem.disLiked = true;
            } else {
              rItem.disLiked = false;
            }
          }
        }

        dispatch(allCommentsSuccess({ postId, comments: caList, limit }));
      } else {
        const errorMsg = response?.data?.message ?? "Something went wrong!!";
        dispatch(allCommentsFailure(errorMsg));
        return rejectWithValue(errorMsg);
      }
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message ?? "Something went wrong!!";
      dispatch(allCommentsFailure(errorMsg));
      return rejectWithValue(errorMsg);
    }
  }
);

export const likeComment = createAsyncThunk(
  "community/likeComment",
  async ({ postId, commentId, postType }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(likeCommentStart({ postId, commentId }));

      const response = await axios.put(
        `community/post/${postType == "question" ? "answer" : "comment"
        }/${commentId}/like`
      );
      if (response?.data?.success) {
        dispatch(
          likeCommentSuccess({ postId, commentId, data: response?.data?.data })
        );
      } else {
        const errorMsg = response?.data?.message ?? "Something went wrong!!";
        dispatch(likeCommentFailure({ postId, commentId, errorMsg }));
        return rejectWithValue(errorMsg);
      }
    } catch (error) {
      console.error("Error in likeComment/disLikeComment:", error);
      const errorMsg =
        error?.response?.data?.message ?? "Something went wrong!!";
      dispatch(likeCommentFailure({ postId, commentId, errorMsg }));
      return rejectWithValue(errorMsg);
    }
  }
);

export const disLikeComment = createAsyncThunk(
  "community/disLikeComment",
  async ({ postId, commentId, postType }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(disLikeCommentStart({ postId, commentId }));

      const response = await axios.put(
        `community/post/${postType == "question" ? "answer" : "comment"
        }/${commentId}/dislike`
      );

      if (response?.data?.success) {
        dispatch(
          disLikeCommentSuccess({
            postId,
            commentId,
            data: response?.data?.data,
          })
        );
      } else {
        const errorMsg = response?.data?.message ?? "Something went wrong!!";
        dispatch(disLikeCommentFailure({ postId, commentId, errorMsg }));
        return rejectWithValue(errorMsg);
      }
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message ?? "Something went wrong!!";
      dispatch(disLikeCommentFailure({ postId, commentId, errorMsg }));
      return rejectWithValue(errorMsg);
    }
  }
);

export const getCommunityConfig = createAsyncThunk(
  'config/getCommunityConfig',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(communityconfigStart());
      const response = await axios.get(`config/getcommunitybanner`)
      dispatch(communityconfigSuccess(response?.data?.requiredcontent))
    } catch (error) {
      dispatch(communityconfigFailure(error.response.data.message))
    }
  }
)
export const getCommunityAds = createAsyncThunk(
  'config/getbreaks',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(communityadsStart());
      const response = await axios.get(`community/getbreaks`)
      console.log(response, 'ads_response')
      dispatch(communityadsSuccess(response?.data?.ads))
    } catch (error) {
      dispatch(communityadsFailure(error.response.data.message))
    }
  }
)

const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    addPostStart(state) {
      state.addPostState.loading = true;
      state.addPostState.data = null;
      state.addPostState.error = null;
    },
    addPostSuccess(state, action) {
      state.addPostState.loading = false;
      state.addPostState.data = action.payload;
      state.addPostState.error = null;
    },
    addPostFailure(state, action) {
      state.addPostState.loading = false;
      //state.addPostState.data = null;
      state.addPostState.error = action.payload;
    },
    communityadsStart(state) {
      state.adsState.loading = true;
      state.adsState.data = null;
      state.adsState.error = null;
    },
    communityadsSuccess(state, action) {
      state.adsState.loading = false;
      state.adsState.data = action.payload;
      state.adsState.error = null;
    },
    communityadsFailure(state, action) {
      state.adsState.loading = false;
      state.adsState.error = action.payload;
    },
    fetchPostStart(state) {
      state.fetchPostState.loading = true;
      state.fetchPostState.error = null;
    },
    fetchPostSuccess(state, action) {
      state.fetchPostState.loading = false;
      if (!action.payload.postList?.length) {
        state.fetchPostState.hideLoadMore = true;
      }
      state.fetchPostState.data = action.payload.postList;
      // state.fetchPostState.data = [...oldList, ...action.payload];
      state.fetchPostState.currentPage = action.payload.currentPage;
      state.fetchPostState.limit = action.payload.limit;
      state.fetchPostState.totalCount = action.payload.totalCount;
      state.fetchPostState.error = null;
    },
    fetchPostFailure(state, action) {
      state.fetchPostState.loading = false;
      state.fetchPostState.data = null;
      state.fetchPostState.error = action.payload;
    },
    fetchPostChangePage(state, action) {
      state.fetchPostState.page = action.payload;
    },
    fetchPostResetPage(state, action) {
      state.fetchPostState.page = 1;
      state.fetchPostState.hideLoadMore = false;
      state.fetchPostState.data = null;
    },
    likePostStart(state, action) {
      state.singlePost = state.singlePost || {};
      state.singlePost.loading = true;
      state.singlePost.loadingId = action.payload;
      state.singlePost.error = null;
    },
    likePostSuccess(state, action) {
      state.singlePost = state.singlePost || {};
      state.likePostState.loading = false;
      state.likePostState.loadingId = null;

      let postList = state.fetchPostState.data ?? [];

      const postResponse = action.payload;

      for (let postItem of postList) {
        if (postItem._id == postResponse._id) {
          postItem.liked = !postItem.liked;
          postItem.likedBy = postResponse.likedBy;
          postItem.lastLikedBy = postResponse.lastLikedBy;
          break;
        }
      }

      state.fetchPostState.data = [...postList];
      state.likePostState.error = null;
    },
    likePostFailure(state, action) {
      state.singlePost = state.singlePost || {};
      state.likePostState.loading = false;
      state.likePostState.loadingId = null;
      state.likePostState.error = action.payload;
    },

    getSinglePostStart(state, action) {
      state.loading = true;
    },
    getSinglePostSuccess(state, action) {
      state.loading = false;
      state.singlePost = action.payload;
    },
    getSinglePostFailure(state, action) {
      state.loading = false;
      state.singlePost.loadingId = null;
      state.singlePost.error = action.payload;
    },

    allCommentsStart(state, action) {
      state.allCommentsState.loading = true;
      state.allCommentsState.loadingId = action.payload;
      state.allCommentsState.error = null;
    },
    allCommentsSuccess(state, action) {
      state.allCommentsState.loading = false;
      state.allCommentsState.loadingId = null;

      let postList = state.fetchPostState.data ?? [];

      const { postId, comments, limit } = action.payload;

      for (let postItem of postList) {
        if (postItem._id == postId) {
          const postType = postItem.type;
          let page = postItem.commentPage ?? 1;
          let commentOrAnswers = [];
          if (postType == "question") {
            commentOrAnswers = postItem.answers;
          } else {
            commentOrAnswers = postItem.comments;
          }

          if (page <= 1) {
            commentOrAnswers = comments;
          } else {
            commentOrAnswers.push(comments);
          }

          if (postType == "question") {
            postItem.answers = commentOrAnswers;
          } else {
            postItem.comments = commentOrAnswers;
          }

          if (comments?.length < limit) {
            postItem.hideShowMore = true;
          }

          postItem.commentPage = page + 1;

          break;
        }
      }

      state.fetchPostState.data = [...postList];
      state.allCommentsState.error = null;
    },
    allCommentsFailure(state, action) {
      state.allCommentsState.loading = false;
      state.allCommentsState.loadingId = null;
      state.allCommentsState.error = action.payload;
    },
    likeCommentStart(state, action) {
      const { postId, commentId, type } = action.payload;
      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          let caList =
            postItem.type == "question" ? postItem.answers : postItem.comments;
          for (let caItem of caList) {
            if (caItem._id == commentId) {
              caItem.likeLoading = true;
              break;
            }

            for (let rItem of caItem.replies) {
              if (rItem._id == commentId) {
                rItem.likeLoading = true;
                break;
              }
            }
          }
          break;
        }
      }
    },
    likeCommentSuccess(state, action) {
      const { postId, commentId, data } = action.payload;
      const userId = localStorage.getItem("userId");


      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          let caList =
            postItem.type == "question" ? postItem.answers : postItem.comments;
          for (let caItem of caList) {
            if (caItem._id == commentId) {
              caItem.liked = !caItem.liked;
              caItem.likeLoading = false;
              caItem.likedBy = data.likedBy;
              if (caItem.liked) {
                caItem.disLiked = false;
                caItem.disLikedBy = caItem.disLikedBy.filter(id => id !== userId);
              }
              break;
            }

            for (let rItem of caItem.replies) {
              if (rItem._id == commentId) {
                rItem.liked = !rItem.liked;
                rItem.likeLoading = false;
                rItem.likedBy = data.likedBy;
                if (rItem.liked) {
                  rItem.disLiked = false;
                  rItem.disLikedBy = rItem.disLikedBy.filter(id => id !== userId);
                }
                break;
              }
            }
          }
          break;
        }
      }
    },
    likeCommentFailure(state, action) {
      const { postId, commentId, errorMsg } = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          let caList =
            postItem.type == "question" ? postItem.answers : postItem.comments;
          for (let caItem of caList) {
            if (caItem._id == commentId) {
              caItem.likeLoading = false;
              break;
            }

            for (let rItem of caItem.replies) {
              if (rItem._id == commentId) {
                rItem.likeLoading = false;
                break;
              }
            }
          }
          break;
        }
      }
    },
    disLikeCommentStart(state, action) {
      const { postId, commentId } = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          let caList =
            postItem.type == "question" ? postItem.answers : postItem.comments;
          for (let caItem of caList) {
            if (caItem._id == commentId) {
              caItem.disLikeLoading = true;
              break;
            }

            for (let rItem of caItem.replies) {
              if (rItem._id == commentId) {
                rItem.disLikeLoading = true;
                break;
              }
            }
          }
          break;
        }
      }
    },
    disLikeCommentSuccess(state, action) {
      const { postId, commentId, data } = action.payload;
      const userId = localStorage.getItem("userId");


      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          let caList =
            postItem.type == "question" ? postItem.answers : postItem.comments;
          for (let caItem of caList) {
            if (caItem._id == commentId) {
              caItem.disLiked = !caItem.disLiked;
              caItem.disLikeLoading = false;
              caItem.disLikedBy = data.disLikedBy;
              if (caItem.disLiked) {
                caItem.liked = false;
                //caItem.likedBy.pull(userId);
                caItem.likedBy = caItem.likedBy.filter(id => id !== userId);
              }
              break;
            }

            for (let rItem of caItem.replies) {
              if (rItem._id == commentId) {
                rItem.disLiked = !rItem.disLiked;
                rItem.disLikeLoading = false;
                rItem.disLikedBy = data.disLikedBy;
                if (rItem.disLiked) {
                  rItem.liked = false;
                  // rItem.likedBy.pull(userId);
                  rItem.likedBy = rItem.likedBy.filter(id => id !== userId);
                }
                break;
              }
            }
          }
          break;
        }
      }
    },
    disLikeCommentFailure(state, action) {
      const { postId, commentId, errorMsg } = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          let caList =
            postItem.type == "question" ? postItem.answers : postItem.comments;
          for (let caItem of caList) {
            if (caItem._id == commentId) {
              caItem.disLikeLoading = false;
              break;
            }

            for (let rItem of caItem.replies) {
              if (rItem._id == commentId) {
                rItem.disLikeLoading = false;
                break;
              }
            }
          }
          break;
        }
      }
    },
    addCommentStart(state, action) {
      const postId = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          postItem.addCommentLoading = true;
          break;
        }
      }
    },
    addCommentSuccess(state, action) {
      const { postId, data, postType } = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          postItem.addCommentLoading = false;

          if (postType == "question") {
            postItem.answers.unshift(data);
          } else {
            postItem.comments.unshift(data);
          }

          break;
        }
      }
    },
    addCommentFailure(state, action) {
      const postId = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          postItem.addCommentLoading = false;
          break;
        }
      }
    },
    addReplyStart(state, action) {
      const { postId, commentId } = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          for (let caItem of postItem.answers) {
            if (caItem._id == commentId) {
              caItem.addReplyLoading = true;
              break;
            }
          }
          break;
        }
      }
    },
    addReplySuccess(state, action) {
      const { postId, data, commentId } = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          let caList =
            postItem.type == "question" ? postItem.answers : postItem.comments;
          for (let caItem of caList) {
            if (caItem._id == commentId) {
              caItem.addReplyLoading = false;
              caItem.replies.unshift(data);
              break;
            }
          }
          break;
        }
      }
    },
    addReplyFailure(state, action) {
      const { postId, commentId } = action.payload;

      let postList = state.fetchPostState.data ?? [];

      for (let postItem of postList) {
        if (postItem._id == postId) {
          for (let caItem of postItem.answers) {
            if (caItem._id == commentId) {
              caItem.addReplyLoading = false;
              break;
            }
          }
          break;
        }
      }
    },
    communityconfigStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    communityconfigSuccess(state, action) {
      state.communityconfig = action?.payload;
      state.isLoading = false;
    },
    communityconfigFailure(state, action) {
      state.communityconfig = null;
      state.isLoading = false;
      state.error = action.payload;

    },
    isAnonymusPost(state, action) {
      state.postAnonymus = action.payload;
    },
    isAnonymusComment(state, action) {
      state.commentAnonymus = action.payload;
    },
    isAnonymusReply(state, action) {
      state.replyAnonymus = action.payload;
    }
  },
});

export const {
  isAnonymusPost,
  isAnonymusComment,
  isAnonymusReply,
  communityadsStart,
  communityadsSuccess,
  communityadsFailure,
  addPostStart,
  addPostSuccess,
  addPostFailure,
  fetchPostStart,
  fetchPostSuccess,
  fetchPostFailure,
  fetchPostChangePage,
  fetchPostResetPage,
  likePostStart,
  likePostSuccess,
  likePostFailure,
  allCommentsStart,
  allCommentsSuccess,
  allCommentsFailure,
  likeCommentStart,
  likeCommentSuccess,
  likeCommentFailure,
  disLikeCommentStart,
  disLikeCommentSuccess,
  disLikeCommentFailure,
  addCommentStart,
  addCommentSuccess,
  addCommentFailure,
  addReplyStart,
  addReplySuccess,
  addReplyFailure,
  getSinglePostStart,
  getSinglePostFailure,
  getSinglePostSuccess,
  communityconfigStart,
  communityconfigSuccess,
  communityconfigFailure,
} = communitySlice.actions;

export default communitySlice.reducer;
