import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ".././component/layout/style.css";
import rect288 from "../images/Rectangle 288.png";
import rect291 from "../images/Rectangle 291.png";
import icon1 from "../images/Icon-1.png";
import icon2 from "../images/Icon-2.png";
import icon3 from "../images/Icon-3.png";
import icon4 from "../images/Icon-4.png";
import mission1 from "../images/mission_1 1.png";
import mission2 from "../images/mission_2 1.png";
import mission3 from "../images/mission_3 1.png";
import rightarrow from "../images/right-arrow 1.png";
import bg2 from "../images/Rectangle-279.png";
import { useNavigate } from "react-router-dom";import partner1 from "../images/Partner-1.png";
import partner2 from "../images/Partner-2.png";
import partner3 from "../images/Partner-3.png";
import partner4 from "../images/Partner-4.png";
import partner5 from "../images/Partner-5.png";
import partner6 from "../images/Partner-6.png";
import MetaData from "../component/layout/MetaData";
const JoinTheCampaign = () => {
  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };
  const navigate = useNavigate();
  const handleNavigateToAnnapurna =()=>{
    navigate("/annapurnaYojana")
  }
  return (
    <>
    <MetaData title="Join The Campaign" />
      <div
        class="joincampaignbg"
        style={{ backgroundSize: "cover", height: "100vh" }}
      >
        <div class="container wisdompad">
          <div class="row">
            <div class="col-lg-8 col-xl-8 col-md-8 col-sm-10 d-flex flex-column align-items-start justify-content-center">
              <div>
                <h1 class="fw-bold ">
                  <span style={{ fontSize: "larger", fontSize: "70px" }}>
                    Join the campaign
                  </span>
                </h1>
                <p
                  class="text-dark  fw-bold w-75"
                  style={{
                    fontFamily: "Lato",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  Designed by seasoned meditation and spiritual guru Dr. Archika
                  Didi, the Blissful Life Programme is a journey of
                  transformation leading participants to the path of inner peace
                  and pure joy. This exclusive programme allows participants to
                  discover the keys to unlocking their own happiness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="featured_campaigns pb-5" style={{ height: "auto" }}>
        <div className="row p-5">
          <div className="col-lg-8 col-sm-12 p-5">
            <h1 className="orange" style={{ fontFamily: "myfont" }}>
              we help around the world
            </h1>
            <h1 className="fw-semibold" style={{ fontFamily: "Lato" }}>
              Our Featured Campaigns
            </h1>
          </div>
          <div className="col-lg-4 col-sm-12 d-flex justify-content-end align-items-center">
            <div className="slider-buttons">
              <button
                className="prev-button text-dark p-3 mx-1"
                style={{ border: "2px solid #FF9933", borderRadius: "100px" }}
                onClick={goToPrev}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </button>
              <button
                className="next-button text-dark p-3 mx-1"
                style={{ border: "2px solid #FF9933", borderRadius: "100px" }}
                onClick={goToNext}
              >
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <div className="col-12">
            <div className="carousel-container">
              <Slider ref={sliderRef} {...settings}>
                <div className="slide shadow" onClick={handleNavigateToAnnapurna}>
                  <div className="row p-3">
                    <div className="col-lg-6 col-sm-12">
                      <img
                        src={rect288}
                        alt=""
                        style={{
                          height: "80%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <h1 style={{ fontFamily: "Lato" }}>Balklyan Yojana</h1>
                      <p
                        style={{
                          fontFamily: "Lato",
                          color: "#6A6A6A",
                          fontSize: "21px",
                          lineHeight: "51px",
                        }}
                      >
                        Diam volutpat commodo sed egestas egestas fringilla
                        phasellus faucibus scelerisque. Et ligula ullamcorper
                        malesuada proin libero nunc. Quis vel eros donec ac odio
                        tempor. Cursus in hac habitasse platea. Phasellus
                        egestas tellus rutrum tellus pellentesque eu. Non
                        diam...
                      </p>
                      <div className="position-relative">
                        <input
                          type="range"
                          name=""
                          id=""
                          className="w-100 rangeorange"
                        />
                        <span className="fw-bold position-absolute top-0 end-0 translate-middle-y">
                          100%
                        </span>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column w-100">
                          <label className="fw-semibold" htmlFor="">
                            Goal:
                          </label>
                          <p className="text-danger fw-semibold">$20,000</p>
                        </div>
                        <div className="d-flex flex-column border border-top-0 border-bottom-0 w-100 text-center">
                          <label className="fw-semibold" htmlFor="">
                            Raised:
                          </label>
                          <p className="orange fw-semibold">$36,955</p>
                        </div>
                        <div className="d-flex flex-column w-100 text-end">
                          <label className="fw-semibold" htmlFor="">
                            To Go:
                          </label>
                          <p className="orange fw-semibold">+$16,955</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slide shadow "onClick={handleNavigateToAnnapurna}>
                  <div className="row p-3">
                    <div className="col-lg-6 col-sm-12">
                      <img
                        src={rect288}
                        alt=""
                        style={{
                          height: "80%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <h1 style={{ fontFamily: "Lato" }}>Balklyan Yojana</h1>
                      <p
                        style={{
                          fontFamily: "Lato",
                          color: "#6A6A6A",
                          fontSize: "21px",
                          lineHeight: "51px",
                        }}
                      >
                        Diam volutpat commodo sed egestas egestas fringilla
                        phasellus faucibus scelerisque. Et ligula ullamcorper
                        malesuada proin libero nunc. Quis vel eros donec ac odio
                        tempor. Cursus in hac habitasse platea. Phasellus
                        egestas tellus rutrum tellus pellentesque eu. Non
                        diam...
                      </p>
                      <div className="position-relative">
                        <input
                          type="range"
                          name=""
                          id=""
                          className="w-100 rangeorange"
                        />
                        <span className="fw-bold position-absolute top-0 end-0 translate-middle-y">
                          100%
                        </span>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column w-100">
                          <label className="fw-semibold" htmlFor="">
                            Goal:
                          </label>
                          <p className="text-danger fw-semibold">$20,000</p>
                        </div>
                        <div className="d-flex flex-column border border-top-0 border-bottom-0 w-100 text-center">
                          <label className="fw-semibold" htmlFor="">
                            Raised:
                          </label>
                          <p className="orange fw-semibold">$36,955</p>
                        </div>
                        <div className="d-flex flex-column w-100 text-end">
                          <label className="fw-semibold" htmlFor="">
                            To Go:
                          </label>
                          <p className="orange fw-semibold">+$16,955</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="slide shadow"onClick={handleNavigateToAnnapurna}>
                  <div className="row p-3">
                    <div className="col-lg-6 col-sm-12">
                      <img
                        src={rect288}
                        alt=""
                        style={{
                          height: "80%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                      <h1 style={{ fontFamily: "Lato" }}>Balklyan Yojana</h1>
                      <p
                        style={{
                          fontFamily: "Lato",
                          color: "#6A6A6A",
                          fontSize: "21px",
                          lineHeight: "51px",
                        }}
                      >
                        Diam volutpat commodo sed egestas egestas fringilla
                        phasellus faucibus scelerisque. Et ligula ullamcorper
                        malesuada proin libero nunc. Quis vel eros donec ac odio
                        tempor. Cursus in hac habitasse platea. Phasellus
                        egestas tellus rutrum tellus pellentesque eu. Non
                        diam...
                      </p>
                      <div className="position-relative">
                        <input
                          type="range"
                          name=""
                          id=""
                          className="w-100 rangeorange"
                        />
                        <span className="fw-bold position-absolute top-0 end-0 translate-middle-y">
                          100%
                        </span>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="d-flex flex-column w-100">
                          <label className="fw-semibold" htmlFor="">
                            Goal:
                          </label>
                          <p className="text-danger fw-semibold">$20,000</p>
                        </div>
                        <div className="d-flex flex-column border border-top-0 border-bottom-0 w-100 text-center">
                          <label className="fw-semibold" htmlFor="">
                            Raised:
                          </label>
                          <p className="orange fw-semibold">$36,955</p>
                        </div>
                        <div className="d-flex flex-column w-100 text-end">
                          <label className="fw-semibold" htmlFor="">
                            To Go:
                          </label>
                          <p className="orange fw-semibold">+$16,955</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Add more slides as needed */}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* What Make Us Different */}
      <div className="py-5" style={{ backgroundImage: `url("${rect291}")` }}>
        <div className="py-5">
          <h1 className="text-center" style={{ fontFamily: "myfont" }}>
            HELP IS OUR GOAL
          </h1>
          <h1 className="text-center" style={{ fontFamily: "Lato" }}>
            What Make Us Different
          </h1>
        </div>

        <div class="container text-center">
          <div class="row g-5">
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={icon1} alt="" />
              <h3 className="fs-4 fw-semibold" style={{ fontFamily: "Lato" }}>
                We Educate
              </h3>
              <p>
                we help local non profits access the funding, tools, training
                and support they need
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={icon2} alt="" />
              <h3 className="fs-4 fw-semibold" style={{ fontFamily: "Lato" }}>
                We Help
              </h3>
              <p>
                we help local non profits access the funding, tools, training
                and support they need
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={icon3} alt="" />
              <h3 className="fs-4 fw-semibold" style={{ fontFamily: "Lato" }}>
                Web Build
              </h3>
              <p>
                we help local non profits access the funding, tools, training
                and support they need
              </p>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={icon4} alt="" />
              <h3 className="fs-4 fw-semibold" style={{ fontFamily: "Lato" }}>
                We Donate
              </h3>
              <p>
                we help local non profits access the funding, tools, training
                and support they need
              </p>
            </div>
          </div>

          {/* <button className="p-3 mt-5 rounded-pill btn-orange">
            LEARN MORE
          </button> */}
        </div>
      </div>

      <div className="py-5" style={{ backgroundColor: "#F8F8F8" }}>
        <div className="py-5">
          <h1
            className="text-center"
            style={{ fontFamily: "myfont", fontSize: "95px" }}
          >
            Be a part of project
          </h1>
          <h4
            className="text-center px-5 text-center lh-lg"
            style={{ fontFamily: "Lato" }}
          >
            Choose your training and register for free. If you are a freelancer,
            the courses are entirely taken care of, you have nothing to pay and
            no money to advance.
          </h4>
        </div>

        <div class="container text-center pb-5">
          <div class="row g-2">
            <div class="col-lg-2 col-md-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={mission1} alt="" />
              <h3
                className="fw-semibold"
                style={{ fontFamily: "Lato", fontSize: "1.2rem" }}
              >
                Become a Supporter
              </h3>
            </div>
            <div class="col-lg-3 col-md-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={rightarrow} alt="" />
            </div>
            <div class="col-lg-2 col-md-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={mission2} alt="" />
              <h3
                className="fw-semibold"
                style={{ fontFamily: "Lato", fontSize: "1.2rem" }}
              >
                Volunteer With Us
              </h3>
            </div>
            <div class="col-lg-3 col-md-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={rightarrow} alt="" />
            </div>
            <div class="col-lg-2 col-md-12 d-flex flex-column align-items-center text-center gap-2">
              <img src={mission3} alt="" />
              <h3
                className=" fw-semibold"
                style={{ fontFamily: "Lato", fontSize: "1.2rem" }}
              >
                Give one time or monthly
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* Join us form */}
      <div className="container pb-5">
        <h1
          className="fs-2 fw-semibold text-center m-5"
          style={{ fontFamily: "Lato" }}
        >
          Join Us To Learn How You Can Help Make Change For Children
        </h1>

        <div className="row g-4">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <label htmlFor="" className="form-label fw-semibold">
              First Name
            </label>
            <input
              className="p-2 w-100 border border-0 shadow"
              style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
              type="text"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <label htmlFor="" className="form-label fw-semibold">
              Last Name
            </label>
            <input
              className="p-2 w-100 border border-0 shadow"
              style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
              type="text"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <label htmlFor="" className="form-label fw-semibold">
              Email Address
            </label>
            <input
              className="p-2 w-100 border border-0 shadow"
              style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
              type="email"
            />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-end">
            <button
              type="button"
              className="btn btn-lg fs-6 w-100 shadow"
              style={{ backgroundColor: "#ff9933", color: "white" }}
            >
              JOIN US
            </button>
          </div>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{
          fontFamily: "myfont",
          backgroundImage: `url(${bg2})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "auto",
        }}
      >
        <div className="row container" style={{ height: "100%" }}>
          <div className="col-md-6 d-flex align-items-center justify-content-center flex-column">
            <div className="bg-white text-center w-75 p-3">
              <h2 className="fw-bold" style={{ fontFamily: "Lato" }}>
                Support Us
              </h2>
              <p style={{ fontFamily: "Lato" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Exercitati ndis rerum?
              </p>
              <div className="d-flex align-items-center justify-content-center">
                <label htmlFor="" className="d-flex w-100">
                  <span className="bg-black text-white d-flex align-items-center justify-content-center">
                    <i class="fa-solid fa-indian-rupee-sign p-4 fs-3"></i>
                  </span>
                  <input
                    type="number"
                    className="w-100 text-center fw-bold"
                    style={{ fontFamily: "Lato", fontSize: "2.5rem" }}
                  />
                </label>
              </div>
              <div class="container text-center">
                <div
                  class="row row-cols-4 py-3 gap-2 mb-2"
                  style={{ fontFamily: "Lato" }}
                >
                  <div
                    class="col btn-orange text-white p-2"
                    style={{ width: "23%" }}
                  >
                    <i class="fa-solid fa-indian-rupee-sign"></i>100.00
                  </div>
                  <div
                    class="col btn-orange text-white p-2"
                    style={{ width: "23%" }}
                  >
                    <i class="fa-solid fa-indian-rupee-sign"></i>150.00
                  </div>
                  <div
                    class="col btn-orange text-white p-2"
                    style={{ width: "23%" }}
                  >
                    <i class="fa-solid fa-indian-rupee-sign"></i>200.00
                  </div>
                  <div
                    class="col btn-orange text-white p-2"
                    style={{ width: "23%" }}
                  >
                    Custom
                  </div>
                </div>
                <div className="d-flex flex-column gap-3 py-3">
                  <button
                    className="btn py-1 fw-semibold rounded-0"
                    style={{ backgroundColor: "#22b6af", fontFamily: "Lato" }}
                  >
                    DONATE NOW
                  </button>
                  <button
                    className="btn py-1 fw-semibold rounded-0"
                    style={{ backgroundColor: "#F2A922", fontFamily: "Lato" }}
                  >
                    OFFLINE DONATION
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-4 text-start text-white d-flex align-items-start justify-content-center flex-column">
            <h1
              className="lh-lg"
              style={{ fontFamily: "myfont", fontSize: "4.2rem" }}
            >
              Make your contribution Online
            </h1>
            <p className="pb-5 lh-lg text-white p-4">
              Nulla molestie et nisi nec scelerisque. Mauris felis erat, sodales
              in pellentesque sit amet, elementum ac mi. Nulla volutpat id lorem
              id tempor. Aliquam sed dolor velit. Morbi lobortis nulla eu. Nulla
              molestie et nisi nec scelerisque. Mauris felis erat, sodales in
              pellentesque sit amet, elementum ac mi.
            </p>
          </div>
        </div>
      </div>

      <div className="partner container py-3" style={{ height: "auto", backgroundColor:"#fcf8f4 !important" }}>
        <div className="row">
          <div className="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center justify-content-around p-0">
            <img src={partner1} alt="" height={100} />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center justify-content-around p-0">
            <img src={partner2} alt="" height={100} />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center justify-content-around p-0">
            <img src={partner3} alt="" height={100} />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center justify-content-around p-0">
            <img src={partner4} alt="" height={100} />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center justify-content-around p-0">
            <img src={partner5} alt="" height={100} />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 d-flex align-items-center justify-content-around p-0">
            <img src={partner6} alt="" height={100} />
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinTheCampaign;
