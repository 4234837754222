import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
  isLoading: false,
  error: null,
  aboutUs: null,
  aboutUsOrganisation: null,
};

export const getaboutUs = createAsyncThunk(
  "config/getaboutUs",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(aboutUsstart());
      const response = await axios.get(`config/all_aboutus`);
      dispatch(aboutUsSuccess(response?.data?.aboutus[0].aboutUs));
    } catch (error) {
      dispatch(aboutUsFailure(error.response.data.message));
    }
  }
);

export const getAboutOrganisation = createAsyncThunk(
  "aboutorganisation/getallaboutorganisations",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(aboutOrganisationStart());
      const response = await axios.get(
        `aboutorganisation/getallaboutorganisations`
      );
      dispatch(aboutOrganisationSuccess(response?.data?.aboutorganisation));
    } catch (error) {
      dispatch(aboutOrganisationFailure(error.response.data.message));
    }
  }
);

const aboutUsSlice = createSlice({
  name: "aboutus",
  initialState,
  reducers: {
    aboutUsstart(state) {
      state.isLoading = true;
      state.error = null;
    },
    aboutUsSuccess(state, action) {
      state.aboutUs = action.payload;
      state.isLoading = false;
    },
    aboutUsFailure(state, action) {
      state.aboutUs = null;
      state.isLoading = false;
      state.error = action.payload;
    },
    aboutOrganisationStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    aboutOrganisationSuccess(state, action) {
      state.aboutUsOrganisation = action.payload;
      state.isLoading = false;
    },
    aboutOrganisationFailure(state, action) {
      state.aboutUsOrganisation = null;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  aboutUsstart,
  aboutUsSuccess,
  aboutUsFailure,
  aboutOrganisationStart,
  aboutOrganisationSuccess,
  aboutOrganisationFailure,
} = aboutUsSlice.actions;

export default aboutUsSlice.reducer;
