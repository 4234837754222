import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";

const initialState = {

    isLoading: false,
    error: null,
    donationImpact: null

}


export const donationImpact = createAsyncThunk(
    '/donation/get_donationImpact',
    async (_, { dispatch, rejectWithValue }) => {
        
     
        try {
            dispatch(donationImpactstart());
            const response = await axios.get(`/donation/get_donationImpact`)
            
            dispatch(donationImpactSuccess(response?.data?.donationImpact1))
            

        } catch (error) {
            dispatch(donationImpactsFailure(error.response))
        }
    }
)


const donationImpactSlice = createSlice({
    name: 'donation',
    initialState,
    reducers: {
        donationImpactstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        donationImpactSuccess(state, action) {
            state.isLoading = false;
            
            state.donationImpact =action.payload;
        },
        donationImpactsFailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.singlealbum=null

        }


    }
})


export const { donationImpactstart, donationImpactSuccess, donationImpactsFailure } = donationImpactSlice.actions;

export default donationImpactSlice.reducer;
