import React from "react";
import rightImg from "../images/eventSuccessRight.png";
import checkImg from "../images/EventSucesscheckImg.png";
import ellipse from "../images/EventSuccesselipse.png";


const EventSuccessPage = () => {
  return (
    <div style={{ marginBottom: "1%" }}>
      <div
        style={{
          background:
            "linear-gradient(180deg, #E0EEF9 0%, rgba(255, 255, 255, 0) 100%)",
        }}
      >
        <div className="row" style={{ width: "100%", height: "50%" }}>
          <div className="col-lg-8"></div>
          <div className="col-lg-4">
            <img
              src={rightImg}
              alt="rightImg"
              style={{ width: "100%", height: "100%", marginTop: "4rem" }}
            />
          </div>
        </div>
        <div className="row justify-content-center" id="eventSuccessContentImg">
          <div className="col-lg-12 text-center">
            <div className="row justify-content-center">
              <div
                style={{
                  width: "150px",
                  height: "150px",
                  border: "1px",
                  background: "#ffff",
                  borderRadius: "50%",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={checkImg}
                  alt="checkImg"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    zIndex: "1",
                    position: "absolute",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12 text-center">
            <h3 style={{ fontSize: "49px" }}>
              You Have Sucessfully Join the event
            </h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <img
            src={rightImg}
            alt="rightImg"
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className="col-lg-8"></div>
      </div>
    </div>
  );
};

export default EventSuccessPage;
