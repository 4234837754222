import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const filters = {
    categoryId: '',
    language: '',
    sort: '',
    contentType: '',
    text: '',
    artistId: '',
    taggg: ''
}
const initialState = {
    isLoading: false,
    error: null,
    album: [],
    totalalbum: 0,
    categories: [],
    artists: [],
    limit: 8,
    currentPage: 0,
    // singlealbum: {}
    musicbanner: null,
    albumbanner: null,
    artiste: null,
    Filters: filters
}



export const getAllalbums = createAsyncThunk(
    'album/allalbum',
    async ({ page, limit, taggg }, { dispatch, getState, rejectWithValue }) => {

        const { album: { Filters: { artistId, categoryId, language, sort, contentType, text } } } = getState();

        try {
            dispatch(getallalbumStart());
            const response = await axios.get(`album/all?page=${page ?? + 1}&limit=${limit ?? ""}&categoryId=${categoryId ?? ""}&artistId=${artistId ?? ""}&sort=${sort ?? ""}&tags=${taggg ?? ""}&text=${text ?? ""}`)
            dispatch(getallalbumSuccess(response?.data))
            return response.data?.albums;
        } catch (error) {
            dispatch(getallalbumfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const getsinglealbum = createAsyncThunk(
    'album/getBySlug',
    async (slug, { dispatch, rejectWithValue, getState }) => {
        try {
            dispatch(singlealbumstart());
            const response = await axios.get(`album/${slug}`); // Construct URL here
            dispatch(singlealbumsuccess(response?.data));



            return response?.data;
        } catch (error) {
            dispatch(singlealbumfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message);
        }
    }
);


export const getallalbumcategories = createAsyncThunk(
    'album/categories',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(categorystart());
            const response = await axios.get(`album/category_all`)
            dispatch(categorysuccess(response?.data?.allalbumcategories))
            return response?.data;
        } catch (error) {
            dispatch(categoryfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)


export const getallartists = createAsyncThunk(
    'album/artists',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(artiststart());
            const response = await axios.get(`album/artists/all`)
            dispatch(artistsuccess(response?.data?.artist))
            return response?.data;
        } catch (error) {
            dispatch(artistfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)

        }

    }
)

export const getMusicBanner = createAsyncThunk(
    'config/getmusicbanner',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(musicbannerstart());
            const response = await axios.get(`config/getmusicbanner`)
            dispatch(musicbannerSuccess(response?.data?.requiredcontent))

        } catch (error) {
            dispatch(musicbannerFailure(error.response.data.message))
        }
    }
)

export const getAlbumBanner = createAsyncThunk(
    'config/albumbanner',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(albumbannerstart());
            const response = await axios.get(`config/albumbanner`)

            dispatch(albumbannerSuccess(response?.data?.albumbanner))

        } catch (error) {
            dispatch(albumbannerFailure(error.response.data.message))
        }
    }
)



const albumSlice = createSlice({
    name: 'album',
    initialState,
    reducers: {
        getallalbumStart(state) {
            state.isLoading = true;
            state.error = null;
            state.album = []
        },
        getallalbumSuccess(state, action) {
            state.isLoading = false;
            // state.albums = action.payload.allalbum
            state.album = action.payload?.albums;
            // state.album = [...state.album, ...action.payload?.albums];
            state.currentPage = action.payload.currentPage;
            state.limit = action.payload.limit;
            state.totalalbum = action.payload.totalalbums

        },
        getallalbumfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.singlealbum = null
        },
        singlealbumstart(state, action) {
            state.isLoading = true;
        },
        singlealbumsuccess(state, action) {
            state.isLoading = false;
            state.artiste = action.payload?.album;
        },
        singlealbumfailure(state, action) {
            state.isLoading = false;
            state.artiste = null
        },
        categorystart(state, action) {
            state.isLoading = true;
        },
        categorysuccess(state, action) {
            state.categories = action.payload;
            state.isLoading = false

        },
        categoryfailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;

        },
        artiststart(state, action) {
            state.isLoading = true;
        },
        artistsuccess(state, action) {
            state.artists = action.payload;
            state.isLoading = false
        },
        artistfailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        musicbannerstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        musicbannerSuccess(state, action) {

            state.musicbanner = action?.payload;
            state.isLoading = false;
        },
        musicbannerFailure(state, action) {
            state.musicbanner = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        albumbannerstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        albumbannerSuccess(state, action) {

            state.albumbanner = action?.payload;
            state.isLoading = false;
        },
        albumbannerFailure(state, action) {
            state.albumbanner = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        filtersUpdate(state, action) {
            const { categoryId, language, sort, contentType, text, artistId, taggg } = action.payload;
            if (categoryId !== undefined) {
                state.Filters.categoryId = categoryId;
            }
            if (language !== undefined) {
                state.Filters.language = language;
            }
            if (sort !== undefined) {
                state.Filters.sort = sort;
            }
            if (contentType !== undefined) {
                state.Filters.contentType = contentType;
            }
            if (text !== undefined) {
                state.Filters.text = text;
            }
            if (artistId !== undefined) {
                state.Filters.artistId = artistId;
            }
            if (taggg !== undefined) {
                state.Filters.taggg = taggg;
            }
        }
    }
})

export const { filtersUpdate, getallalbumStart, getallalbumSuccess, getallalbumfailure,
    singlealbumstart, singlealbumsuccess, singlealbumfailure, categorystart,
    categorysuccess, categoryfailure, artiststart, artistsuccess, artistfailure, musicbannerstart, musicbannerSuccess, musicbannerFailure, albumbannerstart, albumbannerSuccess, albumbannerFailure
} = albumSlice.actions;

export default albumSlice.reducer;