import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import menicon from '../../../src/images/menicon.jpeg';
import mic from '../../../src/images/mic.png';
import "../../component/layout/style.css";
import moment from 'moment';
import AppCarousel from '../../component/shared/AppCarousel';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';

import { SHOW_ERROR_NOTIFICATION } from '../../appUtils/appToast';
import { addReply, disLikeComment, isAnonymusReply, likeComment } from '../../Allslices/communitySlice';
import AddFileModal from '../../component/modal/AddFileModal';
import AppCarouselforComment from '../../component/shared/AppCarouselforComment';


const CommentItem = ({ caItem, postId, postType }) => {

    const [showAddReply, setShowAddReply] = useState(false);
    const { replyAnonymus } = useSelector((state) => state.community);
    const userImage = localStorage.getItem('userImage');

    const dispatch = useDispatch();

    const likeCommentApi = useCallback((commentId) => {
        dispatch(likeComment({ postId, commentId, postType })).then((resultAction) => {
            if (resultAction?.error) {
                SHOW_ERROR_NOTIFICATION(resultAction.payload);
                return;
            }
        })
    }, [postId]);

    const disLikeCommentApi = useCallback((commentId) => {
        dispatch(disLikeComment({ postId, commentId, postType })).then((resultAction) => {
            if (resultAction?.error) {
                SHOW_ERROR_NOTIFICATION(resultAction.payload);
                return;
            }
        })
    }, [postId]);

    const toggleReply = useCallback(() => {
        setShowAddReply((prev) => !prev);
        dispatch(isAnonymusReply(false))
    }, [showAddReply]);



    // #region addReply
    const [selectedFiles, setSelectedFiles] = useState({
        audio: null,
        video: null,
        image: [],
    });


    const initialValue = {
        description: '',
        image: null,
        audio: null,
        video: null,
        anonymous: false,
    };

    const [formData, setFormData] = useState(initialValue);
    const callAddReply = ((commentId) => {
        formData.image = selectedFiles.image;
        formData.audio = selectedFiles.audio;
        formData.video = selectedFiles.video;
        formData.anonymous = replyAnonymus;
        
        dispatch(addReply({ postId, inputData: formData, commentId })).then((resultAction) => {
            if (resultAction?.error) {
                SHOW_ERROR_NOTIFICATION(resultAction.payload);
                return;
            }
            setFormData(initialValue);
        });
    });

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;

        if (name === 'anonymous') {
            dispatch(isAnonymusReply(!replyAnonymus))
        } else {
            setFormData({
                ...formData,
                [name]: type === "file" ? files : value
            });
        }
    }
    // #endregion

    return (
        <>
            <div className="commeimg p-1">
                {caItem.anonymous ? (
                    <>
                        <div className="d-flex">
                            <i className="fa-regular fa-circle-user ms-4" style={{ fontSize: '3rem' }}></i>
                            <div className='ms-3'>
                                <h6 className="fw-bold">Anonymous User  <span className="text-secondary"><span style={{ position: 'relative', bottom: '4px' }}>.</span> {moment(caItem?.createdAt).fromNow()}</span></h6>
                                <div>
                                    <p className="text-secondary">{caItem?.description}</p>
                                </div>
                            </div>
                        </div>

                    </>
                ) : (
                    < >
                        <div className='d-flex m-2'>
                            <img src={caItem?.createdBy?.imageUrl ?? ''} alt="user_image" style={{ height: '48px', width: '48px', borderRadius: '50%' }}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "default_usericon.jpg";
                                    // currentTarget.style.width = "100px";
                                }}
                            />
                            <div className='mx-3'>
                                <h6 className="fw-bold">{caItem?.createdBy?.name}
                                    <span className="text-secondary"><span style={{ position: 'relative', bottom: '4px' }}>.</span> {moment(caItem?.createdAt).fromNow()}</span>
                                </h6>
                                <div>
                                    <p className="text-secondary">{caItem?.description}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="mx-3 d-flex-child-fit">
                    <div>
                        {caItem?.fileUrls?.images?.length > 0 && <AppCarouselforComment
                            id={caItem._id}
                            images={caItem?.fileUrls?.images?.map((item, index) => item.url)}
                        />}

                        {caItem?.fileUrls?.audio && <div className="d-flex justify-content-center mb-5">
                            <audio controlsList="nodownload" controls src={caItem?.fileUrls?.audio} />
                        </div>}

                        {caItem?.fileUrls?.video &&
                            <MediaPlayer title={`Video by ${caItem?.createdBy?.name}`} src={caItem?.fileUrls?.video}>
                                <MediaProvider />
                                <DefaultVideoLayout icons={defaultLayoutIcons} />
                            </MediaPlayer>
                        }

                    </div>

                </div>
            </div>
            {likeDisLikeBar(caItem, likeCommentApi, disLikeCommentApi, postType == 'question' ? toggleReply : null)}

            {showAddReply && <div className="bgcolorc p-2 mt-2" >
                <div className="smcomimg mx-3">
                    {/* <img src={userImage ?? menicon} alt="" /> */}
                    <textarea
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        className="border-0 ms-4 me-2 p-2"
                        placeholder=" Add a Reply..........."
                        rows="2">
                    </textarea>
                    {/* <button onClick={() => setShowAddFile(true)}>
                        <i class="fa-solid fa-paperclip me-2 text-secondary" />
                    </button> */}
                    <div className="d-flex">
                        {caItem?.addReplyLoading ?
                            <div className="spinner-border align-self-center ms-3" role="status"></div>
                            :
                            <>
                                <div class="form-check d-flex align-items-center gap-2">
                                    <input class="form-check-input" type="checkbox" checked={replyAnonymus} id="anonymusReply" name='anonymous' onChange={handleInputChange} />
                                    <label class="form-check-label" for="">
                                        Post Anonymously
                                    </label>
                                </div>
                                <button className="btn shadowbuttons btn-lg text-light align-self-center ms-3"
                                    onClick={() => callAddReply(caItem._id)}
                                    style={{ borderRadius: '25px', fontSize: '0.8rem' }}>Add a Reply</button>
                            </>
                        }

                    </div>
                </div>
            </div>}

            {caItem?.replies?.map((replyItem, index) => (
                <div key={replyItem?._id} className="pt-3 mx-5">
                    <div className="replyimg d-flex">
                        {replyItem?.anonymous ? (
                            <>
                                <i className="fa-regular fa-circle-user ms-4" style={{ fontSize: '3rem' }}></i>
                                <div className="mx-3">
                                    <h6 className="fw-bold">Anonymous User
                                        <span className="text-secondary">
                                            <span style={{ position: 'relative', bottom: '4px' }}>
                                                .</span> {moment(replyItem?.createdAt).fromNow()}</span>
                                    </h6>
                                    <p className="text-secondary" style={{ fontSize: '13px!important' }}>{replyItem?.description}</p>
                                </div>
                            </>
                        ) : (
                            <>
                                {/* <img src={replyItem?.createdBy?.imageUrl ?? menicon} alt="" /> */}
                                <img src={replyItem?.createdBy?.imageUrl ?? ''} alt="user_image"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "default_usericon.jpg";
                                        // currentTarget.style.width = "80px";
                                    }}
                                />
                                <div className="mx-3">
                                    <h6 className="fw-bold">{replyItem?.createdBy?.name}  <span className="text-secondary"><span style={{ position: 'relative', bottom: '4px' }}>.</span> {moment(replyItem?.createdAt).fromNow()}</span></h6>
                                    <p className="text-secondary" style={{ fontSize: '13px!important' }}>{replyItem?.description}</p>
                                </div>
                            </>
                        )}
                    </div>
                    {likeDisLikeBar(replyItem, likeCommentApi, disLikeCommentApi)}
                </div>
            ))}

            <div className="horizontal_dashed_line my-3" style={{ width: "90%" }} />

            {/* <!-- Modal --> */}
            {/* {showAddFile && <AddFileModal open={showAddFile}
                onHide={() => setShowAddFile(false)}
                inputFiles={selectedFiles}
                onSubmit={(result) => setSelectedFiles(result)}
            />} */}
        </>
    );
}

function likeDisLikeBar(caItem, likeCommentApi, disLikeCommentApi, toggleReply) {
    return <div className="d-flex mx-3">
        <div className="btn-group border" style={{ borderRadius: '25px' }}>

            <button type="button" className="btn text-dark" style={{
                backgroundColor: caItem?.liked ? "lightgray" : null,
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
            }}
                onClick={() => likeCommentApi(caItem._id)}>
                {caItem?.likeLoading ?
                    <div className="spinner-border text-dark" role="status"></div> :
                    <div>
                        <i className="fa-solid fa-circle-up text-primary"></i>&nbsp;{caItem?.likedBy?.length}
                    </div>}
            </button>

            <div className="vl"></div>

            <button type="button" className="btn" style={{
                backgroundColor: caItem?.disLiked ? "lightgray" : null,
                borderTopRightRadius: "25px",
                borderBottomRightRadius: "25px",
            }}
                onClick={() => disLikeCommentApi(caItem._id)}>
                {caItem?.disLikeLoading ?
                    <div className="spinner-border text-dark" role="status"></div> :
                    <div>
                        <i className="fa-solid fa-circle-down text-primary"></i>&nbsp;
                    </div>}
            </button>
        </div>
        {toggleReply && <button>
            <p className="mx-3 text-dark"
                onClick={toggleReply} style={{ position: 'relative', top: '8px' }}>
                Reply
            </p>
        </button>}
        <div style={{ flexGrow: "1" }}></div>
    </div>;
}

export default CommentItem;
