import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
    isLoading: false,
    error: null,
    meditation_config: null,
    meditation_retreats: null,
    meditation_single_retreats: null,
}

export const getMeditation = createAsyncThunk(
    "meditation/getMeditaton_banner",
    async (_, { dispatch, rejectWithValue }) => {
      try {
        dispatch(meditationStart());
        const response = await axios.get(`meditation/getMeditaton_banner`);
        dispatch(meditationSuccess(response?.data));
      } catch (error) {
        dispatch(meditationFailure(error.response.data.message));
      }
    }
  );

  export const getAllMeditation = createAsyncThunk(
    "meditation/getallMeditationLists",
    async (_, { dispatch, rejectWithValue }) => {
      try {
        dispatch(AllMeditationStart());
        const response = await axios.get(`meditation/getallMeditationLists`);
        dispatch(AllMeditationSuccess(response?.data));
      } catch (error) {
        dispatch(AllMeditationFailure(error.response.data.message));
      }
    }
  );

  export const getSingleMeditation = createAsyncThunk(
    "meditation/getsingle/${slug}",
    async (slug, { dispatch, rejectWithValue }) => {
      try {
        dispatch(singleMeditationStart());
        const response = await axios.get(`meditation/getsingle/${slug}`);
        dispatch(singleMeditationSuccess(response.data));
      } catch (error) {
        dispatch(singleMeditationFailure(error.response.data.message));
        return rejectWithValue(error.response.data.message);
      }
    }
  );

  const meditationSlice = createSlice({
    name: "meditation",
    initialState,
    reducers: {
      meditationStart(state) {
        state.isLoading = true;
        state.error = null;
      },
      meditationSuccess(state, action) {
        state.meditation_config = action.payload?.meditation_data;
        state.isLoading = false;
      },
      meditationFailure(state, action) {
        state.meditation_config = null;
        state.isLoading = false;
        state.error = action.payload;
      },
      AllMeditationStart(state) {
        state.isLoading = true;
        state.error = null;
      },
      AllMeditationSuccess(state, action) {
        state.meditation_retreats = action.payload?.MeditationsLists;
        state.isLoading = false;
      },
      AllMeditationFailure(state, action) {
        state.meditation_retreats = null;
        state.isLoading = false;
        state.error = action.payload;
      },
      singleMeditationStart(state) {
        state.isLoading = true;
        state.error = null;
      },
      singleMeditationSuccess(state, action) {
        state.meditation_single_retreats = action?.payload.data;
        state.isLoading = false;
      },
      singleMeditationFailure(state, action) {
        state.meditation_single_retreats = null;
        state.isLoading = false;
        state.error = action.payload;
      }
    }
  });
  
  export const {
    meditationStart,
    meditationSuccess,
    meditationFailure,
    AllMeditationStart,
    AllMeditationSuccess,
    AllMeditationFailure,
    singleMeditationStart,
    singleMeditationSuccess,
    singleMeditationFailure,
   
  } = meditationSlice.actions;
  
  export default meditationSlice.reducer;
  