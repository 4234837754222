import React, { useEffect } from "react";
import ".././component/layout/style.css";
import bg from "../../src/images/Rectangle 57 (3).png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import {
  playTrack,
  setCurrentTrack,
  setTrackList,
} from "../Allslices/playerSlice";
import { getSingleWellness } from "../Allslices/wellnessSlice";
import WellnessSongMusicPlayer from "./WellnessSongMusicPlayer";
import MetaData from "../component/layout/MetaData";
const WellnessMusicSubPage = ({ audioRef, songDetails, setSongDetails }) => {
  const { currentTrack, isPlaying } = useSelector((state) => state.player);
  const dispatch = useDispatch();
console.log("current track", currentTrack)
  const { wellnessslug } = useParams();

  console.log(
    "The songDetails and setSongDetails value is:",
    songDetails,
    setSongDetails
  );
  let url = window.location.href;
  url = url.split("/");
  const urlleng = url.length;
  const songId = url[urlleng - 1];

  useEffect(() => {
    if (!isPlaying && currentTrack == null) {
      if (wellnessslug) {
        dispatch(getSingleWellness(wellnessslug)).then((res) => {
          dispatch(setTrackList({ list: res.payload?.wellness?.songList }));
          dispatch(setCurrentTrack(res.payload?.wellness?.songList.find((song)=>song?.slug == songId)));
        });
      }
    }
  }, [dispatch]);

  const seekPoint = (e) => {
    audioRef.current.currentTime = (e / 100) * audioRef.current.duration;

    setSongDetails((prev) => ({
      ...prev,
      time: Math.round(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      ),
    }));
  };

  return (
    <>
      <MetaData title={currentTrack?.title} />
      <div className="conatainer-fluid" style={{ overflow: "hidden" }}>
        <img
          src={bg}
          alt=""
          height={"100%"}
          width={"100%"}
          className="image-fluid"
        />
      </div>
      <div className="container py-4 mb-4" style={{ overflow: "hidden" }}>
        <div className="row">
          <div className="col-lg-6 col-xs-12 d-flex justify-content-center text-center p-2 position-relative">
            <img
              src={currentTrack?.thumbnail_image}
              alt=""
              style={{
                maxWidth: "90%",
                backgroundSize: "cover",
              }}
            />
            <div className="musicplayerwellness bg-light p-3 rounded-3">
              <WellnessSongMusicPlayer
                albumId={wellnessslug}
                audioRef={audioRef}
                songDetails={songDetails}
                onSeek={seekPoint}
                time={songDetails?.time}
                setSongDetails={setSongDetails}
              />
            </div>
          </div>
          <div className="col-lg-6 col-xs-12 text-lg-start p-3 mt-md-5 mt-lg-0 text-sm-center">
            <h2
              className="fw-semibold h2wellnesssong"
              style={{ fontFamily: "Lato" }}
            >
              About Track
            </h2>
            <p style={{ fontFamily: "Lato" }}>{currentTrack?.description}</p>
            <div className="fw-bold">
              {/* <span>
                Label :{" "}
                <b>
                  {currentTrack?.label?.map((item) => item.title).join(", ")}
                </b>
              </span>
              <br />
              <span>
                Artists :Dr Archika Didi
                <b>
                  {currentTrack?.artist?.map((item) => item.title).join(", ")}
                </b>
              </span>
              <br />
              <span>Genres : Pop-goth</span>
              <br /> */}
              <span>
                Release Date :{" "}
                <Moment format="D MMM YYYY" withTitle>
                  {currentTrack?.created_at}
                </Moment>
              </span>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WellnessMusicSubPage;
