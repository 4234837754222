import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Rectangle74 from "../../../src/images/Rectangle 74.png";
import Download from "../../../src/images/google_logo (2).png";
import Facebook from "../../../src/images/Facebook_Logo_2023 (1).png";
import { useSelector, useDispatch } from "react-redux";
import "./auth.css";
import Slider from "react-slick";
import { loginUser } from "./authSlice";
import MetaData from "../../component/layout/MetaData";
import { getTestimonials } from "../../Allslices/homeSlice";

function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const key = searchParams.get("key");
  const slug = searchParams.get("slug");
  const { isAuthenticated, error, isLoading } = useSelector((state) => state.auth);
  const { testimonials } = useSelector((state) => state.home);

  const [formdata, setformdata] = useState({
    email: "",
    password: "",
    forUser:true
  });

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  let sliderRef1 = useRef();
  useEffect(() => {
    dispatch(getTestimonials());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;

    setformdata({
      ...formdata,
      [name]: type === "file" ? files : value,
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(loginUser(formdata));
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (key) {
        navigate(`/${key}/${slug}`);
      } else {
        navigate("/");
      }
    }
  }, [dispatch, error, isAuthenticated, navigate, key, slug]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const googleAuth = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/google/callback`,
      "_self"
    );
  };

  const facebookAuth = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/auth/facebook/callback`,
      "_self"
    );
  };

  return (
    <>
      <MetaData title="Login" />
      <div
        className="LoginHero mt-2"
        style={{
          backgroundImage: `url(${Rectangle74})`,
          backgroundSize: "cover",
          height: "950px",
        }}
      >
        <div className="login12 container login">
          <div className="text-center pt-6 pb-3">
            <h1>
              <span style={{ fontSize: "60px" }} className="loginfont">Login</span>
            </h1>
          </div>
          <div
            className="row justify-content-center"
            style={{ columnGap: "3rem", rowGap: "2rem" }}
          >
            <div className="main_div_review" style={{ height: "21rem" , width: "450px"}}>
              <Slider
                ref={(slider) => (sliderRef1 = slider)}
                {...sliderSettings}
              >
                {Array.isArray(testimonials) &&
                  testimonials.map((element, index) => (
                    <div className="card-body" key={index}>
                      <h5
                        className="card-title text-center"
                        style={{
                          fontFamily: "myFont",
                          fontSize: "1.5rem",
                          fontWeight: "700",
                        }}
                      >
                        Dr. Archika Didi
                      </h5>
                      <p
                        className="customer_comment"
                        style={{ fontFamily: "myFont" }}
                      >
                        {element?.description}
                      </p>
                      <div className="image_review" style={{ padding: "15px" }}>
                        <div className="customer_image">
                          <img src={element?.user_image} alt="userImage" />
                        </div>
                        <div className="customer_name_review_status pt-2">
                          <div className="customer_name">
                            {element?.user_name}
                          </div>
                        </div>
                        <div className="customer_name_review_status ">
                          <div
                            className="customer_name lato"
                            style={{ color: "gray" }}
                          >
                            {element?.name_id}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="main_div">
              <form onSubmit={handleLogin} className="mt-1">
                <label htmlFor="email" className="form-label textgray">
                  Email Address
                </label>
                <div className="input-group mb-3">
                  <span className="input-group-text text-white rounded-0 bgPcolor">
                    <i className="fa-regular fa-envelope"></i>
                  </span>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formdata.email}
                    onChange={handleInputChange}
                    className="form-control rounded-0 textgray"
                    placeholder="Email address"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <label htmlFor="password" className="form-label textgray">
                  Password
                </label>
                <div className="input-group mb-3">
                  <span className="input-group-text text-white rounded-0 bgPcolor">
                    <i
                      className="bx bx-lock-open-alt"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </span>
                  <input
                    id="password"
                    name="password"
                    onChange={handleInputChange}
                    value={formdata.password}
                    className="form-control rounded-0 textgray"
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Password"
                    aria-describedby="basic-addon1"
                    autoComplete="off"
                  />
                  <i
                    className={`far ${
                      passwordVisible ? "fa-eye-slash" : "fa-eye"
                    }`}
                    onClick={togglePasswordVisibility}
                  ></i>
                </div>
                <div className="option_div pb-4">
                  <div className="forget_div">
                    <a href="/forgotpassword">Forgot password?</a>
                  </div>
                </div>
                <div className="input_box button">
                <input
    type="submit"
    className="bgPcolor"
    value={isLoading ? "" : "Sign in"}
    disabled={isLoading}
  />
  {isLoading && (
    <div
      className="spinner-border text-light position-absolute"
      role="status"
      style={{
        top: "18%",
        left: "45%",
        transform: "translate(-50%, -50%)",
        animation: "rotateSpinner 1s linear infinite"
      }}
    >
      <span className="visually-hidden">Loading...</span>
    </div>
  )}

                </div>
                <h5
                  className="p-3 text-center fw-bolder"
                  style={{ color: "#9C90D8" }}
                >
                  OR
                </h5>
                <div className="input-group mb-3">
                  <button
                    type="button"
                    onClick={googleAuth}
                    className="form-control googlebtn btn btn-success rounded-3"
                  >
                    Sign in with Google
                    <img src={Download} alt="" className="appimg" />
                  </button>
                </div>
                {/* <div className="input-group mb-3">
                  <button
                    type="button"
                    onClick={facebookAuth}
                    className="form-control fbbtn btn btn-primary rounded-3"
                  >
                    Sign in with Facebook
                    <img src={Facebook} alt="" className="appimg" />
                  </button>
                </div> */}
                <div className="links text-center pt-2">
                  Don't have an account?
                  <a
                    href="/register"
                    style={{ color: "#9C90D8", paddingLeft: "5px" }}
                  >
                    Register here
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
