import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import gita from "../images/Bhagwadgita.png";
import banner from "../images/banner-photo.png";
import shrikrishna from "../images/pngegg.png";
import ad from "../images/ad.png";
import bg from "../images/bground.png";
import geeta from "../images/Geeta.png";
import bg1 from "../images/bgstar.png";

import depression from "../images/depression.png";
import motivation from "../images/motivation.png";
import meditation from "../images/meditation.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Carousel from "./SlideCarousel";
import ImageCarousel from "./ImageCarousel";
import rightarrow from "../../src/images/right-arrow 1.png";
import Slider from "react-slick";
import MetaData from "../component/layout/MetaData";
import Moment from "react-moment";
import crown from "../images/crown.png";
import ReactModal from 'react-modal';

import {
  getCategoryBhagwat,
  getGeetaMainPage,
  getlistBhagwat,
  get_topvideo_VersesBhagwat,
} from "../Allslices/BhagwatGitaSlice";
import SmallLoader from "./SmallLoader";
import { getmyOrder } from "../Allslices/orderSlice";
import OrderModal from "../Payments/OrderModal";


// import { useNavigate } from "react-router-dom";
const titlemaxLength = 25;
const BhagwatGita = () => {
  const dispatch = useDispatch();
  const maxLength = 100;
  const { BhagwatVerses } = useSelector((state) => state.BhagwatGita);

  const { Bhagwattopvideo } = useSelector((state) => state.BhagwatGita);
  console.log("Bhagwattopvideo",Bhagwattopvideo)
  const { orderAll } = useSelector((state) => state?.order);

  const orderType = "bhagwat";

  const productId = [];

  const [orderId, serOrderId] = useState("")

  useEffect(() => {
    orderAll.forEach((item) => {
      return serOrderId(item?.orderItems?.product)
    })
  })










  const [buynow, setbuynow] = useState(false);
  const [modelOpen, setModelOpen] = useState(null)
  const [singleVideo, setSingleVideo] = useState({})

  const handlebuynow = (proId) => {
    const singleV = Bhagwattopvideo?.find((data) => data?._id === proId);
    setSingleVideo(singleV);
    console.log('singleV', singleV);
  
    const checkID = orderAll.some((orderItem) => orderItem?.orderItems?.product === proId);
    console.log('checkID', checkID);
  
    if (checkID) {
      setbuynow(false);
      handleNavigatetoCART1(singleV.slug);
      return;
    } 
  
    if (singleV?.isPaid === false) {
      setbuynow(false);
      handleNavigatetoCART1(singleV.slug);
      return;
    }
  
    // If the product is not purchased and not free
    setbuynow(true);
    setModelOpen(0); // Open the modal for buying
  };
  


  
  
  


console.log("singleVideo111",modelOpen)




  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);



  const { BhagwatCategory } = useSelector((state) => state.BhagwatGita);

  const { Bhagwatlist, geetamainpage } = useSelector(
    (state) => state.BhagwatGita
  );



  useEffect(() => {
    dispatch(getCategoryBhagwat());
    dispatch(getlistBhagwat({}));
    dispatch(getGeetaMainPage());
    dispatch(get_topvideo_VersesBhagwat());
  }, []);

  const Navigate2 = useNavigate();
  const handleNavigatetoAllChapter = () => {
    Navigate2("/bhagwatgeeta/chapter");
  };
  const Navigate3 = useNavigate();
  const handleNavigatetoCART1 = (slug) => {

    Navigate3(`/cart1/${slug}`);
  };


  const { slug } = useParams();
  const Navigate1 = useNavigate();
  const handleNavigatetoSubChapter = (slug) => {
    Navigate1(`/bhagwatgeeta/chapter/${slug}`);
  };

  const [chaptersToShow, setChaptersToShow] = useState(4);
  const handleShowMoreChapters = () => {
    setChaptersToShow(chaptersToShow + 4);
  };
  const url = window.location.href;

  let sliderRef = useRef();

  let next = () => {
    sliderRef.slickNext();
  };
  let previous = () => {
    sliderRef.slickPrev();
  };

  let sliderRef1 = useRef();
  let next1 = () => {
    sliderRef1.slickNext();
  };
  let previous1 = () => {
    sliderRef1.slickPrev();
  };
  const settings = {
    // dots: true,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const settings1 = {
    // dots: true,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };


  
  useEffect(() => {
    if (buynow && singleVideo && modelOpen.length === 0) {
      handleNavigatetoCART1(singleVideo.slug);
    }
  }, [buynow, singleVideo, modelOpen]);


  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');

  useEffect(() => {
    if (isOpenVideo) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isOpenVideo]);

  const openModal = (video_url) => {
    console.log("Opening modal with video URL:", video_url); // Debug log
    setSelectedVideo(video_url);
    setIsOpenVideo(true);
  };

  const closeModal = () => {
    setSelectedVideo('');
    setIsOpenVideo(false);
  };

  return (
    <>
      <MetaData title="Bhagwad Geeta" />
      <div className="mb-5">
        {geetamainpage?.bhagwatgeetabanner ? (
          <>
            <div
              className="container-fluid pt-5 pe-3"
              id="h"
              style={{
                backgroundImage: `url(${geetamainpage?.bhagwatgeetabanner?.banner_image})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "auto",
                minHeight: "auto",
              }}
            >
              <div className="row pt-5">
                <div className="col-md-6 col-sm-12 d-flex justify-content-center flex-column p-5 order-sm-first order-last ">
                  <h1 className="bhagwathead" style={{ fontFamily: "myFont" }}>
                    {geetamainpage?.bhagwatgeetabanner?.heading}
                  </h1>
                  <p className="pt-3 text-dark" style={{ lineHeight: "30px", fontSize: "18px",textAlign:"justify" }}>
                    {geetamainpage?.bhagwatgeetabanner?.sub_heading}
                  </p>
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-end order-sm-last order-first">
                  <img
                    src={geetamainpage?.bhagwatgeetabanner?.side_image}
                    alt=""
                    className="image-fluid"
                    id="mobile_image"
                  //   width={747.5}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <SmallLoader />
        )}

        {geetamainpage?.section_1 ? (
          <>
            <div
              className="container-fluid p-2"
              style={{
                //   fontFamily: "myfont",
                backgroundImage: `url(${geetamainpage?.section_1?.background_image})`,
                backgroundPosition: "center right",
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                minHeight: "70vh",
              }}
            >
              <div className="container d-flex  align-items-center flex-column  lh-lg">
                <h1
                  className="text-center pb-4"
                  style={{ fontFamily: "'Playfair Display SC', serif", textTransform: "uppercase", fontSize: "3rem" }}
                >
                  <strong>{geetamainpage?.section_1?.heading}</strong>
                </h1>
                <p className="text-center text-dark pb-3" style={{ fontSize: "20px",textAlign:"justify"  }}>
                  {geetamainpage?.section_1?.sub_heading}
                </p>

              </div>
              <div className=" pt-4">
                <div className="position-relative">

                  <div
                    className="overlay"
                    onClick={() => openModal(geetamainpage?.section_1?.video_url)} style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: '100%',
                      cursor: 'pointer',
                      zIndex: 1,
                    }}
                  ></div>
                  <iframe
                    width="50%"
                    height="350px"
                    style={{ borderRadius: '10px', margin: 'auto', display: 'block' }}
                    src={geetamainpage?.section_1?.video_url}
                    title="YouTube video player"
                    onClick={() => openModal(geetamainpage?.section_1?.video_url)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>

                </div>

              </div>
            </div>
          </>
        ) : (
          <SmallLoader />
        )}


        <ReactModal
          isOpen={isOpenVideo}
          onRequestClose={closeModal}
          style={{
            overlay: { backgroundColor: 'rgba(0, 0, 0, 0.75)' },
            content: {
              top: '45%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              width: '50%',
              height: '54%',
              padding: '0',
              overflow: 'hidden',
              border: 'transparent',
              backgroundColor: 'transparent',
            }
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: '20px' }}>
            <button onClick={closeModal} style={{ background: 'none', border: 'none', fontSize: '20px', }}>✖</button>
          </div>
          <div style={{ width: '100%', height: 'calc(100% - 20px)' }}>
            <iframe
              width="100%"
              height="100%"
              style={{ borderRadius: '10px' }}
              src={selectedVideo}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </ReactModal>

        <div
          className="container-fluid py-5"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            minHeight: "85vh",
          }}
        >
          <div className="pt-3 text-center">
            <h1 style={{ fontFamily: "myFont", fontSize: "4rem" }}>
              Top Videos
            </h1>
          </div>

          <div className="carousel-container mt-5 container">
            <Slider className="pb-4"
              ref={slider => {
                sliderRef = slider;
              }}
              {...settings}
            >
              {Array.isArray(Bhagwattopvideo) && Bhagwattopvideo.filter(item => item?.videourl).map((item, index) => item.top == "yes" ? (

                <div className="col-lg-4 col-sm-12 " key={index} >
                  <div
                    className="card item-card text-dark mx-2 card_height1_1 "
                    style={{
                      borderRadius: "10px",
                    }}


                    data-bs-toggle={modelOpen === 0 ? "modal" : undefined}
                    data-bs-target={modelOpen === 0 ? "#staticBackdrop" : undefined}
                    onClick={() => {
                      // handleNavigatetoCART1(item.slug,item);
                      handlebuynow(item._id);
                    }}>
                    {item?.isPaid== true && !orderAll.some(orderItem => orderItem?.orderItems?.product === item._id) && (
                      <img
                        src={crown}
                        alt="Crown icon"
                        className="position-absolute end-0 mx-2"
                        height={25}
                      />
                    )}
                    <img
                      src={item?.thumbnail_image}
                      alt=""
                      height="auto"
                      width="auto"
                      style={{ borderRadius: "10px 10px 0px 0px", aspectRatio: "3/2" }}
                    />
                    <div className="container">
                      <h4
                        className="lato pt-3"
                        style={{ fontSize: "16px" }}
                      >
                        <Moment
                          className="fw-semibold"
                          format="D MMM YYYY"
                          withTitle
                        >
                          {item?.createdAt}
                        </Moment>
                      </h4>

                      <span
                        className="mt-1 fs-4 text-capitalize"
                        style={{ fontFamily: "playfair", color: "black" }}
                      >
                        {item?.title?.length > titlemaxLength
                          ? `${item.title.substring(0, titlemaxLength)}...`
                          : item?.title}
                      </span>

                      <p
                        className="card-text lato text-capitalize"
                        dangerouslySetInnerHTML={{
                          __html:
                            item?.shortdescription?.length > maxLength
                              ? `${item.shortdescription.substring(
                                0,
                                maxLength
                              )}...`
                              : item?.shortdescription,
                        }}
                        style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                      ></p>
                      <div>

                      </div>
                    </div>

                  </div>
                </div>

              ) : (
                ""
              )
              )}
            </Slider>
            {/* <div className="d-flex justify-content-center gap-5 mt-5  ">

              <button className="button text-white p-3 rounded-circle" onClick={previous} style={{ backgroundColor: "#ffbe59", border: "none", boxShadow: "0 2px 4px rgba(0,0,0,0.2)", borderRadius: "50%", fontSize: "18px", fontWeight: "bold" }}>
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              <button className="button text-white p-3 rounded-circle" onClick={next} style={{ backgroundColor: "#ffbe59", border: "none", boxShadow: "0 2px 4px rgba(0,0,0,0.2)", borderRadius: "50%", fontSize: "18px", fontWeight: "bold" }}>
                <i className="fa-solid fa-chevron-right"></i></button>

            </div> */}
          </div>




        </div>

        {geetamainpage ? (

          <div
            className="container mt-5 d-flex align-items-center flex-column p-2 lh-lg text-center"
            style={{
              fontFamily: "myfont",
              backgroundImage: `url(${geetamainpage?.section_3?.background_image})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              minHeight: "80vh",
              color: "white",
              fontSize: "23px",
            }}
          >
            <h1 className="py-3" style={{ fontFamily: "myFont", fontSize: "4rem" }}>
              {geetamainpage?.section_3?.heading}
            </h1>
            <p className="text-light lh-lg px-3" style={{ fontSize: "18px",textAlign:"justify"}}>
              {geetamainpage?.section_3?.sub_heading}
            </p>
          </div>
        ) : (
          <SmallLoader />
        )}

        <div className="container mt-5">
          <h1
            className="text-center p-3"
            style={{ fontFamily: "myFont", fontSize: "4rem" }}
          >
            Chapters
          </h1>
          <div class="row g-3 mt-4">
            {Bhagwatlist ? (
              <>
                {Array.isArray(Bhagwatlist) &&
                  Bhagwatlist?.slice(0, chaptersToShow).map((item, index) => (
                    <div class="col-sm-6" key={index}>
                      <div
                        class="card shadow m-1" style={{ transition: "0.5s" }}
                        onClick={() => handleNavigatetoSubChapter(item.slug)}
                      >
                        <div class="card-body">
                          <h5 style={{ color: "#ff9f54", fontWeight: "600" }}>
                            {item.chapter_number}
                          </h5>
                          <h3 className="fw-bold">{item.title}</h3>
                          <p class="card-text text-secondary"style={{textAlign:"justify" }}>
                            {item.short_description}
                          </p>
                          {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
                          <div className="d-flex align-items-center gap-4">
                            <i
                              class="fa-solid fa-list-ul lh"
                              style={{ color: "#7b7b7b" }}
                            ></i>
                            <p className="lh m-0 text-black">{item?.verses?.length} Verses</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            ) : (
              <SmallLoader />
            )}
          </div>

          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn cmn-t-shake rounded-5 btn-lg mt-5 mb-2 d-sm-5"
              style={{ backgroundColor: "#ff9933", color: "white", padding: "15px 30px" }}
              onClick={() => {
                handleNavigatetoAllChapter();
                handleShowMoreChapters();
              }}
            >
              More Chapter
            </button>
          </div>
        </div>

        <div
          className="container-fluid  mt-5"
          style={{
            //   fontFamily: "myfont",
            backgroundImage: `url(${bg1})`,
            backgroundPosition: "center right",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",

          }}
        >
          <h2 className="text-center fw-bolder py-5 fs-1" style={{ fontFamily: 'Playfair' }}>Bhagwat Geeta Audio</h2>



          <div className=" carousel-container container">
            <Slider className="pb-4"
              ref={slider => {
                sliderRef1 = slider;
              }}
              {...settings1}
            >
              {Array.isArray(Bhagwattopvideo) && Bhagwattopvideo.filter(item => item?.audiofile).map((item, index) => (
                <div className="d-flex justify-content-center " key={index}   >


                  <div
                    className="card item-card text-dark  mx-2 "
                    style={{
                      borderRadius: "20px",
                    }}
                    data-bs-toggle={modelOpen === 0 ? "modal" : undefined}
                    data-bs-target={modelOpen === 0 ? "#staticBackdrop" : undefined}
                    onClick={() => {
                      // handleNavigatetoCART1(item.slug,item);
                      handlebuynow(item._id);
                    }}
                  >
                    {item?.isPaid===true && !orderAll.some(orderItem => orderItem?.orderItems?.product === item._id) && (
                      <img
                        src={crown}
                        alt="Crown icon"
                        className="position-absolute end-0 mx-2"
                        height={25}
                      />
                    )}
                    <img
                      src={item?.thumbnail_image}
                      alt=""
                      height="auto"
                      width="auto"
                      style={{ borderRadius: "20px 20px 0 0", aspectRatio: "3/2" }}
                    />
                    <div className="container">
                      <h4
                        className="lato pt-3"
                        style={{ fontSize: "16px" }}
                      >
                        <Moment
                          className="fw-semibold"
                          format="D MMM YYYY"
                          withTitle
                        >
                          {item?.createdAt}
                        </Moment>
                      </h4>

                      <span
                        className="mt-1 fs-4 text-capitalize"
                        style={{ fontFamily: "playfair", color: "black" }}
                      >
                        {item?.title?.length > maxLength
                          ? `${item.title.substring(0, maxLength)}...`
                          : item?.title}
                      </span>

                      <p
                        className="card-text lato text-capitalize"
                        dangerouslySetInnerHTML={{
                          __html:
                            item?.shortdescription?.length > maxLength
                              ? `${item.shortdescription.substring(
                                0,
                                maxLength
                              )}...`
                              : item?.shortdescription,
                        }}
                        style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                      ></p>
                      <div>

                      </div>
                    </div>
                  </div>
                </div>

              )
              )}
            </Slider>
            {/* <div className="d-flex justify-content-center gap-5 mt-5  ">
{Array.isArray(Bhagwattopvideo) && Bhagwattopvideo.filter(item => item?.audiofile).map((item, index) => (
  <div className="d-flex justify-content-center " key={index}  data-bs-toggle={modelOpen === 0 ? "modal" : undefined} data-bs-target={modelOpen === 0 ? "#staticBackdrop" : undefined} onClick={() => { handlebuynow(item._id);}}>
    {item?.price > 1 && !orderAll.some(orderItem => orderItem?.orderItems?.product === item._id) && (
      <img src={crown} alt="Crown icon" className="position-absolute end-0" height={35} />
    )}
    <div className="card item-card text-dark  mx-2" style={{ borderRadius: "20px" }}>
      <img src={item?.thumbnail_image} alt="" height="auto" width="auto" style={{ borderRadius: "20px 20px 0 0", aspectRatio: "3/2" }} />
      <div className="container">
        <h4 className="lato pt-3" style={{ fontSize: "16px" }}>
          <Moment className="fw-semibold" format="D MMM YYYY" withTitle>
            {item?.createdAt}
          </Moment>
        </h4>
        <span className="mt-1 fs-4 text-capitalize" style={{ fontFamily: "playfair", color: "black" }}>
          {item?.title?.length > maxLength ? `${item.title.substring(0, maxLength)}...` : item?.title}
        </span>  
        <p className="card-text lato text-capitalize" dangerouslySetInnerHTML={{ __html: item?.shortdescription?.length > maxLength ? `${item.shortdescription.substring(0, maxLength)}...` : item?.shortdescription }} style={{ fontFamily: "Lato", color: "#5B5B5B" }}></p>
        <div></div>
      </div>
    </div>
  </div>
))}

              <button className="button text-white p-3 rounded-circle" onClick={previous1} style={{ backgroundColor: "#ffbe59", border: "none", boxShadow: "0 2px 4px rgba(0,0,0,0.2)", borderRadius: "50%", fontSize: "18px", fontWeight: "bold" }}>
                <i className="fa-solid fa-chevron-left"></i>
              </button>
              <button className="button text-white p-3 rounded-circle" onClick={next1} style={{ backgroundColor: "#ffbe59", border: "none", boxShadow: "0 2px 4px rgba(0,0,0,0.2)", borderRadius: "50%", fontSize: "18px", fontWeight: "bold" }}>
                <i className="fa-solid fa-chevron-right"></i></button>

            </div> */}
          </div>

        </div>
      </div>
      <OrderModal orderType={orderType} product={buynow ? singleVideo : ""} navigate={true} isExpired={false}/>



    </>
  );
};

export default BhagwatGita;
