import React from "react";
import img3 from "../images/image 3.png";
import rect20 from "../images/Rectangle 20.png";
import { useLocation } from "react-router-dom";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon,
} from "react-share";
// import { useDispatch, useSelector } from 'react-redux'
// import { getsingleBlog } from "../Allslices/blogSlice"
import MetaData from "../component/layout/MetaData";
const Wisdom_Video = () => {
  // const dispatch = useDispatch()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  // useEffect(() => {
  //   dispatch(getsingleBlog(id))
  // }, [])

  // const {singleblog} = useSelector((state)=>state.blog)

  const url = window.location.href;

  return (
    <>
    <MetaData title="Wisdom SubPage" />
      {/* banner section */}
      <div className="rect572bg blogimg mb-2">
        <div className="container blogsub ">
          <div className="row text-center p-5">
            <div className="col-lg-12 col-sm-12">
              <h1 className=" text-dark text-uppercase">
                Ram Navami Story: Rama and <br/> a Dog’s Justice
              </h1>
              <p className="pt-3 text-dark fs-6 fw-semibold" style={{lineHeight: "2"}}>
                The story of Mahabharat is rife with curses and boons. Ever
                wondered how much energy it takes to curse someone? Is it easier
                to bless than to curse? Why does the potency of a curse vary, in
                traditional terms? Discover the answers to all these questions
                with Dr Archika Didi.
              </p>
              {/* <div class="imagesee">
     <img src="asset/img/blog/Rectangle 26.png"alt="img"class=""   alt="">
</div> */}
            </div>
          </div>
        </div>
        <div className="imagesee pt-5 mt-5">
              <img src={img3} alt="img" className="" />
            </div>
      </div>
      {/* banner section end */}
      {/* text section all */}
      <section>
        <div className="container pt-3">
          <div className="textrt pt-5 mt-5">
            
            <div className="row px-5 icotext text-center">
              <div className="col-lg-6">
                <p>Audio | feb 8, 2022</p>
              </div>
              <div className="col-lg-6">
                    <a href="#" className="p-1">
                      <FacebookShareButton
                        url={"https://archikadidi.com"}
                        hashtag="#ArchikaDidiBlog"
                        quote={"CampersTribe - World is yours to explore"}
                      >
                        <FacebookIcon size={45} round={true} />
                      </FacebookShareButton>
                    </a>
                    {/* <a href="#" className="p-1">
                      <FacebookMessengerShareButton
                        appId=""
                        title="Share on Messenger"
                        url={url}
                        hashtag="#ArchikaDidiBlog"
                      >
                        <FacebookMessengerIcon size={45} round={true} />
                      </FacebookMessengerShareButton>
                    </a> */}
                    {/* <a href="#" className="p-1">instagram</a> */}
                    <a href="#" className="p-1">
                      <WhatsappShareButton
                        appId=""
                        title="Hi I am happy to share Blogs by Archika Didi"
                        url={url}
                        hashtag="#ArchikaDidiBlog"
                      >
                        <WhatsappIcon size={45} round={true} />
                      </WhatsappShareButton>
                    </a>
                    <a href="#" className="p-1">
                      <TwitterShareButton
                        appId=""
                        title="Hi I am happy to share Blogs by Archika Didi"
                        hashtag="#ArchikaDidiBlog"
                        url={url}
                      >
                        <XIcon size={45} round={true} />
                      </TwitterShareButton>
                    </a>
                    <a href="#" className="p-1">
                      <TelegramShareButton
                        title="Hi I am happy to share Blogs by Archika Didi"
                        url={url}
                      >
                        <TelegramIcon size={45} round={true} />
                      </TelegramShareButton>
                    </a>
                  </div>
            </div>
            <div className="contyu px-5 pt-4" />
            <p>
            Originally, brahmana, kshatriya, vaishya, and shudra were not seen as sects of people who are born in certain families as it is understood today, but as descriptions of qualifications. A shudra was someone who did menial jobs; all he knew was his body. A vaishya was someone who was using his body, and to some extent, his mind. He was largely free of emotion, except for his own family. His body and his calculations ruled his nature. A kshatriya was passionate to the point of involving his life energies. He was more in his heart; otherwise he could not rule or lay down his life for what he believed in..
            </p>

            {/* </div> */}
          </div>
        </div>
      </section>
      {/* text section all end */}

      <div className="mahab-mixer  d-none d-lg-block">
        <h1 className="text-center pt-5 galeryhead">
          <span>Related Content</span>
        </h1>
      </div>
      <div className="container artvid pt-5">
        <div className="row g-5 py-4">
          <div className="col-md-6 col-sm-12 col-lg-4 item">
            <div className="card item-card card-block">
              <img src={rect20} alt="Photo of sunset" />

              <div
                className="d-flex justify-content-end"
                style={{ position: "relative", right: "26px" }}
              >
                <h6 className="pt-3">Article</h6>
                <i
                  className="fa-solid fa-book-open"
                  style={{ position: "relative", top: "18px", left: "9px" }}
                ></i>
              </div>
              <div className="container">
                <h4 className="m-2 mt-3">
                  Get Out of Cyclical Existence and Karma
                </h4>
                <p className="card-text">
                  Why is existence cyclical? Does the wheel of karma turn
                  relentlessly? What role does one’s individual
                  dharma................
                </p>

                <div className=" pb-3 d-flex">
                  <h4 className="">Mar 3, 2021</h4>
                  <a href="#">
                    {" "}
                    <i
                      className="fa-solid fa-bookmark text-dark"
                      style={{
                        position: "absolute",
                        right: "70px",
                        fontSize: "25px",
                      }}
                    ></i>
                  </a>
                  <a href="#">
                    {" "}
                    <i
                      className="fa-solid fa-share-nodes text-dark"
                      style={{
                        position: "absolute",
                        right: "27px",
                        fontSize: "26px",
                      }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-4 item">
            <div className="card item-card card-block">
              <img src={rect20} alt="Photo of sunset" />

              <div
                className="d-flex justify-content-end"
                style={{ position: "relative", right: "26px" }}
              >
                <h6 className="pt-3">Article</h6>
                <i
                  className="fa-solid fa-book-open"
                  style={{ position: "relative", top: "18px", left: "9px" }}
                ></i>
              </div>
              <div className="container">
                <h4 className="m-2 mt-3">
                  Get Out of Cyclical Existence and Karma
                </h4>
                <p className="card-text">
                  Why is existence cyclical? Does the wheel of karma turn
                  relentlessly? What role does one’s individual
                  dharma................
                </p>

                <div className=" pb-3 d-flex">
                  <h4 className="">Mar 3, 2021</h4>
                  <a href="#">
                    {" "}
                    <i
                      className="fa-solid fa-bookmark text-dark"
                      style={{
                        position: "absolute",
                        right: "70px",
                        fontSize: "25px",
                      }}
                    ></i>
                  </a>
                  <a href="#">
                    {" "}
                    <i
                      className="fa-solid fa-share-nodes text-dark"
                      style={{
                        position: "absolute",
                        right: "27px",
                        fontSize: "26px",
                      }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 col-lg-4 item">
            <div className="card item-card card-block">
              <img src={rect20} alt="Photo of sunset" />

              <div
                className="d-flex justify-content-end"
                style={{ position: "relative", right: "26px" }}
              >
                <h6 className="pt-3">Article</h6>
                <i
                  className="fa-solid fa-book-open"
                  style={{ position: "relative", top: "18px", left: "9px" }}
                ></i>
              </div>
              <div className="container">
                <h4 className="m-2 mt-3 text-uppercase">
                  Get Out of Cyclical Existence and Karma
                </h4>
                <p className="card-text">
                  Why is existence cyclical? Does the wheel of karma turn
                  relentlessly? What role does one’s individual
                  dharma................
                </p>

                <div className=" pb-3 d-flex">
                  <h4 className="">Mar 3, 2021</h4>
                  <a href="#">
                    {" "}
                    <i
                      className="fa-solid fa-bookmark text-dark"
                      style={{
                        position: "absolute",
                        right: "70px",
                        fontSize: "25px",
                      }}
                    ></i>
                  </a>
                  <a href="#">
                    {" "}
                    <i
                      className="fa-solid fa-share-nodes text-dark"
                      style={{
                        position: "absolute",
                        right: "27px",
                        fontSize: "26px",
                      }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wisdom_Video;
