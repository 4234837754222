import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";

const initialState = {

    isLoading: false,
    error: null,
    termsConditions: null

}


export const gettermsAndConditions = createAsyncThunk(
    'config/gettermsAndConditions',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(termsAndConditionsstart());
            const response = await axios.get(`config/all_TermsAndConditions`)
            dispatch(termsAndConditionsSuccess(response?.data?.termsConditions))

        } catch (error) {
            dispatch(termsAndConditionsFailure(error.response.data.message))
        }
    }
)


const termsAndConditionsSlice = createSlice({
    name: 'terms',
    initialState,
    reducers: {
        termsAndConditionsstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        termsAndConditionsSuccess(state, action) {

            state.termsConditions = action.payload;
            state.isLoading = false;
        },
        termsAndConditionsFailure(state, action) {
            state.termsConditions = null;
            state.isLoading = false;
            state.error = action.payload;

        }


    }
})


export const { termsAndConditionsstart, termsAndConditionsSuccess, termsAndConditionsFailure } = termsAndConditionsSlice.actions;

export default termsAndConditionsSlice.reducer;
