import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import SmallLoader from "./SmallLoader";
import searchicon from "../images/searchicons.png";
import filtericon from "../images/filtericons.png";
import nodata from "../images/No-Data.png";

import { useSelector, useDispatch } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import {
  filtersUpdate,
  getAllCourses,
  getcoursebanner,
  getCourseCategories,
  getLanguages,
} from "../Allslices/courseSlice";
// import StarRating from './StarRating'
import MetaData from "../component/layout/MetaData";
import usePreserveScroll from "./histrorysave";
export default function OnlineClasses() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const listStartRef = useRef(null);
  const [yCoordinate, setYCoordinate] = useState(null);
  usePreserveScroll();

  const {
    courselist,
    course,
    courseCategories,
    Languages,
    Filters: { categoryId, language, sort, contentType, text },
  } = useSelector((state) => state.course);

  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [fetchedCourses, setFetchedCourses] = useState([]);
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    const element = listStartRef.current;
    if (element) {
      const position = element.getBoundingClientRect();
      const y = position.top;
      setYCoordinate(y);
    }
  }, []);

  const [totalcoursesCount, settotalCoursesCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [waitResponse, setWaitResponse] = useState(false);

  useEffect(() => {
    dispatch(getcoursebanner());
    dispatch(getCourseCategories());
    dispatch(getLanguages());
  }, []);

  const isComponentMounted = useRef(true);
  const [hoveredCourseIndex, setHoveredCourseIndex] = useState(null);
  const sliderRefs = useRef([]);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleMouseEnter = (index) => {
    setHoveredCourseIndex(index);
    sliderRefs?.current[index]?.slickPlay(); // Start autoplay for the hovered slider
  };
  const query = location.search.split("?")[1];

  const handleMouseLeave = () => {
    setHoveredCourseIndex(null);
    sliderRefs?.current?.forEach((slider) => slider?.slickPause()); // Pause autoplay for all sliders
  };
  // let initialfilters = {
  //   categoryId: null,
  //   language: null,
  //   contenttype: null,
  //   newest: "",
  //   text: "",
  // };
  // const [filters, setfilters] = useState(initialfilters);
  const [page, setPage] = useState(1);

  const handleFilterChange = (e) => {
    const { value, name } = e.target;
    const payload = {
      [name]: value,
    };
    dispatch(filtersUpdate(payload));
  };

  const maxLength = 100;
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!hasMore) {
          setLoader(false);
          return;
        }
        setLoader(true);

        setWaitResponse(true);
        dispatch(filtersUpdate(query ? { text: query } : { text: text }));

        const action = await dispatch(
          getAllCourses({
            page: page,
          })
        );

        if (getAllCourses.fulfilled.match(action)) {
          if (action.payload.courses?.length === 0) {
            setHasMore(false);
            setLoader(false);
            return;
          }
          setFetchedCourses([...fetchedCourses, ...action.payload.courses]);
          settotalCoursesCount(action.payload?.totalCourses);
          setLoader(false);
          setWaitResponse(false);
        } else if (getAllCourses.rejected.match(action)) {
        }
      } catch (error) {
        setLoader(false);
        setWaitResponse(false);
      }
    };

    if (isComponentMounted.current) {
      fetchData();
    }
  }, []);

  const cardStyle = {
    borderTop: "1px solid #08080833",
  };

  const handlenavigateToCourseSinglePage = (item) => {
    console.log("The course coming soon value is:", item.coming_soon);
    if (item.coming_soon) {
      handleShow();
    } else {
      navigate(`/courses/${item?.slug}`);
    }
  };

  const handleFiltersSubmit = (e, action) => {
    e.preventDefault();
    if (action == "loadmore") {
      setLoader(true);
    }
    isComponentMounted.current = false;

    if (action == "loadmore") {
      setPage((prevpage) => prevpage + 1);
    }

    dispatch(
      getAllCourses({
        page: action == "loadmore" ? page + 1 : page,
      })
    ).then((response) => {
      // setFetchedwellness(response?.payload?.wellnessLists)
      if (action == "loadmore") {
        setFetchedCourses([...fetchedCourses, ...response?.payload?.courses]);
        setLoader(false);
      } else {
        setFetchedCourses(response?.payload?.courses);
        window.scrollTo({
          top: 650,
          behavior: "smooth",
        });
      }
    });
  };

  return (
    <div>
      <MetaData
        title={course?.coursebanner?.meta_title}
        description={course?.coursebanner?.meta_description}
        tags={course?.coursebanner?.meta_tag}
      />
      {course ? (
        <>
          <div
            className="wisdomimg bg-cover photo_p desktop-only"
            style={{
              backgroundImage: `url(${course?.coursebanner?.background_image})`,
            }}
          >
            <div className="container wisdompad ">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {course?.coursebanner?.alignItem === "left" && (
                    <>
                      <div>
                        <h1 className="fw-bold ">
                          <span className="wisdomhead haedingfont">
                            {course?.coursebanner?.heading}
                          </span>
                        </h1>
                        <p
                          className=" w-75 wisdomPara homebdes"
                          style={{
                            fontFamily: "Lato",
                            textAlign: "justify",
                          }}
                        >
                          {course?.coursebanner?.subheading}
                        </p>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-lg-6  col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {course?.coursebanner?.alignItem === "right" && (
                    <>
                      <div>
                        <h1 className="fw-bold ">
                          <span className="wisdomhead haedingfont">
                            {course?.coursebanner?.heading}
                          </span>
                        </h1>
                        <p
                          className=" w-75 wisdomPara homebdes"
                          style={{
                            fontFamily: "Lato",
                            textAlign: "justify",
                          }}
                        >
                          {course?.coursebanner?.subheading}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="wisdomimg mobile-only "
            style={{
              backgroundSize: "contain",
              backgroundImage: `url(${course?.coursebanner?.res_background_image})`,
            }}
          >
            <div className="container wisdompad onlinett ">
              <div className="row">
                <div className="col-lg-6  col-md-6 col-sm-6 col-12 d-flex flex-column align-items-start justify-content-center">
                  <div className="">
                    <h1 className="fw-bold w-50 ">
                      <span className="wisdomhead haedingfont_chek">
                        {course?.coursebanner?.res_heading}
                      </span>
                    </h1>
                    <p
                      className="  wisdomPara homebdes"
                      style={{
                        fontFamily: "Lato",
                        textAlign: "justify",
                      }}
                    >
                      {course?.coursebanner?.res_subheading}
                    </p>
                  </div>
                </div>

                <div></div>
              </div>
              </div>
            </div> */}

          {/* banner starts here */}


          <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${course?.coursebanner?.res_background_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {course?.coursebanner?.alignItem === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html:course?.coursebanner?.res_heading,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {course?.coursebanner?.alignItem === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html:course?.coursebanner?.res_heading,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: course?.coursebanner?.res_subheading,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>



          {/* <div className="container-fluid mobile-only">
            <div
              className="container mycontainer3"
              style={{
                backgroundSize: "contain",
                backgroundImage: `url(${course?.coursebanner?.res_background_image})`,
                backgroundRepeat: "no-repeat",
                height: "63vh",
              }}
            >
              <div className="row h-50">
                <div className="col-6 d-flex justify-content-center align-items-center h-75">
                  {course?.coursebanner?.alignItem === "left" && (
                    <>
                      <h4 className="haedingfont">
                        <span className="wisdomhead haedingfont">
                          {course?.coursebanner?.res_heading}
                        </span>
                      </h4>
                    </>
                  )}
                </div>
                <div className="col-6 d-flex justify-content-center align-items-center h-75">
                  {course?.coursebanner?.alignItem === "right" && (
                    <>
                      <h1 className="fw-bold ">
                        <span className="wisdomhead haedingfont">
                          {course?.coursebanner?.res_heading}
                        </span>
                      </h1>
                    </>
                  )}
                </div>
              </div>

              <p
                className="m-0  "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify",
                }}
              >
                {course?.coursebanner?.res_subheading}
              </p>
            </div>
          </div> */}
          {/* banner ends here */}
          <div className="container filterpad  shadow sidemedia py-2">
            
            <div className="row pb-4 p-3 justify-content-center">
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center gap-2"
                id="mobileScreen"
              >
                <label
                  htmlFor="filter"
                  className="fw-bold myfiltericon"
                  style={{ color: "#A0A0A0" }}
                >
                  <img style={{ width: "25px" }} src={filtericon} alt="" />
                </label>
                <select
                  className="select searchborder rounded-pill p-2 w-100 my-2"
                  value={language}
                  name="language"
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="">All Languages</option>
                  {Array.isArray(Languages) &&
                    Languages?.map((item, index) => {
                      return (
                        <option value={item?.language} className="text-black">
                          {item?.language}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <select
                  className="select searchborder rounded-pill p-2 w-100 px-2 mx-1 my-2"
                  value={categoryId}
                  name="categoryId"
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="">Category</option>
                  {Array.isArray(courseCategories) &&
                    courseCategories?.map((item, index) => {
                      return <option value={item?._id}>{item?.name}</option>;
                    })}
                </select>
              </div>
              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <select
                  value={sort}
                  name="sort"
                  className="select searchborder rounded-pill p-2 w-100 px-2 my-2"
                  onChange={(e) => handleFilterChange(e)}
                >
                  <option value="English">Sort by</option>
                  <option value="newest">Newest</option>
                  <option value="oldest">Oldest</option>
                </select>
              </div>

              <div
                className="col-lg-3 col-sm-12 col-md-6 d-flex justify-content-center align-items-center"
                id="mobileScreen"
              >
                <label
                  for="filter"
                  className="fw-bold mysearchicon"
                  style={{ color: "#A0A0A0" }}
                >
                  <img
                    src={searchicon}
                    alt=""
                    style={{ width: "25px" }}
                    onClick={handleFiltersSubmit}
                  />
                </label>
                <form className=" w-100 pt-4" role="search">
                  <input
                    className="form-control me-2 w-100 searchborder"
                    // type="search"
                    name="text"
                    placeholder="Search by keyword..."
                    aria-label="Search"
                    onChange={(e) => handleFilterChange(e)}
                    value={text}
                    style={{ borderRadius: "19px" }}
                  />
                  <i class="fa-solid fa-magnifying-glass mysearchiconforphone "></i>
                </form>
              </div>
              <div className="pt-3 d-flex justify-content-center">
                <button
                  className="btn  shadowbuttons btn-lg text-light cmn-t-shake"
                  style={{
                    padding: "0.6rem 2.5rem",
                  }}
                  onClick={handleFiltersSubmit}
                >
                  SEARCH
                </button>
              </div>
            </div>
          </div>
       
          <section className="mb-5">
            <div className="container artvid mt-2 pe-2">
              {fetchedCourses?.length > 0 ? <div className="row" ref={listStartRef} id="listStart">
                {Array.isArray(fetchedCourses) &&
                  fetchedCourses.map((item, index) => (
                    <div
                      className="col-lg-4 col-sm-12 col-12 my-2"
                      onClick={() => handlenavigateToCourseSinglePage(item)}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                      key={index}
                    >
                      <div
                        className="card item-card card-block mx-1"
                        style={{
                          borderRadius: "20px",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                        }}
                      >
                        <Slider
                          ref={(slider) => (sliderRefs.current[index] = slider)}
                          {...(hoveredCourseIndex === index
                            ? sliderSettings
                            : {})}
                        >
                          {item?.sliderImages?.map((slider_image, idx) => (
                            <div className="carousel-item" key={idx}>
                              <img
                                src={slider_image?.course_image}
                                className="d-block w-100"
                                alt="..."
                                style={{
                                  // aspectRatio: "3/2",
                                  objectFit: "cover",
                                  borderRadius: "20px",
                                }}
                              />
                            </div>
                          ))}
                        </Slider>
                        {/* <div className="cardBottom d-flex flex-column">
                          <div className="container px-4 pb-3">
                            <h6
                              className="pt-3 text-dark text-end"
                              style={{
                                marginLeft: "0.0rem",
                                fontFamily: "Lato",
                                color: "#5B5B5B",
                              }}
                            >
                              {item?.category?.name}
                            </h6>
                            <h4
                              className="mt-2 fs-4 text-capitalize"
                              style={{ fontFamily: "playfair", color: "black" }}
                            >
                              {item?.title?.length > maxLength
                                ? `${item.title.substring(0, maxLength)}...`
                                : item?.title}
                            </h4>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ))}
              </div> : 
              <section>
                <div className="text-center d-flex justify-content-center">
                <img style={{ width: "400px" }} src={nodata} alt="No Data Image" />
                </div>
              </section>}
            </div>
          </section>
        </>
      ) : (
        <SmallLoader />
      )}
      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden={!showModal}
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ fontFamily: "myFont", color: "#9767a2" }}
              >
                Coming Soon
              </h5>
              <button
                type="button"
                className="fs-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
                style={{ color: "#9767a2" }}
              >
                <IoCloseSharp />
              </button>
            </div>
            <div className="modal-body lato pb-4 ">
              {/* <img src={coming_soon_image} alt="Coming Soon Image" height={10} width={100}/> */}
              This Course is coming soon. Please check back later!
            </div>
            {/* <div className="modal-footer">
              <button type="button" className="btn shadowbuttons text-white fs-6" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
