import React, { useEffect, useState } from "react";
import img from "../images/song-img.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createorder,
  getmyOrder,
  pendingOrder,
  updateOrderStatus,
} from "../Allslices/orderSlice";
import { verifyPromoCode } from "../Allslices/PromoSlice";
import { Country, State, City } from "country-state-city";
import {
  getCountryList,
  getStatelist,
  getCitylist,
} from "../Allslices/countrycitystateSlice";

import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../appUtils/appToast";
import MetaData from "../component/layout/MetaData";
import useRazorpay from "react-razorpay";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { joinEventUser } from "../Allslices/EventConfigSlice";
import useGeolocation from "../component/shared/UserGeolocation";
import { IoCloseSharp } from "react-icons/io5";

const OrderModal = ({
  product,
  orderType,
  navigate,
  albumId,
  scheduleid,
  formDataToSend,
  isExpired,
  eventParent,
}) => {
  const [proId, setProId] = useState("");
  const [productImagePath, setProductImagePath] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const dispatch = useDispatch();
  const { orderAll } = useSelector((state) => state?.order);
  const { user } = useSelector((state) => {
    return state.auth;
  });
  const { countrylist, statelist, citylist } = useSelector(
    (state) => state?.countrystatecity
  );
  console.log("product", product);
  const navigation = useNavigate();
  const userGeolocationCode = useGeolocation();

  const isIndianUser = userGeolocationCode === "IN";

  const currencySymbol = isIndianUser ? "₹" : "$";

  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    countryCode: "",
    phone: "",
    addressLine: "",
    occupation: "",
    dob: "",
    country: "",
    state: "",
    city: "",
    pinCode: "",
  });

  console.log("The user Value is:", user);
  useEffect(() => {
    if (user) {
      setUserData({
        name: user?.name,
        email: user?.email,
        addressLine:
          [user?.addressLine1, user?.addressLine2].filter(Boolean).join(", ") ||
          null,
        occupation: user?.occupation,
        dob: user?.dob,
        country: user?.country,
        state: user?.state,
        city: user?.city,
        pinCode: user?.pincode,
      });
      setMobileNumber(user.phone);
      setCountryCode(user.countryCode);
    }
    dispatch(getCountryList());
  }, [user, dispatch]);

  console.log("The Product Value is:", product);

  const [pricess, setPricess] = useState(() => {
    if (isExpired) {
      return isIndianUser ? product.renew_price_rupee : product.renew_price_usd;
    }
    return isIndianUser
      ? product.selling_price_rupee
      : product.selling_price_usd;
  });

  useEffect(() => {
    if (
      isExpired
        ? isIndianUser
          ? product?.renew_price_rupee <= 0
          : product?.renew_price_usd <= 0
        : isIndianUser
        ? product?.selling_price_rupee <= 0
        : product?.selling_price_usd <= 0
    ) {
      setPricess("Free");
    } else {
      setPricess(
        isExpired
          ? isIndianUser
            ? product.renew_price_rupee
            : product.renew_price_usd
          : isIndianUser
          ? product.selling_price_rupee
          : product.selling_price_usd
      );
    }
  }, [product, isIndianUser, isExpired]);

  const rows = [];

  useEffect(() => {
    orderAll &&
      orderAll.forEach((item, index) => {
        var productId = item?.orderItems?.product;
        setProId(item?.orderItems?.product);
        rows.push({
          idpo: item?.orderItems?.product,
        });
      });
  });

  useEffect(() => {
    const absoluteImagePath = product?.courseImage || product?.thumbnail_image;
    const publicImagesIndex = absoluteImagePath?.indexOf("/images");

    if (publicImagesIndex !== -1) {
      const imagePath = absoluteImagePath?.slice(publicImagesIndex);
      setProductImagePath(imagePath);
    } else {
      console.error(
        "Invalid image URL. Expected URL containing '/public/images/'."
      );
    }
  }, [product]);

  console.log("the product image path value is:", productImagePath);
  const [productPrice, setProductPrice] = useState(0);
  const [discountvalue, setdiscountvalue] = useState(0);
  useEffect(() => {
    if (productPrice > 0) {
      setdiscountvalue(pricess - productPrice);
    }
  }, [productPrice]);
  const order = {
    orderItems: {
      name: product?.title || eventParent,
      monthValidity: product?.monthValidity,
      description: product?.shortDescription ?? product?.description,
      // price: isIndianUser
      //   ? product?.selling_price_rupee || product?.renew_price_rupee
      //   : product?.selling_price_usd || product?.renew_price_usd,

      price: isExpired
        ? isIndianUser
          ? product?.renew_price_rupee
          : product?.renew_price_usd
        : isIndianUser
        ? product?.selling_price_rupee
        : product?.selling_price_usd,
      quantity: 1,
      image: productImagePath,
      product: product?._id,
      slug: product?.slug || product?.scheduleslug,
      paidAmount: productPrice,
      appliedPromoCode: promoCode,
      songParentId: albumId,
      eventParent: eventParent,
    },

    userDetails: {
      name: userData?.name,
      email: userData?.email,
      phone: mobileNumber,
      countryCode: countryCode,
      addressLine: userData?.addressLine,
      occupation: userData?.occupation,
      dob: userData?.dob,
      pinCode: userData?.pinCode,
      country: userData?.country,
      state: userData?.state,
      city: userData?.city,
    },
    itemsPrice: isExpired
      ? isIndianUser
        ? product?.renew_price_rupee
        : product?.renew_price_usd
      : isIndianUser
      ? product?.selling_price_rupee
      : product?.selling_price_usd,
    taxPrice: 0,
    shippingPrice: 0,
    // totalPrice: isIndianUser
    //   ? product?.selling_price_rupee || product?.renew_price_rupee
    //   : product?.selling_price_usd || product?.renew_price_usd,
    totalPrice: isExpired
      ? isIndianUser
        ? product?.renew_price_rupee
        : product?.renew_price_usd
      : isIndianUser
      ? product?.selling_price_rupee
      : product?.selling_price_usd,
    paymentInfo: {
      id: Math.random() * 100,
      status: true,
    },
    orderType: orderType,
    currencyType: currencySymbol,
  };

  console.log("The Order value is:", order);
  useEffect(() => {
    dispatch(getmyOrder());
  }, [dispatch]);

  const [showPromocode, setShowPromocode] = useState(true);
  const handlePromoCodeApply = () => {
    if (isPromoApplied) {
      // Remove promo code
      setPromoCode("");
      setProductPrice(pricess);

      setIsPromoApplied(false);
      SHOW_SUCCESS_NOTIFICATION("Promo Code Removed Successfully.");
    } else {
      // Apply promo code
      // const amount = isIndianUser
      //   ? product?.selling_price_rupee || product?.renew_price_rupee
      //   : product?.selling_price_usd || product?.renew_price_usd;

      const amount = isExpired
        ? isIndianUser
          ? product?.renew_price_rupee
          : product?.renew_price_usd
        : isIndianUser
        ? product?.selling_price_rupee
        : product?.selling_price_usd;
      const payload = {
        amount,
        promoCode,
        orderType,
        userApplyFor: isIndianUser ? "national" : "international",
      };

      dispatch(verifyPromoCode(payload)).then((response) => {
        if (response.payload.success) {
          const newPrice = response?.payload?.newMrp;
          setDiscountedPrice(newPrice?.toFixed(2));
          setProductPrice(newPrice);
          SHOW_SUCCESS_NOTIFICATION("Promo Code Applied Successfully.");
          setIsPromoApplied(true);
        } else {
          SHOW_ERROR_NOTIFICATION(
            response.error.message || "Failed to apply promo code."
          );
        }
      });
    }
  };

  console.log(
    "The Final Product Price Value is:",
    productPrice,
    pricess,
    discountedPrice,
    discountvalue
  );
  const [Razorpay] = useRazorpay();

  useEffect(() => {
    if (discountedPrice !== null) {
      setProductPrice(discountedPrice);
    } else {
      setProductPrice(order?.orderItems?.price);
    }
  }, [discountedPrice, order?.orderItems?.price]);

  const [countries, setCountries] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const fetchCountries = () => {
      const countryList = Country.getAllCountries();
      setCountries(countryList);
    };

    fetchCountries();
  }, []);
  const handlePayment = async (params) => {
    if (
      !userData?.name ||
      !userData?.email ||
      !mobileNumber ||
      !userData?.addressLine ||
      !userData?.occupation ||
      !userData?.dob ||
      !userData?.country ||
      !userData?.pinCode
    ) {
      setIsFormValid(false);
      // alert("Details are required.");
      SHOW_INFO_NOTIFICATION("Fill the All Payment Detials as Required!!");
      return;
    }
    if (mobileNumber?.length < 10) {
      setIsFormValid(false);
      SHOW_INFO_NOTIFICATION("Mobile Number must be at least 10 digits long!!");
      return;
    }
    setIsFormValid(true);
    dispatch(pendingOrder(order));
    const createPendingOrder = async () => {
      const response = await dispatch(createorder(order));
      console.log("The Response Value is:", response);
      return response?.payload?.order._id;
    };
    const orderid = await createPendingOrder();

    // Check if orderid was successfully retrieved
    if (!orderid) {
      console.error("Order ID is not defined");
      return;
    }

    if (pricess > 0 || discountedPrice > 0) {
      const options = {
        key: "rzp_test_BwG2bmG3Gc3ep7",
        amount: productPrice * 100,
        currency: isIndianUser ? "INR" : "USD",
        name: order?.orderItems?.name,
        // description: order?.orderItems?.description,
        image: order?.orderItems?.image,
        // order_id: order?.orderItems?.product,
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
          const orderStatus = "Confirm";
          // dispatch(createorder(order));
          dispatch(updateOrderStatus({orderid, orderStatus}));
          if (navigate) {
            navigation("/ordersuccess");
          }
          SHOW_SUCCESS_NOTIFICATION("Purchase");
          formDataSubmit();
          window.location.reload();
        },
        modal: {
          ondismiss: function () {
            const orderStatus = "Pending";
            dispatch(updateOrderStatus({orderid, orderStatus}));
            window.location.reload();
          },
        },
        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
        console.log("The Payment Failed Response Value is:", response);
        const orderStatus = "Failed";
        dispatch(updateOrderStatus({orderid, orderStatus}));
      });
      rzp1.open();
    } else {
      const orderStatus = "Confirm";
      // dispatch(createorder(order));
      dispatch(updateOrderStatus({orderid, orderStatus}));
      if (navigate) {
        navigation("/ordersuccess");
      }
      SHOW_SUCCESS_NOTIFICATION("Purchase");
      formDataSubmit();
      window.location.reload();
    }
  };

  const formDataSubmit = () => {
    console.log("eventuser call ", formDataToSend, scheduleid);
    dispatch(joinEventUser({ joinUser: formDataToSend, scheduleid }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleChangePhoneNumber = (value, data) => {
    const number = value?.slice(data?.dialCode?.length);
    setMobileNumber(number);
    setCountryCode(`+${data?.dialCode}`);
  };

  const handleSelectChange = (e) => {
    const { name, value } = e;
    if (name == "country") {
      let countryId = value?.id;
      dispatch(getStatelist({ countryId }));
    }
    setUserData({
      ...userData,
      [name]: value,
    });
    if (name == "state") {
      let countryidd = value?.country_id;
      let stateidd = value?.id;
      dispatch(getCitylist({ countryidd, stateidd }));
    }
  };

  const handleStatus = (e) => {
    e.preventDefault();
    SHOW_SUCCESS_NOTIFICATION("Already Buy");
    navigation("/course");
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
      >
        <div
          className="modal-dialog modal-lg ff1 rounded-5"
          style={{ overflow: "visible", padding: "0.5rem" }}
        >
          <div className="modal-content h-75 first1">
            <div className="modal-header d-flex justify-content-center pt-5 align-items-center border-0">
              <h2
                className="text-center fw-bold text-dark"
                style={{
                  fontFamily: "myFont",
                }}
              >
                Order Summary
              </h2>
            </div>
            <button
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                background: "black",
                border: "2px solid #9767a2",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            >
              <IoCloseSharp
                cursor="pointer"
                color="white"
                className="fs-sm-2 fs-1 rounded-circle"
                style={{ border: "6px solid white" }}
              />
            </button>
            <div className="modal-body p-0">
              <div className="container">
                <div className=" ">
                  <div className="container-fluid mx-auto mt-4">
                    <div className="row mt-3">
                      {/* First Section */}
                      <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="row container mx-0 mx-sm-auto text-start">
                          {/* Product Details */}
                          <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="px-1 my-2 d-flex justify-content-center align-items-center">
                              <img
                                src={
                                  product
                                    ? product?.thumbnail_image ||
                                      product?.courseImage
                                    : img
                                }
                                alt=""
                                style={{ borderRadius: "12px" }}
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-12 col-sm-12">
                            {/* <div className=""> */}
                            <h6 className="fw-bold fs-5 my-2 lato">
                              {product?.title || eventParent}
                            </h6>
                            <p
                              className="mb-0 text-justify"
                              dangerouslySetInnerHTML={{
                                __html:
                                  product?.shortDescription?.slice(0, 100) ||
                                  product?.description ||
                                  product?.shortdescription,
                              }}
                            ></p>
                            <p className="fw-semibold lato fs-6 text-end">
                              TYPE :{" "}
                              <span className="text-danger">
                                {" "}
                                {orderType?.toUpperCase()}
                              </span>
                            </p>
                            {/* </div> */}
                          </div>
                        </div>
                        {/* Price Details */}
                        {product?.selling_price_rupee > 0 ||
                        product?.selling_price_usd > 0 ? (
                          <>
                            <div className="row mx-0 container  my-3 py-2">
                              <div className="col-12 bg-body-secondary rounded-3 py-4">
                                <div className="d-flex container justify-content-between">
                                  <p className="fs-5 lato">Sub Total:</p>
                                  <p className="fw-bold fs-5 lato">
                                    {" "}
                                    {currencySymbol}
                                    {pricess}
                                  </p>
                                </div>
                                {/* <div className="d-flex container justify-content-between">
                              <p className="fs-6 lato mb-0">Tax:</p>
                              <p className="fw-bold fs-6 lato mb-0">₹18.00</p>
                            </div> */}

                                {discountvalue > 0 ? (
                                  <div className="d-flex container justify-content-between">
                                    <p className="fs-5 lato">Discount:</p>
                                    <p className="fw-bold fs-5 lato">
                                      {currencySymbol}
                                      {`${
                                        discountvalue == 0
                                          ? discountvalue
                                          : `-${discountvalue}`
                                      }`}
                                    </p>
                                  </div>
                                ) : (
                                  <></>
                                )}
                                <div className="d-flex container justify-content-between">
                                  <p className="fs-5 lato">Grand Total:</p>
                                  <p className="fw-bold fs-5 lato">
                                    {currencySymbol}
                                    {productPrice}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row container mx-0 my-3 mx-sm-auto py-2">
                              {showPromocode && (
                                <p className="fw-bold lato fs-5 mb-1">
                                  Promo Code:
                                </p>
                              )}
                              <div className="d-flex container justify-content-between my-2">
                                {(product?.selling_price_rupee > 0 ||
                                  product?.renew_price_rupee ||
                                  product?.selling_price_usd > 0 ||
                                  product?.renew_price_usd) &&
                                  showPromocode && (
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Promo Code"
                                        value={promoCode}
                                        onChange={(e) =>
                                          setPromoCode(e.target.value)
                                        }
                                        disabled={isPromoApplied}
                                      />
                                      <span
                                        className={`btn ${
                                          isPromoApplied
                                            ? ` btn-danger`
                                            : `btn-success`
                                        } fw-semibold`}
                                        onClick={handlePromoCodeApply}
                                      >
                                        {isPromoApplied ? "Remove" : "Apply"}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-12">
                              <div className="d-flex container justify-content-end mx-auto">
                                <p className="fw-bold fs-5 lato pt-4 pe-3">
                                  FREE
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      {/* First Section End */}
                      {/* Second Section Payment Details */}
                      <div className="col-lg-5 col-md-12 col-sm-12">
                        <div className="row container mx-0 text-start">
                          <h6 className="fw-bold fs-5 lato">Payment Details</h6>

                          <div className="col-md-12 col-sm-12 col-12">
                            <label htmlFor="name" className="form-label mb-1">
                              Name :
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control form-control-lg my-1 fs-6"
                                type="text"
                                required
                                placeholder="Name"
                                name="name"
                                id="name"
                                aria-label=".form-control-lg example"
                                value={userData.name}
                                onChange={handleInputChange}
                              />
                              <span className="text-danger position-absolute required-star">
                                *
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <label htmlFor="email" className="form-label mb-1">
                              Email :
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control form-control-lg my-1 fs-6"
                                type="email"
                                required
                                name="email"
                                placeholder="Email"
                                id="email"
                                aria-label=".form-control-lg example"
                                value={userData.email}
                                onChange={handleInputChange}
                              />
                              <span className="text-danger position-absolute required-star">
                                *
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12 col-lg-12 orderphone my-1">
                            <label
                              htmlFor="phone-input"
                              className="form-label mb-1"
                            >
                              Mobile Number :
                            </label>
                            <div className="position-relative">
                              <PhoneInput
                                value={`${countryCode}+ " " +${mobileNumber}`}
                                placeholder="Enter Mobile Number"
                                enableSearch
                                id="phone-input"
                                onChange={handleChangePhoneNumber}
                              />
                              <span className="text-danger position-absolute required-star">
                                *
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <label
                              htmlFor="occupation"
                              className="form-label mb-1"
                            >
                              Occupation :
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control form-control-lg my-1 fs-6"
                                type="text"
                                required
                                name="occupation"
                                placeholder="Occupation"
                                id="occupation"
                                aria-label=".form-control-lg example"
                                value={userData.occupation}
                                onChange={handleInputChange}
                              />
                              <span className="text-danger position-absolute required-star">
                                *
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <label htmlFor="dob" className="form-label mb-1">
                              Date of Birth :
                            </label>
                            <div className="position-relative">
                              <input
                                className="form-control form-control-lg my-1 fs-6"
                                type="date"
                                required
                                name="dob"
                                placeholder="Date of Birth"
                                id="dob"
                                aria-label=".form-control-lg example"
                                value={userData.dob}
                                onChange={handleInputChange}
                              />
                              <span className="text-danger position-absolute required-star">
                                *
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 col-sm-12 col-lg-12">
                            <div className="d-flex flex-column gap-2">
                              <label
                                htmlFor="addressLine"
                                className="form-label mb-0"
                              >
                                Address :
                              </label>
                              <div className="position-relative">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="addressLine"
                                  name="addressLine"
                                  value={userData.addressLine}
                                  placeholder="Address"
                                  onChange={handleInputChange}
                                  required
                                  rows={2}
                                />
                                <span className="text-danger position-absolute required-star">
                                  *
                                </span>
                              </div>
                            </div>

                            <div className="my-1">
                              <label
                                htmlFor="country"
                                className="form-label mb-1"
                              >
                                Country :
                              </label>
                              <div className="position-relative">
                                <Select
                                  options={countrylist}
                                  getOptionLabel={(options) => options["name"]}
                                  getOptionValue={(options) => options["name"]}
                                  value={userData.country}
                                  name="country"
                                  placeholder="Country"
                                  onChange={(e) =>
                                    handleSelectChange({
                                      value: e,
                                      name: "country",
                                    })
                                  }
                                />
                                <span className="text-danger position-absolute required-star">
                                  *
                                </span>
                              </div>
                            </div>

                            <div className="my-1">
                              <label
                                htmlFor="state"
                                className="form-label mb-1"
                              >
                                State :
                              </label>
                              <div className="position-relative">
                                <Select
                                  options={statelist}
                                  getOptionLabel={(options) => options["name"]}
                                  getOptionValue={(options) => options["name"]}
                                  value={userData.state}
                                  name="state"
                                  placeholder="State"
                                  onChange={(e) =>
                                    handleSelectChange({
                                      value: e,
                                      name: "state",
                                    })
                                  }
                                />
                                <span className="text-danger position-absolute required-star">
                                  *
                                </span>
                              </div>
                            </div>

                            <div className="my-1 ">
                              <label htmlFor="city" className="form-label mb-1">
                                City :
                              </label>
                              <div className="position-relative">
                                <Select
                                  options={citylist}
                                  getOptionLabel={(options) => options["name"]}
                                  getOptionValue={(options) => options["name"]}
                                  value={userData.city}
                                  name="city"
                                  placeholder="City"
                                  onChange={(e) =>
                                    handleSelectChange({
                                      value: e,
                                      name: "city",
                                    })
                                  }
                                />
                                <span className="text-danger position-absolute required-star">
                                  *
                                </span>
                              </div>
                            </div>

                            <div className="my-1">
                              <label
                                htmlFor="pinCode"
                                className="form-label mb-1"
                              >
                                Pin Code
                              </label>
                              <div className="position-relative">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="pinCode"
                                  name="pinCode"
                                  placeholder="Pin Code"
                                  value={userData.pinCode}
                                  onChange={handleInputChange}
                                  required
                                />
                                <span className="text-danger position-absolute required-star">
                                  *
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Second Section Payment Detials Ended Here */}
                    </div>
                  </div>
                </div>
              </div>
              {/* paymentmodal */}
            </div>
            <div className="modal-footer border-0 my-2 mx-2">
              <button
                className="paybtn text-white fs-5"
                data-bs-dismiss={isFormValid ? "modal" : ""}
                aria-label="Close"
                onClick={handlePayment}
              >
                {product?.price > 0 || product?.selling_price_rupee > 0
                  ? "Pay Now"
                  : "Get Now"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderModal;
