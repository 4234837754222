import { useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const usePreserveScroll = () => {

  const location = useLocation();

  useLayoutEffect(() => {
    if (location.state && location.state.scrollY !== undefined) {
      window.scrollTo(0, location.state.scrollY);
    }
  }, [location.key]);

  useEffect(() => {
    return () => {
      if (location.state) {
        location.state.scrollY = window.scrollY;
      }
    };
  }, [location]);


};

export default usePreserveScroll;
