import { React, useEffect, useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import WisdomAudioPlayer from "./WisdomAudioPlayer";
import Moment from "react-moment";

import {
  FacebookIcon, FacebookShareButton, TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  XIcon,
  TelegramIcon
} from "react-share";
import { useDispatch, useSelector } from "react-redux";
import { getsinglewisdomlist, getwisdomConfig } from "../Allslices/wisdomSlice";

import MetaData from "../component/layout/MetaData";
import { setTrackList, setCurrentTrack } from "../Allslices/playerSlice";

export default function Wisdom_Article({
  audioRef,
  songDetails,
  setSongDetails,
}) {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const dispatch = useDispatch();

  const { currentTrack, isPlaying, trackList, currentIndex } = useSelector(
    (state) => state.player
  );


  const { wisdomSlug } = useParams();
  const { wisdombanner } = useSelector((state) => state.wisdom);
  console.log("wisdombanner", wisdombanner?.default_image)
  useEffect(() => {
    if (!isPlaying) {
      if (wisdomSlug) {
        dispatch(getsinglewisdomlist(wisdomSlug)).then((res) => {
          const songList = [res.payload?.wisdom]; // Wrap in an array
          if (songList && songList.length > 0) {
            dispatch(setTrackList({ list: songList }));
            dispatch(setCurrentTrack(songList[0])); // Set first item as current track
          }
        });
      }
    }
  }, [dispatch, , isPlaying, wisdomSlug]);

  console.log("newtrackLIst_wisdom: ", trackList);

  useEffect(() => {
    dispatch(getwisdomConfig());

  }, []);

  // useEffect(() => {
  //   if (audioRef.current && currentTrack) {
  //     audioRef.current.src = currentTrack.audioFileUrl; // Ensure audioFileUrl is correct
  //     if (isPlaying) {
  //       audioRef.current.play();
  //     } else {
  //       audioRef.current.pause();
  //     }
  //   }
  // }, [currentTrack, isPlaying]);

  // useEffect(() => {
  //   if (audioRef.current) {
  //     const handleEnded = () => {
  //       dispatch(nextTrack());
  //     };
  //     audioRef.current.addEventListener("ended", handleEnded);
  //     return () => {
  //       audioRef.current.removeEventListener("ended", handleEnded);
  //     };
  //   }
  // }, [audioRef, dispatch]);

  console.log("The CurrentTrack value is:", currentTrack);

  const handleNavigateToWisdomSubpage = (slug) => {
    dispatch(getsinglewisdomlist(slug));
    navigate(`/wisdom/${slug}`);
  };
  // const handleNavigateToWisdomSubpage = (slug) => {
  //   dispatch(getsinglewisdomlist(slug));
  //   navigate(`/wisdom/${slug}`);
  // };


  // const [isPlaying, setPlaying] = useState(false);

  const { singlewisdomlist } = useSelector((state) => state.wisdom);

  const [fetchedWisdom, setFetchedWisdom] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);


useEffect(() => {
  if (!singlewisdomlist?.wisdom?.contentType || !Array.isArray(fetchedWisdom)) return;

  const filteredWisdom = fetchedWisdom.filter((item) => item.contentType === singlewisdomlist.wisdom.contentType);
  setFetchedWisdom(filteredWisdom);
}, [singlewisdomlist]);
console.log("fetchedWisdom",fetchedWisdom)


  // const relatedList=singlewisdomlist?.relatedlist

  // useEffect(() => {
  //   if (singlewisdomlist && audioRef.current) {
  //     audioRef.current.src = singlewisdomlist?.wisdom?.audiofile;
  //   }
  // }, [singlewisdomlist]);

  useEffect(() => {
    setFetchedWisdom(singlewisdomlist?.relatedlist);
  }, [singlewisdomlist?.relatedlist]);

  // const handlePlay = () => {
  //   audioRef.current.play();
  //   setPlaying(true);
  // };

  // const handlePause = () => {
  //   audioRef.current.pause();
  //   setPlaying(false);
  // };

  // const handleTogglePlayPause = () => {
  //   if (isPlaying) {
  //     handlePause();
  //   } else {
  //     handlePlay();
  //   }
  // };

  // const handlePlay = () => {
  //   audioRef.current.play();
  //   setPlaying(true);
  // };

  // const handlePause = () => {
  //   audioRef.current.pause();
  //   setPlaying(false);
  // };

  // const handleTogglePlayPause = () => {
  //   if (isPlaying) {
  //     handlePause();
  //   } else {
  //     handlePlay();
  //   }
  // };

  // const handleTimeUpdate = () => {
  //   setCurrentTime(audioRef.current.currentTime);
  // };

  // const handleSeek = (time) => {
  //   audioRef.current.currentTime = time;
  //   setCurrentTime(time);
  // };

  // const handleLoadedMetadata = () => {
  //   setDuration(audioRef.current.duration);
  // };

  // const handleBackward = () => {
  //   handleSeek(currentTime - 10); // Adjust backward duration as needed
  // };

  // const handleForward = () => {
  //   handleSeek(currentTime + 10); // Adjust forward duration as needed
  // };
  // const handleProgressClick = (e) => {
  //   const rect = e.target.getBoundingClientRect();
  //   const offsetX = e.clientX - rect.left;
  //   const percentage = offsetX / rect.width;
  //   const newTime = percentage * duration;
  //   handleSeek(newTime);
  // };

  const url = window.location.href;

  const maxLength = 100;
  const maxLength2 = 100;

  console.log(
    "The Song Details Value is in the Wisdom Article is:",
    songDetails,
    setSongDetails
  );
  let sliderRef1 = useRef();

  let sliderRef2 = useRef();
  const numCards = fetchedWisdom?.length || 0
  const minSlidesToShow = Math.min(4, numCards);


  const settings1 = {
    // dots: true,
    infinite: true,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 800,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  settings1.slidesToShow = minSlidesToShow;
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  if (minSlidesToShow === 4) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  }
  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  }
  else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  }
  const seekPoint = (e) => {
    audioRef.current.currentTime = (e / 100) * audioRef.current.duration;

    setSongDetails((prev) => ({
      ...prev,
      time: Math.round(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      ),
    }));
  };
  let splitUrl = singlewisdomlist?.wisdom?.page_banner_image.split('http://api.drarchikadidi.com/');
  console.log(splitUrl, 'splitUrl')
  let bannerImage;

  if (splitUrl) {
    bannerImage = splitUrl[1] == "publicundefined" ? wisdombanner?.default_image : singlewisdomlist?.wisdom?.page_banner_image;
  }
  const shareMessage = `${singlewisdomlist?.wisdom?.meta_title}\n\n${singlewisdomlist?.wisdom?.meta_description}\n\n#${singlewisdomlist?.wisdom?.meta_tag}\n\n`;
  const shareHashtag = `#${singlewisdomlist?.wisdom?.meta_title}\n#${singlewisdomlist?.wisdom?.meta_tag}#${singlewisdomlist?.wisdom?.title}\n#${singlewisdomlist?.wisdom?.title}\n${singlewisdomlist?.wisdom?.tags?.map((item) => `#${item?.name}`)}#${singlewisdomlist?.wisdom.slug}\n#DrArchikaDidi`;
  return (
    <div>
      <MetaData title={singlewisdomlist?.wisdom?.meta_title} description={singlewisdomlist?.wisdom?.meta_description} tags={singlewisdomlist?.wisdom?.meta_tag} />
      <>
        {/* banner section */}
        <div
          className=" blogimg mb-2  "
          style={{
            backgroundImage: `url(${bannerImage})`,
            // backgroundImage: `radial-gradient(45deg,rgba(0,0,0,0.42)),url(${singlewisdomlist?.wisdom?.thumbnail_image})`,
            //             backgroundImage: `linear-gradient(45deg, rgba(249, 200, 124, 0.3), rgba(249, 200, 123, 21)
            // ), url(${singlewisdomlist?.wisdom?.thumbnail_image})`,
            backgroundSize: "cover",
            // filter: "blur(2px)"
          }}
        >
          <div className="container blogsub " style={{ overflow: "hidden" }}>
            <div className="row text-center p-5">
              <div className="col-lg-12 col-sm-12">
                <h2 className=" text-dark sbpagetitle " style={{ fontFamily: "myFont" }}>
                  {singlewisdomlist?.wisdom?.title}
                </h2>

                {/* <div class="imagesee">
     <img src="asset/img/blog/Rectangle 26.png"alt="img"class=""   alt="">
</div> */}
              </div>
            </div>
          </div>

          <div className="imagesee pt-lg-5 pt-md-5 pt-sm-1" style={{ overflow: "hidden" }}>
            {singlewisdomlist?.wisdom?.contentType === "article" ? (
              <img
                src={singlewisdomlist?.wisdom?.thumbnail_image}
                alt="img"
                className=""
                style={{
                  borderRadius: "10px",
                  boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)",
                }}
              />
            ) : singlewisdomlist?.wisdom?.contentType === "audio" ? (
              <div className="imagesee pt-5 " style={{ overflow: "hidden" }}>
                <img
                  src={singlewisdomlist?.wisdom?.thumbnail_image}
                  alt="img"
                  className=""
                  style={{
                    borderRadius: "20px",
                    boxShadow: "1px 1px 17px 2px rgba(0,0,0,1)",
                  }}
                />
                {/* <div className="position-absolute top-100 w-50"> */}
                {/* <div className=" w-50 " style={{
                  position: "absolute", top: "99%"
                }}>
                  <>
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        width: "78%",
                        backgroundColor: "#D9D9D9E5",
                        padding: "2rem",
                        borderRadius: "8px",
                        margin: "auto",


                      }}
                    >
                      <div
                        className="d-flex justify-content-between"
                        style={{ width: "8rem" }}
                      >
                        <span
                        // onClick={
                        //   !currentTrack?.isPaid && currentTrack
                        //     ? handlePlayPause
                        //     : null
                        // }
                        >
                          {isPlaying ? (
                            <i
                              className={`fas fa-pause fs-4 `}
                              onClick={() => {
                                handlePause();
                              }}
                            ></i>
                          ) : (
                            <i
                              className={`fas fa-play fs-4 `}
                              onClick={() => {
                                handlePlay();
                              }}
                            ></i>
                          )}
                        </span>
                      </div>
                      <audio
                        src={singlewisdomlist?.wisdom?.audiofile}
                        ref={audioRef}
                        onClick={() => {
                          if (isPlaying) {
                            handlePause();
                          } else {
                            handlePlay();
                          }
                        }}
                      ></audio>

                      <div className="progress-bar-container mt-3">
                        <div className="progress-bar" id="progress-bar"></div>
                      </div>

                      <div
                        className="d-flex justify-content-between"
                        style={{ width: "171px", backgroundColor: "#D9D9D9E5" }}
                      >
                        <div className="d-flex gap-1 text-secondary">
                          <span>
                            {audioRef.current
                              ? convertToMins(audioRef.current.currentTime)
                              : "0:00"}
                          </span>
                          /
                          <span>
                            {audioRef.current?.duration
                              ? convertToMins(audioRef.current.duration)
                              : "0:00"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                </div> */}
                {/* <div className="d-flex justify-content-center position-absolute top-100 w-100">
                <div className="musicplayerpodcast bg-light p-4">
                    <div
                      className="d-flex justify-content-between align-items-center gap-3"
                      style={{
                        backgroundColor: "#D9D9D9E5",
                        padding: "2rem",
                        borderRadius: "9px",
                      }}
                    > */}
                <div className="wisdomSongPlayer bg-light py-1 py-sm-2 py-md-2 py-lg-3 px-2">
                  <WisdomAudioPlayer
                    onSeek={seekPoint}
                    albumId={wisdomSlug}
                    audioRef={audioRef}
                    songDetails={songDetails}
                    time={songDetails?.time}
                    setSongDetails={setSongDetails}
                  />
                </div>
              </div>
            ) : (
              <iframe
                // width="650"
                // height="330"
                src={singlewisdomlist?.wisdom?.videourl}
                title={singlewisdomlist?.wisdom?.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className="singlevideoiframe"
                allowFullScreen
                style={{
                  borderRadius: "20px",
                  boxShadow: " -2px 1px 26px -1px rgba(0,0,0,1)",
                }}
              ></iframe>
            )}

            {/* content type article */}
            {/* <img src={singlewisdomlist?.thumbnail_image} alt="img" className="" /> */}
            {/* //// */}
            {/* content type audio */}
            {/* <img src={singlewisdomlist?.thumbnail_image} alt="img" className="" />
            <div className="position-absolute top-100 w-50">
                <WisdomAudioPlayer audioRef={audioRef} />
            </div> */}
            {/*  */}
            {/* content type video */}
            {/* <iframe
              width="560"
              height="315"
              src={singlewisdomlist?.videourl}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> */}
            {/* //////// */}
            {/* content type video */}
            {/* <img src={singlewisdomlist?.thumbnail_image} alt="img" className="" /> */}
            {/* //// */}
          </div>
        </div>
        {/* banner section end */}
        {/* text section all */}
        <section style={{ overflow: "hidden" }}>
          <div className="container" >
            <div className="textrt  mt-5 wisdomhshareione2 ">
              <div className="row wisdomicotext icotext text-center wisdomhshareione">
                <div className="col-lg-6 col-5 ">
                  <p
                    style={{ textTransform: "capitalize", paddingTop: "10px" }}
                  >
                    {singlewisdomlist?.wisdom?.contentType === 'article' ? (
                      <>
                        Published :
                        <Moment format="D MMM YYYY" withTitle style={{ margin: "0px 5px" }}>
                          {singlewisdomlist?.wisdom?.createdAt}
                        </Moment>
                      </>
                    ) : ('')}
                  </p>
                </div>
                <div className="col-lg-6 col-7">
                  <a href="#" className="p-1">
                    <FacebookShareButton
                      url={url}
                      hashtag={shareHashtag}
                      quote={shareMessage}
                    >
                      <FacebookIcon size={30} round={true} />
                    </FacebookShareButton>
                  </a>
                  {/* <a href="#" className="p-1">
                    <FacebookMessengerShareButton
                      appId=""
                      title="Share on Messenger"
                      url={url}
                      hashtag="#ArchikaDidiBlog"
                    >
                      <FacebookMessengerIcon size={45} round={true} />
                    </FacebookMessengerShareButton>
                  </a> */}
                  {/* <a href="#" className="p-1">instagram</a> */}
                  <a href="#" className="p-1">
                    <WhatsappShareButton
                      appId=""
                      title={shareMessage}
                      url={url}
                      hashtag={shareHashtag}
                    >
                      <WhatsappIcon size={30} round={true} />
                    </WhatsappShareButton>
                  </a>
                  <a href="#" className="p-1">
                    <TwitterShareButton
                      appId=""
                      title={shareMessage}
                      hashtag={shareHashtag}
                      url={url}
                    >
                      <XIcon size={30} round={true} />
                    </TwitterShareButton>
                  </a>
                  <a href="#" className="p-1">
                    <TelegramShareButton
                      title={shareMessage}
                      url={url}
                    >
                      <TelegramIcon size={30} round={true} />
                    </TelegramShareButton>
                  </a>
                </div>
              </div>
              {/* <div
                className="contyu px-5 pt-4 lato"
                dangerouslySetInnerHTML={{ __html: singlewisdomlist?.wisdom?.description }}
              ></div> */}
            </div>
          </div>
        </section>
        {/* content paragraph  */}
        <section style={{ overflow: "hidden" }} className="pb-3">
          <div className="container wisdomcont" style={{ marginTop: "1rem" }}>
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12 col-sm-12  singlepagefont">
                <p
                  className="singlepagefont"
                  style={{ color: "#807F7F" }}
                  dangerouslySetInnerHTML={{
                    __html: singlewisdomlist?.wisdom?.fulldescription,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </section>

        {/* text section all end */}
        {Array.isArray(fetchedWisdom) && fetchedWisdom.length === 0 ? (
    ""
  ) : (<>
        <div
          className="mahab-mixer d-none d-lg-block"
          style={{ overflow: "hidden" }}
        >
          <div className="text-center pt-2 mt-5">
  
    
      {singlewisdomlist?.wisdom?.contentType === "audio" && (
        <h1 className="text-center pt-2 mt-5">
          <span className="haedingfont"> Related Audio </span>
        </h1>
      )}
      {singlewisdomlist?.wisdom?.contentType === "video" && (
        <h1 className="text-center pt-2 mt-5">
          <span className="haedingfont"> Related Video </span>
        </h1>
      )}
      {singlewisdomlist?.wisdom?.contentType === "article" && (
        <h1 className="text-center pt-2 mt-5">
          <span className="haedingfont"> Related Article </span>
        </h1>
      )}
    
</div>


          <div
            className="container artvid  singlewisdomart pb-4 mb-5 "
            style={{ overflow: "hidden" }}
          >
            <div className={`row g-3 py-2 artvid33 ${fetchedWisdom?.length <= 2 ? 'sliderscontainer' : ''}`}>
              <Slider
                ref={(slider) => {
                  sliderRef1 = slider;
                }}
                {...settings1}
              >
                {Array.isArray(fetchedWisdom) && fetchedWisdom.filter((item)=>item?.contentType=== singlewisdomlist?.wisdom?.contentType).map((item, index) => (
                    <div
                      className="col-md-6 col-sm-12 col-lg-3"
                      onClick={() => handleNavigateToWisdomSubpage(item?.slug)}
                      style={{ borderRadius: "22px" }}
                    >
                      <div
                        className="card item-card card-block mx-2 my-3 overflow-hidden"
                        style={{
                          borderRadius: "10px",
                          height: "450px",
                        }}
                      >
                        <img
                          src={item?.thumbnail_image}
                          alt="Photo of sunset"
                          style={{ aspectRatio: "3/2" }}
                        />

                        <div
                          className="d-flex justify-content-end"
                          style={{ position: "relative", right: "26px" }}
                        >
                          <h6 className="pt-0">
                            <h6 className="pt-3">
                              {`${item.contentType === "video"
                                ? `Video`
                                : item.contentType === "audio"
                                  ? `Audio`
                                  : `Article`
                                }`}
                            </h6>
                          </h6>
                          <i
                            className={`fa-solid ${item.contentType === "video"
                              ? `fa-circle-play`
                              : item.contentType === "audio"
                                ? `fa-headphones`
                                : `fa-book-open`
                              }`}
                            style={{
                              position: "relative",
                              top: "18px",
                              left: "9px",
                            }}
                          ></i>
                        </div>
                        <div className="container">
                          <h4
                            className="mt-3"
                            style={{
                              fontFamily: "Playfair Display SC",
                              color: "#000000",
                            }}
                          >
                            {item.title.length > maxLength2
                              ? `${item.title.substring(0, maxLength2)}...`
                              : item.title}
                          </h4>
                          <p
                            className="card-text lato my-2"
                            dangerouslySetInnerHTML={{
                              __html:
                                item.description.length > maxLength
                                  ? `${item.description.substring(
                                    0,
                                    maxLength
                                  )}...`
                                  : item.description,
                            }}
                            style={{
                              marginLeft: "0.0rem",
                              fontFamily: "Lato",
                              color: "#5B5B5B",
                            }}
                          ></p>

                          {/* <div className="pb-1 d-flex pt-1">
                          <h4 className="lato" style={{ fontSize: "16px" }}>
                            <Moment
                              className="fw-semibold"
                              format="D MMM YYYY"
                              withTitle
                            >
                              {item?.createdAt}
                            </Moment>
                          </h4>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
        </div>
        </>
  )}
      </>
    </div>
  );
}
