import React, { useEffect, useState, useCallback } from "react";
import Rectangle74 from "../../../src/images/Rectangle 74.png";
import { useLocation, useNavigate } from "react-router-dom";
import "./auth.css";
import MetaData from "../../component/layout/MetaData";
import { verifyOTP } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../appUtils/appToast";

function OTPVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const mobile = location.state?.mobile;
  const email = location.state?.email;
  const countryCode = location.state?.countryCode;
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.auth);

  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((event, index) => {
    const value = event.target.value;
    if (/^[0-9A-Z]$/.test(value)) {
      const newOTP = [...otp];
      newOTP[index] = value.toUpperCase();
      setOTP(newOTP);
      if (index !== otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  }, [otp]);

  const handleKeyDown = useCallback((event, index) => {
    if (event.key === "Backspace") {
      const newOTP = [...otp];
      newOTP[index] = "";
      setOTP(newOTP);
      if (index !== 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  }, [otp]);

  useEffect(() => {
    const inputs = document.querySelectorAll("#otp > *[id]");
    inputs.forEach((input, index) => {
      input.addEventListener("keydown", (event) => handleKeyDown(event, index));
      return () => {
        input.removeEventListener("keydown", (event) => handleKeyDown(event, index));
      };
    });
  }, [handleKeyDown]);

  const handleSubmitOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const code = otp.join("");
      const payload = { email, mobile, countryCode, otp: code };

      dispatch(verifyOTP(payload)).then((result) => {
        if (result?.payload?.success) {
          const resetToken = result?.payload?.resetToken;
          SHOW_SUCCESS_NOTIFICATION(result?.payload?.message);
          setLoading(false);
          navigate(`/password/reset/${resetToken}`);
        } else {
          SHOW_ERROR_NOTIFICATION(
            result?.payload?.message || "Failed to verify OTP"
          );
          setLoading(false);
        }
      });
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setLoading(false);
    }
  };

  return (
    <>
      <MetaData title="OTP Verification" />
      <div
        className="hero112 hero"
        style={{
          backgroundImage: `url(${Rectangle74})`,
          backgroundSize: "cover",
          height: "650px",
        }}
      >
        <div className="login12">
          <div className="text-center p-3">
            <h1>
              <span style={{ fontSize: "60px" }} className="loginfont">Verify Now!</span>
            </h1>
          </div>

          <div className="main_div">
            <div className="clmix">
              <div className="title">
                <h3 className="adden" style={{ fontFamily: "lato" }}>
                  Enter your OTP
                </h3>
                <p className="pt-4">
                  Verification code has been Sent to Mobile No or Email
                </p>
              </div>
            </div>
            <form action="#" className="pt-5">
              <div
                id="otp"
                className="inputs d-flex flex-row justify-content-center mt-2"
              >
                {otp.map((value, index) => (
                  <input
                    key={index}
                    style={{ color: "#9C90D8", border: "2px solid #9C90D8" }}
                    className="m-2 text-center form-control rounded-0 stra custom-placeholder"
                    placeholder="*"
                    type="text"
                    id={`otp-${index}`}
                    maxLength="1"
                    value={value}
                    onChange={(event) => handleChange(event, index)}
                  />
                ))}
              </div>
              <div className="input_box button">
                {loading ? (
                  <button
                    className="btn bgPcolor rounded-3 w-100 h-100"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                ) : (
                  <input
                    type="submit"
                    onClick={handleSubmitOTP}
                    className="bgPcolor rounded-3"
                    value="Verify OTP"
                  />
                )}
              </div>
              <div className="links text-center pt-2">
                I did not receive code.{" "}
                <a
                  href="login.php"
                  style={{ color: "#9C90D8", fontWeight: "bold" }}
                >
                  Resend Code
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default OTPVerification;