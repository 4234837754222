import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";


const initialState = {

    isLoading: false,
    error: null,
    video: null,
    EventConfig:null,
    EventAnnualConfig:null,
    EventMonthConfig:null,
    EventSpecialConfig:null,
    EventOtherConfig:null,
    EventAllList:null,
    EventShivConfig:null,
    EventAllCategory:null,
    EventSingle_Category:null,
    EventSchedule:null,
    EventSingle_final:null,
    eventUserData:null,
}

function saveJoinUserData(responseData){
    localStorage.setItem("name", responseData?.eventUserData?.name);
    localStorage.setItem("email", responseData?.eventUserData?.email);
    localStorage.setItem("joinId", responseData?.eventUserData?._id);
    localStorage.setItem("phoneNo", responseData?.eventUserData?.phoneNo);
}

export const getEventConfig = createAsyncThunk(
    'events/geteventsbanner',
    async (_, { dispatch, rejectWithValue }) => {
      
        try {
            dispatch(EventConfigstart());
            const response = await axios.get(`events/geteventsbanner`)
         
            dispatch(EventConfigSuccess(response?.data))
            

        } catch (error) {
            dispatch(EventConfigFailure(error.response.data.message))
        }
    }
)

export const getEventAnnualConfig = createAsyncThunk(
    'events/geteventsannual',
    async (_, { dispatch, rejectWithValue }) => {
      
        try {
            dispatch(EventAnnualConfigstart());
            const response = await axios.get(`events/geteventsannual`)
         
            dispatch(EventAnnualConfigSuccess(response?.data))
            

        } catch (error) {
            dispatch(EventAnnualConfigFailure(error.response.data.message))
        }
    }
)

export const getEventMonthConfig = createAsyncThunk(
    'events/geteventsmonth',
    async (_, { dispatch, rejectWithValue }) => {
      
        try {
            dispatch(EventMonthConfigstart());
            const response = await axios.get(`events/geteventsmonth`)
         
            dispatch(EventMonthConfigSuccess(response?.data))
            

        } catch (error) {
            dispatch(EventMonthConfigFailure(error.response.data.message))
        }
    }
)
export const getEventSpecialConfig = createAsyncThunk(
    'events/geteventsSpecial',
    async (_, { dispatch, rejectWithValue }) => {
      
        try {
            dispatch(EventSpecialConfigstart());
            const response = await axios.get(`events/geteventsSpecial`)
         
            dispatch(EventSpecialConfigSuccess(response?.data))
            

        } catch (error) {
            dispatch(EventSpecialConfigFailure(error.response.data.message))
        }
    }
)
export const getEventOtherConfig = createAsyncThunk(
    'events/geteventsOther',
    async (_, { dispatch, rejectWithValue }) => {
      
        try {
            dispatch(EventOtherConfigstart());
            const response = await axios.get(`events/geteventsOther`)
         
            dispatch(EventOtherConfigSuccess(response?.data))
            

        } catch (error) {
            dispatch(EventOtherConfigFailure(error.response.data.message))
        }
    }
)
export const getShivratriConfig = createAsyncThunk(
    'events/getMahaShivratri',
    async (_, { dispatch, rejectWithValue }) => {
      
        try {
            dispatch(EventShivratriConfigstart());
            const response = await axios.get(`events/getMahaShivratri`)
         
            dispatch(EventShivratriConfigSuccess(response?.data))
            

        } catch (error) {
            dispatch(EventShivratriConfigFailure(error.response.data.message))
        }
    }
)


export const getEventList = createAsyncThunk(
    'events/getevents_data',
    async (eventType, { dispatch, rejectWithValue }) => {
      
        try {
            let ext = ''; 
        
            if (eventType !== undefined) {
                if(eventType=='anual')
                {
                    ext = '?type=Anual'; 
                }
                else if(eventType=='monthly')
                {
                    ext = '?type=Monthly'; 
                }
                else if(eventType=='special')
                {
                    ext = '?type=Special'; 
                }
                else if(eventType=='others')
                {
                    ext = '?type=Others'; 
                }
                else if(eventType=='current')
                {
                    ext = '?type=current'; 
                }
               
                
                
            } 
            dispatch(EventListstart());
            
            const response = await axios.get(`events/getevents_data${ext}`); 
         
            dispatch(EventListSuccess(response?.data));
        } catch (error) {
            dispatch(EventListFailure(error.response.data.message));
        }
    }                                                   
);


export const getEventSchedule = createAsyncThunk(
    'events/alleventSchedule',
    async (id, { dispatch, rejectWithValue }) => {
        try {
            dispatch(EventSchedulestart());

            const response = await axios.get(`events/alleventSchedule/65efd98ba1823858079ce048`)
            dispatch(EventScheduleSuccess(response?.data))


        } catch (error) {
            dispatch(EventScheduleFailure(error.response.data.message))
        }
    }
)


export const getSingleEventList = createAsyncThunk(
    'events/getsingle_event_blog',
    async (slug, { dispatch, rejectWithValue }) => {
      
        try {
            dispatch(EventSingleListstart());
            const response = await axios.get(`events/getsingle_event_blog/${slug}`);
            dispatch(EventSingleListSuccess(response?.data));
        } catch (error) {
            
            dispatch(EventSingleListFailure(error.response.data.message));
        }
    }
);
export const getSingleEvent = createAsyncThunk(
    'events/deatils',
    async ({slug,scheduleslug}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(EventSinglestart());
            const response = await axios.get(`events/deatils/${slug}/${scheduleslug}`);
            console.log("hellllooorespons",response)
            dispatch(EventSingleSuccess(response?.data));
        } catch (error) {
            dispatch(EventSingleFailure(error.response.data.message));
        }
    }
);

export const joinEventUser = createAsyncThunk(
    'events/update_event_Schedule',
    async({ joinUser, scheduleid },{dispatch, rejectWithValue})=>{
        console.log("joinUser",joinUser)
        try{
            dispatch(joinEventStart());
            
            const response= await axios.put(`events/update_event_Schedule/${scheduleid}`,joinUser);  
            dispatch(joinEventSuccess(response.data))
            return Promise.resolve(response.data);
        }
        catch(error){
            dispatch(joinEventFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
const EventConfigSlice = createSlice({
    name: 'Event',
    initialState,
    reducers: {
        EventConfigstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventConfigSuccess(state, action) {

         state.EventConfig = action.payload?.Event;
         state.isLoading = false;
        },
        EventConfigFailure(state, action) {
            state.EventConfig = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        EventAnnualConfigstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventAnnualConfigSuccess(state, action) {

         state.EventAnnualConfig = action.payload?.AnnualEvent
         ;
         state.isLoading = false;
        },
        EventAnnualConfigFailure(state, action) {
            state.EventConfig = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        EventMonthConfigstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventMonthConfigSuccess(state, action) {

         state.EventMonthConfig = action.payload?.MonthEvent
         ;
         state.isLoading = false;
        },
        EventMonthConfigFailure(state, action) {
            state.EventConfig = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        EventSpecialConfigstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventSpecialConfigSuccess(state, action) {

         state.EventSpecialConfig = action.payload?.SpecialEvent
         ;
         state.isLoading = false;
        },
        EventSpecialConfigFailure(state, action) {
            state.EventConfig = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        EventOtherConfigstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventOtherConfigSuccess(state, action) {

         state.EventOtherConfig = action.payload?.OtherEvent
         ;
         state.isLoading = false;
        },
        EventOtherConfigFailure(state, action) {
            state.EventConfig = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        EventShivratriConfigstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventShivratriConfigSuccess(state, action) {

         state.EventShivConfig = action.payload?.Shivratri
         ;
         state.isLoading = false;
        },
        EventShivratriConfigFailure(state, action) {
            state.EventShivConfig = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        EventListstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventListSuccess(state, action) {
         state.EventAllList = action.payload
         ;
         state.isLoading = false;
        },
        EventListFailure(state, action) {
            state.EventAllList = null;
            state.isLoading = false;
            state.error = action.payload;

        },




        EventSingleListstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventSingleListSuccess(state, action) {
         state.EventSingle_List = action?.payload?.singleEventBlog

;
         state.isLoading = false;
        },
        EventSingleListFailure(state, action) {
            state.EventSingle_List = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        EventSchedulestart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventScheduleSuccess(state, action) {

         state.EventSchedule = action?.payload?.EventSchedule_data

         ;
         state.isLoading = false;
        },
        EventScheduleFailure(state, action) {
            state.EventSchedule = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        EventSinglestart(state) {
            state.isLoading = true;
            state.error = null;
        },
        EventSingleSuccess(state, action) {
         state.EventSingle_final = action?.payload?.event;
         state.isLoading = false;
        },
        EventSingleFailure(state, action) {
            state.EventSingle_final = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        joinEventStart(state){
            state.isLoading= true;
            state.error=null;
            
        },
        joinEventSuccess(state,action){
            state.eventUserData=action.payload;
            state.isLoading = false;

        },
        joinEventFailure(state,action){
            state.error = action.payload;
            state.isLoading = false;


        }
        

    }
})


export const { EventConfigstart,joinEventStart, joinEventSuccess,joinEventFailure,EventConfigSuccess, EventConfigFailure,EventAnnualConfigstart, EventAnnualConfigSuccess, EventAnnualConfigFailure,
    EventShivratriConfigstart, EventShivratriConfigSuccess,EventShivratriConfigFailure,EventListstart,EventListSuccess,EventListFailure,EventSingleListstart,EventSingleListSuccess,EventSingleListFailure,EventMonthConfigstart,EventMonthConfigSuccess,EventMonthConfigFailure,EventSpecialConfigstart,EventSpecialConfigSuccess,EventSpecialConfigFailure,EventOtherConfigstart,EventOtherConfigSuccess,EventOtherConfigFailure,EventSchedulestart,EventScheduleSuccess,EventScheduleFailure,EventSinglestart ,EventSingleSuccess,EventSingleFailure} = EventConfigSlice.actions;

export default EventConfigSlice.reducer;
