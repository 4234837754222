import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";

const initialState = {

    isLoading: false,
    error: null,
    donation: null,
    donationCategory:[],
    successstories:[],
    donationVideos:[],
    humanitarian:[],
    partners:[],
    campaigns:[],
    singlecampaign:[],
    campaignJoinedUsers:null,
}


export const getdonation = createAsyncThunk(
    'get_donation_page',
    async (_, { dispatch, rejectWithValue }) => {
       
        try {
            dispatch(donationstart());
            const response = await axios.get(`config/get_donation_page`)
           

            dispatch(donationSuccess(response?.data?.donation[0]?.donation))
          

        } catch (error) {
            dispatch(donationFailure(error.response.data.message))
        }
    }
)


export const getDonationCategory = createAsyncThunk(
    'getall_doncategory',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(donationcategorystart());
            const response = await axios.get(`donation/getall_doncategory`)
          
            // dispatch(donationSuccess(response?.data?.donation[0]?.donation))
            dispatch(donationcategorySuccess(response?.data.donationcategory))
           

        } catch (error) {
            dispatch(donationcategoryFailure(error.response.data.message))
        }
    }
)

export const getDonationSuccessStories = createAsyncThunk(
    'getall_donsucess_story',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(donationstorystart());
            const response = await axios.get(`donation/getall_donsucess_story`)
            
            dispatch(donationstorySuccess(response?.data.successstories))
        

        } catch (error) {
            dispatch(donationstoryFailure(error.response.data.message))
        }
    }
)
export const getdonationVideoReducer = createAsyncThunk(
    'donation/getall_donVideo',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(donationVideostart());
            const response = await axios.get(`donation/getall_donVideo`)
            
            dispatch(donationVideoSuccess(response?.data.DonationVideoList))
        

        } catch (error) {
            dispatch(donationVideoFailure(error.response.data.message))
        }
    }
)

export const getDonationHumanitarian = createAsyncThunk(
    'gethumanitarian',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(donationhumanitarianstart());
            const response = await axios.get(`donation/gethumanitarian`)
         
            dispatch(donationhumanitarianSuccess(response?.data.humanitarian))
           

        } catch (error) {
            dispatch(donationhumanitarianFailure(error.response.data.message))
        }
    }
)

export const getDonationPartner = createAsyncThunk(
    'getpartner',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(donationpartnerstart());
            const response = await axios.get(`donation/getpartner`)
            dispatch(donationpartnerSuccess(response?.data))
        } catch (error) {
            dispatch(donationpartnerFailure(error.response.data.message))
        }
    }
)

export const getAllDonationCampaigns = createAsyncThunk(
    'get_allcompaign',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(donationcampaignsstart());
            const response = await axios.get(`donation/get_allcompaign`)
          
            dispatch(donationcampaignsSuccess(response?.data?.campaignitem))
        } catch (error) {
            dispatch(donationcampaignsFailure(error.response.data.message))
        }
    }
)

export const getsingleCampaign = createAsyncThunk(
    'getsinglecampaign',
    async (slug, { dispatch, rejectWithValue }) => {

        try {
            dispatch(singleCampaignstart());
            const response = await axios.get(`donation/campaign/${slug}`)
           
            dispatch(singleCampaignsuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(singleCampaignfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const joinCampaign = createAsyncThunk(
    'donation/create_compaignUser',
    async({campaignJoinedUsers},{dispatch, rejectWithValue})=>{
        try{
            dispatch(joinCampaignStart());

            const response= await axios.post(`donation/create_compaignUser`,campaignJoinedUsers);

            dispatch(joinCampaignSuccess(response.data))
        }
        catch(error){
            dispatch(joinCampaignFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
const donationSlice = createSlice({
    name: 'donation',
    initialState,
    reducers: {
        donationstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        donationSuccess(state, action) {

            state.donation = action.payload;
            state.isLoading = false;
        },
        donationFailure(state, action) {
            state.donation = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        donationcategorystart(state) {
            state.isLoading = true;
            state.error = null;
        },
        donationcategorySuccess(state, action) {
            state.donationCategory = action.payload;
            state.isLoading = false;
        },
        donationcategoryFailure(state, action) {
            state.donationCategory = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        donationstorystart(state) {
            state.isLoading = true;
            state.error = null;
        },
        donationstorySuccess(state, action) {
            state.successstories = action.payload;
            state.isLoading = false;
        },
        donationstoryFailure(state, action) {
            state.successstories = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        donationVideostart(state) {
            state.isLoading = true;
            state.error = null;
        },
        donationVideoSuccess(state, action) {
            state.donationVideos = action.payload;
            state.isLoading = false;
        },
        donationVideoFailure(state, action) {
            state.donationVideos = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        donationhumanitarianstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        donationhumanitarianSuccess(state, action) {
            state.humanitarian = action.payload;
            state.isLoading = false;
        },
        donationhumanitarianFailure(state, action) {
            state.humanitarian = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        donationpartnerstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        donationpartnerSuccess(state, action) {
            state.partners = action.payload;
            state.isLoading = false;
        },
        donationpartnerFailure(state, action) {
            state.partners = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        donationcampaignsstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        donationcampaignsSuccess(state, action) {
            state.campaigns = action.payload;
            state.isLoading = false;
        },
        donationcampaignsFailure(state, action) {
            state.campaigns = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        singleCampaignstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        singleCampaignsuccess(state, action) {
            state.singlecampaign = action.payload;
            state.isLoading = false;
        },
        singleCampaignfailure(state, action) {
            state.singlecampaign = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        joinCampaignStart(state){
            state.isLoading= true;
            state.error=null
        },
        joinCampaignSuccess(state ,action){
            state.campaignJoinedUsers=action.payload;
            state.isLoading=false;
        },
        joinCampaignFailure(state, action){
            state.error =action.payload;
            state.isLoading=false;
        }
    }
})



export const {donationVideoSuccess,donationVideostart ,donationVideoFailure,donationstart, donationSuccess, donationFailure,donationcategorystart,donationcategorySuccess,donationcategoryFailure,donationstorystart,donationstorySuccess,donationstoryFailure,donationhumanitarianstart,donationhumanitarianSuccess,donationhumanitarianFailure,donationpartnerstart,donationpartnerSuccess,donationpartnerFailure,singleCampaignstart,singleCampaignsuccess,singleCampaignfailure,donationcampaignsstart,donationcampaignsSuccess,donationcampaignsFailure,joinCampaignStart,joinCampaignSuccess,joinCampaignFailure} = donationSlice.actions;

export default donationSlice.reducer;
