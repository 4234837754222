import React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MetaData from "../component/layout/MetaData";
import bgimg from "../images/Rectangle 413.png";
import SmallLoader from "./SmallLoader";
import Slider from "react-slick";

import Moment from "react-moment";
import img3 from "../images/Rectangle 72.png";
import img4 from "../images/eve_2.jpg";
import img5 from "../images/eve1.jpg";
import img6 from "../images/eve_3.jpg";
import moment from "moment";
import BG from "../images/Rectangle 413.png";
import { getEventConfig } from "../Allslices/EventConfigSlice";
import { getEventList } from "../Allslices/EventConfigSlice";
import { useSelector, useDispatch } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import crownimg from "../../src/images/crown.png";
import { getAllBlogs } from "../Allslices/blogSlice";
const localizer = momentLocalizer(moment);

function Events() {
  const { eventType } = useParams();

  const { EventAllList } = useSelector((state) => state?.Event_Config);
  const { blog } = useSelector((state) => state?.blog);
  const [displayedEvents, setDisplayedEvents] = useState(3);
  const eventsPerPage = 3;

  const loadMoreEvents = () => {
    setDisplayedEvents((prev) => prev + eventsPerPage);
  };

  const maxLength2 = 300;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEventConfig());
    dispatch(getEventList(eventType));
    dispatch(getAllBlogs({ forEvent: true }));
  }, []);

  // const [subheading, setSubheading] = useState(false);
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   if (window.scrollY > 60) {
  //     setSubheading(true);
  //   } else {
  //     setSubheading(false);
  //   }
  // };

  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isFadeIn = window.scrollY > 70;
      setFadeIn(isFadeIn);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const Navigate = useNavigate();
  const anualevent = () => {
    Navigate(`/events/anual`);
  };
  const monthEvents = () => {
    Navigate(`/events/monthly`);
  };
  const specialevent = () => {
    Navigate(`/events/special`);
  };
  const otherEvents = () => {
    Navigate(`/events/others`);
  };
  const currentEvent = () => {
    Navigate(`/events/current`);
  };

  const [currentMonth, setCurrentMonth] = useState(moment());
  const handleNextMonth = () => {
    // Update the current month state to the next month
    setCurrentMonth((prevMonth) => moment(prevMonth).add(1, "month"));
  };

  const handlePrevMonth = () => {
    // Update the current month state to the previous month
    setCurrentMonth((prevMonth) => moment(prevMonth).subtract(1, "month"));
  };

  let eventdata = EventAllList?.alleventsFinal?.map(
    (element, scheduleIndex) => ({
      startDate: element?.eventsSchedule[0]?.start_Date,
      endDate: element?.eventsSchedule[0]?.end_Date,
      title: element?.title,
    })
  );

  const [currentDate, setCurrentDate] = useState(moment());
  const [isEventFormOpen, setIsEventFormOpen] = useState(false);
  const [pageBanner, setPageBanner] = useState("");
  const [pageImage, setPageImage] = useState("");

  const handleToday = () => {
    setCurrentMonth(moment());
  };

  const CustomToolbar = (toolbar) => {
    const { label } = toolbar;
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={handleToday}>
            Today
          </button>
          <button type="button" onClick={handlePrevMonth}>
            Previous
          </button>
          <button type="button" onClick={handleNextMonth}>
            Next
          </button>
        </span>
        <span
          className="rbc-toolbar-label fs-3 fw-bold"
          style={{ color: "orange" }}
        >
          {label}
        </span>
        <span className="rbc-btn-group">
          <button type="button" onClick={toolbar.onView.bind(null, "month")}>
            Month
          </button>
          <button type="button" onClick={toolbar.onView.bind(null, "week")}>
            Week
          </button>
          <button type="button" onClick={toolbar.onView.bind(null, "day")}>
            Day
          </button>
        </span>
      </div>
    );
  };
  const titlemaxLength = 50;
  const maxLength = 100;
  const settings1 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,

          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  let sliderRef1 = useRef();
  let sliderRef2 = useRef();

  const numCards = EventAllList?.allupcomingEvents?.length || 0;
  const minSlidesToShow = Math.min(3, numCards);
  const settings2 = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: minSlidesToShow, 
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  settings2.slidesToShow = minSlidesToShow;
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  }
  const singleevent = (slug,scheduleslug) => {
    Navigate(`/events/eventdetails/${slug}/${scheduleslug}`);
  };
  const annualsingleEvents = (slug) => {
    Navigate(`/blog/${slug}`);
  };
  return (
    <>
      <MetaData title={EventAllList?.config?.eventsbanner?.meta_title}
      description={EventAllList?.config?.eventsbanner?.meta_description} tags={EventAllList?.config?.eventsbanner?.meta_tag}/>
      {EventAllList ? (
        <>
          <div
            className="blogimg evenetbgb evenrrespo desktop-only "
            style={{
              backgroundImage: `url('${EventAllList?.config?.eventsbanner?.background_image}')`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {EventAllList?.config?.eventsbanner?.alignItem === "left" && (
                    <>
                      <div>
                        <h1
                          className="text-dark d-flex align-items-end haedingfont"
                          style={{
                            fontFamily: "myFont",
                            color: "rgba(255, 255, 255, 1)",
                            fontWeight: 400,
                            marginTop: "200px",
                          }}
                          onMouseOver={handleMouseOver}
                          onMouseOut={handleMouseOut}
                        >
                          {EventAllList?.config?.eventsbanner?.heading}
                        </h1>

                        <p
                          className=" eventsubheading text-dark homebdes"
                          style={{
                            fontFamily: "lato",
                            textAlign: "justify",
                            // opacity: fadeIn ? 1 : 0, // Set opacity to 0 if not fadeIn
                            // transform: fadeIn ? 'translateY(0)' : 'translateY(-30px)', // Apply translateY animation if fadeIn
                            // transition: 'opacity 0.5s ease, transform 0.5s ease' // Transition for smooth animation
                          }}
                        >
                          {EventAllList?.config?.eventsbanner?.subheading}
                        </p>
                      </div>
                      
                    </>
                  )}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">
                  {EventAllList?.config?.eventsbanner?.alignItem ===
                    "right" && (
                    <>
                      <div>
                        <h4
                          className="text-white d-flex align-items-end justify-content-center haedingfont"
                          style={{
                            fontFamily: "myFont",
                            color: "rgba(255, 255, 255, 1)",
                            marginTop: "200px",
                          }}

                          // onMouseOver={handleMouseOver}
                          // onMouseOut={handleMouseOut}
                        >
                          {EventAllList?.config?.eventsbanner?.heading}
                        </h4>

                        <h6
                          className=" eventsubheading text-dark homebdes"
                          style={{
                            fontFamily: "Playfair Display",
                            fontSize: "18px",
                            textAlign: "justify",
                            // opacity: fadeIn ? 1 : 0, // Set opacity to 0 if not fadeIn
                            // transform: fadeIn ? 'translateY(0)' : 'translateY(-30px)', // Apply translateY animation if fadeIn
                            // transition: 'opacity 0.5s ease, transform 0.5s ease' // Transition for smooth animation
                          }}
                        >
                          {EventAllList?.config?.eventsbanner?.subheading}
                        </h6>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* 
      <div
        className="wisdomimg mobile-only "
        style={{
          backgroundImage: `url('${EventAllList?.config?.eventsbanner?.background_image}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="container wisdompad">
          <div className="row">

          <div className="col-lg-6 col-md-6 col-sm-6 col-6 d-flex flex-column align-items-start justify-content-center">


  <div>
  <h1 className="text-white d-flex align-items-end justify-content-center wisdomhead haedingfont "
                style={{
                  fontSize: "75px",
                  fontFamily: "Playfair Display",
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: 400,
                  marginTop: "200px"
                }}

                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                {EventAllList?.config?.eventsbanner?.heading}
              </h1>


  </div>


</div>




          </div>
        </div>
      </div> */}

          {/* banner starts here */}
          <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ EventAllList?.config?.eventsbanner?.background_image}  />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {EventAllList?.config?.eventsbanner?.alignItem === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: EventAllList?.config?.eventsbanner?.res_heading,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {EventAllList?.config?.eventsbanner?.alignItem === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: EventAllList?.config?.eventsbanner?.res_heading,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>


             
             

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: EventAllList?.config?.eventsbanner?.res_subheading,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>
          
        </>
      ) : (
        <SmallLoader />
      )}

    

      {/* {eventType && eventType === "current" && Array.isArray(EventAllList?.alleventsFinal) && EventAllList?.alleventsFinal.filter(item => item?.eventType === "Anual").length > 0 && ( */}
      {EventAllList?.alleventsFinal.length > 0 && (
        <section className="eventsres">
          <h3
            className="text-center pt-3 haedingfont"
            style={{
              fontFamily: "myFont",
              // fontSize: "3rem",
              color: "rgba(0, 0, 0, 1)",
            }}
          >
            {/* {eventType ? (eventType === "current" ? "Annual" : "Latest Upcoming") : ""} */}
            Upcoming Events
          </h3>
          <div className="container-fluid beloved pt-5">
            <div className="container">
              <div className={`row mb-5 d-flex justify-content-center ${EventAllList?.allupcomingEvents?.length<2 ?'sliderscontainerevent' :""}`}>
                <Slider
                  ref={(slider) => {
                    sliderRef2 = slider;
                  }}
                  {...settings2}
                >
                  {Array.isArray(EventAllList?.allupcomingEvents) &&
                    EventAllList?.allupcomingEvents
                      // .filter((item) => item?.eventType === "Anual")
                      .map((element, innerIndex) => (
                        
                        <div
                          className="col-md-4 col-sm-12 col-lg-4"
                          style={{ cursor: "pointer" }}
                          key={innerIndex}

                          // onClick={() => singleevent(element.slug)}
                        >
                          <div
                            className="card item-card text-dark mx-2 overflow-hidden  "
                            onClick={() => singleevent(element.slug,element?.eventsSchedule[0]?.scheduleslug)}
                            style={{
                              borderRadius: "10px",
                            }}
                          >
                            <div className="position-relative">
                              <img
                                src={element?.eventsSchedule[0]?.thumbnail_image}
                                alt=""
                                style={{
                                  aspectRatio: "3/2",
                                }}
                              />
                              <p
                                className="position-absolute m-0"
                                style={{
                                  background:
                                    "linear-gradient(0deg, rgba(183,103,162,1) 35%, rgba(183,103,162,0.10696778711484589) 100%)",
                                  padding: "10px 0px",
                                  borderRadius: "12px",
                                  color: "white",
                                  width: "100%",
                                  textAlign: "center",
                                  bottom: "0px",
                                  fontSize: "28px",
                                  fontWeight: "500px",
                                }}
                              >
                                {element?.eventsSchedule[0].eventType}
                              </p>
                            </div>
                            <div className="container pt-4">
                              <h4
                                className="mt-1  text-capitalize"
                                style={{
                                  fontFamily: "lato",
                                  color: "black",
                                }}
                              >
                                {element?.title?.length > titlemaxLength
                                  ? `${element?.title.substring(
                                      0,
                                      titlemaxLength
                                    )}...`
                                  : element?.title}
                              </h4>
                              <h5
                                className="text-capitalize"
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(0, 0, 0, 1)",
                                  fontFamily: "Lato",
                                  fontWeight: 600,
                                  paddingTop: "10px",
                                }}
                              >
                                <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}
                                {element?.eventsSchedule.map(
                                  (item) => item.location
                                )}
                              </h5>
                              <p
                                className="card-text lato text-capitalize pt-2"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    element?.eventsSchedule[0]?.shortdescription?.length >
                                    maxLength
                                      ? `${element?.eventsSchedule[0]?.shortdescription.substring(
                                          0,
                                          maxLength
                                        )}...`
                                      : element?.eventsSchedule[0]?.shortdescription,
                                }}
                                style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                              ></p>
                              <div className="  ">
                                {/* <a href="/courses"> */}
                                <button
                                  //  className="rounded-2 cmn-t-shake mb-3 btn-sm p-3 fs-6"
                                  type="button"
                                  className="btn btn-lg mb-3 d-sm-5 fs-6"
                                  
                                  style={{
                                    backgroundColor: "#B767A2",
                                    color: "white",
                                    height: "60px !important",
                                    borderRadius: "20px",
                                  }}
                                >
                                  JOIN EVENTS
                                </button>
                                {/* </a> */}
                              </div>
                              <div></div>
                              {element?.isPaid ? (
                                <img
                                  src={crownimg}
                                  style={{
                                    top: "1.2rem",
                                    right: "1.7rem",
                                    width: "1.5rem",
                                  }}
                                  className="img-fluid  position-absolute"
                                  alt=""
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* )} */}
      {EventAllList?.alleventsFinal.length > 0 && (
        <section>
          <div
            className="container-fluid eventsres"
            style={{
              background:
                "linear-gradient(180deg, #E5F0FB 32%, rgba(229, 240, 251, 0) 100%)",
            }}
          >
            <div className="py-5 haedingfont">
              <h3
                className="text-center haedingfont"
                style={{
                  // fontSize: "3rem",
                  fontFamily: "myfont",
                  color: "rgba(0, 0, 0, 1)",
                }}
              >
                List Of All Events
              </h3>
            </div>
            <div className="container">
              <div className="row">
                {Array.isArray(EventAllList?.alleventsFinal) &&
                  EventAllList?.alleventsFinal
                    .slice(0, displayedEvents)
                    .map((element, innerIndex) => (
                      <div
                        key={`${innerIndex}-${innerIndex}`}
                        className="col-md-6 col-sm-12 col-lg-12 mb-2 pb-3"
                      >
                        <div
                          className="card item-card text-dark"
                          onClick={() => singleevent(element.slug,element?.eventsSchedule[0]?.scheduleslug)}
                        >
                          <div className="row">
                            <div className="col-sm-12 col-lg-3 d-block align-content-center">
                              <img
                                src={element?.eventsSchedule[0]?.thumbnail_image}
                                alt=""
                                style={{
                                  // height: "100%",
                                  // margin: "20px",
                                  // borderRadius: "12px",
                                  aspectRatio: "3/2"
                                }}
                              />
                            </div>
                            <div className="col-sm-12 card-body col-lg-9 px-4">
                              <h5
                                className="mt-1  text-capitalize"
                                style={{
                                  fontFamily: "lato",
                                  color: "black",
                                }}
                              >
                                {element?.title}
                              </h5>
                              <p
                                className="card-text text-capitalize"
                                style={{
                                  color: "#B767A2",
                                  fontWeight: 700,
                                  fontFamily: "Lato",
                                }}
                              >
                                <span>
                                  <Moment
                                    className="fw-semibold"
                                    format="D MMM YYYY HH:mm "
                                    withTitle
                                  >
                                    {element?.eventsSchedule[0]?.start_Date}
                                  </Moment>
                                </span>
                                <span>-</span>
                                <span>
                                  <Moment
                                    className="fw-semibold"
                                    format="D MMM YYYY HH:mm "
                                    withTitle
                                  >
                                    {element?.eventsSchedule[0]?.end_Date}
                                  </Moment>
                                </span>
                              </p>
                              <p
                                className="card-text text-capitalize"
                                style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                                dangerouslySetInnerHTML={{
                                  __html:element?.eventsSchedule[0]?.shortdescription?.length > maxLength2
                                  ? `${element?.eventsSchedule[0].shortdescription.substring(
                                      0,
                                      maxLength2
                                    )}...`
                                  : element?.eventsSchedule[0]?.shortdescription,
                                }}
                              >
                                
                              </p>
                              <p
                                className=" card-texttext-capitalize"
                                style={{ fontFamily: "Lato", color: "black" }}
                              >
                                <i className="fa-solid fa-location-dot text-capitalize"></i>{" "}
                                {element?.eventsSchedule[0]?.location}
                              </p>
                            </div>
                          </div>
                          {element?.isPaid ? (
                            <img
                              src={crownimg}
                              style={{
                                top: "1.2rem",
                                right: "1.7rem",
                                width: "1.5rem",
                              }}
                              className="img-fluid  position-absolute"
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))}
              </div>
              <div className="mt-5 text-center">
                {EventAllList?.alleventsFinal &&
                  EventAllList?.alleventsFinal.length > displayedEvents && (
                    <button
                      className="btn btn-lg text-light rounded cmn-t-shake py-3 px-5 rounded-pill shadowbuttons"
                     
                      onClick={loadMoreEvents}
                    >
                      LOAD MORE
                    </button>
                  )}
              </div>
            </div>
          </div>
        </section>
      )}

      {/* <div className='container'>
        <Calendar
          localizer={localizer}
          events={eventdata}
          startAccessor={(e) => { return new Date(e.startDate) }}
          endAccessor={(e) => { return new Date(e.endDate) }}
          style={{ height: '500px', width: "100%", border: "none" }}
          components={{
            toolbar: CustomToolbar // Use custom toolbar component
          }}
          date={currentMonth.toDate()}
        />
      </div> */}

      {/* end calender */}
      {blog.length > 0 && (
        <>
          <section>
            <div className="cotainer-fluid mt-5 ">
              <div className=" d-flex align-items-end justify-content-center ">
                <h1
                  className="mt-5 haedingfont"
                  style={{ fontFamily: "myFont" }}
                >
                  Latest Blog News
                </h1>
              </div>
            </div>
          </section>
          <section>
            <div
              className="container-fluid beloved pt-5 "
              // style={{ backgroundColor: "#F9F9F9" }}
            >
              <div className="container">
                <div className="row mb-5 d-flex justify-content-center  ">
                  <Slider
                    ref={(slider) => {
                      sliderRef1 = slider;
                    }}
                    {...settings1}
                  >
                    {Array.isArray(blog) &&
                      blog
                        .filter((item) => item.forEvent == true)
                        .map((element, innerIndex) => (
                          <div
                            key={`${innerIndex}-${innerIndex}`}
                            className="col-md-6 col-sm-12 col-lg-4 pb-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => annualsingleEvents(element?.slug)}
                          >
                            <div
                              className="card item-card text-dark mx-2 card_height1"
                              style={{
                                borderRadius: "10px",
                              }}
                            >
                              <img
                                src={element?.thumbnail_image}
                                // src={element?.profile}
                                alt=""
                                style={{
                                  borderRadius: "10px 10px 0px 0px",
                                  aspectRatio: "3/2",
                                }}
                              />
                              <div className="container">
                                {/* <h4
                            className="lato pt-3"
                            style={{ fontSize: "16px" }}
                          >
                            <Moment
                              className="fw-semibold"
                              format="D MMM YYYY"
                              withTitle
                            >
                              {element?.createdAt}
                            </Moment>
                          </h4> */}

                                <h5
                                  className="mt-1  text-capitalize"
                                  style={{
                                    fontFamily: "lato",
                                    color: "black",
                                  }}
                                >
                                  {element?.title?.length > titlemaxLength
                                    ? `${element.title.substring(
                                        0,
                                        titlemaxLength
                                      )}...`
                                    : element?.title}
                                </h5>
                                <p
                                  className="card-text lato text-capitalize pb-3"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      element?.description?.length > maxLength
                                        ? `${element.description.substring(
                                            0,
                                            maxLength
                                          )}...`
                                        : element?.description,
                                  }}
                                  style={{
                                    fontFamily: "Lato",
                                    color: "#5B5B5B",
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        ))}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default Events;
