import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";
const filters = {
    categoryId: '',
    language: '',
    sort: '',
    contentType: '',
    text: ''
}
const initialState = {

    isLoading: false,
    error: null,
    video: null,
    singlevideo: null,
    videotag: null,
    currentPage: 0,
    limit: 8,
    totalVideoList: 0,
    trendingvideo: null,
    videolist: null,
    Filters: filters
}

// videoCategory

export const getVideo = createAsyncThunk(
    'all_videocategory',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(videostart());
            const response = await axios.get(`video/all_videocategory`)

            dispatch(videoSuccess(response?.data))

        } catch (error) {
            dispatch(videoFailure(error.response.data.message))
        }
    }
)


export const getConfigVideo = createAsyncThunk(
    'get_videobanner',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(videoConfigstart());
            const response = await axios.get(`config/get_videobanner`)

            dispatch(videoConfigSuccess(response?.data))


        } catch (error) {
            dispatch(videolistFailure(error.response.data.message))
        }
    }
)
// list


export const getlistVideo = createAsyncThunk(
    'video/all_videolist',
    async ({ page, limit }, { dispatch, getState, rejectWithValue }) => {
        const { video: { Filters: { categoryId, language, sort, contentType, text } } } = getState();

        try {
            dispatch(videoliststart());
            const response = await axios.get(`video/all_videolist?&page=${page ?? ""}&limit=${limit ?? ''}&categoryId=${categoryId ?? ""}&sort=${sort ?? ""}&language=${language ?? ""}&text=${text ?? ""}`);
            dispatch(videolistSuccess(response?.data));
        } catch (error) {
            dispatch(videolistFailure(error.response.data.message));
        }
    }
);


// export const getlistVideo = createAsyncThunk(
//     'video/all_videolist',
//     async (_, { dispatch, rejectWithValue }) => {

//         try {
//             dispatch(videoliststart());
//             const response = await axios.get(`video/all_videolist`)
//             dispatch(videolistSuccess(response?.data))


//         } catch (error) {
//             dispatch(videolistFailure(error.response.data.message))
//         }
//     }
// )

export const getLanguages = createAsyncThunk(
    'languages/get_languages',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(languagesStart());
            const response = await axios.get("languages/get_languages")
            dispatch(languagesSuccess(response?.data?.settingItems))

        } catch (error) {
            dispatch(languagesFailure(error.response.data.message))
        }
    }
)

// Trending Video
export const getTrendingVideo = createAsyncThunk(
    'all_videolist',
    async (_, { dispatch }) => {
        try {
            dispatch(trendingvideoStart());
            const response = await axios.get(`video/all_videolist`)
            dispatch(trendingvideoSuccess(response?.data?.videoListItems))
        }
        catch (error) {
            dispatch(trendingvideoFailure(error.response.data.message))
        }
    }
)

// Trending Video


export const getsinglelistvideo = createAsyncThunk(
    'video/getsingleVideo/',
    async (slug, { dispatch, rejectWithValue }) => {
        try {
            dispatch(singlevideostart());
            const response = await axios.get(`video/getsingleVideo/${slug}`);
            dispatch(singlevideoSuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(singlevideoFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)


export const putinterstvideo = createAsyncThunk(
    'video/recommendedVideo',
    async ({tags}, { dispatch, rejectWithValue }) => {
        console.log(tags,'tags in slice')
      try {
        dispatch(starttagvideo());
        const response = await axios.post(`video/recommendedVideo`, {tags:tags});
        dispatch(successtagvideo(response.data));
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.message || error.message;
        dispatch(failuretagvideo(errorMessage));
        return rejectWithValue(errorMessage);
      }
    }
  );

const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        videostart(state) {
            state.isLoading = true;
            state.error = null;
        },
        videoSuccess(state, action) {

            state.videoCategory = action.payload?.allvideocategories
                ;
            state.isLoading = false;
        },
        videoFailure(state, action) {
            state.video = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        // single video
        singlevideostart(state) {
            state.isLoading = true;
            state.error = null;
        },
        singlevideoSuccess(state, action) {

            state.singlevideo = action?.payload;
            state.isLoading = false;
        },
        singlevideoFailure(state, action) {
            state.video = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        videoConfigstart(state) {
            state.isLoading = true;
            state.error = null;
        },
        videoConfigSuccess(state, action) {

            state.videoConfig = action.payload?.videobanner;
            state.isLoading = false;
        },
        videoConfigFailure(state, action) {
            state.video = null;
            state.isLoading = false;
            state.error = action.payload;

        },

        videoliststart(state) {
            state.isLoading = true;
            state.error = null;

        },
        videolistSuccess(state, action) {
            state.videolist = action?.payload?.videoListItems;
            state.currentPage = action.payload.currentPage;
            state.limit = action.payload.limit;
            state.totalVideoList = action.payload.totalVideoList;
            state.isLoading = false;
        },
        videolistFailure(state, action) {
            state.videolist = null;
            state.isLoading = false;
            state.error = action.payload;

        },
        trendingvideoStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        trendingvideoSuccess(state, action) {
            state.trendingvideo = action.payload;
            state.isLoading = false;
        },
        trendingvideoFailure(state, action) {
            state.trendingvideo = null;
            state.isLoading = false;
            state.error = action.payload;
        },

        languagesStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        languagesSuccess(state, action) {
            state.Languages = action.payload;
            state.isLoading = false;
        },
        languagesFailure(state, action) {
            state.Languages = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        filtersUpdate(state, action) {
            const { categoryId, language, sort, contentType, text } = action.payload;
            if (categoryId !== undefined) {
                state.Filters.categoryId = categoryId;
            }
            if (language !== undefined) {
                state.Filters.language = language;
            }
            if (sort !== undefined) {
                state.Filters.sort = sort;
            }
            if (contentType !== undefined) {
                state.Filters.contentType = contentType;
            }
            if (text !== undefined) {
                state.Filters.text = text;
            }
        },
        starttagvideo(state,action){
            state.isLoading=true;
            state.error=null;
          },
          successtagvideo(state,action){
            state.isLoading=false;
            state.videotag=action?.payload?.recommendedVideoList;
        
          },
          failuretagvideo(state,action){
         state.isLoading=false;
         state.error=null;
          }


    }
})


export const { filtersUpdate,videostart, videoSuccess, videoFailure, singlevideostart, singlevideoSuccess, singlevideoFailure, videoConfigstart, videoConfigSuccess, videoConfigFailure, videoliststart, videolistSuccess, videolistFailure, trendingvideoStart, trendingvideoSuccess, trendingvideoFailure, languagesStart, languagesSuccess, languagesFailure, starttagvideo,
    successtagvideo,
    failuretagvideo } = videoSlice.actions;

export default videoSlice.reducer;
