import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const SHOW_ERROR_NOTIFICATION = (msg) => {

    toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
        transition: Slide,
        autoClose:1000
    })
};

export const SHOW_INFO_NOTIFICATION = (msg) => {

    toast.info(msg, {
        position: toast.POSITION.TOP_RIGHT,
        transition: Slide
    })

};

export const SHOW_SUCCESS_NOTIFICATION = (msg) => {

    toast.success(msg, {
        position: toast.POSITION.TOP_RIGHT,
        transition: Slide,
        autoClose:1000
    });
};
