import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ".././component/layout/style.css";
import { getAllSongs } from "../Allslices/songSlice";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import playbtn from "../images/playy-button (1).png";
import crownimg from "../../src/images/crown.png";
import { getsinglealbum } from "../Allslices/albumSlice";
import { useNavigate } from "react-router-dom";

const Carousel = ({ tags, id }) => {
  const navigate = useNavigate();

  const { artiste } = useSelector((state) => state.album);

  const sliderRef = useRef();
  const dispatch = useDispatch();
  const params = useParams();
  // const { slug } = params;
  var url = window.location.href;
  url = url.split("/");
  const urlleng = url.length;
  const albumId = url[urlleng - 2];
  const songId = url[urlleng - 1];
  const { singlealbum } = useSelector((state) => state.album);
  const { allsongs } = useSelector((state) => state.song);

  const [filterAllsongs, setFilterAllSongs] = useState([]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  useEffect(() => {
    dispatch(getAllSongs());
  }, [dispatch]);

  useEffect(() => {
    if (allsongs && tags !== undefined) {
      const filteredSongs = allsongs.filter((item) =>
        compareTags(item?.tags, tags)
      );
      setFilterAllSongs(filteredSongs);
    }
  }, [allsongs, tags]);

  const compareTags = (itemtags, tags) => {
    let match = false;
    itemtags.forEach((ele) => {
      if (tags.includes(ele)) {
        match = true;
      }
    });
    return match;
  };
  // const slug = allsongs?.slug;
  // const albumid = songList.parentAlbumId[0]?.slug;

  const handleNavigateToSongpage = (albumid, slug) => {
    dispatch(getsinglealbum(slug));
    navigate(`/music/${albumid}/${slug}`);
  };
  return (
    <div className="carousel-container" style={{ overflow: "hidden" }}>
      <h1 className="text-center pt-2">
        <span className="fs-1">Related Content</span>
      </h1>
      {filterAllsongs.length > 0 && (
        <Slider ref={sliderRef} {...settings}>
          {filterAllsongs
            .filter((item) => item._id !== id)
            .map((item, index) => (
              <div
                className="slide "
                key={index}
                onClick={() => handleNavigateToSongpage(albumId, item?.slug)}
              >
                <div
                  className="capybg rounded px-2 pb-2 mx-1"
                  style={{
                    backgroundImage: `url(${item.thumbnail_image})`,
                  }}
                >
                  <div className="iconde d-flex p-2">
                    <a
                      href=""
                      className="text-dark p-1 rounded ms-auto fs-6 fw-semibold"
                      style={{ backgroundColor: "rgb(224 224 224 / 69%)" }}
                    >
                      {moment(item.created_at).format("MMMM Do YYYY")}
                    </a>
                  </div>
                  <div className="moucis pt-5">
                    <div>
                      <div>
                        <p className="text-dark">
                          <i className="fa-solid fa-headphones px-2"></i>
                        </p>
                      </div>
                      <div className="d-flex gap-1">
                        <a href="#">
                          <img src={playbtn} style={{ width: "42px" }} alt="" />
                        </a>
                        <h3 className="text-light px-2 fw-semibold text-black">
                          {item.title}
                        </h3>
                      </div>
                    </div>
                  </div>
                  {item?.isPaid ? (
                    <img
                      src={crownimg}
                      style={{
                        top: "1rem",

                        width: "1.5rem",
                      }}
                      className="img-fluid  position-absolute"
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </Slider>
      )}
    </div>
  );
};

export default Carousel;
