import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
    isLoading: false,
    error: null,
    tags: [],
}



export const getalltags = createAsyncThunk(
    'tag/tags',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(tagstart());
            const response = await axios.get(`tag/all`)
            dispatch(tagsuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(tagfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)

        }

    }
)


const tagsSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {
        tagstart(state, action) {
            state.isLoading = true;
        },
        tagsuccess(state, action) {
            state.tags = action.payload?.alltags;
            state.isLoading = false
        },
        tagfailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        }
    }

})

export const { tagstart, tagsuccess, tagfailure } = tagsSlice.actions;

export default tagsSlice.reducer;
