import React, { useEffect, useState } from "react";
import "../component/layout/style.css";

import morpankh from "../images/morpankh.png";
import Rectangle13 from "../images/Rectangle13.png";
import Yogashala from "../../src/images/zapp2.png";

import ExploreBg from "../../src/images/explorebg.png";

import MetaData from "../component/layout/MetaData";

import { getaboutUs, getAboutOrganisation } from "../Allslices/aboutusSlice";
import { useDispatch, useSelector } from "react-redux";
import { getHome } from "../Allslices/homeSlice";
import { Link } from "react-router-dom";
import SmallLoader from "./SmallLoader";

const About = () => {
  const dispatch = useDispatch();
  const { aboutUs, aboutUsOrganisation } = useSelector(
    (state) => state.aboutus
  );

  const { home } = useSelector((state) => state.home);
  function imgzoom(imageId, modalId) {
    var modal = document.getElementById(modalId);
    var img = document.getElementById(imageId);
    var modalImg = modal.getElementsByClassName("modal-content")[0];
    img.onclick = function () {
      modal.style.display = "block";
      modalImg.src = this.src;
    };

    var closeBtn = modal.getElementsByClassName("imgclose")[0];

    closeBtn.onclick = function () {
      modal.style.display = "none";
    };
  }
  useEffect(() => {
    dispatch(getaboutUs());
    dispatch(getAboutOrganisation());
    dispatch(getHome());
  }, []);

  const [hovered, setIsHovered] = useState(true);
  const [gyandeep, setgyandeep] = useState(false);
  const [dafhover, setdafhover] = useState(false);
  const [vjmhover, setvjmhover] = useState(false);

  const handleMouseEnter = (text) => {
    if (text == "yoga") {
      setIsHovered(true);
      setgyandeep(false);

      setdafhover(false);
      setvjmhover(false);
    } else {
      setIsHovered(false);
    }

    if (text == "gyandeep") {
      setgyandeep(true);
      setIsHovered(false);
      setdafhover(false);
      setvjmhover(false);
    }
    if (text == "daf") {
      setdafhover(true);
      setgyandeep(false);
      setIsHovered(false);
      // setdafhover(false)
      setvjmhover(false);
    }
    if (text == "vjm") {
      setvjmhover(true);
      setdafhover(false);
      setgyandeep(false);
      setIsHovered(false);
      // setdafhover(false)
      // setvjmhover(false)
    }

    // Call your function here or do whatever you want on hover
  };
  const [hoveredIndex, setHoveredIndex] = useState(0);

  const handleOnclick = (index) => {
    setHoveredIndex(index);
  };

  return (
    <>
      {/* <!-- banner section --> */}
      <MetaData title={aboutUs?.meta_title} description={aboutUs?.meta_description} tags={aboutUs?.meta_tag} />

      {aboutUs ? (
        <>
        <div>
  {/* Desktop and large screens */}
  <div
    className="aboutban2 desktop-only "
    style={{
      backgroundImage: `url(${aboutUs?.container_1?.banner_image})`,
    }}
  >
    <div className="container aboutpadi ">
      <div className="row">
   
       
        {aboutUs?.container_1?.alignItem==="left"&&(<> 
          <div className="col-lg-5 col-md-5 col-sm-5 col-5 ps-0">
        <p
            className="aboutmytitel"
            style={{ fontFamily: "myFont" }}
            dangerouslySetInnerHTML={{
              __html: aboutUs?.container_1?.title,
            }}
          ></p>
          <p
            className="aboutmydes pt-3"
            dangerouslySetInnerHTML={{
              __html: aboutUs?.container_1?.description,
            }}
          ></p>
          </div>
          <div className="col-lg-7 col-md-7 col-sm-7 col-7 ps-0">
            </div>
          </>)}
         
       
        {aboutUs?.container_1?.alignItem==="right"&&(<>   
          <div className="col-lg-7 col-md-7 col-sm-7 col-7 ps-0">
          </div>
         <div className="col-lg-5 col-md-5 col-sm-5 col-5 ps-0">
        <p
            className="aboutmytitel"
            style={{ fontFamily: "myFont" }}
            dangerouslySetInnerHTML={{
              __html: aboutUs?.container_1?.title,
            }}
          ></p>
          <p
            className="aboutmydes pt-3"
            dangerouslySetInnerHTML={{
              __html: aboutUs?.container_1?.description,
            }}
          ></p>
          </div>
          </>)}
         
       
      </div>
    </div>
  </div>

 
</div>


<div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${aboutUs?.container_1?.res_banner_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {aboutUs?.container_1?.alignItem  === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: aboutUs?.container_1?.titleres,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {aboutUs?.container_1?.alignItem  === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: aboutUs?.container_1?.titleres,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>


             
             

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: aboutUs?.container_1?.descriptionres,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          </div>





          {/* <!-- banner section end --> */}

          {/* <!-- beloved solution --> */}

          <section>
            <div className=" container beloved">
              <h1 className="text-center haedingfont">
                <span style={{ fontFamily: "myFont" }}>
                  {aboutUs?.container_2?.title}
                </span>
              </h1>
              <p
                className="text-center lato lh-lg fs-3 aboutdesse"
                style={{
                  fontSize: "20px",
                  fontFamily: "Lato",
                  marginBottom: "52px",
                }}
                dangerouslySetInnerHTML={{
                  __html: aboutUs?.container_2?.description,
                }}
              ></p>
            </div>
          </section>
          {/* <!-- beloved solution end --> */}

          {/* <!-- doughter to --> */}
          <section className="">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-xl-6 col-md-6 col-sm-12 messagetitle">
                  <h1 className="pt-3 haedingfont" id="santa">
                    <span className="">{aboutUs?.container_3?.title}</span>
                  </h1>

                  <p
                    className=" lato "
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: aboutUs?.container_3?.description,
                    }}
                  >
                    {/* {aboutUs?.container_3?.description} */}
                  </p>
                </div>
                <div className="col-md-1"></div>
                <div className="col-md-5 d-md-block col-sm-12 mt-3 mb-5">
                  <img 
                    src={aboutUs?.container_3?.thumbnail_image}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
          <div
            className="container-fluid text-center d-flex justify-content-center align-items-center flex-column text-light"
            style={{
              backgroundImage: `url(${aboutUs?.container_5?.thumbnail_image})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "80vh",
            }}
          >
            <h1
              className="whoami haedingfont"
              style={{
                fontFamily: "myFont",
                fontSize: "40px",
                marginTop: "150px",
              }}
            >
              {aboutUs?.container_5?.title}
            </h1>
            <span
              className="text-light  px-5 pb-2   whoiam"
              style={{ fontFamily: "myFont!important" }}
              dangerouslySetInnerHTML={{
                __html: aboutUs?.container_5?.description,
              }}
            ></span>
          </div>

          <div className="container p-3 mt-3">
            <div className="p-2 text-center py-4">
              <h1
                className="humanitariantitle haedingfont"
                style={{ fontFamily: "myFont", fontSize: "" }}
              >
                {aboutUs?.container_6?.title}
              </h1>
              <p
                    className="pt-3 lato "
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: aboutUs?.container_6?.description,
                     }} />
             
            
            </div>
            {/* for the About us Organisation */}

            <div className="row d-flex align-items-center">
              <div className="col-lg-6 d-flex flex-column">
                {aboutUsOrganisation?.map((org, index) => (
                  <button
                    key={index}
                    className="btn btn-lg border text-dark shadow rounded-0  mt-4 aboutorg1 rounded-2"
                    style={{ padding: "0.6rem 2.5rem", color: "#b767a2" }}
                    onMouseEnter={() => handleOnclick(index)}
                  >
                    <span target="_blank" rel="noreferrer">
                      <a href={org.link} target="_blank" rel="noreferrer ">
                        {org.title}
                      </a>
                    </span>
                  </button>
                ))}
                {/* <button
              className="btn btn-lg border text-dark shadow rounded-0 cmn-t-shake mt-4"
              style={{ padding: "0.6rem 2.5rem" }}
            >
              More
            </button> */}
              </div>
              <div className="col-lg-6">
                {aboutUsOrganisation?.length > 0 && (
                  <div className="d-flex align-items-center justify-content-center ">
                    <video
                      className=""
                      src={aboutUsOrganisation[hoveredIndex]?.thumbnail_video}
                      controls
                      autoPlay
                      style={{ height: "429px", margin: "45px 0" }}
                    ></video>
                    {/* <iframe
                  src={aboutUsOrganisation[hoveredIndex]?.thumbnail_video}
                  title={aboutUsOrganisation[hoveredIndex]?.thumbnail_video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  style={{
                    height: "90%",
                    width: "90%",
                    boxShadow: " -2px 1px 26px -1px rgba(0,0,0,1)",
                    borderRadius: "30px"
                  }}
                ></iframe> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="container-fluid text-center "
            style={{
              // backgroundImage: `url(${Rectangle13})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              height: "10%",
            }}
          >
            <div className="color-mixer ">
            <div className="container">
              <div className="col-12 ">
                <div className="p-2 text-center py-4">
              <h1
                className="humanitariantitle haedingfont"
                style={{ fontFamily: "myFont", fontSize: "" }}
              >
                {aboutUs?.container_7?.title}
              </h1>
              </div>
              <div className="col-12 desktop-only">
             
             
             </div>
          
              </div>
            </div>
            </div>

            <h1
              className="humanitariantitle haedingfont"
              style={{ fontFamily: "myFont" }}
            >
              {aboutUs?.humanitarian_images?.title}
            </h1>
            <p className="px-4 py-2 ">
              {aboutUs?.humanitarian_images?.description}
            </p>

            <div className="container gallerysec pb-5">

            <p
                    className=" lato "
                    style={{ textAlign: "justify" }}
                    dangerouslySetInnerHTML={{
                      __html: aboutUs?.container_7?.description,
                     }} />
              
              <div className="row g-3">
                <div className="col-lg-3 col-sm-12">
                  <img
                    src={aboutUs?.humanitarian_images?.h_image2}
                    alt=""
                    className="shadow-1-strong object-fit-cover"
                    style={{ width: "100%", aspectRatio: "1.27" }}
                  />
                  <img
                    src={aboutUs?.humanitarian_images?.h_image3}
                    alt=""
                    className="shadow-1-strong object-fit-cover mt-3"
                    style={{ width: "100%", aspectRatio: "1.27" }}
                  />
                </div>
                <div className="col-lg-6 col-sm-12">
                  <img
                    src={aboutUs?.humanitarian_images?.h_image1}
                    alt=""
                    className="shadow-1-strong object-fit-cover"
                    style={{ width: "100%", aspectRatio: "1.27" }}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <img
                    src={aboutUs?.humanitarian_images?.h_image4}
                    alt=""
                    className="shadow-1-strong object-fit-cover"
                    style={{ width: "100%", aspectRatio: "1.273904483" }}
                  />
                  <img
                    src={aboutUs?.humanitarian_images?.h_image5}
                    alt=""
                    className="shadow-1-strong object-fit-cover mt-3"
                    style={{ width: "100%", aspectRatio: "1.273904483" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <section className="downloadapp">
            <div
              className="bg-imgd"
              style={{
                backgroundImage: `url(${Yogashala})`,
                height: "927px",
                backgroundSize: "cover",
              }}
            >
              <div className="container mainappcon">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 paddingtt ">
                    <h1 className="btnphone haedingfont">
                      <span>{home?.image_6?.title}!</span>
                    </h1>
                    <hr style={{ color: "#FD6E12" }} />
                    <p className="lato btnphone">
                      {home?.image_6?.description}
                    </p>
                    <div className="pt-4 btnphone">
                      <a
                        href="#"
                        target="_blank"
                        className="market-btn google-btn mx-1 text-white rounded-3 bg-black"
                        role="button"
                      >
                        <span className="market-button-subtitle text-white">
                          Download on the
                        </span>
                        <span className="market-button-title text-white">
                          Google Play
                        </span>
                      </a>
                      <a
                        href="#"
                        target="_blank"
                        className="market-btn apple-btn mx-1 text-white rounded-3 bg-black"
                        role="button"
                      >
                        <span className="market-button-subtitle text-white">
                          Download on the
                        </span>
                        <span className="market-button-title text-white">
                          App Store
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 mycontainerdapp ">
                    <div className="downimg" style={{
                      backgroundImage:`url(${home?.image_6?.thumbnail_image})`
                      
                    }}>
                      {/* <img src={home?.image_6?.thumbnail_image} alt="" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- explore start=========== --> */}
          <section className="explore">
            <div
              className="exploimg "
              style={{
                backgroundImage: `url(${ExploreBg})`,
                backgroundSize: "cover",
              }}
            >
              <div className="container ">
                <div className="row">
                  <div className="col-12 exploretext ">
                    <div className="aboutusexplore haedingfont">
                      <p
                        className="text-center haedingfont"
                        dangerouslySetInnerHTML={{
                          __html: home?.bannercontent?.title_e,
                        }}
                      ></p>
                    </div>

                    <p className="text-center lato">
                      {home?.bannercontent?.description_e}
                    </p>
                    <div className=" pt-3 d-flex justify-content-center">
                      <a href="/courses">
                        <button className="btn-read cmn-t-shake">
                          EXPLORE NOW
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <SmallLoader />
      )}
    </>
  );
};

export default About;
