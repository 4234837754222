import React, { useEffect } from 'react'


import Moment from 'react-moment';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSingleWellness } from '../Allslices/wellnessSlice';
import { setCurrentTrack, setTrackList } from '../Allslices/playerSlice';
import { SHOW_INFO_NOTIFICATION } from '../appUtils/appToast';

const WellnessSonglist = ({ song, handlePlay, handlePause, albumid, checkBuyStatus, isPaid }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { wellness } = useSelector((state) => state.allwellness)

    const { currentTrack, isPlaying, trackList } = useSelector((state) => state.player);

    const isCurrentTrack = currentTrack?._id === song?._id;


    useEffect(() => {

        dispatch(getSingleWellness(albumid))

    }, [])


    const playSong = () => {
        handlePlay(song);
    };
    const pauseSong = () => {
        handlePause(song)
    }

    const handleNavigateToSongpage = (slug) => {
        if (isPaid) {
            if (checkBuyStatus) {
                const index = wellness?.songList.findIndex((s) => s.slug == slug);

                console.log("The index value is:", index);
                dispatch(setTrackList({ list: wellness?.songList, index }))
                dispatch(setCurrentTrack(song));
                navigate(`/wellness/${albumid}/${slug}`);
            } else {
                SHOW_INFO_NOTIFICATION('Please Buy To View More')
            }
        } else if (!isPaid) {
            const index = wellness?.songList.findIndex((s) => s.slug == slug);
            console.log("The index value is:", index);
            dispatch(setTrackList({ list: wellness?.songList, index }))
            dispatch(setCurrentTrack(song));
            navigate(`/wellness/${albumid}/${slug}`);
        }


    };
const maxLength= 35
    return (
        <div className="elemt pt-2" key={song._id}>
            <div className="apixoss py-4" style={{gap: "3px"}}>
                {isCurrentTrack && isPlaying ?
                    <i
                        onClick={pauseSong}
                        className="fas fa-pause fs-4 pt-2" style={{cursor: "pointer"}}>

                    </i> :
                    (
                        <i
                            onClick={!song?.isPaid ? playSong : null}
                            className={`fas fa-play fs-4 pt-2`} style={{cursor: "pointer"}}>

                        </i>
                    )}
                <p className="pt-2 yera yeratitle fw-semibold">
                    
                    {/* {song?.title?.length>maxLength ?`${song?.title.substring(0,maxLength)}...`:song?.title} */}
                    {song?.title}
                </p>
                {
                    isCurrentTrack && isPlaying ? (
                        <iframe src="https://giphy.com/embed/H4Ke1ghWdhic0HLZ6L" width="60" height="36" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
                    ) : ""
                }
                {/* <p className="pt-2 yera fw-semibold">
                    <Moment format="D MMM YYYY" withTitle>
                        {song?.created_at}
                    </Moment>
                </p> */}
                <p  className="pt-2 yera fw-semibold">
                    <i className="fa-solid fa-headphones px-2" style={{color: "#b767a2"}}></i>AUDIO
                </p>
                <a

                    className="btn vieww text-dark text-dark pt-2 yera"

                    onClick={() => handleNavigateToSongpage(song.slug)}
                >
                    View More<i className="fa-solid fa-angle-right "></i>
                </a>
            </div>
        </div>
    )
}

export default WellnessSonglist