import React, { useState, useEffect } from "react";
// import "./style.css";
import ".././component/layout/style.css"
import Contact_1_1 from "../../src/images/Contact-1 1.png";
import Contact_2_1 from "../../src/images/Contact-2 1.png";
import Contact_3_1 from "../../src/images/Contact-3 1.png";
import { useSelector, useDispatch } from "react-redux";
import { contactus } from "../Allslices/mainSlice";
import { getContactUs } from "../Allslices/ContactUsSlice";
import SmallLoader from "./SmallLoader";
import MetaData from "../component/layout/MetaData";
const ContactUs = () => {

    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => state.contact)

    const { contact } = useSelector((state) => state.contactUs)
    const [formdata, setFormdata] = useState({
        name: "",
        email: "",
        phone: null,
        subject: "",
        message: ""


    })
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormdata({
            ...formdata,
            [name]: value
        })
    }


    const handleFormsubmit = (e) => {
        e.preventDefault()

        dispatch(contactus(formdata))
        alert('message sent successfully')


    }

    useEffect(() => {
        dispatch(getContactUs())

    }, []);

    return (
        <>
            <MetaData title="Contact Us" />

            <section className="color-mixcon " style={{
                backgroundImage: "linear-gradient(to bottom, rgb(240 210 240) 0%, #FFFFFF 10% )",

            }}>
                <div className="container-fluid contpadi">
                    <div className="container pt-5">
                        <h1 className="pt-5 text-center mt-4">
                            <span style={{ fontSize: "40px" }}>Contact Us</span>
                        </h1>
                        <p className="text-center text-dark lato mb-4">
                            {contact?.description}
                        </p>

                        {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.1139827314632!2d77.0139014752266!3d28.656305583001206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0575f296ea71%3A0x987b52f2e27648e9!2sAnand%20Dham%20Ashram!5e0!3m2!1sen!2sin!4v1696485815189!5m2!1sen!2sin"
                        width="100%"
                        height="450"
                        style={{ border: "0" }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>

                    <div className="container mt-2 pt-5 pb-5 contactsec">
                        <div className="row">
                            {contact ? (

                            <div className="col-md-4 col-lg-4 col-sm-12 item">
                                <div className="card item-card card-block contactcard">
                                    <img src={Contact_1_1} alt="Photo of sunset" />
                                    <h2 className=" mt-3 mb-3 text-center fw-bold lato">Address</h2>
                                    <p className="card-text text-center text-dark lato" >
                                       {contact?.address}
                                    </p>
                                </div>
                            </div>
                            ):(
                                <SmallLoader/>
                            )}

                            {contact ? (

                            <div className="col-md-4 col-lg-4 col-sm-12 item">
                                <div className="card item-card card-block contactcard" >
                                    <img src={Contact_2_1} alt="Photo of sunset" />
                                    <h2 className=" mt-3 mb-3 text-center fw-bold lato">Mail</h2>
                                    <p className="card-text text-center text-dark lato" style={{ paddingBottom: "20px!important" }}>
                                    {contact?.email}
                                    </p>
                                </div>
                            </div>
                            ) : (
                                <SmallLoader />
                            )}

                            {contact ? (

                            <div className="col-md-4 col-lg-4 col-sm-12 item">
                                <div className="card item-card card-block contactcard" >
                                    <img src={Contact_1_1} alt="Photo of sunset" />
                                    <h2 className=" mt-3 mb-3 text-center fw-bold lato">Contact</h2>
                                    <p className="card-text text-center text-dark lato" style={{ paddingBottom: "20px!important" }}>
                                    {contact?.phone}
                                    </p>
                                </div>
                            </div>
                            ) : (
                                <SmallLoader />
                            )}
                            
                            <div className="col-md-4 col-lg-4 col-sm-12 item">
                                <div className="card item-card card-block">
                                    <img src={Contact_3_1} alt="Photo of sunset" />
                                    <h2 className=" mt-3 mb-3 text-center fw-bold">Contact</h2>
                                    <p className="card-text text-center">+91 7291986652</p>
                                </div>
                            </div>
                        </div>
                    </div> */}


                        <div className="container d-flex justify-content-center align-items-center py-5">
                            <form className='w-75 shadow p-5 rounded-xl my-5'>
                                <div className="form-group" >
                                    <h1><span className="fs-2">Contact Us</span></h1>
                                </div>
                                <div className="form-group my-4">

                                    <input type="text" className="form-control border border-dark contactForm" id="formName" placeholder="Name" />
                                </div>
                                <div className="form-group my-4">

                                    <input type="email" className="form-control border border-dark contactForm" id="formEmail" placeholder="Email" />
                                </div>
                                <div className="form-group my-4">

                                    <input type="number" className="form-control border border-dark contactForm" id="formPhone" placeholder="Phone" />
                                </div>
                                <div className="form-group my-4">

                                    <button
                                        type="submit"
                                        className="btn btn-lg text-light cmn-t-shake rounded"
                                        style={{background:'#9C90D8'}}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                        {/* <!-- form section start --> */}
                        <div className=" contactform pb-5 shadow mb-5 mt-3">
                            <div style={{ backgroundImage: "linear-gradient(to bottom,rgb(240 210 240) 0%, #FFFFFF 100% )"}}>
                                <h4 className="pt-5 text-center">
                                    <span style={{ fontSize: "30px", fontFamily: "myFont" }}>Have a Question?</span>
                                </h4>
                                <p className="text-center lato">
                                    {contact?.formdescription}
                                </p>
                            </div>
                            <form onSubmit={handleFormsubmit} className="formpad pt-3">
                                <div className="row p-4 mt-2">
                                    <div className="col-lg-6 col-sm-12 col-md-6 ">
                                        <label for="name" className="fw-bold lato">
                                            Full Name
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formdata.name}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            required
                                        />
                                    </div>

                                    <div className="col-lg-6 col-sm-12 col-md-6  ">
                                        <label for="email" className="fw-bold lato">
                                            Email Address
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formdata.email}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-6 pt-3 ">
                                        <label for="phone" className="fw-bold lato">
                                            Phone Number
                                        </label>
                                        <input
                                            type="number"
                                            name="phone"
                                            value={formdata.phone}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-md-6 pt-3 ">
                                        <label for="subject" className="fw-bold lato">
                                            Subject
                                        </label>
                                        <input
                                            type="text"
                                            name="subject"
                                            value={formdata.subject}
                                            onChange={handleInputChange}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className="col-lg-12 col-sm-12 col-md-12 ">
                                        <label for="comment" className="fw-bold lato">
                                            Your Message
                                        </label>
                                        <textarea
                                            className="form-control"
                                            name="message"
                                            value={formdata.message}
                                            onChange={handleInputChange}
                                            rows="4"
                                            id="comment"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <a >
                                        <button
                                            type="submit"
                                            className="btn shadowbuttons btn-lg text-light cmn-t-shake rounded-pill mx-3"

                                        >
                                            Send Message
                                        </button>
                                    </a>
                                </div>
                            </form>
                        </div>
                    </div>

                    {/* <!-- form section end --> */}
                </div>
            </section>


        </>
    );
};

export default ContactUs;
