import React, { useState, useEffect, useRef } from "react";
import bg from "../images/bg-3-firstscreen-copyright-1-scaled 1.png";
import { useParams } from "react-router-dom";

import img3 from "../images/image-3.png";
import relatedimg from "../images/blishrelated.png";
import blue from "../images/Blissful-living-page-icon.png";
import Testimonials from "./Testimonials";
import OrderModal from "../Payments/OrderModal";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getmyOrder } from "../Allslices/orderSlice";
import moment from "moment";
import Slider from "react-slick";

import {
  getAllCourses,
  getcoursedetails,
  getcoursebanner,
  promoVideo,
} from "../Allslices/courseSlice";
import MetaData from "../component/layout/MetaData";
import { getTestimonials } from "../Allslices/homeSlice";
import useGeolocation from "../component/shared/UserGeolocation";

export default function Blissful() {
  const [buynow, setbuynow] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const currentpathUrl = location.pathname;

  const navigate = useNavigate();
  const { courseDetails, coursePromo } = useSelector((state) => state.course);
  const { user } = useSelector((state) => state.auth);
  const { orderAll } = useSelector((state) => state?.order);
  const mainkey = currentpathUrl.split("/")[1];
  const slug = currentpathUrl.split("/")[2];
  // const [coursePurchased, setcoursePurchased] = useState({});
  const singleCourse = courseDetails?.course;
  const orderType = "course";
  console.log(
    "singleCourse?.courseBenifits?.CourseBenifitList",
    singleCourse?.courseBenifits?.CourseBenifitList
  );

  let coursePurchased = null;
  for (const order of orderAll) {
    if (order?.orderItems?.product === courseDetails?.course?._id) {
      coursePurchased = order;
      break;
    }
  }
  const maxLength = 170;
  function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          right: "-15px!important",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowRight color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          left: "-35px",
          height: "40px", // Increased height
          width: "40px", // Increased width
          zIndex: "1",
        }}
        onClick={onClick}
      >
        {/* <FaArrowLeft color="grey" size={24} /> Adjusted size */}
      </div>
    );
  }

  useEffect(() => {
    if (singleCourse?.coming_soon || singleCourse?.status === false) {
      navigate("/courses");
    }
  }, [singleCourse]);

  const calculateDaysLeft = (coursePurchased) => {
    const createdAt = moment(coursePurchased?.createdAt);

    const expiryDate = moment(createdAt).add(
      coursePurchased?.orderItems?.monthValidity || 0,
      "months"
    );

    const now = moment();
    const daysLeft = expiryDate.diff(now, "days");

    const futureDate = moment(now).add(daysLeft, "days");
    const formattedDate = futureDate.format("YYYY-MM-DD");

    return daysLeft;
  };

  const formatDaysLeft = (days) => {
    if (days > 1) {
      return `${days} days Left`;
    } else if (days === 1) {
      return `1 day left`;
    } else {
      return `Expired`;
    }
  };

  let isExpired = false;
  if (coursePurchased === null) {
    isExpired = false;
  } else {
    if (formatDaysLeft(calculateDaysLeft(coursePurchased)) === "Expired") {
      isExpired = true;
    } else {
      isExpired = false;
    }
  }

  const handleOtherCousrse = (item) => {
    navigate(`/courses/${item?.slug}`);
  };

  const handlebuynow = () => {
    // For the Renew Functionality
    if (isExpired && user) {
      setbuynow(true);
    }
    // If the course is not purchased and user is not logged in
    else if (coursePurchased === null) {
      if (!user) {
        navigate(`/login?key=${mainkey}&slug=${slug}`);
      } else {
        setbuynow(true);
      }
    }
    // if the course is not expired or user is logged in
    else {
      navigate(`/course/${slug}`, {
        state: {
          orderid: `${coursePurchased._id}`,
          productId: `${coursePurchased?.orderItems?.product}`,
        },
      });
    }
  };

  useEffect(() => {
    dispatch(getcoursedetails(slug));
    dispatch(promoVideo(slug));
    dispatch(getmyOrder());
  }, [slug]);

  const sellingPriceINR = singleCourse?.selling_price_rupee;
  const mrpPriceINR = singleCourse?.mrp_price_rupee;
  const renewPriceINR = singleCourse?.renew_price_rupee;

  const sellingPriceUSD = singleCourse?.selling_price_usd;
  const mrpPriceUSD = singleCourse?.mrp_price_usd;
  const renewPriceUSD = singleCourse?.renew_price_usd;
  const userGeolocationCode = useGeolocation();

  const isIndianUser = userGeolocationCode === "IN";

  console.log(
    "userGeoLocation and IsIndianUser value is:",
    userGeolocationCode,
    isIndianUser
  );

  const renderPrice = () => {
    const sellingPrice = isIndianUser || !userGeolocationCode? sellingPriceINR : sellingPriceUSD;
    const mrpPrice = isIndianUser || !userGeolocationCode? mrpPriceINR : mrpPriceUSD;
    const currencySymbol = isIndianUser || !userGeolocationCode? "₹" : "$";

    if (sellingPrice === mrpPrice) {
      return `Enroll Course (${currencySymbol}${sellingPrice})`;
    } else if (sellingPrice < mrpPrice) {
      return (
        <>
          Enroll Course{" "}
          <span style={{ textDecoration: "line-through", color: "white" }}>
            {currencySymbol}
            {mrpPrice}
          </span>{" "}
          ({currencySymbol}
          {sellingPrice}){" "}
        </>
      );
    } else {
      return `Enroll Course (${currencySymbol}${sellingPrice})`;
    }
  };

  const dispatch3 = useDispatch();
  let sliderRef1 = useRef();

  const minSlidesToShow = Math.min(4);
  let gridColumnClass = "col-md-4 col-sm-12 col-lg-3";

  if (minSlidesToShow === 3) {
    gridColumnClass = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass = "col";
  }
  const settingsForTrendingVideo = {
    infinite: false,
    autoplay: true,
    autoplaySpeed: 6000,
    speed: 700,
    slidesToShow: minSlidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const minSlidesToShow1 = Math.min(3);
  let gridColumnClass1 = "col-md-4 col-sm-12 col-lg-3";

  if (minSlidesToShow1 === 3) {
    gridColumnClass1 = "col-md-4 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 2) {
    gridColumnClass1 = "col-md-3 col-sm-12 col-lg-3";
  } else if (minSlidesToShow === 1) {
    gridColumnClass1 = "col";
  }
  const settings1 = {
    infinite: true,
    autoscroll: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: minSlidesToShow1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  console.log(
    "The CoursePROMO Video Value is:",
    singleCourse?.courseCertificateSection
  );

  return (
    <>
      {/* banner section */}
      <MetaData
        title={singleCourse?.courseMetaDetials?.meta_title}
        description={singleCourse?.courseMetaDetials?.meta_description}
        tags={singleCourse?.courseMetaDetials?.meta_tags}
      />
      <div
        className="aboutban2 myblishh "
        style={{
          backgroundImage: `url("${singleCourse?.beforestartbanner_image}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="container aboutpadiw">
          <div className="row pb-5">
            <div className="clo-lg-6 col-md-6 col-12">
              <div className=" py-3">
                <h1 className="fw-bold">
                  <span
                    style={{
                      fontSize: "larger",
                      color: singleCourse?.bannerTitleColor,
                    }}
                  >
                    {singleCourse?.title}
                  </span>
                </h1>
                <p
                  className="pt-3 fw-bold aboutusdes"
                  // style={{color:singleCourse?.bannerDesColor }}
                  dangerouslySetInnerHTML={{
                    __html: singleCourse?.shortDescription,
                  }}
                ></p>
                <div className="pt-3">
                  {/* <a href="">
                <button
                  className="btn btn-orange px-4 btn-lg text-light rounded-0 cmn-t-shake "
                  style={{ backgroundColor: "#FF9933!important" }}
                >
                  Learn More
                </button>
              </a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 d-flex justify-content-center align-items-center">
              <iframe
                // width="100%"
                // height="300"
                src={singleCourse?.bannerVideoUrl}
                title={singleCourse?.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                className="singlevideoiframe"
                allowFullScreen
                style={{
                  borderRadius: "22px",
                  // boxShadow: " -2px 1px 26px -1px rgba(0,0,0,1)"
                }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      {/* banner section end */}
      {/* beloved solution */}
      <section>
        <div className=" container dayremark mt-5">
          <p
            className="text-center justifyyy1 mytopdd "
            dangerouslySetInnerHTML={{ __html: singleCourse?.longDescription }}
          ></p>
        </div>
      </section>

      <section>
        <div className="container">
          <h1
            className="text-center benifit "
            dangerouslySetInnerHTML={{
              __html: singleCourse?.courseBenifits?.title,
            }}
          ></h1>
          <p
            className="text-center justifyyy1"
            dangerouslySetInnerHTML={{
              __html: singleCourse?.courseBenifits?.description,
            }}
          ></p>
          <div className="row ">
            {Array.isArray(singleCourse?.courseBenifits?.CourseBenifitList) &&
              singleCourse?.courseBenifits?.CourseBenifitList.map(
                (element, index) => (
                  <div
                    key={`${index}`}
                    className="col-lg-4 col-md-4 col-sm-12 "
                  >
                    <div className="image-container text-center">
                      {!element?.link?.trim() || element.link.trim() === "#" ? (
                        <p>
                          <img
                            src={element?.image}
                            alt="Table Full of Spices"
                            className="w-100 mb-2 mb-md-4 shadow-1-strong gallery-image"
                          />
                          <h1
                            className="image-heading position-relative"
                            style={{
                              bottom: "8rem",
                              fontFamily: "myFont",
                              color: "white",
                            }}
                          >
                            {element?.title}
                          </h1>
                        </p>
                      ) : (
                        <a href={element?.link.trim()}>
                          <img
                            src={element?.image}
                            alt="Table Full of Spices"
                            className="w-100 mb-2 mb-md-4 shadow-1-strong gallery-image"
                          />
                          <h1
                            className="image-heading position-relative"
                            style={{
                              bottom: "8rem",
                              fontFamily: "myFont",
                              color: "white",
                            }}
                          >
                            {element?.title}
                          </h1>
                        </a>
                      )}
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </section>

      {/* <div className="container">
        <div className="row ">
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <div className="image-container text-center">
              <a>
                <img
                  src={relatedimg}
                  alt="Table Full of Spices"
                  className="w-100 mb-2 mb-md-4 shadow-1-strong gallery-image"
                />
                <h4
                  className="image-heading position-relative "
                  style={{ bottom: "3.5rem" }}
                >
                  Hello
                </h4>
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <div className="image-container text-center">
              <a>
                <img
                  src={relatedimg}
                  alt="Table Full of Spices"
                  className="w-100 mb-2 mb-md-4 shadow-1-strong gallery-image"
                />
                <h4
                  className="image-heading position-relative "
                  style={{ bottom: "3.5rem" }}
                >
                  Hello
                </h4>
              </a>
            </div>
            <a href="#"></a>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 ">
            <div className="image-container text-center">
              <a>
                <img
                  src={relatedimg}
                  alt="Table Full of Spices"
                  className="w-100 mb-2 mb-md-4 shadow-1-strong gallery-image"
                />
                <h4
                  className="image-heading position-relative "
                  style={{ bottom: "3.5rem" }}
                >
                  Hello
                </h4>
              </a>
            </div>
            <a href="#"></a>
          </div>
        </div>
      </div> */}

      <section>
        <div
          className="container-fluid text-center d-flex flex-column align-items-center justify-content-center"
          style={{
            backgroundImage: `url("${singleCourse?.coursePurchaseSection?.banner_image}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "450px",
            aspectRatio: "3/4",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1
                  className="fw-5 text-white purchestitlr"
                  dangerouslySetInnerHTML={{
                    __html: singleCourse?.coursePurchaseSection?.title,
                  }}
                ></h1>
                <p
                  className="text-center purchesdes px-2"
                  dangerouslySetInnerHTML={{
                    __html: singleCourse?.coursePurchaseSection?.description,
                  }}
                ></p>
              </div>
            </div>
          </div>

          <section style={{ textAlign: "center" }}>
            {isExpired && user ? (
              <div className="pt-5">
                <a>
                  <button
                    className="btn px-5 py-3 btn-lg text-light cmn-t-shake buttonsColor"
                    data-bs-toggle={`${user ? "modal" : ""}`}
                    data-bs-target="#staticBackdrop"
                    style={{
                      backgroundColor: "#FF9933!important",
                      borderRadius: "20px",
                    }}
                    onClick={handlebuynow}
                  >
                    Renew Course ({isIndianUser || !userGeolocationCode?  "₹":"$"}
                    {isIndianUser || !userGeolocationCode ?   renewPriceINR:renewPriceUSD})
                  </button>
                </a>
              </div>
            ) : (
              <div className="pt-5">
                <a>
                  <button
                    className="btn px-xs-4 px-md-5 px-lg-5  py-3 btn-lg text-light cmn-t-shake buttonsColor"
                    data-bs-toggle={`${
                      user && coursePurchased == null ? "modal" : ""
                    }`}
                    data-bs-target="#staticBackdrop"
                    style={{
                      backgroundColor: "#FF9933!important",
                      borderRadius: "30px",
                    }}
                    onClick={handlebuynow}
                  >
                    {coursePurchased === null
                      ? sellingPriceINR > 0
                        ? renderPrice()
                        : "Get Now (Free)"
                      : "Start Here"}
                  </button>
                </a>
              </div>
            )}
          </section>
        </div>
      </section>
      {/* how does this coures empower your life */}

      {/* Course Features Section */}
      <section>
        <div className="container py-4">
          <h1
            className="text-center featuretitle"
            dangerouslySetInnerHTML={{
              __html: singleCourse?.courseFeatures?.title,
            }}
          ></h1>
          <p
            className="text-center justifyyy1"
            dangerouslySetInnerHTML={{
              __html: singleCourse?.courseFeatures?.description,
            }}
          ></p>
          <div className="row ">
            {Array.isArray(singleCourse?.courseFeatures?.CourseFeatureList) &&
              singleCourse?.courseFeatures?.CourseFeatureList.map(
                (element, index) => (
                  <div
                    className="col-lg-3 col-md-4 col-sm-12  my-2"
                    key={index}
                  >
                    <div className="image-container text-center d-flex flex-column p-3 courseimgsblue align-items-center featurecard">
                      <img
                        src={element.image}
                        alt="Table Full of Spices"
                        className="m-2"
                      />
                      <h4
                        className=" "
                        style={{ fontFamily: "myfont", fontSize: "25px" }}
                      >
                        {element.title}
                      </h4>
                      <p className="featurdesd">
                        {element?.description?.length > maxLength
                          ? `${element?.description.substring(0, maxLength)}...`
                          : element.description}
                      </p>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
      </section>

      {/* Course Certificate Section Start*/}

      {singleCourse?.courseCertificateSection?.certificate_status === true ? (
        <section>
          <div className="container py-4">
            <h1
              className="fw-5 text-center"
              style={{ fontFamily: "myFont" }}
              dangerouslySetInnerHTML={{
                __html: singleCourse?.courseCertificateSection?.title,
              }}
            ></h1>
            <p
              className="text-center justifyyy1"
              style={{ fontFamily: "lato" }}
              dangerouslySetInnerHTML={{
                __html: singleCourse?.courseCertificateSection?.description,
              }}
            ></p>
          </div>
          <div
            className="container-fluid py-4"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <div className="container">
              <div className="row mx-auto">
                <div className="col-lg-6 col-md-6 col-sm-12  my-2">
                  <div className="image-container text-center d-flex flex-column align-items-center justify-content-center">
                    <img
                      src={
                        singleCourse?.courseCertificateSection
                          ?.certificate_image
                      }
                      alt="Certificate_Image"
                      className="my-3"
                      // style={{ aspectRatio: "16/9" }}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 my-2 d-flex">
                  <div className="image-container text-center d-flex flex-column align-items-center justify-content-center">
                    <h2
                      className="featuretitle"
                      style={{ fontFamily: "myfont" }}
                    >
                      {
                        singleCourse?.courseCertificateSection
                          ?.certificate_title
                      }
                    </h2>
                    <p className="px-2">
                      {
                        singleCourse?.courseCertificateSection
                          ?.certificate_description
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}

      {/* Course Certificate Section End */}

      {/* <Testimonials /> */}
      {singleCourse?.courseTestimonial.length > 0 && (
        <section>
          <div
            className="container beloved pt-5 mt-2"
            style={{ paddingTop: "20px" }}
          >
            <h3
              className="haedingfont text-center"
              style={{ fontFamily: "myFont", fontSize: "20px" }}
            >
              Testimonials
            </h3>
            <div
              className="container"
              style={{ background: "rgba(244, 182, 227, 0.2)" }}
            >
              <div className="row mb-5 d-flex justify-content-center mx-2">
                <Slider
                  ref={(slider) => {
                    sliderRef1 = slider;
                  }}
                  {...settings1}
                >
                  {Array.isArray(singleCourse?.courseTestimonial) &&
                    singleCourse?.courseTestimonial.map(
                      (element, innerIndex) => (
                        <div
                          key={`${innerIndex}-${innerIndex}`}
                          className="col-md-6 col-sm-12 col-lg-4"
                          style={{ cursor: "pointer" }}
                        >
                          {/* <div
                        className="card item-card text-dark my-3 mx-2"
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <div className="customer_comment lato"style={{fontFamily:"lato"}}>
                          {element?.description}
                        </div>
                        <div
                          className="image_review"
                          style={{ padding: "15px" }}
                        >
                          <div className="customer_image">
                            <img src={element.user_image} />
                          </div>
                          <div className="customer_name_review_status pt-2">
                            <div className="customer_name ">
                              {element?.name}
                            </div>
                          </div>
                          <div className="customer_name_review_status ">
                            <div
                              className="customer_name lato"
                              style={{ color: "gray" }}
                            >
                              {element?.designation}
                            </div>
                          </div>
                        </div>
                      </div> */}

                          <div
                            className="card item-card text-dark my-3 mx-2 card_heighttest"
                            style={{
                              borderRadius: "20px",
                            }}
                          >
                            <div className="customer_comment lato">
                              {element?.description}
                            </div>
                            <div
                              className="image_review"
                              style={{ padding: "15px" }}
                            >
                              <div className="customer_image">
                                <img src={element.user_image} />
                              </div>
                              <div className="customer_name_review_status pt-2">
                                <div className="customer_name ">
                                  {element?.name}
                                </div>
                              </div>
                              <div className="customer_name_review_status ">
                                <div
                                  className="customer_name lato"
                                  style={{ color: "gray" }}
                                >
                                  {element?.designation}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </Slider>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* promo videos */}
      <section>
        <div className="container py-3" style={{ paddingTop: "20px" }}>
          {Array.isArray(coursePromo) && coursePromo.length > 0 && (
            <h1
              className="headingfont text-center mb-2"
              style={{ fontFamily: "myFont" }}
            >
              Other Courses
            </h1>
          )}
          <div className="row mb-5 d-flex justify-content-center mx-2">
            <Slider
              ref={(slider) => {
                sliderRef1 = slider;
              }}
              {...settingsForTrendingVideo}
            >
              {Array.isArray(coursePromo) &&
                coursePromo.map((item, innerIndex) => (
                  <div key={innerIndex} className="item">
                    <div className="position-relative px-1 trandingvideo">
                      <div
                        className="overlay"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          zIndex: 1,
                        }}
                        onClick={() => handleOtherCousrse(item)}
                      ></div>
                      <iframe
                        style={{ borderRadius: "10px" }}
                        width="100%"
                        height="150px"
                        src={item?.bannerVideoUrl}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </section>

      <OrderModal
        orderType={orderType}
        product={buynow ? singleCourse : ""}
        navigate={true}
        isExpired={isExpired}
      />
      {/* <OrderModal product={buynow ? { ...singleCourse, orderType } : ""} /> */}
    </>
  );
}
