import React, { useCallback, useEffect, useState } from "react";
import ordersuccess from "../images/ordersuccess.png";
import bg from "../images/ordersuccessbg.png";
import { Link } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Rectangle74 from '../assets/Rectangle74.png';
import Background1 from "../../src/images/profilebg1.png";
import "../component/layout/style.css";
import "../component/auth/auth.css";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";
import {
  getuserintrest,
  loadUser,
  updateUserProfile,
} from "../component/auth/authSlice";
// import { updateUserPROFILE } from "../../src/component/auth/updateUserAction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import Quill styles

import Moment from "react-moment";
import moment from "moment";
import AppCarousel from "../component/shared/AppCarousel";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { getmyOrder } from "../Allslices/orderSlice";
import {
  getuserAnswers,
  getAnswerResetPage,
  getuserQuestionsandThoughts,
} from "../component/auth/authSlice";

import { getcoinsValue } from "../Allslices/coinsSlice";
import { fetchPostResetPage, fetchPosts } from "../Allslices/communitySlice";
import { SHOW_ERROR_NOTIFICATION } from "../appUtils/appToast";
import MetaData from "../component/layout/MetaData";
const OrderSuccessfull = () => {
  const [music, setMusic] = useState(false);
  const [song, setsong] = useState(false);
  const [overview, setOverview] = useState(true);
  const dispatch = useDispatch();
  const [question, setQuestions] = useState(false);
  const [showOrder, setShowOrder] = useState([]);
  const [course, setCourse] = useState(false);
  const [answers, setAnswers] = useState(false);
  const [thought, setThoughts] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [bhagwat, setBhagwat] = useState(false);
  const [wellness, setWellness] = useState(false);
  const [events, setEvents] = useState(false);
  const [podcast, setPodcast] = useState(false);

  const [page, setPage] = useState(0);
  const { orderAll } = useSelector((state) => state?.order);
  const orderslug = orderAll
    .filter((order) => order.orderType === "course")
    .flatMap((order) => order);

  const courseslug1 = orderslug.map((item) => item);
  const ordermusic = orderAll
    .filter((order) => order.orderType === "music")
    .flatMap((order) => order.orderItems);
  const musicslug1 = ordermusic.map((item) => item.slug);

  const ordersong = orderAll
    .filter((order) => order?.orderType == "song")
    .flatMap((order) => order.orderItems);
  const songslug1 = ordersong.map((item) => item.slug);
  const songParentId = ordersong[0]?.songParentId;

  const orderwellness = orderAll
    .filter((order) => order?.orderType == "wellness")
    .flatMap((order) => order.orderItems);
  const wellnessslug1 = orderwellness.map((item) => item.slug);

  const orderevents = orderAll
    .filter((order) => order?.orderType == "events")
    .flatMap((order) => order.orderItems);
  const eventslug1 = orderevents?.map((item) => item?.slug);
const eventparent =orderevents?.map((item) => item?.eventParent);
  const {
    user,
    userQuestionandThoughtlist,
    userAnswer,
    currentPage,
    totalPages,
    intrest,
    isProfileUpdated,
  } = useSelector((state) => {
    return state.auth;
  });

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const key = searchParams.get("key");
  const slug = searchParams.get("slug");

  const handleOrder = (orderType) => {
    const myOlderList = orderAll?.filter(
      (item) => item?.orderType === orderType
    );

    setShowOrder(myOlderList);
  };

  const {
    fetchPostState: {
      loading: fetchPostloading,
      data: fetchPostData,
      page: postPage,
      hideLoadMore,
    },
  } = useSelector((state) => state.community);

  const handleOnClicknavigation = (params) => {
    // navigate('/music_favourites')

    setMusic(false);
    setsong(false);
    setCourse(false);
    setPodcast(false);
    setBhagwat(false);
    setWellness(false);
    setEvents(false);
    setOverview(false);
    setQuestions(false);
    setAnswers(false);
    setThoughts(false);
    setActiveTab("");

    switch (params) {
      case "music":
        setMusic(true);
        break;
      case "song":
        setsong(true);
        break;
      case "podcast":
        setPodcast(true);
        break;
      case "bhagwat":
        setBhagwat(true);
        break;
      case "wellness":
        setWellness(true);
        break;
      case "events":
        setEvents(true);
        break;
      // // // case "donation":
      // //   setDonation(true);
      //   break;
      case "overview":
        setOverview(true);
        break;
      case "question":
        setQuestions(true);
        break;
      case "answers":
        setAnswers(true);
        break;
      case "thought":
        setThoughts(true);
        break;
      case "course":
        setCourse(true);
    }
  };

  const [formdata, setFormData] = useState({
    username: "",
    userimage: "",
    useroccupation: "",
    userintrest: "",
    userbio: "",
  });
  // useEffect(()=>{
  // if(key=='questions'){
  //   setQuestions(true);
  // }
  // else if(key=='answers'){
  //   setAnswers(true);
  // }
  // },[key])

  useEffect(() => {
    if (user) {
      // Initialize form data with user details
      setFormData({
        username: user?.name,
        userimage: user?.image,
        useroccupation: user?.occupation,
        userintrest: user?.interest?._id, // Corrected from userintrest to userinterest
        userbio: user?.bio,
      });
    }
    dispatch(getuserintrest());
  }, [user, dispatch]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append(name, file);
      setFormData({ ...formdata, userimage: file });
    } else if (name === "userintrest") {
      // If no interest option is chosen, set userintrest to the already selected interest from the user's profile
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      const userintrest =
        selectedOptions.length === 0 ? formdata.userintrest : selectedOptions;
      setFormData({
        ...formdata,
        [name]: userintrest,
      });
    } else {
      setFormData({
        ...formdata,
        [name]: value,
      });
    }
  };

 

  const handleSubmit = (e) => {
    // const updatedUserData = {
    //   name: formdata.username,
    //   occupation: formdata.useroccupation,
    //   interest: formdata.userintrest,
    //   bio: formdata.userbio,
    //   image: formdata.userimage,
    // };

    e.preventDefault(); // Prevent default form submission behavior

    const updatedUserData = new FormData(); // Create FormData object
    updatedUserData.append("name", formdata.username);
    updatedUserData.append("occupation", formdata.useroccupation);
    updatedUserData.append("intrest", formdata.userintrest);
    updatedUserData.append("bio", formdata.userbio);
    updatedUserData.append("image", formdata.userimage); // Append file data

    dispatch(updateUserProfile(updatedUserData));
  };

  useEffect(() => {
    if (isProfileUpdated) {
      dispatch(loadUser());
    } else {
      dispatch(loadUser());
    }
  }, [dispatch, isProfileUpdated]);

  useEffect(() => {
    dispatch(getuserintrest());
  }, [user, dispatch]);

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  useEffect(() => {
    dispatch(getmyOrder());
    dispatch(getcoinsValue());

    dispatch(fetchPostResetPage());
    dispatch(getAnswerResetPage());
  }, [dispatch]);

  const [loader, setLoader] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [waitResponse, setWaitResponse] = useState(false);

  const userId = localStorage.getItem("userId");

  const callFetchPost = useCallback((type) => {
    dispatch(fetchPosts({ type, user: userId })).then((resultAction) => {
      if (resultAction?.error) {
        SHOW_ERROR_NOTIFICATION(resultAction.payload);
        return;
      }
    });
  }, []);
  useEffect(() => {
    if (key === null) {
      setOverview(true);
    } else {
      handleOnClicknavigation(key);
      callFetchPost(key);
      handleOrder(key);
    }
  }, [key]);
  const handleLoadMoreAnswers = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (currentPage != totalPages) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [currentPage, totalPages]);
  useEffect(() => {
    if (user?._id)
      dispatch(getuserAnswers({ userId: user._id, page: page })).then(
        (resultAction) => {
          if (resultAction?.error) {
            SHOW_ERROR_NOTIFICATION(resultAction.payload);
            return;
          }
          if (resultAction.payload?.length < 10) {
            setHasMoreData(false);
          } else {
            setHasMoreData(true);
          }
        }
      );
  }, [page]);

  // const [daysLeft, setDaysLeft] = useState([]);

  // const [initialReloadDone, setInitialReloadDone] = useState(false);

  const uniqueOrderTypes = [...new Set(orderAll.map((item) => item.orderType))];

  useEffect(() => {
    const reloadTracker = sessionStorage.getItem("hasReloaded");

    if (!reloadTracker) {
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  }, []);

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(`/course/${courseslug1[0]?.orderItems?.slug}`, {state: {orderid: `${courseslug1[0]?._id}`, productId: `${courseslug1[0]?.orderItems?.product}`}});
    
  };
  const handleMusicNavigation = () => {
    navigate(`/music/${musicslug1[0]}`);
  };
  const handleSongNavigation = () => {
    navigate(`/music/${songParentId}/${songslug1[0]}`);
  };
  const handleWellnessNavigation = () => {
    navigate(`/wellness/${wellnessslug1[0]}`);
  };
  const handleEventsNavigation = () => {
    navigate(`/events/eventdetails/${eventparent[0]}/${eventslug1[0]}`);
  };
  return (
    <>
      <MetaData title="Order Successful" />
      <div
        className="d-flex align-items-center justify-content-center flex-column"
        style={{
          paddingTop: "150px",
          paddingBottom: "100px",
          backgroundImage: `url(${bg})`,
          position: "center",
          backgroundSize: "cover",
        }}
      >
        <img src={ordersuccess} alt="" className="myordersucces" />
        <h3 className="mt-5" style={{ color: "#B767A2" }}>
          Payment Successfull!
        </h3>
        <p>Your order is confirmed</p>

        {orderAll[0]?.orderType === "course" && (
          <button
            type="button"
            className="btn btn-lg my-3 shadowbuttons fs-4"
            style={{
              backgroundColor: "#B767A2",
              color: "white",
              width: "200px",
            }}
            onClick={handleNavigation}
          >
            Start Here
          </button>
        )}

        <div>
          {orderAll[0]?.orderType === "music" && (
            <button
              type="button"
              className="btn btn-lg my-3 shadowbuttons fs-4"
              style={{
                // backgroundColor: "#B767A2",
                color: "white",
              }}
              onClick={handleMusicNavigation}
            >
             Go to Music Album
            </button>
          )}
          {orderAll[0]?.orderType === "song" && (
            <button
              type="button"
              className="btn btn-lg my-3 shadowbuttons fs-4"
              style={{
                // backgroundColor: "#B767A2",
                color: "white",
                width: "250px",
              }}
              onClick={handleSongNavigation}
            >
              Go to Song
            </button>
          )}

          {orderAll[0]?.orderType === "wellness" && (
            <button
              type="button"
              className="btn btn-lg my-3 shadowbuttons fs-4"
              style={{
                // backgroundColor: "#B767A2",
                color: "white",
                width: "200px",
              }}
              onClick={handleWellnessNavigation}
            >
              View Wellness
            </button>
          )}

          {orderAll[0]?.orderType === "events" && (
            <button
              type="button"
              className="btn btn-lg my-3 shadowbuttons fs-4"
              style={{
                // backgroundColor: "#B767A2",
                color: "white",
                width: "200px",
              }}
              onClick={handleEventsNavigation}
            >
              View Event
            </button>
          )}
          {uniqueOrderTypes.map((orderType, index) => {
            switch (orderType) {
              // case "course":
              // return (
              //   <Link to="/profile?key=course" key={index}>
              //     <button
              //       type="button"
              //       className="btn btn-lg my-3 rounded-0 fs-4"
              //       style={{
              //         backgroundColor: "#B767A2",
              //         color: "white",
              //         width: "200px",
              //       }}
              //       onClick={() => handleOrder("course")}
              //     >
              //       Order Course
              //     </button>
              //     <br />
              //   </Link>
              // );

              // case "music":
              //   return (
              //     <Link to="/profile?key=music" key={index} >
              //      { orderAll[0]?.orderType =='music' && (
              //        <button
              //        type="button"
              //         className="btn btn-lg my-3 rounded-0 fs-4"
              //         style={{
              //           backgroundColor: "#B767A2",
              //           color: "white",
              //           width: "200px",
              //         }}
              //         onClick={() => handleOrder("music")}
              //       >
              //         Order Music
              //       </button>) }

              //     </Link>
              //   );

              // case "wellness":
              //   return (
              //     <Link to="/profile?key=wellness" key={index}>
              //           {orderAll[0]?.orderType ==='wellness' && (
              //             <button
              //         type="button"
              //         className="btn btn-lg my-3 rounded-0 fs-4"
              //         style={{
              //           backgroundColor: "#B767A2",
              //           color: "white",
              //           width: "200px",
              //         }}
              //         onClick={handleWellnessNavigation}
              //       >
              //         Order Wellness
              //       </button>
              //           )}
              //     </Link>
              //   );

              // case "events":
              //   return (
              //     <Link to="/profile?key=events" key={index}>
              //       {orderAll[0]?.orderType ==='events' && (

              //       <button
              //         type="button"
              //         className="btn btn-lg my-3 rounded-0 fs-4"
              //         style={{
              //           backgroundColor: "#B767A2",
              //           color: "white",
              //           width: "200px",
              //         }}
              //         onClick={() => handleOrder("events")}
              //       >
              //         Order Events
              //       </button>
              //       )}
              //     </Link>
              //   );
              case "bhagwat":
                return (
                  <Link to="/profile?key=bhagwat" key={index}>
                    {orderAll[0]?.orderType === "bhagwat" && (
                      <button
                        type="button"
                        className="btn btn-lg my-3 rounded-0 fs-4"
                        style={{
                          backgroundColor: "#B767A2",
                          color: "white",
                          width: "200px",
                        }}
                        onClick={() => handleOrder("bhagwat")}
                      >
                        Order bhagwat
                      </button>
                    )}
                  </Link>
                );

              default:
                return null;
            }
          })}
        </div>
      </div>
    </>
  );
};

export default OrderSuccessfull;
