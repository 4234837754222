import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AppCarousel.css';

const AppCarousel = ({images, id}) => {

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    return (
        <div className='content'>
            <Slider {...sliderSettings}>
                {images.map((image, index) => (
                    <img className="w-100 resize-img" alt="" key={`carousel_${id}_${index}`} src={image}   />
                ))}
            </Slider>
        </div>
    );
}

export default AppCarousel;