import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import onlineclass from "../../src/images/online_class.png";
import bgimg1 from "../../src/images/bgimg1.jpg";
import bgimg2 from "../../src/images/bgimg2.png";
import bgimg3 from "../../src/images/bgimg3.png";
import bgimg5 from "../../src/images/bgimg5.png";
import card1 from "../../src/images/card1.png";
import card2 from "../../src/images/card2.png";
import card3 from "../../src/images/card3.png";
import container3 from "../../src/images/container3.png";
import container4 from "../../src/images/container4.png";
import carousel1 from "../../src/images/carousel1.png";
import carousel2 from "../../src/images/carousel2.png";
import Carousel from "./SlideCarousel";
import ImageCarousel from "./ImageCarousel";
import BigHearts from "../../src/images/bighearts.png";
import partner1 from "../../src/images/Partner-1.png";
import partner2 from "../../src/images/Partner-2.png";
import partner3 from "../../src/images/Partner-3.png";
import partner4 from "../../src/images/Partner-4.png";
import partner5 from "../../src/images/Partner-5.png";
import partner6 from "../../src/images/Partner-6.png";
import AnnapurnaBg from '../../src/images/annapuranaBg.png'
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../component/layout/MetaData";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";


import {
  // getdonation,
  // getDonationCategory,
  getDonationPartner,
  getAllDonationCampaigns,
  getsingleCampaign,
  joinCampaign
} from "../Allslices/donationSlice";
import Slider from "react-slick";
import SmallLoader from "./SmallLoader";

const AnnapurnaYojana = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();


  useEffect(() => {
    dispatch(getsingleCampaign(slug));
    dispatch(getDonationPartner());
    dispatch(getAllDonationCampaigns());
  }, []);

  const sliderRef = useRef();
  const settings = {
    dots: false,
    infinite: true,
    autoscroll: true,
    speed: 500,
    slidesToShow:3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };
  const maxLength = 100;


  const { singlecampaign, partners,campaigns } = useSelector(
    (state) => state.donation
  );

  const navigate = useNavigate();
  const handleNavigateToSingleCampaign = (slug) => {
    navigate(`/donation/campaign/${slug}`)
  }

  const sliderRef2 = useRef();
  const settings2 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplayspeed: 6000,
    speed: 700,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };
  const initialFormData = {
    name: '',
    last_name: '',
    email: '',
  }

  const [formData, setFormData] = useState(initialFormData);
  const [email, setEmail] = useState('');
  const handleInputChange = (donation) => {
    const { name, value, type, checked, files } = donation.target;
    const updatedValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;
    if (name === 'email') {
      setEmail(updatedValue);
    }
    setFormData({
      ...formData,
      [name]: updatedValue
    });
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const formDataToSend = { ...formData };
  const handleSubmit = (e)=>{
    e.preventDefault();
    dispatch(joinCampaign({campaignJoinedUsers:formDataToSend}))
    setTimeout(() => {
      setFormData(initialFormData);
    }, 5000);
  }

  return (<>
    <MetaData title={singlecampaign?.title}/>
    <div style={{
      fontFamily: "Lato"
    }}>
      <div className="campionbanner" style={{ backgroundImage: `url(${singlecampaign?.banner_image})`, backgroundSize: "cover",height:"700px" }}>
        <div className="container wisdompad">
          <div className="row ">
          <div className="col-lg-7 col-xl-7 col-md-6 col-0">
            </div>
            <div className="col-lg-5 col-xl-5 col-md-6 col-sm-12">
              <h1 className="fw-bold text-start campiontext "><span style={{color: 'black',fontSize:'4rem' }}>
                {singlecampaign?.title}</span></h1>
              <p className="pt-3 text-dark fw-bold fs-5 " style={{textAlign:" justify"}}>{singlecampaign?.shortdescription}</p>
              
            </div>
          </div>
        </div>
      </div>


      <div className="container mt-2 pt-3">
        <div className="row ">
          <div className="col-md-6 col-12">
            <h1 className="text-start"><span style={{ fontSize: '2.5rem', color: '#FD6E12' }}>{singlecampaign?.section_1?.subtitle}</span></h1>

            <h3>{singlecampaign?.section_1?.heading}</h3>
            <p>
            {singlecampaign?.section_1?.description}
            </p>
            {/* Donate Now Button */}
            <button
              type="button"
              className="btn btn-lg mb-2 d-sm-5"
              style={{ backgroundColor: "#ff9933", color: "white" }}
            >
              DONATE NOW
            </button>
          </div>

          <div
            className="col-md-5" >
           <div className="d-flex"  >
              <div className=" d-flex align-items-end mb-3">
                <img
                  className="img-fluid rounded"
                  src={singlecampaign?.section_1?.image }
                  
             alt=""
                />
              </div>
            
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 m-0 ">
        <div
          className="wisdomimg"
          style={{
            backgroundImage: `url(${bgimg2})`,
            backgroundSize: "cover",
            height: "80vh",
          }}
        >
          <div className="container  text-center align-center">
            <div className="row ">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-light pt-5 ">
                <h1 className="py-3">
                  <span style={{ fontSize: "3.5rem" }}>Join The Campaign</span>
                </h1>

                <p className="fs-5 px-5" style={{ lineHeight: "2", color: "white" }}>
                  There are many variations of passages of orem Ipsum available,
                  but the majority have suffered alteration in some form, by
                  cted ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do usmod temponcid idunt ut labore et dolore magna aliqua. Ut
                  enim ad minim veniam, quis nostrud exercitation ullamco aboris
                  nisi.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="position-relative container-fluid px-5" style={{ bottom: "10rem"}}>
          <div className="carousel-container ">

          <Slider ref={sliderRef} {...settings}>
            {Array.isArray(campaigns) &&
              campaigns.map((item, innerIndex) => (
                    <div
                      key={`${innerIndex}-${innerIndex}`}
                      className="col-md-6 col-sm-12 col-lg-4 pb-3"
                      style={{ cursor: "pointer" }}
                      onClick={ ()=> handleNavigateToSingleCampaign(item.slug)}
                    >
                      <div
                        className="card item-card text-dark mx-2"
                        style={{
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          src={item?.thumbnail_image}
                          // src={item?.profile}
                          alt=""
                         
                          style={{ borderRadius: "20px",aspectRatio:"3/2" }}
                        />
                        <div className="container">
                          <h4
                            className="lato pt-3"
                            style={{ fontSize: "16px" }}
                          >
                            <Moment
                              className="fw-semibold"
                              format="D MMM YYYY"
                              withTitle
                            >
                              {item?.createdAt}
                            </Moment>
                          </h4>

                          <h4
                            className="mt-1 fs-4 text-capitalize"
                            style={{ fontFamily: "playfair", color: "black" }}
                          >
                            {item?.title?.length > maxLength
                              ? `${item.title.substring(0, maxLength)}...`
                              : item?.title}
                          </h4>
                          <p
                            className="card-text lato text-capitalize pb-3"
                            dangerouslySetInnerHTML={{
                              __html:
                                item?.shortdescription?.length > maxLength
                                  ? `${item.shortdescription.substring(
                                      0,
                                      maxLength
                                    )}...`
                                  : item?.shortdescription,
                            }}
                            style={{ fontFamily: "Lato", color: "#5B5B5B" }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            
          </div>
          
        </div>
      </div>

      <div className="container-fluid">
        <div className="row container">
          <div className="col-md-5 col-lg-5 col-12 p-4 mt-3">
            <img className="img-fluid d-sm-5 " src={singlecampaign?.section_2?.image} alt="" />
          </div>
          <div className="col-md-7 col-lg-7 col-12 px-5 text-dark">
            <h1 className="text-start"><span style={{ fontSize: '3rem', color: '#000' }}>{singlecampaign?.section_2?.heading}</span></h1>

            <p className="text-dark" dangerouslySetInnerHTML={{ __html: singlecampaign?.section_2?.description}}>
              
            </p>
            <div className="d-flex flex-nowrap gap-4 ms-md-4 ">
              <button
                type="button"
                className="btn btn-lg px-5"
                style={{
                  backgroundColor: "#ff9933",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                DONATE NOW
              </button>
              <button
                className="btn btn-lg px-md-5"
                type="button"
                style={{
                  border: "1px solid #ff9933",
                  color: "#ff9933",
                }}
              >
                VOLUNTEER
              </button>
            </div>
          </div>
        </div>
      </div>

        {/* <div className="container-fluid mt-5 pt-5">
          <div className="row container pb-5 ">
            <div className="col-md-6 col-12">
              <img
                className="img-fluid"
                src={container4}
                alt=""
                style={{ width: "70%" }}
              />
            </div>

            <div
              className="col-md-5 col-11 d-flex align-items-center mb-md-5 shadow-lg col-sm-12 ms-3 "
              style={{ borderRadius: "20px" }}
            >
              <form>
                <div>
                  <label htmlFor="" className="form-label fw-bold pt-5">
                    Your Donation
                  </label>
                  <div
                    className="btn-toolbar"
                    role="toolbar"
                    aria-label="Toolbar with button groups"
                  >
                    <div className="d-flex flex-wrap gap-2" role="group">
                      <button type="button" className="btn border rounded-0 text-dark">
                        $500.00
                      </button>
                      <button type="button" className="btn border rounded-0 text-dark">
                        $200.00
                      </button>
                      <button type="button" className="btn border rounded-0 text-dark">
                        $150.00
                      </button>
                      <button type="button" className="btn border rounded-0 text-dark">
                        $100.00
                      </button>
                      <button type="button" className="btn border rounded-0 text-dark">
                        $50.00
                      </button>
                    </div>
                  </div>
                </div>

                <div className="d-flex mt-3 gap-4">
                  <div className="border p-2" style={{ width: "50%" }}>
                    <span className="ms-3">Custom amount</span>
                  </div>
                  <div style={{ width: "50%" }}>
                    <input
                      className="p-2"
                      style={{ width: "100%", border: "1px solid #dadada" }}
                      type="number"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="form-label mt-4 fw-bold">
                    Details
                  </label>

                  <div className="row ">
                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">
                        First Name*
                      </label>
                      <div style={{ width: "100%" }}>
                        <input
                          className="p-2"
                          style={{ width: "100%", border: "1px solid #dadada" }}
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">
                        Last Name*
                      </label>
                      <div style={{ width: "100%" }}>
                        <input
                          className="p-2"
                          style={{ width: "100%", border: "1px solid #dadada" }}
                          type="text"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">
                        Email*
                      </label>
                      <div style={{ width: "100%" }}>
                        <input
                          className="p-2"
                          style={{ width: "100%", border: "1px solid #dadada" }}
                          type="email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">
                        Phone Number
                      </label>
                      <div style={{ width: "100%" }}>
                        <input
                          className="p-2"
                          style={{ width: "100%", border: "1px solid #dadada" }}
                          type="tel"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <label htmlFor="" className="form-label fw-bold mt-4">
                    Payment
                  </label>
                  <div>
                    Choose Your Payment Method
                    <div className="d-flex">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          Offline Donation
                        </label>
                      </div>
                      <div className="form-check ms-4">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          PayPal
                        </label>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-lg fs-6 mt-3 mb-4 d-sm-5"
                    style={{ backgroundColor: "#ff9933", color: "white" }}
                  >
                    DONATE NOW
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div> */}

      <div
        className="container-fluid py-5"
        style={{
          backgroundImage: `url(${bgimg3})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <h3 className="text-center py-5">Success Stories</h3>
        <div className="container">
          <Carousel />
        </div>
      </div>
      {/* <ImageCarousel /> */}

      <div className="container pb-5">
        <h2 className="fs-4 text-center m-5">
          Join Us To Learn How You Can Help Make Change For Children
        </h2>

        <form className="row g-4 "onSubmit={handleSubmit}>
               
               <div className="col-lg-3 col-md-6 col-sm-12">
                 <label htmlFor="" className="form-label lato fw-semibold">
                   First Name
                 </label>
                 <input
                 value={formData.name}
                   className="p-2 w-100 border border-0 shadow rounded-pill"
                   style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
                   placeholder="First Name"
                   type="text"
                   name="name"
                   onChange={handleInputChange}
                 />
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12">
                 <label htmlFor="" className="form-label lato fw-semibold">
                   Last Name
                 </label>
                 <input
                 value={formData.last_name}
                 name="last_name"
                   placeholder="Last Name"
                   className="p-2 w-100 border border-0 shadow rounded-pill"
                   style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
                   type="text"
                   onChange={handleInputChange}
                 />
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12">
                 <label htmlFor="" className="form-label lato fw-semibold">
                   Email Address
                 </label>
                 <input
                 value={formData.email}
                 name="email"
                   placeholder="example@gmail.com"
                   className="p-2 w-100 border border-0 shadow rounded-pill"
                   style={{ backgroundColor: "#f2f2f2", borderRadius: "8px" }}
                   type="email"
                   onChange={handleInputChange}
                 />
                  {email && !validateEmail(email) && (
                        <div style={{ color: 'red' }}>Please enter a valid email address</div>
                      )}
               </div>
               <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-end">
                 <button
                   type="submit"
                   className="btn btn-lg fs-6 w-100 shadow rounded-pill"
                   style={{ backgroundColor: "#ff9933", color: "white" }}
                 >
                   JOIN US
                 </button>
               </div>
             </form>
      </div>
<div className="container">
      <div className="text-center my-3">
            <img src={BigHearts} style={{width:"90%"}}></img>
      </div>

</div>

      <div className="container pb-5">
  <Slider ref={sliderRef2} {...settings2}>
    {Array.isArray(partners?.partner) &&
      partners.partner.map((item, index) => (
        <div className="card mx-2 px-3 border-0" key={index}> 
          <img
            src={item.logo_image}
            className="card-img-top"
            alt=""
            style={{ height: "30vh" }}
          />
        </div>
      ))}
  </Slider>
</div>
    </div>
    </>
  );
};

export default AnnapurnaYojana;
