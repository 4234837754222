import React, { useEffect, useState } from 'react';

const LoaderPage = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const myVar = setTimeout(() => {
      setIsVisible(true);
    }, 3000);

    return () => clearTimeout(myVar);
  }, []);

  const loaderStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 1,
    width: '120px',
    height: '120px',
    margin: '-76px 0 0 -76px',
    border: '16px solid #f3f3f3',
    borderRadius: '50%',
    borderTop: '16px solid #3498db',
    WebkitAnimation: 'spin 2s linear infinite',
    animation: 'spin 2s linear infinite',
  };

  const animateBottomStyle = {
    position: 'relative',
    WebkitAnimationName: 'animatebottom',
    WebkitAnimationDuration: '1s',
    animationName: 'animatebottom',
    animationDuration: '1s',
  };

  // const myDivStyle = {
  //   display: isVisible ? 'block' : 'none',
  //   textAlign: 'center',
  // };

  return (
    <div style={{ margin: 0,height:"1000vh" }}>
      <div style={loaderStyle}></div>

      {/* <div style={myDivStyle} className="animate-bottom">
        <h2>Tada!</h2>
        <p>Some text in my newly loaded page..</p>
      </div> */}

      <style>
        {`
          @-webkit-keyframes spin {
            0% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(360deg); }
          }

          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          @-webkit-keyframes animatebottom {
            from { bottom: -100px; opacity: 0; } 
            to { bottom: 0px; opacity: 1; }
          }

          @keyframes animatebottom { 
            from { bottom: -100px; opacity: 0; } 
            to { bottom: 0; opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default LoaderPage;
