import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../appUtils/axiosConfig";
const initialState = {
  isLoading: false,
  error: null,
  countrylist: [],
  statelist:[],
  citylist: [],
};

export const getCountryList = createAsyncThunk(
    'countrystatecity/countries',
    async (_,{ dispatch, rejectWithValue }) => {
        try {
            dispatch(getallcountrylistStart());
            const response = await axios.get(`countrystatecity/countries`)
            dispatch(getallcountrylistSuccess(response?.data))
            return response.data;
        } catch (error) {
            dispatch(getallcountrylistfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
export const getStatelist = createAsyncThunk(
    'countrystatecity/states',
    async ({countryId}, { dispatch, rejectWithValue }) => {

        try {
            dispatch(getStateliststart());
            const response = await axios.get(`countrystatecity/states?country_id=${countryId}`)
            dispatch(getStatelistsuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(getStatelistfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
export const getCitylist = createAsyncThunk(
    'countrystatecity/cities',
    async ({countryidd,stateidd}, { dispatch, rejectWithValue }) => {

        try {
            dispatch(getCityliststart());
            const response = await axios.get(`countrystatecity/cities?country_id=${countryidd}&state_id=${stateidd}`)
            dispatch(getCitylistsuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(getCitylistfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)


const countryStateCitySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        getallcountrylistStart(state) {
            state.isLoading = true;
            state.error = null;
            state.countrylist = []
        },
        getallcountrylistSuccess(state, action) {
            state.isLoading = false;
            state.countrylist=action.payload?.allCountries

        },
        getallcountrylistfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
        },
       getStateliststart(state, action) {

            state.isLoading = true;
            state.statelist = []

        },
       getStatelistsuccess(state, action) {
            state.isLoading = false;
            state.statelist = action.payload?.data;

        },
       getStatelistfailure(state, action) {
            state.isLoading = false;
            state.statelist = null
        },
       getCityliststart(state, action) {

            state.isLoading = true;
            state.citylist = []

        },
       getCitylistsuccess(state, action) {
            state.isLoading = false;
            state.citylist = action.payload?.data;

        },
       getCitylistfailure(state, action) {
            state.isLoading = false;
            state.citylist = null
        },
      
       
    }
})

export const { getallcountrylistStart, getallcountrylistSuccess, getallcountrylistfailure,
   getStateliststart,getStatelistsuccess,getStatelistfailure, getCitylistfailure,getCitylistsuccess,getCityliststart
} = countryStateCitySlice.actions;

export default countryStateCitySlice.reducer;