import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";

const initialState = {

    isLoading: false,
    error: null,
    privacyPolicy: null

}


export const getprivacyPolicy = createAsyncThunk(
    'config/getprivacy',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(privacystart());
            const response = await axios.get(`config/privacydetails`)
            dispatch(privacySuccess(response?.data?.privacyPolicy))
        } catch (error) {
            dispatch(privacyFailure(error.response.data.message))
        }
    }
)


const privacyPolicySlice = createSlice({
    name: 'privacy',
    initialState,
    reducers: {
        privacystart(state) {
            state.isLoading = true;
            state.error = null;
        },
        privacySuccess(state, action) {

            state.privacyPolicy = action.payload;
            state.isLoading = false;
        },
        privacyFailure(state, action) {
            state.privacyPolicy = null;
            state.isLoading = false;
            state.error = action.payload;

        }


    }
})


export const { privacystart, privacySuccess, privacyFailure } = privacyPolicySlice.actions;

export default privacyPolicySlice.reducer;
