import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./app/store";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { ChakraProvider, CSSReset } from '@chakra-ui/react';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  <HelmetProvider>
  <Provider store={store}>
      <ChakraProvider>
      <App />
      </ChakraProvider>
  </Provider>
  </HelmetProvider>
);
