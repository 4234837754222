import React, { useState, useEffect } from "react";
import MetaData from "../component/layout/MetaData";
import { useSelector, useDispatch } from "react-redux";
import { gettermsAndConditions } from "../Allslices/t&cSlice";
import LoaderPage from "./SmallLoader";
const TermsAndConditions = () => {
  const dispatch = useDispatch();
  const { termsConditions, isLoading } = useSelector((state) => state.terms);

  useEffect(() => {
    dispatch(gettermsAndConditions());
  }, []);

  return (
    <>
      <MetaData title="Terms And Conditions" />
      {termsConditions ? (
        <>
          <div className="color-mixterm"></div>

          <section>
            <div className="container contpadi">
              <h1 className="pt-3 haedingfont">
                <span>Terms & Conditions</span>
              </h1>

              <p
                className="mb-5 termcon_justify"
                style={{ fontFamily: "myFont" }}
                dangerouslySetInnerHTML={{
                  __html: termsConditions,
                }}
              ></p>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}
    </>
  );
};

export default TermsAndConditions;
