import React, { useEffect, useState } from "react";
import Rectangle57 from "../../src/images/Rectangle57(2).png";
import pic from "../../src/images/16.png";
import MetaData from "../component/layout/MetaData";
import { useSelector, useDispatch } from "react-redux";
import { getmyOrder } from "../Allslices/orderSlice";
import ".././component/layout/style.css";
import {
  getAllCourses,
  getcoursedetails,
  getcoursebanner,
} from "../Allslices/courseSlice";

export default function Course() {
  const dispatch = useDispatch();

  const { orderAll } = useSelector((state) => state?.order);
  const myorderlist = orderAll?.filter((item) => item?.orderType === "course");

  
  useEffect(() => {
    dispatch(getmyOrder());
  }, []);

  const slug = "course-testing";
  useEffect(() => {
    dispatch(getcoursedetails(slug));
  }, []);

  return (
    <div>
      <MetaData title="Course" />
      <>
        {/* banner section */}
        <div
          className="poascastsub2"
          style={{
            backgroundImage: `url(${Rectangle57})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            marginBottom: "2rem",
          }}
        >
          <div className=" passtrsub2 pt-5">
            <h2 className="mx-5 pt-5">Your Library </h2>
            <hr className="hr-3" />
            <div className="container artvid pt-4">
              <div className="row g-3">
                {Array.isArray(myorderlist) &&
                  myorderlist?.map((item, index) => (
                    <div className="col-md-4 ">
                      <div
                        className=" card"
                        style={{
                          borderRadius: "15px",
						  padding: "10px",
                        }}
                      >
                        <img
                          src={item?.orderItems?.image}
                          alt=""
                          className="card-img-top rounded-top"
                          style={{
                            height: "66vh",
                            borderRadius: "20px",
                          }}
                        />
                        <div className="penel p-2">
                          <h4 style={{ fontFamily: "Lato" }}>
                            {item?.orderItems?.name}
                          </h4>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item?.orderItems?.description,
                            }}
                          >
                          </p>
                          <div className="pt-3">
                            <a
                              href={`/course/${item?.orderItems?.slug}`}
                              className="nav-link "
                            >
                              <button
                                className="btn shadowbuttons btn-lg text-light  cmn-t-shake "
                                style={{
                                  backgroundColor: "#FF9933!important",
                                  borderRadius: "20px",
                                }}
                              >
                                Open now
                              </button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {/* banner section end */}
      </>
    </div>
  );
}
