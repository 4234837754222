import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import playbtn from "../images/playy-button (1).png";
import ".././component/layout/style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPodcast,
  getSinglePodcast,
  getPodcastBanner,
} from "../Allslices/podcastSlice";
import PodcastsongList from "./PodcastsongList";
import { pauseTrack, playTrack, setTrackList } from "../Allslices/playerSlice";
import MetaData from "../component/layout/MetaData";
const PodcastSinglePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const handleNavigateToMusicPlayer = () => {
    navigate("/podcastsubpage");
  };
  const isComponentMounted = useRef(true);

  const { podcast, podcastbanner } = useSelector((state) => state.podcast);
  console.log("podcastbanner!!!", podcastbanner?.podcastbanner?.default_image);
  const [page, setPage] = useState(1);

  const [loader, setLoader] = useState(true);

  const [fetchedPodcast, setFetchedpodcast] = useState([]);
  const [totalpodcastCount, settotalpodcastCount] = useState(0);

  console.log("The Podcast Value is:", podcast);

  useEffect(() => {
    dispatch(getSinglePodcast(slug));
    dispatch(getPodcastBanner());
  }, []);

  const onSongPlay = (song) => {
    const index = podcast?.songList.findIndex((s) => s._id == song._id);

    dispatch(setTrackList({ list: podcast?.songList, index }));

    dispatch(playTrack(song));
  };

  const onSongPause = (song) => {
    const index = podcast?.songList.findIndex((s) => s._id == song._id);

    dispatch(setTrackList({ list: podcast?.songList, index }));
    dispatch(pauseTrack(song));
  };

  const handleFiltersSubmit = (e, action) => {
    e.preventDefault();
    if (action == "loadmore") {
      setLoader(true);
    }
    isComponentMounted.current = false;
    if (action == "loadmore") {
      setPage((prevpage) => prevpage + 1);
    }
  };
  let splitUrl = podcast?.banner_image.split("http://api.drarchikadidi.com/");
  console.log(splitUrl, "splitUrl");
  let bannerImage;

  if (splitUrl) {
    bannerImage =
      splitUrl[1] == "publicundefined"
        ? podcastbanner?.podcastbanner?.default_image
        : podcast?.banner_image;
  }
  console.log(
    "podcastbanner?.podcastban?.default_image",
    podcastbanner?.podcastbanner?.default_image
  );

  return (
    <>
      <MetaData
        title={podcast?.meta_title}
        description={podcast?.meta_description}
        tags={podcast?.meta_tag}
      />
      {/* <?php
      include 'include/header.php';
    ?> */}

      {/*<!-- banner section -->*/}
      <div
        className="poascast1 desktop-only"
        style={{
          backgroundImage: `url(${bannerImage})`,
        }}
      >
        <div className="container passtr1r">
          <div className="row">
            <div className="col-lg-7 col-xl-5 col-md-12 col-sm-10">
              {/* <h1 className="text-white">
                <span style={{ fontSize: "4rem",fontWeight:"900" }}>
                  {podcast?.title}
                </span>
              </h1> */}
              {/* <p
                className="pt-2 text-white fs-5 drd lh-lg"
                style={{
                  fontWeight: "600",
                  fontFamily: "Lato",
                 
                }}
              >
              {podcast?.description}
              </p> */}
            </div>
            <div className="col-lg-7 col-xl-7 col-md-12`"></div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid mobile-only">
        <div
          className="container mycontainer1"
          style={{
            backgroundSize: "contain",
            backgroundImage: `url(${bannerImage})`,
            backgroundRepeat: "no-repeat",
            height: "38vh",
          }}
        ></div>
      </div> */}
        <div className="mobile-only">
        <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}>
      <img src={ `${bannerImage}` } />
      <div className="container" style={{position:"absolute",height:"100%"}} 
           ></div>
        
      </div>
        </div>
      

      {/* <!-- banner section end --> */}
      {/* <div className="mobile-only">
          <div className="container-fluid mobile-only" style={{position:"relative",display:"flex"}}> 
            <img src={ `${gallerybanner?.backgroundres_image}` } />
            <div className="container" style={{position:"absolute",height:"100%"}} 
           >
              <div className="row" style={{height:"100%"}}>
                <div className="col-6 d-flex justify-content-start align-items-center">

                  {gallerybanner?.alignItem === "left" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: gallerybanner?.headingres,
                      }}
                    ></p>
                  </>)}

                </div>
                <div className="col-6 d-flex justify-content-start align-items-center">
                  {gallerybanner?.alignItem  === "right" && (<>
                    <p
                      className="homebannertitle"
                      style={{ fontFamily: "myFont",fontSize:"24px" }}
                      dangerouslySetInnerHTML={{
                        __html: gallerybanner?.headingres,
                      }}
                    ></p>
                  </>)}
                </div>


              </div>


             
             

            </div>

          
          </div>

          <div className="container">
               <p
                className="py-4 "
                style={{
                  fontFamily: "Lato",
                  textAlign: "justify"
                }}
                dangerouslySetInnerHTML={{
                  __html: gallerybanner?.subheadingres,
                }}
               >
               
               </p>
              
             </div>

             
          {/* banner ends here */}

          {/* </div> */}
      {/* <!-- all apisode section --> */}

      <section className="container">
        <h1 className="text-center pt-4">
          <span style={{ fontSize: "50px" }}>All Episodes</span>
        </h1>

        {podcast?.songList?.length > 0 &&
          podcast?.songList.map((song, index) => (
            <PodcastsongList
              key={song?._id}
              song={song}
              handlePlay={onSongPlay}
              handlePause={onSongPause}
              // artiste={artiste}
              // albumid={podcast?.slug}
            />
          ))}

      </section>
      <div className="p-4 text-center">
        {fetchedPodcast?.length == totalpodcastCount ? (
          ""
        ) : (
          <a>
            <button
              className="btn btn-orange btn-lg text-light rounded cmn-t-shake "
              style={{
                backgroundColor: "#FF9933!important",
                padding: "1rem 2.5rem",
              }}
              onClick={(e) => handleFiltersSubmit(e, "loadmore")}
            >
              LOAD MORE
            </button>
          </a>
        )}
      </div>

      {/* <!-- all apisode section end --> */}

      {/* <?php  
       include 'include/footer.php';
    ?> */}
    </>
  );
};

export default PodcastSinglePage;
