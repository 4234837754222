import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


import axios from "../appUtils/axiosConfig";

const initialState = {

    isLoading: false,
    error: null,
    contact: null

}


export const getContactUs = createAsyncThunk(
    '/get_contactus_page',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(contactusStart());
            const response = await axios.get(`/get_contactus_page`)
            dispatch(contactusSuccess(response?.data?.contactUsPage[0]))
			
        } catch (error) {
            dispatch(contactusFailure(error.response.data.message))
        }
    }
)


const contactUsSlice = createSlice({
    name: 'contactus',
    initialState,
    reducers: {
        contactusStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        contactusSuccess(state, action) {

            state.contact = action.payload;
            state.isLoading = false;
        },
		contactusFailure(state, action) {
            state.contact = null;
            state.isLoading = false;
            state.error = action.payload;

        }


    }
})


export const { contactusStart, contactusSuccess, contactusFailure } = contactUsSlice.actions;

export default contactUsSlice.reducer;
