import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
    isLoading: false,
    error: null,
    home: null,

    // singlealbum: {}
}



export const getHome = createAsyncThunk(
    'home/Home',
    async (_, { dispatch, getState, rejectWithValue }) => {
        // async (page, { dispatch, rejectWithValue }) => {
        // const { page } = getState().album;
        try {
            dispatch(getHomeStart());
            // const response = await axios.get(`album/all?page=${page + 1}`)
            const response = await axios.get(`config/homepage`)
            dispatch(getHomeSuccess(response?.data?.homepage[0].homePage))
            return response.data?.home;
        } catch (error) {
            dispatch(gethomefailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)


export const getHomeModal = createAsyncThunk(
    'home/Home',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(getHomeModalStart());

            const response = await axios.get(`messages/allmessages`)


            dispatch(getHomeModalSuccess(response?.data))
            return response.data;
        } catch (error) {
            dispatch(gethomeModalfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
export const todayMessageHomeModal = createAsyncThunk(
    'home/todaymessage',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(getTodaymessageStart());

            const response = await axios.get(`messages/todaysmessage`)

            dispatch(getTodaymessageSuccess(response?.data))
            return response.data;
        } catch (error) {
            dispatch(getTodaymessageFailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
export const getTestimonials = createAsyncThunk(
    'testimonials/getall_testimonials',
    async (_, { dispatch, rejectWithValue }) => {

        try {
            dispatch(getTestimonialsStart());
            const response = await axios.get(`testimonials/getall_testimonials`)

            dispatch(getTestimonialsSuccess(response?.data?.testimonials
            ))
            return response.data;
        } catch (error) {
            dispatch(getTestimonialsfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        getHomeStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getHomeSuccess(state, action) {
            state.isLoading = false;

            state.home = action.payload;


        },
        gethomefailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.singlealbum = null
        },

        getHomeModalStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getHomeModalSuccess(state, action) {
            state.isLoading = false;

            state.homeModal = action.payload?.messages


        },
        gethomeModalfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.homeModal = null
        },


        getTodaymessageStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getTodaymessageSuccess(state, action) {
            state.isLoading = false;

            state.todaymessage = action.payload?.message


        },
        getTodaymessageFailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.todaymessage = null
        },

        getTestimonialsStart(state) {
            state.isLoading = true;
            state.error = null;

        },
        getTestimonialsSuccess(state, action) {
            state.isLoading = false;
            state.testimonials = action.payload;


        },
        getTestimonialsfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;

        },

    }
})

export const { getHomeStart, getHomeSuccess, gethomefailure, gethomeModalfailure, getHomeModalSuccess, getHomeModalStart, getTestimonialsStart, getTestimonialsSuccess, getTestimonialsfailure,
    getTodaymessageStart, getTodaymessageSuccess, getTodaymessageFailure
} = homeSlice.actions;

export default homeSlice.reducer;