import React from 'react'
import rect57 from "../images/rectangle57.png";
import $21 from "../images/21.png";

const MusicFavourites = () => {
    return (
        <>
        <div className="container-fluid">
          <img src={rect57} alt="" height={'252px'} width={'100%'}/>
        </div>
    
        <section>
            <div className="container">
              <h1 className="text-start" style={{ fontSize: "3.2rem",fontFamily:"lato" }}>
                My Favourite
              </h1>
              <div className="row pt-2 g-3">
                <div className="col-lg-4">
                  <div className="perty">
                    <div className="imgr">
                      <img src={$21} className="img-fluid" alt="" />
                    </div>
                    <h4 className="fw-bold pt-2">Upbeating</h4>
                    <span>2018</span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="perty">
                    <div className="imgr">
                      <img src={$21} className="img-fluid" alt="" />
                    </div>
                    <h4 className="fw-bold pt-2">Pop Summer</h4>
                    <span>2017</span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="perty">
                    <div className="imgr">
                      <img src={$21} className="img-fluid" alt="" />
                    </div>
                    <h4 className="fw-bold pt-2">Emotional Piano</h4>
                    <span>2016</span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="perty">
                    <div className="imgr">
                      <img src={$21} className="img-fluid" alt="" />
                    </div>
                    <h4 className="fw-bold pt-2">Motivation trailer</h4>
                    <span>2015</span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="perty">
                    <div className="imgr">
                      <img src={$21} className="img-fluid" alt="" />
                    </div>
                    <h4 className="fw-bold pt-2">Acoustic Indie Folk</h4>
                    <span>2015</span>
                  </div>
                </div>
    
                <div className="col-lg-4">
                  <div className="perty">
                    <div className="imgr">
                      <img src={$21} className="img-fluid" alt="" />
                    </div>
                    <h4 className="fw-bold pt-2">Sport Big Beat</h4>
                    <span>2014</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
        
      )
}

export default MusicFavourites