import React, { useCallback, useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import Rectangle74 from '../assets/Rectangle74.png';
import Background1 from "../../src/images/Rectangle38.png";
import "../component/layout/style.css";
import "../component/auth/auth.css";
import menicon from "../images/menicon.jpeg";
import gold from "../images/Gold.png";
import silver from "../images/Silver.png";
import diamond from "../images/Diamond.png";
import bronze from "../images/Bronze.png";
import $21 from "../images/21.png";
import platinum from "../images/Plantinum.png";
import { useDispatch, useSelector } from "react-redux";
import LoaderPage from "./LoaderPage";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getuserintrest,
  loadUser,
  updateUserProfile,
} from "../component/auth/authSlice";
// import { updateUserPROFILE } from "../../src/component/auth/updateUserAction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import Quill styles

import Moment from "react-moment";
import moment from "moment";
import AppCarousel from "../component/shared/AppCarousel";
import "@vidstack/react/player/styles/default/theme.css";
import "@vidstack/react/player/styles/default/layouts/video.css";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import { getmyOrder } from "../Allslices/orderSlice";
import {
  getuserAnswers,
  getAnswerResetPage,
  getuserQuestionsandThoughts,
} from "../component/auth/authSlice";

import { getcoinsValue } from "../Allslices/coinsSlice";
import { fetchPostResetPage, fetchPosts } from "../Allslices/communitySlice";
import PostItem from "./post/PostItem";
import MetaData from "../component/layout/MetaData";
import { nullTrack } from "../Allslices/playerSlice";
import Select from "react-select";
import {
  getCountryList,
  getStatelist,
  getCitylist,
} from "../Allslices/countrycitystateSlice";
import {
  SHOW_INFO_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../appUtils/appToast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
function Profile() {
  const [music, setMusic] = useState(false);
  const [song, setsong] = useState(false);
  const [overview, setOverview] = useState(true);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [question, setQuestions] = useState(false);
  const [showOrder, setShowOrder] = useState([]);
  const [course, setCourse] = useState(false);
  const [answers, setAnswers] = useState(false);
  const [thought, setThoughts] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [bhagwat, setBhagwat] = useState(false);
  const [wellness, setWellness] = useState(false);
  const [events, setEvents] = useState(false);
  const [podcast, setPodcast] = useState(false);
  const { countrylist, statelist, citylist } = useSelector(
    (state) => state?.countrystatecity
  );
  const [page, setPage] = useState(0);
  const { orderAll } = useSelector((state) => state?.order);

  const orderWithSongParentId = orderAll.find(
    (order) => order.orderItems?.songParentId !== undefined
  );

  const singesongParentId = orderWithSongParentId?.orderItems?.songParentId;

  const {
    user,
    userQuestionandThoughtlist,
    userAnswer,
    currentPage,
    totalPages,
    isLoading,
    intrest,
    isProfileUpdated,
  } = useSelector((state) => {
    console.log("dsf", state.auth);
    return state.auth;
  });

  console.log("user@@", user);
  const { coinsValue } = useSelector((state) => state.coinsdata);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const key = searchParams.get("key");
  const slug = searchParams.get("slug");

  const handleOrder = (orderType) => {
    let myOlderList;
    let musicOlderList;
    let songOlderList;

    if (orderType == "music" || orderType == "song") {
      musicOlderList = orderAll?.filter((item) => item?.orderType === "music");
      console.log(musicOlderList, "musicOlderList");
      songOlderList = orderAll?.filter((item) => item?.orderType === "song");
      console.log(songOlderList, "songOlderList");
      setShowOrder([...musicOlderList, ...songOlderList]);
    } else {
      myOlderList = orderAll?.filter((item) => item?.orderType === orderType);
      setShowOrder(myOlderList);
    }
  };

  const {
    fetchPostState: {
      loading: fetchPostloading,
      data: fetchPostData,
      page: postPage,
      hideLoadMore,
    },
  } = useSelector((state) => state.community);

  const handleOnClicknavigation = (params) => {
    // navigate('/music_favourites')

    setMusic(false);
    setsong(false);
    setCourse(false);
    setPodcast(false);
    setBhagwat(false);
    setWellness(false);
    setEvents(false);
    setOverview(false);
    setQuestions(false);
    setAnswers(false);
    setThoughts(false);
    setActiveTab("");

    switch (params) {
      case "music":
        setMusic(true);
        break;
      case "song":
        setsong(true);
        break;
      case "podcast":
        setPodcast(true);
        break;
      case "bhagwat":
        setBhagwat(true);
        break;
      case "wellness":
        setWellness(true);
        break;
      case "events":
        setEvents(true);
        break;
      // // // case "donation":
      // //   setDonation(true);
      //   break;
      case "overview":
        setOverview(true);
        break;
      case "question":
        setQuestions(true);
        break;
      case "answers":
        setAnswers(true);
        break;
      case "thought":
        setThoughts(true);
        break;
      case "course":
        setCourse(true);
    }
  };

  const handleNavigation = (item) => {
    if (item?.orderType === "course") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));
      if (courseExpired != "Expired") {
        navigate(`/course/${item?.orderItems?.slug}`, {
          state: {
            orderid: `${item?._id}`,
            productId: `${item?.orderItems?.product}`,
          },
        });
      } else {
        navigate(`/courses/${item?.orderItems?.slug}`);
      }
    }
    if (item?.orderType === "music") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));
      if (courseExpired != "Expired") {
        navigate(`/music/${item?.orderItems?.slug}`, {
          state: { key: `${item?._id}` },
        });
      } else {
        navigate(`/music/${item?.orderItems?.slug}`);
      }
    }

    if (item?.orderType === "song") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));

      if (courseExpired != "Expired") {
        navigate(
          `/music/${item?.orderItems?.songParentId}/${item?.orderItems?.slug}`,
          {
            state: { key: `${item?._id}` },
          }
        );
      } else {
        window.location.href = `/music/${item?.orderItems?.songParentId}/${item?.orderItems?.slug}`;
      }
    }

    if (item?.orderType === "events") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));

      if (courseExpired != "Expired") {
        navigate(
          `/events/eventdetails/${item?.orderItems?.eventParent}/${item?.orderItems?.slug}`,
          {
            state: { key: `${item?._id}` },
          }
        );
      } else {
        navigate(
          `/events/eventdetails/${item?.orderItems?.eventParent}/${item?.orderItems?.slug}`
        );
      }
    }
    if (item?.orderType === "wellness") {
      let courseExpired = formatDaysLeft(calculateDaysLeft(item));
      if (courseExpired != "Expired") {
        navigate(`/wellness/${item?.orderItems?.slug}`, {
          state: { key: `${item?._id}` },
        });
      } else {
        navigate(`/wellness/${item?.orderItems?.slug}`);
      }
    }
  };

  console.log(getmyOrder, "The all ordered courses are");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setMusic(false);
    setsong(false);
    setOverview(false);
    setQuestions(false);
    setAnswers(false);
    setThoughts(false);
  };

  const [formdata, setFormData] = useState({
    username: "",
    userimage: "",
    useroccupation: "",
    userintrest: "",
    userbio: "",
    whatsapp: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    dob: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    countryCode: "",
  });
  // useEffect(()=>{
  // if(key=='questions'){
  //   setQuestions(true);
  // }
  // else if(key=='answers'){
  //   setAnswers(true);
  // }
  // },[key])
  const [whatsapp, setWhatsapNo] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const handlewhatsappChange = (value, data) => {
    setCountryCode(`+${data.dialCode}`);
    const number = value.slice(data.dialCode.length);
    setWhatsapNo(number);
    setFormData({
      ...formdata,
      whatsapp: value,
    });
  };
  useEffect(() => {
    if (user) {
      // Initialize form data with user details
      setFormData({
        username: user?.name,
        userimage: user?.image,
        useroccupation: user?.occupation,
        userintrest: user?.interest?._id, // Corrected from userintrest to userinterest
        userbio: user?.bio,
        whatsapp: user?.whatsapp,
        country: user?.country,
        state: user?.state,
        city: user?.city,
        pincode: user?.pincode,
        dob: user?.dob,
        addressLine1: user?.addressLine1,
        addressLine2: user?.addressLine2,
        addressLine3: user?.addressLine3,
      });
    }
    dispatch(getuserintrest());
  }, [user, dispatch]);

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "file") {
      const file = e.target.files[0];
      // console.log("The file is:", file);
      const formData = new FormData();
      formData.append(name, file);
      setFormData({ ...formdata, userimage: file });
    } else if (name === "userintrest") {
      // If no interest option is chosen, set userintrest to the already selected interest from the user's profile
      const selectedOptions = Array.from(
        e.target.selectedOptions,
        (option) => option.value
      );
      const userintrest =
        selectedOptions.length === 0 ? formdata.userintrest : selectedOptions;
      console.log(selectedOptions);
      setFormData({
        ...formdata,
        [name]: userintrest,
      });
    } else {
      setFormData({
        ...formdata,
        [name]: value,
      });
    }
    setIsvalidation(validateForm());
  };

  const handleSelectChange = (e) => {
    const { name, value } = e;
    if (name == "country") {
      let countryId = value?.id;
      dispatch(getStatelist({ countryId }));
    }
    setFormData({
      ...formdata,
      [name]: value,
    });
    if (name == "state") {
      let countryidd = value?.country_id;
      let stateidd = value?.id;
      dispatch(getCitylist({ countryidd, stateidd }));
    }
  };

  // console.log("The form data userImage is:", formdata.userimage);
  const [errors, setErrors] = useState({});

  const [isvalidation, setIsvalidation] = useState(false);
  console.log("isvalidation", isvalidation);
  const validateForm = () => {
    const requiredFields = [
      formdata.username,
      // formdata.userimage,
      formdata.useroccupation,
      formdata.userintrest,
      formdata.whatsapp,
      formdata.addressLine1,
      formdata.addressLine2,
      // formdata.addressLine3,
      formdata.country,
      formdata.state,
      formdata.city,
      formdata.pincode,
      // formdata.userbio,
    ];

    for (let field of requiredFields) {
      if (!field) {
        
        return false;
      }
    }

    return true;

    // const newErrors = {};

    // if (!formdata.username) newErrors.username = 'Name is required';
    // if (!formdata.useroccupation) newErrors.useroccupation = 'Occupation is required';
    // if (!formdata.userintrest) newErrors.userintrest = 'Interest is required';
    // if (!formdata.whatsapp) newErrors.whatsapp = 'WhatsApp number is required';
    // if (!formdata.dob) newErrors.dob = 'Date of Birth is required';
    // if (!formdata.addressLine1) newErrors.addressLine1 = 'Address Line 1 is required';
    // if (!formdata.country) newErrors.country = 'Country is required';
    // if (!formdata.state) newErrors.state = 'State is required';
    // if (!formdata.city) newErrors.city = 'City is required';
    // if (!formdata.pincode) newErrors.pincode = 'Pin Code is required';

    // setErrors(newErrors);
    // return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    // const updatedUserData = {
    //   name: formdata.username,
    //   occupation: formdata.useroccupation,
    //   interest: formdata.userintrest,
    //   bio: formdata.userbio,
    //   image: formdata.userimage,
    // };

    // e.preventDefault(); // Prevent default form submission behavior

    if (!validateForm()) {
      SHOW_ERROR_NOTIFICATION('All field are mandatory');
      setShowModal(true);
      return;
    }
    setShowModal(false)
    // SHOW_SUCCESS_NOTIFICATION("Profile updated successfully");
    // setShowModal(false);
    // setShowModal(true);
    // SHOW_SUCCESS_NOTIFICATION("Successfully Update")
    
    // const updatedUserData = new FormData(); // Create FormData object
    let updatedUserData = {}; // Create FormData object
    // updatedUserData.append("name", formdata.username);
    // updatedUserData.append("occupation", formdata.useroccupation);
    // updatedUserData.append("intrest", formdata.userintrest);
    // updatedUserData.append("bio", formdata.userbio);
    // updatedUserData.append("image", formdata.userimage);
    // updatedUserData.append("whatsapp", formdata.whatsapp);
    // updatedUserData.append("country", formdata.country);
    // updatedUserData.append("state", formdata.state);
    // updatedUserData.append("city", formdata.city);
    // updatedUserData.append("pincode", formdata.pincode);
    // updatedUserData.append("dob", formdata.dob);
    // updatedUserData.append("addressLine1", formdata.addressLine1);
    // updatedUserData.append("addressLine2", formdata.addressLine2);
    // updatedUserData.append("addressLine3", formdata.addressLine3);

    updatedUserData = {
      name: formdata.username,
      occupation: formdata.useroccupation,
      intrest: formdata.userintrest,
      bio: formdata.userbio,
      image: formdata.userimage,
      whatsapp: formdata.whatsapp,
      country: formdata.country,
      state: formdata.state,
      city: formdata.city,
      pincode: formdata.pincode,
      dob: formdata.dob,
      addressLine1: formdata.addressLine1,
      addressLine2: formdata.addressLine2,
      addressLine3: formdata.addressLine3,
      countryCode: formdata.countryCode,
    };
    // console.log("The Updated User Form data is:", updatedUserData);
    dispatch(updateUserProfile(updatedUserData));
    
  };

  useEffect(() => {
    if (isProfileUpdated) {
      dispatch(loadUser());
    } else {
      dispatch(loadUser());
    }
  }, [dispatch, isProfileUpdated]);

  useEffect(() => {
    dispatch(getuserintrest());
    dispatch(getCountryList());
  }, [user, dispatch]);
  console.log(user, "userdata");
  // console.log(user?.interest?.name, "formdata")

  const modules = {
    toolbar: {
      container: [
        [{ header: "1" }, { header: "2" }, { font: [] }],
        [{ size: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image", "video"],
        ["clean"],
      ],
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  useEffect(() => {
    dispatch(getmyOrder());
    dispatch(getcoinsValue());

    dispatch(fetchPostResetPage());
    dispatch(getAnswerResetPage());
  }, [dispatch]);

  const [hasMoreData, setHasMoreData] = useState(true);

  const userId = localStorage.getItem("userId");

  const callFetchPost = useCallback((type) => {
    dispatch(fetchPosts({ type, user: userId })).then((resultAction) => {
      if (resultAction?.error) {
        SHOW_ERROR_NOTIFICATION(resultAction.payload);
        return;
      }
    });
  }, []);
  useEffect(() => {
    if (key === null) {
      setOverview(true);
    } else {
      handleOnClicknavigation(key);
      callFetchPost(key);
      handleOrder(key);
    }
  }, [key]);
  const handleLoadMoreAnswers = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    if (currentPage != totalPages) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [currentPage, totalPages]);
  useEffect(() => {
    if (user?._id)
      dispatch(getuserAnswers({ userId: user._id, page: page })).then(
        (resultAction) => {
          if (resultAction?.error) {
            SHOW_ERROR_NOTIFICATION(resultAction.payload);
            return;
          }
          if (resultAction.payload?.length < 10) {
            setHasMoreData(false);
          } else {
            setHasMoreData(true);
          }
        }
      );
  }, [page]);

  const [daysLeft, setDaysLeft] = useState([]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setDaysLeft(calculateDaysLeft(item));
  //   }, 24 * 60 * 60 * 1000); // Update every 24 hours

  //   return () => clearInterval(timer);
  // }, [item]);

  const calculateDaysLeft = (item) => {
    const createdAt = moment(item.createdAt);
    // console.log("Purchased Date is:", createdAt.format("YYYY-MM-DD"));

    const expiryDate = moment(createdAt).add(
      item?.orderItems?.monthValidity || 0,
      "months"
    );
    // console.log("Expiry Date is:", expiryDate.format("YYYY-MM-DD"));

    const now = moment();
    // console.log("The now ", now);
    const daysLeft = expiryDate.diff(now, "days");

    const futureDate = moment(now).add(daysLeft, "days");

    // Format the future date as YYYY-MM-DD
    const formattedDate = futureDate.format("YYYY-MM-DD");

    // console.log("the daysleft", formattedDate);
    // console.log("The Days Left from the Purchased data is", daysLeft, "for Item", item?.orderItems?.name);
    return daysLeft;
  };

  const formatDaysLeft = (days) => {
    if (days > 1) {
      return `${days} days Left`;
    } else if (days === 1) {
      return `1 day left`;
    } else {
      return `Expired`;
    }
  };

  const formatFutureDate = (daysLeft, item) => {
    if (daysLeft > 0) {
      const futureDate = moment(item.createdAt)
        .add(item?.orderItems?.monthValidity || 0, "months")
        .format("D MMM YYYY");
      return `${futureDate} ,`;
    } else {
      return "";
    }
  };

  return (
    <>
      <MetaData title="Profile" />
      {user ? (
        <>
          <div
            className={`profile_box  `}
            style={{
              backgroundImage: `url(${Background1})`,
              backgroundSize: "cover",
              top: "0rem",
            }}
          >
            <div
              className="container"
              style={{
                paddingTop: "10rem",
              }}
            >
              <div className="row mb-2 " style={{ justifyContent: "center" }}>
                <div className="col-lg-11 col-12 mb-5">
                  <div className="text-center">
                    <h1>
                      <span
                        style={{
                          fontSize: "60px",
                          position: "relative",
                          bottom: "1.8rem",
                        }}
                      >
                        Dashboard
                      </span>
                    </h1>
                  </div>
                  <div className={`profile_container ${showModal ? "show d-block" : ""} `}>
                    <div className="profileTopContainer d-flex flex-wrap p-3 ">
                      <div className="m-1 rounded-circle profile_user_img">
                        <img
                          src={user?.image ?? ""}
                          alt="user_image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = "default_usericon.jpg";
                            currentTarget.style.width = "100px";
                          }}
                        />
                      </div>
                      <div className="medals_container  ">
                        <h5 className="text-muted mx-3">
                          {user?.name}
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ color: "#0c5de9", paddingLeft: "0.5rem" }}
                            data-bs-toggle="modal"
                            onClick={() => setShowModal(true)}
                            data-bs-target="#userEditModal"
                          ></i>
                        </h5>

                        {/* <!-- Modal --> */}
                        <div
                          className={`modal fade `}
                          id="userEditModal"
                          tabindex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                          
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h1
                                  className="modal-title fs-5"
                                  id="exampleModalLabel"
                                >
                                  Edit Profile
                                </h1>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => setShowModal(false)}
                                ></button>
                              </div>
                              <form enctype="multipart/form-data">
                                <div className="modal-body">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="username"
                                      className="form-label"
                                    >
                                      Name
                                    </label>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="text"
                                        className="form-control "
                                        id="username"
                                        name="username"
                                        value={formdata.username}
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        className="text-danger required-star mx-2 mt-2"
                                        style={{
                                          position: "absolute",
                                          top: "revert-layer",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      for="formFile"
                                      className="form-label"
                                    >
                                      Profile Pic
                                    </label>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        name="userimage"
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        className="text-danger required-star mx-2 mt-2"
                                        style={{
                                          position: "absolute",
                                          top: "revert-layer",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      htmlFor="useroccupation"
                                      className="form-label"
                                    >
                                      Occupation
                                    </label>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="useroccupation"
                                        name="useroccupation"
                                        value={formdata.useroccupation}
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        className="text-danger required-star mx-2 mt-2"
                                        style={{
                                          position: "absolute",
                                          top: "revert-layer",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </div>
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      htmlFor="userintrest"
                                      className="form-label"
                                    >
                                      Interest
                                    </label>
                                    <select
                                      id="userintrest"
                                      name="userintrest"
                                      className="form-select"
                                      value={formdata.userintrest}
                                      onChange={handleInputChange}
                                      // multiple  // Allow multiple selections
                                    >
                                      <option value="" disabled>
                                        Select Interest
                                      </option>
                                      {intrest &&
                                        intrest.map((item) => (
                                          <option
                                            key={item._id}
                                            value={item._id}
                                          >
                                            {" "}
                                            {/* Use _id instead of name */}
                                            {item.name}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  <div className="mb-3 whatsappp">
                                    <label
                                      htmlFor="whatsapp"
                                      className="form-label"
                                    >
                                      WhatsApp No.
                                    </label>
                                    <div style={{ position: "relative" }}>
                                      {/* <input
                                      type="text"
                                      className={`form-control ${errors.whatsapp ? 'is-invalid' : ''}`}
                                      id="whatsapp"
                                      name="whatsapp"

                                      placeholder="required ***"
                                      value={formdata.whatsapp}
                                      onChange={handleInputChange}
                                    /> */}
                                      <PhoneInput
                                        country={"in"}
                                        className="form-control ps-0 textgray"
                                        enableSearch
                                        required
                                        value={formdata?.whatsapp}
                                        type="number"
                                        name="whatsapp"
                                        id="form4"
                                        placeholder="Enter WhatsApp No."
                                        onChange={handlewhatsappChange}
                                      />
                                      {/* {errors.whatsapp && (
                                        <div className="invalid-feedback">
                                          {errors.whatsapp}
                                        </div>
                                      )} */}
                                      <span
                                        className="text-danger required-star mx-2 mt-2"
                                        style={{
                                          position: "absolute",
                                          top: "revert-layer",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <label htmlFor="dob" className="form-label">
                                      DOB
                                    </label>

                                    <input
                                      type="date"
                                      className="form-control"
                                      id="dob"
                                      name="dob"
                                      value={formdata.dob}
                                      onChange={handleInputChange}
                                    />
                                  </div>

                                  <label
                                    htmlFor="address"
                                    className="mt-2 form-label"
                                  >
                                    Address
                                  </label>
                                  <div className="mb-3 d-flex flex-column gap-2">
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="addressLine1"
                                        name="addressLine1"
                                        value={formdata.addressLine1}
                                        placeholder="Address Line 1"
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        className="text-danger required-star mx-2 mt-2"
                                        style={{
                                          position: "absolute",
                                          top: "revert-layer",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </div>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="addressLine2"
                                        name="addressLine2"
                                        placeholder="Address Line 2"
                                        value={formdata.addressLine2}
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        className="text-danger required-star mx-2 mt-2"
                                        style={{
                                          position: "absolute",
                                          top: "revert-layer",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </div>

                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="addressLine3"
                                        name="addressLine3"
                                        placeholder="Address Line 3"
                                        value={formdata.addressLine3}
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        className="text-danger required-star mx-2 mt-2"
                                        style={{
                                          position: "absolute",
                                          top: "revert-layer",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    {/* <select placeholder="Country" name="country" id="country" value={formdata.country} onChange={handleInputChange}>
                                      {Country.getAllCountries().map((item, index) => (
                                        <option value={item["name"]} label={item["name"]} key={index}>{item?.name}</option>
                                      ))}
                                    </select> */}

                                    <Select
                                      options={countrylist}
                                      getOptionLabel={(options) => {
                                        return options["name"];
                                      }}
                                      getOptionValue={(options) => {
                                        return options["name"];
                                      }}
                                      value={formdata.country}
                                      name="country"
                                      placeholder="Country"
                                      // onChange={(item) => {
                                      //   setSelectedCountry(item);
                                      // }}
                                      onChange={(e) =>
                                        handleSelectChange({
                                          value: e,
                                          name: "country",
                                        })
                                      }
                                    />
                                  </div>
                                  <div className="mb-3">
                                    {/* <select
                                      placeholder="Country"
                                      name="country"
                                      id="country"
                                      value={formdata.country}
                                      onChange={handleInputChange}
                                    >
                                      {Country.getAllCountries().map((item, index) => (
                                        <option value={item.name} label={item.name} key={index}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select> */}

                                    <Select
                                      options={statelist}
                                      getOptionLabel={(options) => {
                                        return options["name"];
                                      }}
                                      getOptionValue={(options) => {
                                        return options["name"];
                                      }}
                                      value={formdata.state}
                                      name="state"
                                      placeholder="State"
                                      onChange={(e) =>
                                        handleSelectChange({
                                          value: e,
                                          name: "state",
                                        })
                                      }
                                    />
                                  </div>
                                  <div className="mb-3">
                                    {/* <select placeholder="City" name="city" id="city" value={formdata.city} onChange={handleInputChange}>

                                      {City.getCitiesOfState(formdata?.country, formdata.state?.isoCode).map((item, index) => (
                                        <option value={item} label={item["name"]} key={index}>{item?.name}</option>
                                      ))}

                                    </select> */}

                                    <Select
                                      options={citylist}
                                      getOptionLabel={(options) => {
                                        return options["name"];
                                      }}
                                      getOptionValue={(options) => {
                                        return options["name"];
                                      }}
                                      value={formdata.city}
                                      name="city"
                                      placeholder="City"
                                      onChange={(e) =>
                                        handleSelectChange({
                                          value: e,
                                          name: "city",
                                        })
                                      }
                                    />
                                  </div>

                                  <div className="mb-3">
                                    <label
                                      htmlFor="pincode"
                                      className="form-label"
                                    >
                                      Pin Code
                                    </label>
                                    <div style={{ position: "relative" }}>
                                      <input
                                        type="number"
                                        className="form-control"
                                        id="pincode"
                                        name="pincode"
                                        value={formdata.pincode}
                                        onChange={handleInputChange}
                                      />
                                      <span
                                        className="text-danger required-star mx-2 mt-2"
                                        style={{
                                          position: "absolute",
                                          top: "revert-layer",
                                        }}
                                      >
                                        {" "}
                                        *
                                      </span>
                                    </div>
                                  </div>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="userbio"
                                      className="form-label"
                                    >
                                      Bio
                                    </label>
                                    {/* <input
                                    type="text"
                                    className="form-control"
                                    id="userbio"
                                    name="userbio"
                                    value={formdata.userbio}
                                    onChange={handleInputChange}
                                  /> */}
                                    <ReactQuill
                                      value={formdata.userbio}
                                      onChange={(value) =>
                                        setFormData({
                                          ...formdata,
                                          userbio: value,
                                        })
                                      }
                                      modules={modules}
                                      formats={formats}
                                    />
                                  </div>

                                  <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleSubmit}
                                    data-bs-dismiss={showModal ? "" : "modal"}
                                    aria-label="Close"
                                    disabled={isLoading}  // Disable the button when loading
                                  >
                                    {isLoading ? (
                                      <div
                                        className="spinner-border spinner-border-sm text-light"
                                        role="status"
                                      >
                                        <span className="visually-hidden">Loading...</span>
                                      </div>
                                    ) : (
                                      "Update"
                                    )}
                                  </button>

                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        {/* /////////// */}

                        <a className="text-muted mx-3">{user?.email}</a>

                        {/* <ul className="medals d-flex flex-wrap ">
                          <li className="justify-content-center p-2">
                            <img src={bronze} alt="" />
                            <button
                              type="button"
                              value={coinsValue?.bronze}
                              className={`btn btn-secondary btn-sm mt-4 ${
                                user.currentPoints < coinsValue?.bronze
                                  ? "disabled"
                                  : ""
                              }`}
                              disabled={user.currentPoints < coinsValue?.bronze}
                            >
                              GET GIFT
                            </button>
                          </li>

                          <li className="justify-content-center p-2">
                            <img src={silver} alt="" />
                            <button
                              type="button"
                              value={coinsValue?.silver}
                              className={`btn btn-secondary btn-sm mt-4 ${
                                user.currentPoints < coinsValue?.silver
                                  ? "disabled"
                                  : ""
                              }`}
                              disabled={user.currentPoints < coinsValue?.silver}
                            >
                              GET GIFT
                            </button>
                          </li>
                          <li className="justify-content-center p-2">
                            <img src={gold} alt="" />
                            <button
                              type="button"
                              value={coinsValue?.gold}
                              className={`btn btn-secondary btn-sm mt-4 ${
                                user.currentPoints < coinsValue?.gold
                                  ? "disabled"
                                  : ""
                              }`}
                              disabled={user.currentPoints < coinsValue?.gold}
                            >
                              GET GIFT
                            </button>
                          </li>
                          <li className=" justify-content-center mt-4">
                            <img src={platinum} alt="" />
                            <button
                              type="button"
                              value={coinsValue?.platinum}
                              className={`btn btn-secondary btn-sm mt-4 ${
                                user.currentPoints < coinsValue?.platinum
                                  ? "disabled"
                                  : ""
                              }`}
                              disabled={
                                user.currentPoints < coinsValue?.platinum
                              }
                            >
                              GET GIFT
                            </button>
                          </li>
                          <li className="justify-content-center p-2">
                            <img src={diamond} alt="" />
                            <button
                              type="button"
                              value={coinsValue?.diamond}
                              className={`btn btn-secondary btn-sm mt-4 ${
                                user.currentPoints < coinsValue?.diamond
                                  ? "disabled"
                                  : ""
                              }`}
                              disabled={
                                user.currentPoints < coinsValue?.diamond
                              }
                            >
                              GET GIFT
                            </button>
                          </li>
                        </ul> */}
                      </div>
                    </div>

                    <ul
                      className="profile_footer d-flex flex-wrap p-2"
                      style={{
                        listStyle: "none",
                        borderTop: "2px solid #d7d0d0",
                        fontWeight: "600",
                      }}
                    >
                      <li
                        className={`px-2 ${overview ? "active-tab" : ""}`}
                        onClick={() => handleOnClicknavigation("overview")}
                      >
                        <div style={{ cursor: "pointer" }}>Overview</div>
                      </li>
                      <li
                        className={`px-2 ${question ? "active-tab" : ""}`}
                        onClick={() => {
                          handleOnClicknavigation("question");
                          dispatch(fetchPostResetPage());
                          callFetchPost("question");
                        }}
                      >
                        <div id="myquestions" style={{ cursor: "pointer" }}>
                          My Question
                        </div>
                      </li>
                      <li
                        className={`px-2 ${answers ? "active-tab" : ""}`}
                        onClick={() => {
                          setPage(0);
                          handleOnClicknavigation("answers");
                          dispatch(getAnswerResetPage());
                          dispatch(
                            getuserAnswers({ userId: user?._id, page: 0 })
                          ).then((resultAction) => {
                            if (resultAction?.error) {
                              SHOW_ERROR_NOTIFICATION(resultAction.payload);
                              return;
                            }
                            if (resultAction.payload?.length < 10) {
                              setHasMoreData(false);
                            } else {
                              setHasMoreData(true);
                            }
                          });
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>My Answer</div>
                      </li>
                      <li
                        className={`px-2 ${thought ? "active-tab" : ""}`}
                        onClick={() => {
                          handleOnClicknavigation("thought");
                          // dispatch(getuserQuestionsandThoughts({ type: 'thought', userId: user._id }))
                          dispatch(fetchPostResetPage());
                          callFetchPost("thought");
                        }}
                      >
                        <div style={{ cursor: "pointer" }}>My Thoughts</div>
                      </li>
                      <li
                        className={`px-2 ${course ? "active-tab" : ""}`}
                        onClick={() => handleOnClicknavigation("course")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("course")}
                        >
                          {" "}
                          My Course
                        </div>
                      </li>
                      <li
                        className={`px-2 ${music ? "active-tab" : ""}`}
                        onClick={() => handleOnClicknavigation("music")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("music")}
                        >
                          My Music
                        </div>
                      </li>

                      {/* <li
                        className="px-2 "
                        // onClick={() => handleTabClick("Welcome")}
                        onClick={() => handleTabClick("podcast")}
                      >
                        <div style={{ cursor: "pointer" }} onClick={()=>handleOrder("podcast")}>My Podcast</div>
                      </li> */}

                      {/* <li
                        className={`px-2 ${bhagwat ? "active-tab" : ""}`}
                        // onClick={() => handleOnClicknavigation("bhagwatgeeta")}
                        onClick={() => handleOnClicknavigation("bhagwat")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("bhagwat")}
                        >
                          My Bhagwat Geeta
                        </div>
                      </li>  */}

                      <li
                        className={`px-2 ${wellness ? "active-tab" : ""}`}
                        // onClick={() => handleOnClicknavigation("wellness")}
                        onClick={() => handleOnClicknavigation("wellness")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("wellness")}
                        >
                          My Wellness
                        </div>
                      </li>

                      <li
                        className={`px-2 ${events ? "active-tab" : ""}`}
                        // onClick={() => handleOnClicknavigation("donation")}
                        onClick={() => handleOnClicknavigation("events")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOrder("events")}
                        >
                          My Events
                        </div>
                      </li>
                    </ul>
                  </div>
                  {overview ? (
                    <div
                      className="profile_container container mt-4 p-3"
                      style={{ height: "10rem !important" }}
                    >
                      <div className="row">
                        <div className="col">
                          <h4>Info</h4>
                        </div>
                      </div>
                      <div className="row text-secondary">
                        <div className="col">
                          <h5 className="lato">Occupation:</h5>
                        </div>
                        <div className="col-12 col-md-9">
                          <p className="lato">{user?.occupation}</p>
                        </div>
                      </div>
                      <div className="row text-secondary">
                        <div className="col">
                          <h5 className="lato">Interests:</h5>
                        </div>
                        <div className="col-12 col-md-9 d-flex">
                          <div className="col-12 col-md-9">
                            <p className="lato">{user?.interest?.name}</p>
                          </div>
                        </div>
                      </div>
                      <div className="row text-secondary">
                        <div className="col">
                          <h5 className="lato">Bio:</h5>
                        </div>
                        <div className="col-12 col-md-9">
                          <p
                            className="lato"
                            dangerouslySetInnerHTML={{
                              __html: formdata.userbio,
                            }}
                          ></p>
                        </div>
                      </div>
                      <div className="row text-secondary">
                        <div className="col">
                          <h5 className="lato">Mobile Number:</h5>
                        </div>
                        <div className="col-12 col-md-9">
                          <p className="lato">{user?.phone}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {course ? (
                    <div className="container profile_container my-3 py-3">
                      <div className="row pt-2 g-3">
                        {showOrder && showOrder.length > 0 ? (
                          showOrder?.map((item, index) => (
                            <div
                              className="col-lg-4"
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="card">
                                <div
                                  className="perty"
                                  onClick={() => handleNavigation(item)}
                                >
                                  <div className="imgr card-img-top overflow-hidden">
                                    <img
                                      src={item?.orderItems?.image}
                                      className="d-block w-100 "
                                      alt="..."
                                      style={{
                                        height: "300px",
                                        aspectRatio: "3/2",

                                        objectFit: "cover",
                                        // borderRadius: "20px",
                                      }}
                                    />
                                  </div>
                                  <div className="card-body">
                                    <h4
                                      className=" pt-2"
                                      style={{ fontFamily: "myFont" }}
                                    >
                                      {item?.orderItems?.name}
                                    </h4>
                                    <span>
                                      Purchased At :
                                      <Moment format="D MMM YYYY" withTitle>
                                        {item?.createdAt}
                                      </Moment>
                                    </span>
                                    <br />
                                    <span>
                                      Month Validity :
                                      {item?.orderItems?.monthValidity} Months
                                    </span>
                                    <br />
                                    <span>
                                      Type :{" "}
                                      {item?.orderType
                                        ?.charAt(0)
                                        .toUpperCase() +
                                        item?.orderType?.slice(1).toLowerCase()}
                                    </span>
                                    <br />
                                    <span>
                                      Expires On:{" "}
                                      {` ${formatFutureDate(
                                        calculateDaysLeft(item),
                                        item
                                      )}`}
                                      {` ${formatDaysLeft(
                                        calculateDaysLeft(item)
                                      )}`}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">No data exists</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {music || song || wellness || bhagwat || events ? (
                    <div className="container profile_container my-3 py-3">
                      <div className="row pt-2 g-3">
                        {showOrder && showOrder.length > 0 ? (
                          showOrder?.map((item, index) => (
                            <div
                              className="col-lg-4"
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="card">
                                <div
                                  className="perty"
                                  onClick={() => handleNavigation(item)}
                                >
                                  <div className="imgr card-img-top overflow-hidden">
                                    <img
                                      src={item?.orderItems?.image}
                                      className="d-block w-100 "
                                      alt="..."
                                      style={{
                                        height: "300px",
                                        aspectRatio: "3/2",

                                        objectFit: "cover",
                                        // borderRadius: "20px",
                                      }}
                                    />
                                  </div>
                                  <div className="card-body">
                                    <h4
                                      className=" pt-2"
                                      style={{ fontFamily: "myFont" }}
                                    >
                                      {item?.orderItems?.name}
                                    </h4>
                                    <span>
                                      Purchased At :
                                      <Moment format="D MMM YYYY" withTitle>
                                        {item?.createdAt}
                                      </Moment>
                                    </span>
                                    <br />
                                    {/* <span>
                                      Month Validity :
                                      {item?.orderItems?.monthValidity} Months
                                    </span> */}
                                    {/* <br /> */}

                                    <span>
                                      Type :{" "}
                                      {item?.orderType === "music"
                                        ? "Album"
                                        : item?.orderType === "song"
                                        ? "Song"
                                        : item?.orderType
                                            ?.charAt(0)
                                            .toUpperCase() +
                                          item?.orderType
                                            ?.slice(1)
                                            .toLowerCase()}
                                    </span>
                                    {/* <br /> */}
                                    {/* <span>
                                      Expires On:{" "}
                                      {` ${formatFutureDate(
                                        calculateDaysLeft(item),
                                        item
                                      )}`}
                                      {` ${formatDaysLeft(calculateDaysLeft(item))}`}
                                    </span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">No data exists</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {question || thought ? (
                    <>
                      {fetchPostData?.map((item, index) => (
                        <PostItem key={item._id} listItem={item} />
                      ))}
                      {hideLoadMore === false && (
                        <div className="container  my-3 py-3">
                          <div className="row">
                            <div className="col-12">
                              {fetchPostloading ? (
                                <div className="d-flex justify-content-center mt-5">
                                  <div
                                    className="spinner-border"
                                    role="status"
                                  ></div>
                                </div>
                              ) : (
                                <div
                                  className="pt-5 d-flex justify-content-center"
                                  onClick={() =>
                                    callFetchPost(
                                      question ? "question" : "thought"
                                    )
                                  }
                                >
                                  <button className="btn shadowbuttons btn-lg text-light  cmn-t-shake my-2">
                                    Load More
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}

                  {answers ? (
                    <div className="profile_container  my-4">
                      <h2 className="fw-bold fs-3 px-4 py-2">My Answers</h2>
                      <hr />
                      <section className=" ">
                        <ul className="timeline-with-icons mx-5 list-unstyled vertical-line">
                          {Array.isArray(userAnswer) &&
                            userAnswer?.map((item, index) => (
                              <li
                                key={index}
                                className="timeline-item my-4 vertical-line-color px-4"
                              >
                                <div className="w-50">
                                  {item?.fileUrls?.images?.length > 0 && (
                                    <AppCarousel
                                      id={item._id}
                                      images={item?.fileUrls?.images.map(
                                        (item, index) => item.url
                                      )}
                                    />
                                  )}

                                  {item?.fileUrls?.audio && (
                                    <div className="d-flex justify-content-center mb-5">
                                      <audio
                                        controlsList="nodownload"
                                        controls
                                        src={item?.fileUrls?.audio}
                                      />
                                    </div>
                                  )}

                                  {item?.fileUrls?.video && (
                                    <MediaPlayer
                                      title={`Video by ${item?.createdBy?.name}`}
                                      src={item?.fileUrls?.video}
                                    >
                                      <MediaProvider />
                                      <DefaultVideoLayout
                                        icons={defaultLayoutIcons}
                                      />
                                    </MediaPlayer>
                                  )}
                                </div>
                                <p className="lato mb-0 text-capitalize">
                                  {item?.description}
                                </p>
                                <p className="text-muted mb-2 fw-bold">
                                  <Moment
                                    className="fw-semibold"
                                    format="D MMM YYYY"
                                    withTitle
                                  >
                                    {item?.createdAt}
                                  </Moment>
                                </p>
                              </li>
                            ))}
                        </ul>
                        {hasMoreData ? (
                          <div className="d-flex justify-center my-2">
                            <button
                              className="btn shadowbuttons btn-lg lato text-light  cmn-t-shake mx-auto profile-loadmore profile-loadmore my-2"
                              onClick={handleLoadMoreAnswers}
                            >
                              Load More
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </section>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>

          {activeTab == "podcast" && (
            <div className="container-fluid">
              <h1 className="fs-3 lato me-auto ps-5 py-5 text-center">
                Sorry no Content Here
              </h1>
            </div>
          )}
          {activeTab == "bhagwat" && (
            <div className="container-fluid">
              <h1 className="fs-3 lato me-auto ps-5 py-5 text-center">
                Sorry no Content Here
              </h1>
            </div>
          )}
          {activeTab == "wellness" && (
            <div className="container-fluid">
              <h1 className="fs-3 lato me-auto ps-5 py-5 text-center">
                Sorry no Content Here
              </h1>
            </div>
          )}
          {activeTab == "donation" && (
            <div className="container-fluid">
              <h1 className="fs-3 lato me-auto ps-5 py-5 text-center">
                Sorry no Content Here
              </h1>
            </div>
          )}
          {/* <!-- Button trigger modal --> */}
          {/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
             </button> */}

          {/* <!-- Modal --> */}
          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Modal title
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">...</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="button" className="btn btn-primary">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoaderPage />
      )}
    </>
  );
}

export default Profile;
