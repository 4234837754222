import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
  isLoading: false,
  error: null,
  coinsValue: null,
};
export const getcoinsValue = createAsyncThunk(
  "config/getcoins",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(coinsValueStart());
      const response = await axios.get(`config/getcoins`);
      dispatch(coinsValueSuccess(response?.data?.coinsbanner));
    } catch (error) {
      dispatch(coinsValueFailure(error.response.data.message));
    }
  }
);

const coinsValueSlice = createSlice({
  name: "coins",
  initialState,

  reducers: {
    coinsValueStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    coinsValueSuccess(state, action) {
      state.coinsValue = action?.payload;
      state.isLoading = false;
    },
    coinsValueFailure(state, action) {
      state.coinsValue = null;
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const { coinsValueStart, coinsValueSuccess, coinsValueFailure } =
  coinsValueSlice.actions;

export default coinsValueSlice.reducer;
