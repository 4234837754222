import React, { useState, useCallback } from 'react';
import { render } from 'react-dom';
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'

function GalleryModal({ photos }) {
	console.log(photos,"photos");
	const [currentImages, setCurrentImages] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);
	const openLightBox = useCallback((event, { photo, index }) => {
		setCurrentImages(index);
		setViewerIsOpen(true)
	}, [])
const closeLightBox = ()=>{
	setCurrentImages(0);
	setViewerIsOpen(false);
}
	return (
		<div>
			<Gallery photos={photos} direction={'row'} onClick={openLightBox}  />
			<ModalGateway>
				{viewerIsOpen?(
					<Modal onClose={closeLightBox}  styles={{ modal: (base) => ({ ...base, height: '80vh' }) }}>
						<Carousel
						currentIndex={currentImages}
						views={photos.map(x=>({
							...x,
							srcset:x.srcSet,
							caption:x.title,
						}))}
						styles={{ view: (base) => ({ ...base, height: '20%' }) }}/>

					</Modal>
				):null
				}
			</ModalGateway>
		
		</div>
	)
}

export default GalleryModal
