import React from 'react'
import Rect22 from '../images/Rectangle22.png'
import bg from "../images/rectangle.png";
import MetaData from "../component/layout/MetaData";
const SubChapter = () => {
  return (
    <>
    <MetaData title="SubChapter" />
<div
        className=""
        style={{ backgroundSize: "cover", backgroundImage: `url(${bg})` }}
      >
        <div
          className="container d-flex align-items-center justify-content-center"
          style={{ minHeight: "80vh" }}
        >
          <div className="row ">
            <div className="col-12  h-100 text-center">
              <h2 className="fw-bold text-light">
                <span style={{ fontSize: "larger", fontSize: "65px" }}>
                  Experience The Geeta <br />
                  <span style={{ color: "#fedf89" }}> Anywhere, Anytime</span>
                </span>
              </h2>
              <button className="btn btn-light text-dark border rounded">
                Read Now
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className=" filterpad pt-5 shadow mx-auto" style={{ width: "50vw" }}>
        <div className="row pb-4 p-3">
          <div className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center">
            <select className="select border-dark rounded-pill p-2 w-100">
              <option value="English">Category</option>
              <option value="Hindi">Spiritual</option>
              <option value="Punjabi">Enlightned</option>
            </select>
          </div>
          <div className="col-lg-6 col-sm-12 col-md-12 d-flex justify-content-center">
            <select className="select border-dark rounded-pill p-2 w-100">
              <option value="English">Chapter</option>
              <option value="Hindi">Hindi</option>
              <option value="Punjabi">Punjabi</option>
            </select>
          </div>

          <div className="pt-3 d-flex justify-content-center">
            <a href="">
              <button
                className="btn btn-orange btn-lg text-light rounded-0 cmn-t-shake"
                style={{
                  backgroundColor: "#FF9933!important",
                  padding: "0.6rem 2.5rem",
                }}
              >
                SEARCH
              </button>
            </a>
          </div>
        </div>
      </div>

    <section className='pb-5'>
        <div className="container artvid mt-2">
            <div className="row g-5">
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block" >
              <iframe style={{width:"100%", height:"100%"}} src="https://www.youtube.com/embed/U6sU7v3wLjc?si=IYbG4uqi34cagXUJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block">
                <img src={Rect22} alt="Photo of sunset" />

                
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block" >
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/U6sU7v3wLjc?si=IYbG4uqi34cagXUJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block">
                <img src={Rect22} alt="Photo of sunset" />

                
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block" >
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/U6sU7v3wLjc?si=IYbG4uqi34cagXUJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block">
                <img src={Rect22} alt="Photo of sunset" />

                
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block" >
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/U6sU7v3wLjc?si=IYbG4uqi34cagXUJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block">
                <img src={Rect22} alt="Photo of sunset" />

                
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-lg-4 item">
              <div className="card item-card card-block" >
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/U6sU7v3wLjc?si=IYbG4uqi34cagXUJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                
              </div>
            </div>
            </div>
        </div>
    </section>
    
    
    </>
  )
}

export default SubChapter
