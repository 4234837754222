import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ".././component/layout/style.css";
import '../component/auth/auth.css';
import { SHOW_ERROR_NOTIFICATION } from '../appUtils/appToast';
import { addComment, likePost } from '../Allslices/communitySlice';
import { allComments } from '../Allslices/communitySlice';
import AppCarousel from '../component/shared/AppCarousel';
import 'react-toastify/dist/ReactToastify.css';
import { fetchPostResetPage, fetchPosts,getSinglePost } from "../Allslices/communitySlice";
import PostItem from "./post/PostItem";
import MetaData from "../component/layout/MetaData";
const Community = () => {
	const url= window.location.href;
	const postId = url.split('/')[4]
	const [showAddPost, setShowAddPost] = useState(false);
	const [feedType, setFeedType] = useState('all');
	const dispatch = useDispatch();

	const userImage = localStorage.getItem('userImage');

	const {singlePost} = useSelector((state) => state.community);
	const { fetchPostState: { loading: fetchPostloading, data: fetchPostData, page: postPage, hideLoadMore } } = useSelector((state) => state.community);
	

	const callFetchPost = useCallback((type) => {
		dispatch(fetchPosts({type})).then((resultAction) => {
			if (resultAction?.error) {
				SHOW_ERROR_NOTIFICATION(resultAction.payload);
				return;
			}
		})
	}, []);

	const changeFeedType = useCallback((type) => {
		setFeedType(type);
		dispatch(fetchPostResetPage());
		callFetchPost(type);
	}, [feedType]);

	useEffect(() => {
		dispatch(getSinglePost(postId));
	
	
	}, [postId]);


	return (
		<>
		<MetaData title="Community" />
		<div>
			<section className="py-5">
				<div className="container">
					<div className="row">
						<div className="col-lg-9 col-sm-12 col-md-10">
					

							<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
								<div className="modal-dialog modal-dialog-centered" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<button type="button" className="close" data-dismiss="modal" aria-label="Close" style={{ border: 'none', background: 'white', fontSize: '30px' }}>
												<span aria-hidden="true">&times;</span>
											</button>
											<button className="btn shadowbuttons">Upload File</button>
										</div>
										<div className="modal-body">
											...
										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-transparent" data-dismiss="modal">Cancel</button>
											<button type="button" className="btn shadowbuttons">Add A Comment</button>
										</div>
									</div>
								</div>
							</div>

							{singlePost?.postList?.map((item, index) => (<PostItem key={item._id} listItem={item} />))}
							
							
							
						</div>

					</div>
				</div>
			</section >
			
			

		</div >
		</>
	);
}

export default Community;
