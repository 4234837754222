import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
    isLoading: false,
    error: null,
    gallery: [],
    totalgallerylist: 0,
    totalgallerycategory: 0,
    limit: 6,
    singlegallerylist: {},
    gallerybanner: [],
    gallerycategory: null,
    currentPage: 0,
    totalgallerycategory: 0,
    singlecategory: [],
}
export const getAllGalleryList = createAsyncThunk(
    'gallery/all_gallerylist',
    async (page, { dispatch, rejectWithValue }) => {
        // const { page } = getState().gallerylist;
        let limit = 10
        try {
            dispatch(getallgallerylistStart());
            const response = await axios.get(`gallery/all_gallerylist?page=${page?? + 1}&limit=${limit??""}`)
            dispatch(getallgallerylistSuccess(response?.data))
            return response.data;
        } catch (error) {
            dispatch(getallgallerylistfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const getsinglegallerylist = createAsyncThunk(
    'gallerylist/singlegallerylist',
    async (slug, { dispatch, rejectWithValue }) => {

        try {
            dispatch(singlegalleryliststart());
            const response = await axios.get(`gallery/${slug}`)
            dispatch(singlegallerylistsuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(singlegallerylistfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
export const getsinglegallerycategory = createAsyncThunk(
    'gallery/singlecategory',
    async (slug, { dispatch, rejectWithValue }) => {

        try {
            dispatch(singlegallerycategorystart());
            const response = await axios.get(`gallery/singlecategory/${slug}`)
            dispatch(singlegallerycategorysuccess(response?.data))
            return response?.data;
        } catch (error) {
            dispatch(singlegallerycategoryfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)
export const getGalleryBanner = createAsyncThunk(
    'config/get_gallerybanner',
    // async (_, { dispatch,getState, rejectWithValue }) => {
    async (_, { dispatch, rejectWithValue }) => {
        // const { page } = getState().gallerylist;
        try {
            dispatch(gallerybannerStart());
            const response = await axios.get(`config/get_gallerybanner`)
            dispatch(gallerybannerSuccess(response?.data?.gallerybanner))
            return response.data;
        } catch (error) {
            dispatch(gallerybannerfailure(error.response.data.message));
            // return rejectWithValue(error.response.data.message)
        }
    }
)

export const getAllGalleryCategory = createAsyncThunk(
    'gallery/all_gallerycategory',
    async ({page, limit}, { dispatch, rejectWithValue }) => {
        try {
            dispatch(getallgalleryCategoryStart());
            const response = await axios.get(`gallery/all_gallerycategory?page=${page ?? + 1}&limit=${limit}`)
            dispatch(getallgalleryCategorySuccess(response?.data))
            return response.data;
        } catch (error) {
            dispatch(getallgalleryCategoryfailure(error.response.data.message));
            return rejectWithValue(error.response.data.message)
        }
    }
)

const gallerySlice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
        getallgallerylistStart(state) {
            state.isLoading = true;
            state.error = null;
            state.gallerylist = []
        },
        getallgallerylistSuccess(state, action) {
            state.isLoading = false;
            state.totalgallerylist = action.payload.totalgallerylists

        },
        getallgallerylistfailure(state, action) {
            state.error = action.payload;
            state.isLoading = false;
            state.singlegallerylist = null
        },
        singlegalleryliststart(state, action) {

            state.isLoading = true;

        },
        singlegallerylistsuccess(state, action) {
            state.isLoading = false;
            state.singlegallerylist = action.payload;

        },
        singlegallerylistfailure(state, action) {
            state.isLoading = false;
            state.singlegallerylist = null
        },
        singlegallerycategorystart(state, action) {

            state.isLoading = true;

        },
        singlegallerycategorysuccess(state, action) {
            state.isLoading = false;
            state.singlecategory = action.payload;
            state.currentPage = action.payload.currentPage;
            state.limit = action.payload.limit;
            state.totalgallerycategory = action.payload.totalgallerycategory;

        },
        singlegallerycategoryfailure(state, action) {
            state.isLoading = false;
            state.singlecategory = null


        },
        gallerybannerStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        gallerybannerSuccess(state, action) {

            state.gallerybanner = action?.payload;
            state.isLoading = false;
        },
        gallerybannerfailure(state, action) {
            state.gallerybanner = null;
            state.isLoading = false;
            state.error = action.payload;
        },
        getallgalleryCategoryStart(state) {
            state.isLoading = true;
            state.error = null;
            state.totalgallerycategory = [];
            state.gallerycategory = []

        },
        getallgalleryCategorySuccess(state, action) {
            state.isLoading = false;
            state.currentPage = action.payload.currentPage;
            state.limit = action.payload.limit;
            state.totalgallerycategory = action.payload.totalgallerycategory;
            state.gallerycategory = action?.payload.allgallerycategories;
        },
        getallgalleryCategoryfailure(state, action) {
            state.gallerycategory = null;
            state.isLoading = false;
            state.error = action.payload;
        }
    }
})

export const { getallgallerylistStart, getallgallerylistSuccess, getallgallerylistfailure,
    singlegalleryliststart, singlegallerylistsuccess, singlegallerylistfailure, gallerybannerStart, gallerybannerSuccess, gallerybannerfailure, getallgalleryCategoryStart, getallgalleryCategorySuccess, getallgalleryCategoryfailure, singlegallerycategorystart, singlegallerycategorysuccess, singlegallerycategoryfailure
} = gallerySlice.actions;

export default gallerySlice.reducer;