import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../appUtils/axiosConfig";
const initialState = {
    isLoading: false,
    error:null,
    configdata:null,
    Bookdata: null,
    singledata:null,
    currentPage: 0,
    limit: 6,
};

export const getconfigBook = createAsyncThunk(
    "config/get_bookconfig",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            dispatch(configStart());
            const response = await axios.get(`config/get_bookconfig`);
            console.log("myresponse", response);
            dispatch(consfigSuccess(response?.data));
            return response.data;
        } catch (error) {
            const errorMessage = error.response ? error.response.data.message : error.message;
            dispatch(configFailure(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);


export const getBookPage = createAsyncThunk(
    "book/getallbooks",
    async ({page,limit}, { rejectWithValue, dispatch }) => {
        try {
            dispatch(BookStart());
            const response = await axios.get(`book/getallbooks?page=${page ?? + 1}&limit=${limit ?? ""}`);
            dispatch(BookSuccess(response.data));
            return response.data;
        } catch (error) {
            const errorMessage = error.response ? error.response.data.message : error.message;
            dispatch(BookFailured(errorMessage));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getsingleBook= createAsyncThunk(
    "book/",async(slug,{dispatch,rejectWithValue})=>{
try{
dispatch(singleStart())
const response= await axios.get(`book/${slug}`)
dispatch(singleSuccess(response.data))
return response.data
}catch(error){
const errorMessage= error.response?error.response.data.message: error.message
  dispatch(singleFailure(errorMessage))
  rejectWithValue(errorMessage)

}
    })



const BookSlice = createSlice({
    name: "Bookpage",
    initialState,
    reducers: {
        configStart(state,action){
            state.isLoading=true
        },
        consfigSuccess(state,action){
            state.isLoading=false;
            state.configdata=action.payload?.bookConfig;
            

        },
        configFailure(state,action){
            state.isLoading=false;
    
            state.configdata=null;

        },
        BookStart(state) {
            state.isLoading = true;
            state.error = null;
        },
        BookSuccess(state, action) {
            state.Bookdata = action.payload?.allBooks;
            state.isLoading = false;
            state.totalAllBooks = action.payload.totalAllBooks;
            state.currentPage = action.payload.currentPage;
            state.limit = action.payload.limit;
        },
        BookFailured(state, action) {
            state.isLoading = false;
            state.Bookdata = null;
           state.error = action.payload;
        },
        singleStart(state,action){
           state.isLoading=true
        },
        singleSuccess(state,action){
            state.stateisLoading=false;
            state.singledata=action.payload?.book
            
        },
        singleFailure(state,action){
            state.isLoading=false;
            state.singledata=null;
            state.error=action.payload;
        }
    },

});

export const { BookStart, BookSuccess, BookFailured,configFailure,consfigSuccess,configStart,singleStart,singleSuccess,singleFailure } = BookSlice.actions;

export default BookSlice.reducer;
