import React, { useState, useEffect } from "react";
// import "./style.css";
// import '.././component/layout/style.css'
import MetaData from "../component/layout/MetaData";
import { useSelector, useDispatch } from "react-redux";
import { getprivacyPolicy } from "../Allslices/configSlice";
import LoaderPage from "./SmallLoader";
const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { privacyPolicy, isLoading } = useSelector((state) => state.privacy);

  useEffect(() => {
    dispatch(getprivacyPolicy());
  }, []);



  return (
    <>
    <MetaData title="Privacy Policy" />
      {privacyPolicy ? (
        <>
          <div className="color-mixterm"></div>

          <section>
            <div className="container contpadi">
              <h1 className="pt-3 text-center haedingfont">
                <span >Privacy Policy</span>
              </h1>

              <p className="py-4 termcon_justify"  
              
              dangerouslySetInnerHTML={{
                __html: privacyPolicy,
              }}>
                
                   
                </p>
            </div>
          </section>
        </>
      ) : (
        <LoaderPage />
      )}
    </>
  );
};

export default PrivacyPolicy;
