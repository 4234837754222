import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentTrack: null,
  currentAlbum: null,
  isPlaying: false,
  isAlbumPurchased: false,
  isSongPurchased: false,
  musicBar: false,
  currentIndex: 0,
  trackList: [],
  repeatStatus: "OFF",
}

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    resetPlayer: (state) => {
      state.currentTrack = null;
      state.isPlaying = false;
      state.currentIndex = 0;
      state.trackList = [];
      state.repeatStatus = "OFF";
    },
    setCurrentTrack: (state, action) => {
      state.currentTrack = action.payload;
    },
    setCurrentAlbum: (state, action) => {
      state.currentAlbum = action.payload;
    },
    setAlbumPurchased(state, action) {
      state.isAlbumPurchased = action.payload;
    },
    setSongPurchased(state, action) {
      state.isSongPurchased = action.payload;
    },
    setPlaying: (state, action) => {
      state.isPlaying = action.payload;
    },
    setShowMusicBar: (state, action) => {
      state.musicBar = action.payload;
    },
    playTrack: (state, action) => {
      state.currentTrack = action.payload;
      state.isPlaying = true;
      state.musicBar = true;
    },
    pauseTrack: (state, action) => {
      state.currentTrack = action.payload;
      state.isPlaying = false;
    },
    nullTrack: (state, action) => {
      state.currentTrack = action.payload;
      state.isPlaying = false;
    },
    setTrackList: (state, action) => {
      if (Array.isArray(action.payload.list)) {
        state.trackList = action.payload.list;
      } else {
        state.trackList = [action.payload.list];
      }
      state.currentIndex = action.payload.index ? action.payload.index : 0;
      localStorage.setItem("currentindex", state.currentIndex);
    },
    nextTrack: (state) => {
      if (state.currentIndex >= state.trackList.length - 1) {
        state.currentIndex = 0;
        state.currentTrack = state.trackList[0];
      } else {
        state.currentTrack = state.trackList[state.currentIndex + 1];
        state.currentIndex += 1;
        localStorage.setItem("currentindex", state.currentIndex);
      }
    },
    prevTrack: (state) => {
      if (state.currentIndex === 0) {
        state.currentIndex = state.trackList.length - 1;
        state.currentTrack = state.trackList[state.trackList.length - 1];
      } else {
        state.currentTrack = state.trackList[state.currentIndex - 1];
        state.currentIndex -= 1;
        localStorage.setItem("currentindex", state.currentIndex);
      }
    },
  },
});

export const {
  setAlbumPurchased,
  setSongPurchased,
  nullTrack,
  resetPlayer,
  setCurrentTrack,
  setCurrentAlbum,
  setPlaying,
  setShowMusicBar,
  playTrack,
  pauseTrack,
  setTrackList,
  nextTrack,
  prevTrack,
} = playerSlice.actions;

export default playerSlice.reducer;
