import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "../appUtils/axiosConfig";

const initialState = {
    isLoading: false,
    error: null,
    singlesong: null,
allsongs:null,
}

// export const getsingleSong = createAsyncThunk('song/singlesong', async ({albumid,songid}, { dispatch, rejectWithValue }) => {
export const getsingleSong = createAsyncThunk('album/:albumId/songs/:songId', async ({ albumid, songid }, { dispatch, rejectWithValue }) => {
    try {
        dispatch(singlesongStart());
        const response = await axios.get(`album/${albumid}/songs/${songid}`)
        dispatch(singlesongSuccess(response?.data))
        return response?.data
    } catch (error) {
        dispatch(singlesongFailure(error.response.data.message))
        return rejectWithValue(error.response.data.message)
    }
})
//all songs
export const getAllSongs = createAsyncThunk('album/song/all', async (_, { dispatch, rejectWithValue }) => {
    try {
        dispatch(allsongStart());
        const response = await axios.get(`album/song/all`)
        dispatch(allsongSuccess(response?.data))
        return response?.data
    } catch (error) {
        dispatch(allsongFailure(error.response.data.message))
        return rejectWithValue(error.response.data.message)
    }
})


const songSlice = createSlice({
    name: 'song',
    initialState,
    reducers: {
        singlesongStart(state) {
            state.isLoading = true;
            state.error = null;
            // state.singlesong = null;
        },
        singlesongSuccess(state, action) {
            state.isLoading = false;
            state.singlesong = action.payload;
        },
        singlesongFailure(state, action) {
            state.isLoading = false;
            state.singlesong = null
        },
        allsongStart(state) {
            state.isLoading = true;
            state.error = null;
            // state.singlesong = null;
        },
        allsongSuccess(state, action) {
            state.isLoading = false;
            state.allsongs = action?.payload?.songs;
        },
        allsongFailure(state, action) {
            state.isLoading = false;
            state.allsongs = null
        },
    }
})

export const { singlesongStart, singlesongSuccess, singlesongFailure,allsongStart,allsongSuccess,allsongFailure } = songSlice.actions;
export default songSlice.reducer;