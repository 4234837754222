import React, { useState, useRef, useEffect } from "react";
import rect259 from "../images/Rectangle 259.png";
import ".././component/layout/style.css";
import {
  nextTrack,
  prevTrack,
  setCurrentTrack,
  setPlaying,
  setShowMusicBar,
  setTrackList,
} from "../Allslices/playerSlice";
import { BiSolidPlaylist } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import Playcontrols from "./Playcontrols";
import VolumeControl from "./VolumeControl";
import PlayingBar from "./PlayingBar";
import { likealbum } from "../component/auth/authSlice";
import {
  SHOW_ERROR_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../appUtils/appToast";
import { getsinglealbum } from "../Allslices/albumSlice";

const MainMusicPagePlayer = ({
  audioRef,
  songDetails,
  setSongDetails,
  id,
  isPurchased,
}) => {
  const dispatch = useDispatch();
  const { currentTrack, repeatStatus, currentIndex, trackList, isPlaying } =
    useSelector((state) => state.player);
  const { artiste } = useSelector((state) => state.album);
  console.log("artiste11", artiste);
  console.log("The songDetails value is:", songDetails, setSongDetails);
  const { isLoading, user } = useSelector((state) => state?.auth);

  const isEndOfTracklist = currentIndex === trackList?.length - 1;

  useEffect(() => {
    if (!isPlaying) {
      if (id) {
        dispatch(getsinglealbum(id))
          .then((res) => {
            const songList = res?.payload?.songList;
            if (songList && songList.length > 0) {
              dispatch(setTrackList({ list: songList }));
              dispatch(setCurrentTrack(songList[0]));
            } else {
              console.error("Song list is empty or undefined");
            }
          })
          .catch((error) => {
            console.error("Error fetching album:", error);
          });
      }
    }
    // }, [dispatch, id, isPlaying]);
  }, [id]);

  




  const changeVolume = (value) => {
    const newVolume = value / 100;

    if (!isNaN(newVolume) && isFinite(newVolume)) {
      setSongDetails((prevValues) => {
        return { ...prevValues, volume: newVolume };
      });

      audioRef.current.volume = newVolume;
    }
  };

  const volumeToggle = () => {
    if (songDetails?.volume > 0) {
      setSongDetails((prev) => ({ ...prev, volume: 0 }));
      audioRef.current.volume = 0;
    } else {
      setSongDetails((prev) => ({ ...prev, volume: 1 }));
      audioRef.current.volume = 1;
    }
  };

  const handlePlayPause = () => {
    if (artiste?.isPaid && !isPurchased) {
      return; // Do not play the song if the artist is paid and the album is not purchased
    }

    if (isPlaying) {
      audioRef?.current.pause();
      dispatch(setPlaying(false));
      dispatch(setShowMusicBar(false));
    } else {
      audioRef?.current.play();
      dispatch(setPlaying(true));
      dispatch(setShowMusicBar(true));
    }
  };


  const handleNextSong = () => {
    if (trackList.length == 1) {
      restartSong();
    } else {
      dispatch(nextTrack());

    }
  };

  const handlePreviousSong = () => {
    if (trackList.length == 1) {
      restartSong();
    } else {
      dispatch(prevTrack());
    }
  };

  const restartSong = () => {
    setSongDetails((prev) => {
      return { ...prev, time: 0 };
    });
    audioRef.current.currentTime = 0;
    audioRef.current.play();
  };

  const handleLike = () => {
    dispatch(likealbum(id)).then((result) => {
      if (result?.error) {
        SHOW_ERROR_NOTIFICATION(result.payload);
        return;
      }
      SHOW_SUCCESS_NOTIFICATION("Your favorites have been updated");
    });
  };

  const handleEnded = () => {
    switch (repeatStatus) {
      case "OFF":
        if (!isEndOfTracklist) {
          handleNextSong();
        }
        break;
      case "TRACKLIST":
        handleNextSong();
        break;
      case "SINGLE":
        audioRef.current.play();
        break;

      default:
        break;
    }
  };

  const seekPoint = (e) => {
    audioRef.current.currentTime = (e / 100) * audioRef.current.duration;

    setSongDetails((prev) => ({
      ...prev,
      time: Math.round(
        (audioRef.current.currentTime / audioRef.current.duration) * 100
      ),
    }));
  };

  return (
    <>
      <div className="music container rounded-3">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-1 col-3 text-center mb-3 mt-lg-1 mt-1 mb-lg-0">
              <img
                src={
                  currentTrack?.thumbnail_image
                    ? currentTrack?.thumbnail_image
                    : rect259
                }
                style={{ height: "60px", width: "60px", borderRadius: "50%" }}
                alt=""
                height={100}
                width={120}
              />
            </div>
            <div className="col-lg-3 col-9 d-flex text-center flex-column mb-3 mb-lg-0">
              <h5 className="text-center mb-0">
                {currentTrack?.title}
              </h5>
              <a className="text-center" href="#">
                {currentTrack?.artist?.map((item) => item.title).join(", ")}
              </a>
            </div>
            <div className="col-lg-4 col-sm-3 col-12 mb-3 mb-lg-0">
              <PlayingBar
                onSeek={seekPoint}
                time={songDetails?.time}
                track={currentTrack}
                trackRef={audioRef?.current}
              />
            </div>
            <div className="col-lg-3 col-sm-6 col-12 d-flex gap-3 justify-content-center mb-3 mb-lg-0">
              <Playcontrols
                audioRef={audioRef}
                isPlaying={isPlaying}
                onNext={handleNextSong}
                onPlay={handlePlayPause}
                onPrevious={handlePreviousSong}
                repeatStatus={repeatStatus}
                isPurchased={isPurchased}
              />
              <div className="PB-range-slider-div">
                <VolumeControl
                  onChange={(value) => changeVolume(value)}
                  onToggle={volumeToggle}
                  volume={songDetails ? songDetails?.volume : 0}
                />
              </div>
            </div>
            <div className="col-lg-1 col-sm-3 col-12 text-center d-none d-lg-block">
              <span>
                <BiSolidPlaylist
                  style={{
                    height: "38px",
                    width: "38px",
                    marginLeft: "30px",
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainMusicPagePlayer;
